
import React, { useState, useEffect,useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CustomerService } from '../service/CustomerService';
import { Toast } from 'primereact/toast';

const DataTableFilterDemo = (props) => {
    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [loading1, setLoading1] = useState(true);
    const [reload, setReload] = useState(true)
    const [sociosPorAsociacion, setSociosPorAsociacion] = useState([])
    const toast = useRef(null);
    const TERCEROS = 'https://www.ancogenecop.com/APIANCO/controllers/terceros.php?op='

    const customerService = new CustomerService();
    useEffect(() => {
        if(props.NID_ASOCIACION==0) return
                const recarga = async (nidAsociacion) => {
                    //setSociosAsocia(false)
                    const data = new FormData();
                    data.append("NID_TERCERO",nidAsociacion)
                    await fetch(TERCEROS + 'tercerosSocios', {
                        method: 'POST',
                        header: {
                            'Accept': 'application/json',
                            'Content-type': 'application/json'
                        },
                        body: data
                    }).then((response) => response.json())
                        .then((response) => {
                            console.log(response)
                            if (response.length>0) {
                                console.log(response);
                                setSociosPorAsociacion(response)
                                customerService.getCustomersLarge().then(setLoading1(false) );
                                initFilters1();
                                return props.setMuestraTabla(true)
                            }else{
                                return props.setMuestraTabla(false)
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
                recarga(props.NID_ASOCIACION)
        }, [reload,props.NID_ASOCIACION])



    const clearFilter1 = () => {
        initFilters1();
    }

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }


    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        setGlobalFilterValue1('');
    }

    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Borrar filtro" className="p-button-outlined" onClick={clearFilter1} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Buscar" />
                </span>
            </div>
        )
    }

    const header1 = renderHeader1();

    return (
    <>
        <div className="datatable-filter-demo">
        <Toast ref={toast} />
                <div className="card">
                <h5>Socios por asociación</h5>
                <DataTable value={sociosPorAsociacion} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} hasta {last} de {totalRecords} asociados"
                    dataKey="id" filters={filters1} filter filterDisplay="menu" loading={loading1} responsiveLayout="scroll"
                    globalFilterFields={['NOMBRE', 'NID_TERCERO', 'TIPO_SOCIO', 'TIPO_TERCERO',
                    'MUNICIPIO','DEPARTAMENTO','TELEFONOS','FECHA_INGRESO','FECHA_RETIRO','TESTADO']} header={header1} emptyMessage="No customers found.">
                    <Column field="NID_TERCERO" header="NID_TERCERO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="NOMBRE" header="NOMBRE"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="FECHA_INGRESO" header="FECHA_INGRESO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="FECHA_RETIRO" header="FECHA_RETIRO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="TIPO_SOCIO" header="TIPO_SOCIO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="TIPO_TERCERO" header="TIPO_TERCERO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="DEPARTAMENTO" header="DEPARTAMENTO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="MUNICIPIO" header="MUNICIPIO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="TELEFONOS" header="TELEFONOS"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="TESTADO" header="ESTADO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                </DataTable>
            </div>

       </div>
      </>
    );
}
export default React.memo(DataTableFilterDemo);
