import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { locale, addLocale } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
 import SeleccionUsuariosDenunciaA04 from '../components/SeleccionUsuariosDenunciaA04';
import SeleccionCodigosDenunciaA04 from '../components/SeleccionCodigosDenunciaA04';
import SeleccionTipoDocumento from '../components/SeleccionTipoDocumento';
import SeleccionConsecutivoServicioDenunciaA04 from '../components/SeleccionConsecutivoServicioDenunciaA04';
import SeleccionFormularios from '../components/SeleccionFormularios';
import SeleccionSexo from '../components/SeleccionSexo';
import SeleccionTipoParto from '../components/SeleccionTipoParto';
import SeleccionHembrasA01a from '../components/SeleccionHembrasA01a';
import Lateral from '../components/Lateral';
import Ayuda from '../components/Ayuda' 

const FormularioA04 = () => {
    let emptyProduct = {
        TID_PROPIETARIO: '',
        NID_PROPIETARIO: '',
        CONSECUTIVO: '',
        CONSECUTIVOADICIONAL: '',
        FORMA_CONCEPCION: '',
        CONSECUTIVO_SERVICIO: '',
        FECHA_SOLICITUD: '',
        COD_ESPECIE: '',
        FECHA_PARTO: '',
        SEXO:'',
        SEXO_ADICIONAL:'',
        RAZA_MACHO:'',
        CHIP_MACHO: '',
        NOMBREMACHO:'',
        CHIP_HEMBRA: '',
        NOMBREHEMBRA:'',
        TIPO_DE_PARTO: '',
        PESO_AL_NACER: '',
        PESO_AL_NACER_ADICIONAL: '',
        OBSERVACIONES: '',
        PREFIJO_SOLICITANTE: '',
        RAZA_MACHO: '',
        NID_REGISTRADOR: '',
        NOMBRE_MACHO:'',
        NOMBRE_HEMBRA:'',
        NOM_ESPECIE:''
    };
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [muestraCodigos, setMuestraCodigos] = useState(false)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [fechaServicio, setFechaServicio] = useState('')
    const [fechaSolicitud, setFechaSolicitud] = useState('')
    const [codigoEspecie, setCodigoEspecie] = useState('')
    const [muestraRaza, setMuestraRaza] = useState(false)
    const [codigoRaza, setCodigoRaza] = useState(0)
    const [parentesco, setParentesco] = useState('')
    const [muestraParentesco, setMuestraParentesco] = useState(false)
    const [reloadPrefijo, setReloadPrefijo] = useState(false)
    const [prefijo, setPrefijo] = useState('')
    const [enviar, setEnviar] = useState(false)
    const [muestraConsecutivo, setMuestraConsecutivo] = useState(false)
    const [consecutivoServicio, setConsecutivoServicio] = useState(0)
    const [reloadDatosConsecutivo, setReloadDatosConsecutivo] = useState(false)
    const [mdatosConsecutivo, setMdatosConsecutivo] = useState([])
    const [codigo, setCodigo] = useState(0)
    const [muestraHembras, setMuestraHembras] = useState(false)
    const [editProductDialog, setEditProductDialog] = useState(false)
    const [consecutivo, setConsecutivo] = useState(0)
    const [sexoAdicional, setSexoAdicional] = useState(false)
    const [tidPropietario, setTidPropietario] = useState('')
    const [muestraUsuario, setMuestraUsuario] = useState(false)
    const [cambiaConcepcion, setCambiaConcepcion] = useState(false)
    const [razaMacho, setRazaMacho] = useState('')
    const [value1, setValue1] = useState(true)
    const toast = useRef(null);
    const dt = useRef(null);
    const [ayuda, setAyuda] = useState(false)    
    const APIFORMULARIOA04 = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA04.php?op='

    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                setUsuariosAnco([])
                const data = new FormData();
                if(localStorage.getItem('administrador')==1){
                    if(nidPropietario==''){
                        data.append('NID_PROPIETARIO','ADMINANCO')
                    }else{
                        data.append('NID_PROPIETARIO',nidPropietario)
                    }
                  }else{
                    data.append('NID_PROPIETARIO',localStorage.getItem('nid_propietario'))
                  }
                await fetch(APIFORMULARIOA04 + 'formulariosA04', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setUsuariosAnco(response)
                        }
                    setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    useEffect(() => {
        if (reloadDatosConsecutivo) {
            setReloadDatosConsecutivo(false)
            const recarga = async (consecutivoServicio,codigo,consecutivo) => {
            if(codigo==0) return
            if(cambiaConcepcion){
                if(codigo=='MD'){
                    codigo='A01'
                }else if(codigo=='IA'){
                    codigo='A02'
                }else if(codigo=='TE'){
                    codigo='A03'
                }else{
                    codigo='A01a'
                }
            }
                setUsuariosAnco([])
                const data = new FormData();
                data.append("CONSECUTIVO", consecutivo)
                data.append("CONSECUTIVO_SERVICIO", consecutivoServicio)
                data.append("NID_PROPIETARIO", nidPropietario)
                data.append("CODIGO", codigo)
                await fetch(APIFORMULARIOA04 + 'datosConsecutivoServicio', {
                    method: 'POST',
                    header: {

                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response!=[]) {
                        console.log("object")
                        console.log(response)
                            product.NOMBRE_MACHO = response[0].NOMBREMACHO
                            product.CHIP_MACHO = response[0].CHIP_MACHO
                            product.PREFIJO_SOLICITANTE = response[0].PREFIJO_SOLICITANTE
                            product.DESCRIPCION = response[0].NOM_ESPECIE
                            product.RAZA_MACHO = response[0].RAZA_MACHO
                            product.COD_ESPECIE = response[0].COD_ESPECIE
                            if(codigo!='A01a'){
                                product.NOMBRE_HEMBRA = response[0].NOMBREHEMBRA                            
                                product.CHIP_HEMBRA = response[0].CHIP_HEMBRA
                            }
                            setReloadUsuarios(true)
                            setMuestraParentesco(true)
                            setMdatosConsecutivo(response)

                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga(consecutivoServicio,codigo,consecutivo)
        }
    }, [reloadDatosConsecutivo, consecutivoServicio,codigo,consecutivo])

    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductAyuda = () => {
        setAyuda(false);
    }

    const deleteProductDialogAyuda = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
        </>
    );

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false)
        setMuestraRaza(false)
        setMuestraParentesco(false)
        setProductDialog(true);
        setEnviar(true)
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false);
        setMuestraParentesco(false)
    }

    const hideDeleteProductDialog = () => {
        setEditProductDialog(false);
    }

    const saveProduct = async () => {
        setSubmitted(true)
        if(product.CONSECUTIVO=='') {console.log(1);return}
         if(product.TID_PROPIETARIO=='') {console.log(2); return }
         if(product.FORMA_CONCEPCION=='') {console.log(3); return}
         if(product.CONSECUTIVO_SERVICIO=='') {console.log(4); return}
         if(product.FECHA_SOLICITUD=='') {console.log(5); return}
         if(product.COD_ESPECIE=='') {console.log(6); return}
         if(product.FECHA_PARTO=='') {console.log(7); return}
         if(product.SEXO=='') {console.log(8); return}
         if(product.RAZA_MACHO=='') {console.log(9); return }
         if(product.CHIP_MACHO=='') {console.log(10); return}
         if(product.CHIP_HEMBRA=='') {console.log(11); return}
         if(product.TIPO_DE_PARTO=='') {console.log(12); return}
         if(product.PESO_AL_NACER=='') {console.log(13); return}
         if(product.PREFIJO_SOLICITANTE=='') {console.log(14); return}

        const data = new FormData();
        if(product.FORMA_CONCEPCION=='A01'){
            product.FORMA_CONCEPCION='MD'
        }else if(product.FORMA_CONCEPCION=='A01a'){
            product.FORMA_CONCEPCION='MDG'
        }if(product.FORMA_CONCEPCION=='A02'){
            product.FORMA_CONCEPCION='IA'
        }if(product.FORMA_CONCEPCION=='A03'){
            product.FORMA_CONCEPCION='TE'
        }
        data.append("TID_PROPIETARIO", product.TID_PROPIETARIO)
        data.append("NID_PROPIETARIO", nidPropietario)
        data.append("CONSECUTIVO", product.CONSECUTIVO)
        data.append("FORMA_CONCEPCION", product.FORMA_CONCEPCION)
        data.append("CONSECUTIVO_SERVICIO", product.CONSECUTIVO_SERVICIO)
        data.append("FECHA_SOLICITUD", product.FECHA_SOLICITUD)
        data.append("COD_ESPECIE", product.COD_ESPECIE)
        data.append("FECHA_PARTO", product.FECHA_PARTO)
        data.append("SEXO", product.SEXO)
        data.append("RAZA_MACHO", product.RAZA_MACHO)
        data.append("CHIP_MACHO", product.CHIP_MACHO)
        data.append("CHIP_HEMBRA", product.CHIP_HEMBRA)
        data.append("TIPO_DE_PARTO", product.TIPO_DE_PARTO)
        data.append("PESO_AL_NACER", product.PESO_AL_NACER)
        data.append("OBSERVACIONES", product.OBSERVACIONES)
        data.append("PREFIJO_SOLICITANTE", product.PREFIJO_SOLICITANTE)
        data.append("NID_REGISTRADOR", localStorage.getItem("nid_propietario"))
        data.append("CODIGO", 'A04')
        await fetch(APIFORMULARIOA04 + 'ingresarNacimiento', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setMuestraParentesco(false)
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const saveProductAdicional = async () => {
        setSubmitted(true);
        const data = new FormData();
        data.append("TID_PROPIETARIO", product.TID_PROPIETARIO)
        data.append("NID_PROPIETARIO", nidPropietario)
        data.append("CONSECUTIVONEW", product.CONSECUTIVOADICIONAL)
        data.append("CONSECUTIVO", product.CONSECUTIVO)
        data.append("FORMA_CONCEPCION", product.FORMA_CONCEPCION)
        data.append("CONSECUTIVO_SERVICIO", product.CONSECUTIVO_SERVICIO)
        data.append("FECHA_SOLICITUD", product.FECHA_SOLICITUD)
        data.append("COD_ESPECIE", product.COD_ESPECIE)
        data.append("FECHA_PARTO", product.FECHA_PARTO)
        data.append("SEXO", product.SEXO_ADICIONAL)
        data.append("RAZA_MACHO", product.RAZA_MACHO)
        data.append("CHIP_MACHO", product.CHIP_MACHO)
        data.append("CHIP_HEMBRA", product.CHIP_HEMBRA)
        data.append("TIPO_DE_PARTO", product.TIPO_DE_PARTO)
        data.append("PESO_AL_NACER", product.PESO_AL_NACER_ADICIONAL)
        data.append("OBSERVACIONES", product.OBSERVACIONES)
        data.append("PREFIJO_SOLICITANTE", product.PREFIJO_SOLICITANTE)
        data.append("CODIGO", 'A04')
        data.append("SIGLA", product.sigla)
        await fetch(APIFORMULARIOA04 + 'ingresarNacimientoAdicionales', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setProductDialog(false);
                    setSubmitted(false);
                    setEditProductDialog(false);
                    setProduct(emptyProduct);
                    setFechaSolicitud('')
                    setSexoAdicional(false)
                    setFechaServicio('')
                    setReloadUsuarios(true)                    
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const editProduct = (product) => {
    
        setProduct({ ...product });
        setEditProductDialog(true);
        setMuestraCodigos(true)
        setMuestraRaza(true)
        setMuestraParentesco(true)
        setSexoAdicional(true)
        setNidPropietario(product.NID_PROPIETARIO)
        setCodigoRaza(product.COD_ESPECIE)
        setParentesco(product.PARENTESCO)
        setFechaServicio(product.FECHA_SERVICIO)
        setFechaSolicitud(product.FECHA_SOLICITUD)
        setRazaMacho(product.RAZA_MACHO)        
        setEnviar(true)
    }
    
    const editProduct1 = (product) => {
        setProduct({ ...product });
        setConsecutivo(product.CONSECUTIVO)
        setConsecutivoServicio(product.CONSECUTIVO_SERVICIO)
        setNidPropietario(product.NID_PROPIETARIO)
        setCodigo(product.FORMULARIO)
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false)
        setMuestraRaza(false)
        setMuestraParentesco(false)
        setProductDialog(true);
        setReloadDatosConsecutivo(true)
        setEnviar(true)
    }

    const deleteProductDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProductAdicional} />
        </>
    );


    const confirmDeleteProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true)
        setMuestraUsuario(true)
        setReloadDatosConsecutivo(true)
        setEnviar(false)
        setCambiaConcepcion(true)
        setCodigoRaza(product.COD_ESPECIE)
        setFechaServicio(product.FECHA_PARTO)
        setFechaSolicitud(product.FECHA_SOLICITUD)
        setCodigoEspecie(product.COD_ESPECIE)
        setPrefijo(product.PREFIJO_SOLICITANTE)
        setConsecutivoServicio(product.CONSECUTIVO_SERVICIO)
        setConsecutivo(product.CONSECUTIVO)
        setCodigo(product.FORMA_CONCEPCION)
        setNidPropietario(product.NID_PROPIETARIO)
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }




    const actionBodyTemplate = (rowData) => {
  
        return (
            <div className="actions">
                {rowData.sigla>0 &&
                    <Button icon="pi pi-copy" className="p-button-rounded p-button-success mr-2"
                    tooltip='Adicionar nacimientos'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    onClick={() => editProduct(rowData)} />
                }
                     {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct1(rowData)} /> */}
                     <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mt-2"
                            tooltip='Consultar nacimiento'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    onClick={() => editProduct1(rowData)} />
                    {/* <Button icon="pi pi-search" className="p-button-rounded p-button-warning mt-2"
                            tooltip='Borrar nacimiento'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">DENUNCIA DE NACIMIENTO FORMULARIO A04</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="Buscar" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const headerDatos = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">INFORMACION CONTENIDA EN LA FORMA DE CONCEPCION Y EL CONSECUTIVO DE SERVICIOS</h5>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label={enviar ? "Cancelar" : "Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {enviar &&
                <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
            }

        </>
    );

    const handleplanta = async (dato, opc) => {    
        setSubmitted(false)
       //console.log(dato,opc);
        if (opc == 1) {
            setNidPropietario(dato)
            product.NID_PROPIETARIO = dato
        } else if (opc == 2) {
            setConsecutivo(dato)
            if(sexoAdicional){
                product.CONSECUTIVOADICIONAL = dato
            }else{
                product.CONSECUTIVO = dato
            }
        } else if (opc == 3) {
            setCodigoEspecie(dato)
            product.COD_ESPECIE = dato
        } else if (opc == 4) {
            setCodigoRaza(dato)
            setMuestraRaza(true)
            product.RAZA_MACHO = dato
        }
        else if (opc == 5) {
            product.CHIP_MACHO = dato
        } else if (opc == 6) {
            setParentesco(dato)
            setMuestraParentesco(true)
            product.PARENTESCO = dato
        } else if (opc == 7) {
            product.RAZA_MESTIZAJE = dato
        } else if (opc == 8) {
            product.CHIP_HEMBRA = dato
        } else if (opc == 9) {
                setMuestraUsuario(true)
                setTidPropietario(dato)
                product.TID_PROPIETARIO = dato
        } else if (opc == 10) {
            if(dato=='A01a'){/* debe buscar las hembras del consecutivo usadas para monta grupal*/
                setMuestraHembras(true)
            }else{
                setMuestraHembras(false)
            }
            setCodigo(dato)
            product.FORMA_CONCEPCION = dato
        } else if (opc == 11) {
            setConsecutivoServicio(dato)
            product.CONSECUTIVO_SERVICIO = dato        
            setReloadUsuarios(true)
            setReloadDatosConsecutivo(true)
        }else if (opc == 12) {
            if(sexoAdicional){
                product.SEXO_ADICIONAL = dato
            }else{
                product.SEXO = dato
            }
            console.log(product.SEXO)
        }else if (opc == 13) {
            product.TIPO_DE_PARTO = dato
        }
        else if (opc == 1000) {
            setNidPropietario(0)
            setMuestraCodigos(false)
        }else if (opc==160){
            product.CHIP_HEMBRA=dato
        }
    }

    const onInputNumberChange = (e, name) => {
         const val = e.value || 0;
        // let _product = { ...product };
        // _product[`${name}`] = val;

        // setProduct(_product);
        product.PESO_AL_NACER=val
    }

    function calcularEdad(fecha, opcion) {

        if (opcion == 1) {
            setFechaSolicitud(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_SOLICITUD = new Date(fecha).toISOString().slice(0, 10)
        } else if (opcion == 2) {
            setFechaServicio(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_PARTO = new Date(fecha).toISOString().slice(0, 10)
        }
    }



    const onLoadingClick = (soporte) => {
        window.open(soporte, "_blank")
    }

    return (
    <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="descripcion" header="TID_PROPIETARIO" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="NID_PROPIETARIO" header="NID_PROPIETARIO" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="NOMBRE" header="NOMBRE" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="CONSECUTIVO" header="CONSECUTIVO" sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="CONCEPCION" header="FORMA_DE_CONCEPCION" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="CONSECUTIVO_SERVICIO" header="CONSECUTIVO_SERVICIO" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="FECHA_SOLICITUD" header="FECHA_SOLICITUD" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="FECHA_PARTO" header="FECHA_PARTO" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="ESPECIE" header="COD_ESPECIE" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="SEXO" header="SEXO" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="CHIP_MACHO" header="CHIP_MACHO" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="CHIP_HEMBRA" header="CHIP_HEMBRA" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="NOMPARTO" header="TIPO_DE_PARTO" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="PESO_AL_NACER" header="PESO_AL_NACER" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="ANIOS" header="AÑOS" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="MESES" header="MESES" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="DIASEDAD" header="DIAS" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '850px' }} header={enviar ? "Adicionar Nacimiento" : "Consultando Nacimiento"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Tipo Documento</label>
                                <SeleccionTipoDocumento handleplanta={handleplanta} opcion={9}
                                TIDPROPIETARIO={product.TID_PROPIETARIO} descripcion={product.descripcion} required/>
                                {submitted && !product.TID_PROPIETARIO && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El tipo de documento es requerido.</small>}
                            </div>
                            <div className="field col">
                                <>
                                <label htmlFor="name">Propietario</label>
                                <SeleccionUsuariosDenunciaA04 codigo={'A04'} handleplanta={handleplanta} NID_PROPIETARIO={product.NOMBRE}
                                tidPropietario={tidPropietario} required />
                                {submitted && !product.NID_PROPIETARIO && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>Nombre del usuario es requerido.</small>}
                                </>
                            </div>
                            <hr />
                        </div>
                        <div className="formgrid grid">
                            {/* {muestraCodigos && nidPropietario > 0 && */}
                                <>
                                    <div className="field col">
                                        <label htmlFor="name">Consecutivo</label>
                                        <SeleccionCodigosDenunciaA04 NID_PROPIETARIO={nidPropietario}
                                            codigo={'A04'} CONSECUTIVO={product.CONSECUTIVO} handleplanta={handleplanta}
                                            required
                                            />
                                        {submitted && !product.CONSECUTIVO && <small className="p-invalid " style={{
                                          color: 'red',
                                        }}>El consecutivo es requerido.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="name">Forma de Concepción</label>
                                        <SeleccionFormularios formulario={'A01'} formulario1={'A02'}
                                        formulario2={'A03'} formulario3={'A03'} formulario4={'A01a'}
                                        codigo={'A04'}
                                        required
                                        FORMULARIO={product.CONCEPCION}
                                        handleplanta={handleplanta} />
                                        {submitted && !product.FORMA_CONCEPCION && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>El tipo de formulario es requerido.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="name">Consecutivo del Servicio</label>
                                        <SeleccionConsecutivoServicioDenunciaA04 codigo={codigo} NID_PROPIETARIO={nidPropietario}
                                        CONSECUTIVO_SERVICIO={product.CONSECUTIVO_SERVICIO}
                                        handleplanta={handleplanta}
                                        required
                                        />
                                        {submitted && !product.CONSECUTIVO_SERVICIO && <small className="p-invalid"
                                        style={{
                                          color: 'red',
                                        }}>El consecutivo de servicio es requerido.</small>}
                                    </div>

                                </>
                            {/* } */}
                        </div>
                        <hr />
                            <>
                                <div className="formgrid grid">
                        {codigo!='A01a' && 
                                <DataTable ref={dt} value={mdatosConsecutivo}
                                    dataKey="id"  className="datatable-responsive"
                                    emptyMessage="No existen registros." header={headerDatos} responsiveLayout="scroll">
                                    <Column field="NOM_ESPECIE" header="ESPECIE" sortable ></Column>
                                    <Column field="CHIP_MACHO" header="CHIP MACHO" sortable ></Column>                                    
                                    <Column field="NOMBREMACHO" header="NOMBRE MACHO" sortable ></Column>
                                    <Column field="CHIP_HEMBRA" header="CHIP HEMBRA" sortable ></Column>                                    
                                    <Column field="NOMBREHEMBRA" header="NOMBRE HEMBRA" sortable ></Column>
                                    <Column field="PREFIJO_SOLICITANTE" header="PREFIJO" sortable ></Column>
                                </DataTable>
                         } 
                        {codigo=='A01a' && consecutivoServicio>0 &&
                        <>
                        <div className="formgrid grid">
                        <div className="field col-12">
                            <DataTable ref={dt} value={mdatosConsecutivo}
                                    dataKey="id"  className="datatable-responsive"
                                    emptyMessage="No existen registros." header={headerDatos} responsiveLayout="scroll">
                                    <Column field="NOM_ESPECIE" header="ESPECIE" sortable ></Column>
                                    <Column field="CHIP_MACHO" header="CHIP MACHO" sortable ></Column>                                    
                                    <Column field="RAZA_MACHO" header="RAZA" sortable ></Column>
                                    <Column field="PREFIJO_SOLICITANTE" header="PREFIJO" sortable ></Column>
                            </DataTable>
                        </div>
                        <hr/>
                        <div className="field col-3">
                                <>
                                <label htmlFor="name">Chip hembra</label>
                                <SeleccionHembrasA01a CONSECUTIVO_SERVICIO={consecutivoServicio} handleplanta={handleplanta} NID_PROPIETARIO={product.NOMBRE}
                                tidPropietario={tidPropietario} required />
                                {submitted && !product.CHIP_HEMBRA && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El chip de la hembra es requerido.</small>}
                                </>
                            </div>
                        </div>
                            </>
                         } 
                                </div>
                                <hr />
                                <div className="grid">
                                    <div className="col">
                                        <label htmlFor="dirección">Fecha de Solicitud: {product.FECHA_SOLICITUD}</label>
                                        <Calendar showIcon showButtonBar value={product.FECHA_SOLICITUD}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            yearRange="1960:2060" required
                                            readOnlyInput={true}
                                            locale='es'
                                            onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                                        {submitted && !product.FECHA_SOLICITUD && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>la fecha de solicitud es requerida.</small>}
                                    </div>
                                    <div className="col">
                                        <label htmlFor="dirección">Fecha de Parto: {product.FECHA_PARTO}</label>
                                        <Calendar showIcon showButtonBar value={product.FECHA_PARTO}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            locale='es'
                                            yearRange="1960:2060" required
                                            onChange={(e) => calcularEdad(e.value, 2)}></Calendar>
                                        {submitted && !product.FECHA_PARTO && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>La fecha de servicio es requerida.</small>}
                                    </div>
                                </div>
                                <hr />
                                {/* {muestraHembras && */}
                                    <div className="formgrid grid">
                                        <div className="field col col">
                                            <label htmlFor="name">Sexo</label>
                                            <SeleccionSexo handleplanta={handleplanta} SEXO={product.SEXO}
                                            required
                                            />
                                            {submitted && !product.SEXO && <small className="p-invalid"
                                            style={{
                                              color: 'red',
                                            }}>El sexo es requerido.</small>}
                                        </div>
                                        <div className="field col col">
                                            <label htmlFor="name">Tipo de Parto</label>
                                            <SeleccionTipoParto handleplanta={handleplanta} NOMPARTO={product.NOMPARTO}
                                            required
                                            />
                                            {submitted && !product.TIPO_DE_PARTO && <small className="p-invalid"
                                            style={{
                                              color: 'red',
                                            }}>El tipo de parto es requerido.</small>}
                                        </div>
                                    </div>
                                    <hr />
                                <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="price">Peso al nacer</label>
                                    <InputText id="name" value={product.PESO_AL_NACER} onChange={(e) => onInputChange(e, 'PESO_AL_NACER')}
                                    required
                                    />
                                    {submitted && !product.PESO_AL_NACER && <small className="p-invalid"
                                            style={{
                                              color: 'red',
                                            }}>El peso al nacer es requerido.</small>}
                                </div>
                                <div className="field col">
                                    <label htmlFor="description">Observaciones</label>
                                    <InputTextarea id="observaciones" value={product.OBSERVACIONES} onChange={(e) => onInputChange(e, 'OBSERVACIONES')} required rows={3} cols={20} />
                                </div>
                                </div>
                            </>
                        {/* } */}

                    </Dialog>

                    <Dialog visible={editProductDialog} style={{ width: '650px' }} header="Nacimientos parto multiple" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                    <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Consecutivo</label>
                                <SeleccionCodigosDenunciaA04 NID_PROPIETARIO={nidPropietario}
                                    codigo={'A04'} CONSECUTIVO={product.CONSECUTIVOADICIONAL} handleplanta={handleplanta} />
                                {submitted && !product.CONSECUTIVOADICIONAL && <small className="p-invalid " style={{
                                  color: 'red',
                                }}>El consecutivo es requerido.</small>}
                            </div>

                            <div className="field col">
                                <label htmlFor="price">Peso al nacer</label>
                                <InputText id="name" value={product.PESO_AL_NACER_ADICIONAL} onChange={(e) => onInputChange(e, 'PESO_AL_NACER_ADICIONAL')}  />
                                {submitted && !product.PESO_AL_NACER_ADICIONAL && <small className="p-invalid"
                                        style={{
                                          color: 'red',
                                        }}>El peso al nacer es requerido.</small>}
                            </div>
                            <div className="field col col">
                                <label htmlFor="name">Sexo</label>
                                <SeleccionSexo handleplanta={handleplanta} />
                                {submitted && !product.SEXO_ADICIONAL && <small className="p-invalid"
                                style={{
                                  color: 'red',
                                }}>El sexo es requerido.</small>}
                            </div>
                    </div>
                    </Dialog>

                    <Dialog visible={ayuda} style={{ width: '300px', height:'200px'}}  header="Denuncia de Nacimiento Formulario A04" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={6} />                    
                    </Dialog>
                </div>
            </div>
        </div>
    </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA04, comparisonFn);
