import React, { useState, useEffect, useRef } from 'react';
import { PDFViewer  } from "@react-pdf/renderer";
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { locale, addLocale } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionUsuariosDash from '../components/SeleccionUsuariosDash';
import SeleccionTipoDocumento from '../components/SeleccionTipoDocumento';
import SeleccionUsuario from '../components/SeleccionUsuario';
import SeleccionCodigos from '../components/SeleccionCodigos';
import SeleccionRegistraInicialMachos from '../components/SeleccionRegistraInicialMachos';
import SeleccionRegistraInicialMachosAdmin from '../components/SeleccionRegistraInicialMachosAdmin';
import SeleccionPedigreeInicial from '../components/SeleccionPedigreeInicial';
import SeleccionCodigoEspecie from '../components/SeleccionCodigoEspecie';
import SeleccionCodigoRaza from '../components/SeleccionCodigoRaza';
import SeleccionSexo from '../components/SeleccionSexo';
import SeleccionComposicionRacial from '../components/SeleccionComposicionRacial';
import SeleccionChipsLibres from '../components/SeleccionChipsLibres';
import FormulariosA05Detalles from '../components/FormulariosA05Detalles';
import Ayuda from '../components/Ayuda'
const FormularioA05Iniciales = () => {
    let emptyProduct = {
        TID_PROPIETARIO:'', 
        NID_PROPIETARIO:'',
        COMPOSICION:'',
        CONSECUTIVO:'',
        FECHA_SOLICITUD:'',
        CRIADERO:'',
        DIRECCION:'',
        DEPARTAMENTO:'',
        MUNICIPIO:'',
        ESPECIE:'',
        RAZA:'',
        SEXO:'',
        NOMBRE:'',
        FECHA_NACIMIENTO:'',
        MARCAS_HATO:'',
        RASGOS_PARTICULARES:'',
        COLOR:'',
        CHIP:'',
        PUNTAJE_FUNDADORES:'',
        PREFIJO:'',
        CONCEPCION:'',
        LIBRO:'',
        TID_REGISTRADOR:'',
        NID_REGISTRADOR:'',
        TID_REGISTRADOR1:'',
        NID_REGISTRADOR1:0,
        TID_REGISTRADOR2:'',
        NID_REGISTRADOR2:0,
        CHIP_PADRE:'',
        CHIP_ABUELO_PATERNO:'',
        CHIP_ABUELA_PATERNA:'',
        CHIP_MADRE:'',
        CHIP_ABUELO_MATERNO:'',
        CHIP_ABUELA_MATERNA:'',
        NOMPROPIETARIO:'',
        TIDPROPIETARIO:'',
        NOMANIMAL:'',
        TIDREGISTRADOR:'',
        TIDREGISTRADOR1:'',
        TIDREGISTRADOR2:'',
        NOMREGISTRADOR:'',
        NOMREGISTRADOR1:'',
        NOMREGISTRADOR2:''
    };
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');
    const [productDialog, setProductDialog] = useState(false);
    const [productDialogDetalle, setProductDialogDetalle] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [muestraCodigos, setMuestraCodigos] = useState(false)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [fechaSolicitud, setFechaSolicitud] = useState('')
    const [fechaNacimiento, setFechaNacimiento] = useState('')
    const [muestraRaza, setMuestraRaza] = useState(false)
    const [muestraParentesco, setMuestraParentesco] = useState(false)
    const [enviar, setEnviar] = useState(false)
    const [reloadDatosConsecutivo, setReloadDatosConsecutivo] = useState(false)
    const [mdatosConsecutivo, setMdatosConsecutivo] = useState([])
    const [editProductDialog, setEditProductDialog] = useState(false)
    const [consecutivo, setConsecutivo] = useState(0)
    const [tidPropietario, setTidPropietario] = useState('')
    const [muestraUsuario, setMuestraUsuario] = useState(false)
    const [fundadorMacho, setFundadorMacho] = useState(false)
    const [fundadorHembra, setFundadorHembra] = useState(false)
    const [puroPedigree, setPuroPedigree] = useState(false)
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false)
    const [reloadDatosIniciales, setReloadDatosIniciales] = useState([])
    const [value1, setValue1] = useState(true)
    const toast = useRef(null);
    const dt = useRef(null);
    const interval = useRef(null);
    const [ayuda, setAyuda] = useState(false)
    const [muestra, setMuestra] = useState(false)
    const [codigoEspecie, setCodigoEspecie] = useState('')
    const [sexo, setSexo] = useState(false)
    const [tipoSexo, setTipoSexo] = useState(false)
    const [composicion, setComposicion] = useState(false)
    const [puntaje, setPuntaje] = useState(0)
    const [composicionAnimal, setComposicionAnimal] = useState('')
    const [administrador, setAdministrador] = useState(localStorage.getItem('administrador'))
    const APIFORMULARIOA05INICIALES = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA05Iniciales.php?op='


    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                setUsuariosAnco([])
                const data = new FormData();
                if(localStorage.getItem('administrador')==1){
                    if(nidPropietario==''){
                        data.append('NID_PROPIETARIO','ADMINANCO')
                    }else{
                        data.append('NID_PROPIETARIO',nidPropietario)
                    }
                }else{
                    data.append('NID_PROPIETARIO',localStorage.getItem('nid_propietario'))
                }

                await fetch(APIFORMULARIOA05INICIALES + 'formulariosA05Iniciales', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setUsuariosAnco(response)
                            if(!muestra){
                                setMuestra(true)
                            }
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])




    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false);
        setMuestraParentesco(false)
    }

    const hideDialogDetalle = () => {
        setSubmitted(false);
        setProductDialogDetalle(false);
    }

    const hideDeleteProductDialog = () => {
        setEditProductDialog(false);
    }
/*tener en cuenta que aca esta el puntaje esta en la funcion puntaje */
    const saveProduct = async () => {
        setSubmitted(true);
        if(product.TID_PROPIETARIO==''){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el tipo de documento del Propietario, verifique por favor... ', life: 5000 });
            return
        }
        if(product.NID_PROPIETARIO==''){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el Propietario, verifique por favor... ', life: 5000 });
            return
        }
        if(product.COMPOSICION==''){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar la composicion racial, verifique por favor... ', life: 5000 });
            return
        }
        if(product.CONSECUTIVO==''){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el consecutivo, verifique por favor... ', life: 5000 });
            return
        }
       
        if(product.FECHA_SOLICITUD==''){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar la fecha de solicitud, verifique por favor... ', life: 5000 });
            return
        }
        if(product.ESPECIE==''){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar la especie, verifique por favor... ', life: 5000 });
            return
        }
        // if(product.COMPOSICION!='FU'){
        //     if(product.FECHA_NACIMIENTO=''){
        //         toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar la fecha de nacimiento, verifique por favor... ', life: 5000 });
        //         return
        //     }
        // }
        // if(product.MARCAS_HATO=='') {console.log(8); return }
        // if(product.RASGOS_PARTICULARES=='') {console.log(9); return }
        if(product.COLOR==''){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el color de la especie, verifique por favor... ', life: 5000 });
            return
        }
        if(product.CHIP==''){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el CHIP, verifique por favor... ', life: 5000 });
            return
        }
        if(product.COMPOSICION=='FU'){
            if(product.CHIP==''){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el puntaje del fundador, verifique por favor... ', life: 5000 });
                return
            }else if(product.SEXO=='M'){
                if(product.PUNTAJE_FUNDADORES<puntaje){
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El puntaje asignado al macho es inferior al permitido de '+puntaje, life: 3000 });
                    return
                }
            }else{
                if(product.PUNTAJE_FUNDADORES<puntaje){
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El puntaje asignado a la hembra es inferior al permitido de '+puntaje, life: 3000 });
                    return
                }
            }
        }
        if(product.COMPOSICION=='FU' && product.SEXO=='M'){
            if(localStorage.getItem('administrador')==1){
                if(product.TID_REGISTRADOR==''){
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el tipo de documento del regitrador, verifique por favor... ', life: 5000 });
                    return
                }
                if(product.NID_REGISTRADOR==''){
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el regitrador, verifique por favor... ', life: 5000 });
                    return
                }
            }
            if(product.TID_REGISTRADOR1==''){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el tipo de documento del regitrador 2 , verifique por favor... ', life: 5000 });
                return
            }
            if(product.NID_REGISTRADOR1==''){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el regitrador 2, verifique por favor... ', life: 5000 });
                return
            }
            if(product.TID_REGISTRADOR2==''){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el tipo de documento del regitrador 3 , verifique por favor... ', life: 5000 });
                return
            }
            if(product.NID_REGISTRADOR2==''){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el regitrador 3, verifique por favor... ', life: 5000 });
                return
            }           
        }else if(product.COMPOSICION=='FU' && product.SEXO=='H'){
            if(localStorage.getItem('administrador')==1){
                if(product.TID_REGISTRADOR==''){
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el tipo de documento del regitrador, verifique por favor... ', life: 5000 });
                    return
                }
                if(product.NID_REGISTRADOR==''){
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el regitrador, verifique por favor... ', life: 5000 });
                    return
                }
            }        
        }
        if(product.RAZA==''){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar la raza del animal, verifique por favor... ', life: 5000 });
            return
        }
        if(product.COMPOSICION=='PP'){
            if(product.CHIP_ABUELO_PATERNO==''){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el Chip del Abuelo Paterno, verifique por favor... ', life: 5000 });
                return
            }        
            if(product.CHIP_ABUELA_PATERNA==''){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el Chip de la Abuela Paterna, verifique por favor... ', life: 5000 });
                return
            }        
            if(product.CHIP_ABUELO_MATERNO==''){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el Chip de la Abuelo Materno, verifique por favor... ', life: 5000 });
                return
            } 
            if(product.CHIP_ABUELA_MATERNA==''){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el Chip de la Abuela Materna, verifique por favor... ', life: 5000 });
                return
            }       
        }
        const data = new FormData();
        if(localStorage.getItem('administrador')==1){
            data.append("TID_REGISTRADOR", product.TID_REGISTRADOR)
            data.append("NID_REGISTRADOR", product.NID_REGISTRADOR)        
        }else{
            data.append("TID_REGISTRADOR", localStorage.getItem('tid_propietario'))
            data.append("NID_REGISTRADOR", localStorage.getItem('nid_propietario'))
        }
       
        data.append("TID_PROPIETARIO", product.TID_PROPIETARIO)
        data.append("NID_PROPIETARIO", product.NID_PROPIETARIO)
        data.append("COMPOSICION", product.COMPOSICION)
        data.append("CONSECUTIVO", product.CONSECUTIVO)
        data.append("FECHA_SOLICITUD", product.FECHA_SOLICITUD)
        data.append("CRIADERO", mdatosConsecutivo[0].CRIADERO)
        data.append("DIRECCION", mdatosConsecutivo[0].DIRECCION)
        data.append("DEPARTAMENTO", mdatosConsecutivo[0].DEP_CODIGO)
        data.append("MUNICIPIO", mdatosConsecutivo[0].MUN_CODIGO)
        data.append("ESPECIE", product.ESPECIE)
        data.append("RAZA", product.RAZA)
        data.append("SEXO", product.SEXO)
        data.append("NOMBRE", product.NOMANIMAL)
        data.append("FECHA_NACIMIENTO", product.FECHA_NACIMIENTO)
        data.append("MARCAS_HATO", product.MARCAS_HATO)
        data.append("RASGOS_PARTICULARES", product.RASGOS_PARTICULARES)
        data.append("COLOR", product.COLOR)
        data.append("CHIP", product.CHIP)
        data.append("PUNTAJE_FUNDADORES", product.PUNTAJE_FUNDADORES)
        data.append("PREFIJO", mdatosConsecutivo[0].PREFIJO)
        data.append("CONCEPCION", mdatosConsecutivo[0].CONCEPCION)
        data.append("LIBRO", mdatosConsecutivo[0].LIBRO)
        data.append("TID_REGISTRADOR1", product.TID_REGISTRADOR1)
        data.append("NID_REGISTRADOR1", product.NID_REGISTRADOR1)
        data.append("TID_REGISTRADOR2", product.TID_REGISTRADOR2)
        data.append("NID_REGISTRADOR2", product.NID_REGISTRADOR2)
        data.append("CHIP_PADRE", product.CHIP_PADRE)
        data.append("CHIP_ABUELO_PATERNO", product.CHIP_ABUELO_PATERNO)
        data.append("CHIP_ABUELA_PATERNA", product.CHIP_ABUELA_PATERNA)
        data.append("CHIP_MADRE", product.CHIP_MADRE)
        data.append("CHIP_ABUELO_MATERNO", product.CHIP_ABUELO_MATERNO)
        data.append("CHIP_ABUELA_MATERNA", product.CHIP_ABUELA_MATERNA)
        data.append("CODIGO", 'A05')
        await fetch(APIFORMULARIOA05INICIALES + 'ingresarAnimalInicial', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setMuestraParentesco(false)
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El Registro no pudo ser Ingresado o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (reloadDatosIniciales) {
            const recarga = async (nidPropietario) => {
                const data = new FormData();
                data.append("NID_PROPIETARIO", nidPropietario)
//                data.append("CONSECUTIVO", consecutivo)
                await fetch(APIFORMULARIOA05INICIALES + 'datosUsuario', {
                    method: 'POST',
                    header: {

                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            
                             setMdatosConsecutivo(response)
                             setReloadDatosIniciales(false)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga(nidPropietario)
        }
    }, [reloadDatosIniciales, nidPropietario])


    const editProduct = (product) => {
   
        setNidPropietario(product.NID_PROPIETARIO)
        setConsecutivo(product.CONSECUTIVO)
        setReloadDatosIniciales(true)
        setProductDialogDetalle(true)
    }
    
    const editProductInicial = (product) => {
       
        setMdatosConsecutivo([])
        setProduct(product)
        if(product.LIBRO='FUNDADORES') setComposicionAnimal('FU')
        setNidPropietario(product.NID_PROPIETARIO)
            setReloadDatosIniciales(true)
            setTipoSexo(product.SEXO)
            setComposicion(true)
            setSexo(true)
            setConsecutivo(product.CONSECUTIVO)
            setSubmitted(false);
            setProductDialog(false);
            setMuestraCodigos(false)
            setMuestraRaza(false)
            setMuestraUsuario(false)
            setMuestraParentesco(false)
            setProductDialog(true);
            if(product.NID_PROPIETARIO==localStorage.getItem('nid_propietario')){
                setEnviar(false)
            }else{
                setEnviar(true)
            }
        }
    
    

    const deleteProductDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
        </>
    );


    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProductsDialogFooter = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
        </>
    );
    const confirmDeleteProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true)
        // setMuestraUsuario(true)
        // setEnviar(false)
        // setCambiaConcepcion(true)
        //  setCodigoRaza(product.COD_ESPECIE)
          //setFechaServicio(product.FECHA_PARTO)
        //  setFechaSolicitud(product.FECHA_SOLICITUD)
        //  setCodigoEspecie(product.COD_ESPECIE)
        // setPrefijo(product.PREFIJO_SOLICITANTE)
        setConsecutivo(product.CONSECUTIVO)
        // setConsecutivo(product.CONSECUTIVO)
        // setCodigo(product.FORMA_CONCEPCION)
         setNidPropietario(product.NID_PROPIETARIO)
         setReloadDatosConsecutivo(true)
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }

    const openNew = () => {
        setMdatosConsecutivo([])
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false)
        setMuestraRaza(false)
        setMuestraUsuario(false)
        setMuestraParentesco(false)
        setProductDialog(true);
        setEnviar(true)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            
            </React.Fragment>
        )
    }




    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                    {/* <Button icon="pi pi-search" className="p-button-rounded p-button-warning mt-2"
                            tooltip='Consultar mas informacióm'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    onClick={() => editProduct(rowData)} /> */}
                    
                    <Button icon="pi pi-replay" className="p-button-rounded p-button-primary mt-2"
                            tooltip='Consultar/Modificar información'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    onClick={() => editProductInicial(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">REGISTRO DE ANIMALES INICIALES</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="Buscar" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const headerDatos = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">INFORMACION BUSCADA POR EL USUARIO SELECCIONADO</h5>
        </div>
    );

    const productDialogFooter = (
       
        <>
            <Button label={enviar ? "Cancelar" : "Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {enviar &&
                <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
            }

        </>
    );

    const productDialogFooterDetalle = (
        <>
            <Button label={"Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialogDetalle} />
        </>
    );

    const handleplanta = async(dato,opc) =>{
        if(dato==undefined) return
        console.log(dato+" "+opc)
        if(opc==1){
           setNidPropietario(dato)
           product.NID_PROPIETARIO=dato
           setReloadDatosIniciales(true)
        }else if(opc==2){   
           product.CONSECUTIVO=dato
           setConsecutivo(dato)
        }else if(opc==3){
            setCodigoEspecie(dato)
            product.ESPECIE=dato
         }else if(opc==4){
            product.RAZA=dato
         }else if(opc==12){
            product.SEXO=dato
            setTipoSexo(dato)
            setSexo(true)
        }else if(opc==9){
          setTidPropietario(dato)
          product.TID_PROPIETARIO=dato
        }
        else if(opc==10){
            product.TID_REGISTRADOR=dato
        }else if(opc==11){
             product.TID_REGISTRADOR1=dato
        }else if(opc==13){
            product.TID_REGISTRADOR2=dato
       }else if(opc==14){
            product.NID_REGISTRADOR=dato
       }else if(opc==15){
            product.NID_REGISTRADOR1=dato
        }else if(opc==16){
            product.NID_REGISTRADOR2=dato
        }else if(opc==100){
            product.CHIP=dato
        }else if(opc==150){
            setNidPropietario(dato)
            setReloadUsuarios(true)
        }else if(opc==200){
            product.COMPOSICION=dato
            setComposicionAnimal(dato)
            if(dato=='FU'){
                setComposicion(true)
            }else{
                setComposicion(false)
            }
        }

        if(composicion && sexo){
            if(sexo){
                if(product.SEXO=='M'){
                    setPuntaje(79)
                    setFundadorMacho(true)
                    setFundadorHembra(false)
                    setPuroPedigree(false)
                }else{
                    setPuntaje(70)
                    setFundadorMacho(false)
                    setFundadorHembra(true)
                    setPuroPedigree(false)
                }
            }
        }else if(product.COMPOSICION!='FU' && product.COMPOSICION!='' && !composicion){
            setFundadorMacho(false)
            setFundadorHembra(false)
            setPuroPedigree(true)
        }


    }
    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductAyuda = () => {
        setAyuda(false);
    }
    const deleteProductDialogAyuda = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
        </>
    );

    function calcularEdad(fecha, opcion) {
        if (opcion == 1) {
            product.FECHA_SOLICITUD = new Date(fecha).toISOString().slice(0, 10)
        }else{
            product.FECHA_NACIMIENTO = new Date(fecha).toISOString().slice(0, 10)
        }
    }

    return (
    <>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>
                    {value1 &&
                    <>
                     <div className="formgrid grid">
                          <div className="field col">
                            <h5>Consultado información por favor espere</h5>
                                <div className="col">
                                <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                            </div>
                        </div>
                    </div>
                    </>                    
                    }
                    <SeleccionUsuariosDash handleplanta={handleplanta} opcion={150}/>                                                
                    <div className="formgrid grid">
                        <div className="field col-12">
                        {muestra &&
                    <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="TIDPROPIETARIO" header="TID_PROPIETARIO" sortable disabled ></Column>
                        <Column field="NID_PROPIETARIO" header="NID_PROPIETARIO" sortable ></Column>
                        <Column field="NOMPROPIETARIO" header="NOMBRE" sortable ></Column>
                        <Column field="CONSECUTIVO" header="CONSECUTIVO" sortable headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        <Column field="CHIP" header="CHIP" sortable headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        <Column field="FECHA_SOLICITUD" header="FECHA_SOLICITUD" sortable headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        <Column field="FECHA_NACIMIENTO" header="FECHA_NACIMIENTO" sortable headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        <Column field="CHIP_PADRE" header="CHIP_MACHO" sortable ></Column>
                        <Column field="CHIP_MADRE" header="CHIP_HEMBRA" sortable ></Column>
                        <Column field="NOMESPECIE" header="ESPECIE" sortable ></Column>
                        <Column field="SEXO" header="SEXO" sortable ></Column>
                        <Column field="RAZA" header="RAZA" sortable ></Column>
                        <Column field="COLOR" header="COLOR" sortable ></Column>
                        <Column field="NOMANIMAL" header="NOMBRE" sortable ></Column>
                        <Column field="PREFIJO" header="PREFIJO" sortable ></Column>
                        <Column field="MARCAR_HATO" header="MARCAR_HATO" sortable ></Column>
                        <Column field="RASGOS_PARTICULARES" header="RASGOS_PARTICULARES" sortable ></Column>
                        <Column field="PUNTAJE_FUNDADORES" header="PUNTAJE_FUNDADORES" sortable ></Column>
                    </DataTable>
                    }
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialogDetalle} style={{ width: '950px' }}  modal className="p-fluid" footer={productDialogFooterDetalle} onHide={hideDialogDetalle}>

                            <FormulariosA05Detalles  nidPropietario={nidPropietario} consecutivo={consecutivo}/>

                    </Dialog>
                    <Dialog visible={productDialog} style={{ width: '900px' }} header={enviar ? "Adicionar Animal Inicial" : "Modificar Animal Inicial"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                        <div className="formgrid grid">
                        <div className="field col">
                                <label htmlFor="name">Tipo Documento</label>
                                <SeleccionTipoDocumento handleplanta={handleplanta} opcion={9} TIDPROPIETARIO={product.TIDPROPIETARIO}
                                required/>
                            </div>
                            {/* {muestraUsuario && */}
                        <div className="field col">
                                <label htmlFor="name">Propietario</label>
                                <SeleccionUsuario codigo={'A05'} handleplanta={handleplanta} NOMPROPIETARIO={product.NOMPROPIETARIO}
                                 required
                                 tidPropietario={tidPropietario}
                                />
                            </div>
                        {/* } */}
                                <div className="field col">
                                <label htmlFor="name">Consecutivo</label>
                                    <SeleccionCodigos NID_PROPIETARIO={nidPropietario} 
                                    codigo={'A05'} CONSECUTIVO={product.CONSECUTIVO} handleplanta={handleplanta}
                                    required
                                    />
                                </div>
                        </div>
                        <hr />
                            <>
                        <div className="formgrid grid">
                        {/* {muestraParentesco && */}
                        <>
                                <DataTable ref={dt} value={mdatosConsecutivo}
                                    dataKey="id"  className="datatable-responsive"
                                     responsiveLayout="scroll">
                                    <Column field="CRIADERO" header="CRIADERO" sortable ></Column>
                                    <Column field="DIRECCION" header="DIRECCION" sortable ></Column>
                                    <Column field="DEPARTAMENTOD" header="DEPARTAMENTO" sortable ></Column>
                                    <Column field="MUNICIPIOD" header="MUNICIPIO" sortable ></Column>
                                    <Column field="PREFIJO" header="PREFIJO" sortable ></Column>
                                    <Column field="NOMCONCEPCION" header="FORMA DE CONCEPCION" sortable ></Column>
                                    <Column field="LIBRO" header="LIBRO" sortable ></Column>
                                </DataTable>
                                <hr/>
                        </>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Especie</label>
                                <SeleccionCodigoEspecie handleplanta={handleplanta} COD_ESPECIE={product.ESPECIE}/>
                            </div>
                            <div className="field col">
                                <label htmlFor="name">Raza</label>
                                    <SeleccionCodigoRaza COD_ESPECIE={codigoEspecie} RAZA={product.RAZA}
                                    NID_PROPIETARIO={nidPropietario}
                                    handleplanta={handleplanta}/>
                                </div>
                                <div className="field col col">
                                    <label htmlFor="name">Sexo</label>
                                    <SeleccionSexo handleplanta={handleplanta} SEXO={product.SEXO}
                                    required
                                    />
                                </div>
                                <div className="field col col">
                                    <label htmlFor="name">Composición Racial</label>
                                    <SeleccionComposicionRacial handleplanta={handleplanta} COMPOSICION={product.COMPOSICION}
                                    required
                                    />
                                </div>

                            </div>

                                <div className="formgrid grid">
                                    <div className="field col-3">
                                        <label htmlFor="dirección">Fecha de Solicitud: {product.FECHA_SOLICITUD}</label>
                                        <Calendar showIcon showButtonBar value={fechaSolicitud}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            yearRange="1960:2060" required
                                            readOnlyInput={true}
                                            locale='es'
                                            onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor="description">Nombre</label>
                                        <InputText id="nombre" value={product.NOMANIMAL} onChange={(e) => onInputChange(e, 'NOMANIMAL')} />
                                        </div>
                                        <div className="field col-3">
                                        <label htmlFor="description">Color</label>
                                        <InputText id="nombre" value={product.COLOR} onChange={(e) => onInputChange(e, 'COLOR')} />
                                        </div>
                                        <div className="field col-3">
                                        <label htmlFor="description">Chip Asignado</label>
                                        <SeleccionChipsLibres handleplanta={handleplanta} CHIP={product.CHIP}
                                        reuired
                                        />
                                        </div>
                                 </div>
                                 <div className="formgrid grid">
                                 <div className="field col-3">
                                        <label htmlFor="dirección">Fecha de Nacimiento: {product.FECHA_NACIMIENTO}</label>
                                        <Calendar showIcon showButtonBar value={fechaNacimiento}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            yearRange="1960:2060" required
                                            readOnlyInput={true}
                                            locale='es'
                                            onChange={(e) => calcularEdad(e.value, 2)}></Calendar>
                                    </div>
                                 <div className="field col-3">
                                        <label htmlFor="description">Marcas de Hato</label>
                                        <InputText id="nombre" value={product.MARCAS_HATO} onChange={(e) => onInputChange(e, 'MARCAS_HATO')} />
                                    </div>

                                    <div className="field col-3">
                                        <label htmlFor="description">Rasgos Particulares</label>
                                        <InputText id="nombre" value={product.RASGOS_PARTICULARES} onChange={(e) => onInputChange(e, 'RASGOS_PARTICULARES')} />
                                     </div>
                                     {composicionAnimal=='FU' &&
                                     <div className="field col-38">
                                        <label htmlFor="description">Puntaje Fundadores</label>
                                        <InputText id="nombre" value={product.PUNTAJE_FUNDADORES} onChange={(e) => onInputChange(e, 'PUNTAJE_FUNDADORES')} />
                                     </div>
                                     
                                     }

                                </div>
                                <hr/> 
                                {composicion && sexo &&
                                <>
                                    {tipoSexo=='M' ?
                                    <>
                                      {administrador==0 ?
                                       <SeleccionRegistraInicialMachos handleplanta={handleplanta} 
                                       product={product} submitted={submitted} 
                                       TIDREGISTRADOR={product.TIDREGISTRADOR}
                                       TIDREGISTRADOR1={product.TIDREGISTRADOR1}
                                       TIDREGISTRADOR2={product.TIDREGISTRADOR2}
                                       NOMREGISTRADOR={product.NOMREGISTRADOR}
                                       NOMREGISTRADOR1={product.NOMREGISTRADOR1}
                                       NOMREGISTRADOR2={product.NOMREGISTRADOR2}
                                       tipoSexo={tipoSexo}/>
                                      :<SeleccionRegistraInicialMachosAdmin handleplanta={handleplanta} 
                                      product={product} submitted={submitted} 
                                      TIDREGISTRADOR={product.TIDREGISTRADOR}
                                       TIDREGISTRADOR1={product.TIDREGISTRADOR1}
                                       TIDREGISTRADOR2={product.TIDREGISTRADOR2}
                                       NOMREGISTRADOR={product.NOMREGISTRADOR}
                                       NOMREGISTRADOR1={product.NOMREGISTRADOR1}
                                       NOMREGISTRADOR2={product.NOMREGISTRADOR2}
                                      tipoSexo={tipoSexo}/>
                                      }
                                    </>    
                                    :
                                    <>
                                      {administrador==1 &&
                                       <SeleccionRegistraInicialMachosAdmin handleplanta={handleplanta} 
                                      product={product} submitted={submitted} 
                                      TIDREGISTRADOR={product.TIDREGISTRADOR}
                                       TIDREGISTRADOR1={product.TIDREGISTRADOR1}
                                       TIDREGISTRADOR2={product.TIDREGISTRADOR2}
                                       NOMREGISTRADOR={product.NOMREGISTRADOR}
                                       NOMREGISTRADOR1={product.NOMREGISTRADOR1}
                                       NOMREGISTRADOR2={product.NOMREGISTRADOR2}
                                      tipoSexo={tipoSexo}/>
                                      }
                                    </>
                                    }
                                </>
                                }
                                {!composicion && tipoSexo!='' &&
                                    <SeleccionPedigreeInicial product={product} submitted={submitted}/>
                                }
                            </>
                    </Dialog>
                    <Dialog visible={ayuda} style={{ width: '300px', height:'200px'}} header="Animales Iniciales" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={'3'} />                    
                    </Dialog>
                </div>
            </div>
        </div>
        </>
    );

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA05Iniciales, comparisonFn);
