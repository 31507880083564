import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { locale, addLocale } from 'primereact/api';
import FileUpload from '../components/fileupload/FileUpload';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import SeleccionTipoDocumento from '../components/SeleccionTipoDocumento';
import SeleccionUsuario from '../components/SeleccionUsuario';
import SeleccionCodigos from '../components/SeleccionCodigos';
import SeleccionCodigoEspecie from '../components/SeleccionCodigoEspecie';
import SeleccionCodigoRaza from '../components/SeleccionCodigoRaza';
import SeleccionPaises from '../components/SeleccionPaises';
import Lateral from '../components/Lateral';
import Ayuda from '../components/Ayuda'

import Swal from 'sweetalert2'
//import { height } from '@mui/system';
const FormularioA11 = () => {
    let emptyProduct = {
        TID_SOLICITANTE:'',
        NID_SOLICITANTE:'',
        CONSECUTIVO:'',
        FECHA_SOLICITUD:'',
        ESPECIE:'',
        CANTIDAD_PAJILLAS:'',
        CRG_MACHO:'',
        NOMBRE_DEL_MACHO:'',
        CRG_PADRE:'',
        CRG_MADRE:'',
        OBSERVACIONES:'',
        CRG_ABUELO_PATERNO:'',
        CRG_ABUELA_PATERNA:'',
        CRG_ABUELO_MATERNO:'',
        CRG_ABUELA_MATERNA:'',
        PAIS:'',
        RAZA:'',
    };

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(false)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [muestraCodigos, setMuestraCodigos] = useState(false)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [codigoEspecie, setCodigoEspecie] = useState('')
    const [muestraRaza, setMuestraRaza] = useState(false)
    const [codigoRaza, setCodigoRaza] = useState(0)
    const [prefijo, setPrefijo] = useState(false)
    const [tidPropietario, setTidPropietario] = useState('')
    const [muestraUsuario, setMuestraUsuario] = useState(false)
    const [cargarArchivos, setCargarArchivos] = useState(true)
    const [enviarInformacion, setEnviarInformacion] = useState(false)
    const [archivosEnviados, setArchivosEnviados] = useState(false)
    const [deleteProductDialog, setDeleteProductDialog] = useState(false)
    const [reloadCargo, setReloadCargo] = useState('')
    const [titulo, setTitulo] = useState('')
    const [datosReubicacion, setDatosReubicacion] = useState('')
    const [macho, setMacho] = useState(false)
    const [datosMacho, setDatosMacho] = useState(0)
    const [hembra, setHembra] = useState(false)
    const [datosHembra, setDatosHembra] = useState(0)
    const [validarSi, setValidarSi] = useState(false)
    const [verificaData, setVerificaData] = useState(false)
    const [pais, setPais] = useState(false)
    const [ayuda, setAyuda] = useState(false)                
    const toast = useRef(null);
    const dt = useRef(null);

    const APIFORMULARIOA11 = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA11.php?op='
    // let today = new Date();
    // let month = today.getMonth();
    // let year = today.getFullYear();
    // let day = '01';
    // let prevMonth = month === 0 ? 11 : month - 1;
    // let prevYear = prevMonth === 11 ? year - 1 : year;
    // let nextMonth = month === 11 ? 0 : month + 1;
    // let nextYear = nextMonth === 0 ? year + 1 : year;
	// let minDate = new Date();
	// 	minDate.setMonth(prevMonth);
	// 	minDate.setFullYear(prevYear);
    // let maxDate = new Date();
	// 	maxDate.setMonth(nextMonth);
	// 	maxDate.setFullYear(nextYear);

    useEffect(() => {
        async function traerNegocios() {
          setReloadUsuarios(false)
          const data = new FormData();
          if(localStorage.getItem('administrador')==1){
            if(nidPropietario==''){
                data.append('NID_SOLICITANTE','ADMINANCO')
            }else{
                data.append('NID_SOLICITANTE',nidPropietario)
            }
          }else{
            data.append('NID_SOLICITANTE',localStorage.getItem('nid_propietario'))
          }
          await fetch(APIFORMULARIOA11 + 'formulariosA11', {
            method: 'POST',
            header: {
              'Accept': 'application/json',
              'Content-type': 'application/json'
            },
            body: data
          }).then((response) => response.json())
            .then((response) => {
                setUsuariosAnco(response)
                setCargarArchivos(false)
            })
            .catch((error) => {
              console.log(error);
            })
        }
        traerNegocios()

      }, [reloadUsuarios]);


      useEffect(() => {
      if(!macho) return
        async function traerNegocios() {
          setReloadUsuarios(false)
          const data = new FormData();
          data.append("CHIP", product.CRG_MACHO)
          await fetch(APIFORMULARIOA11 + 'formulariosA11DatosMachos', {
            method: 'POST',
            header: {
              'Accept': 'application/json',
              'Content-type': 'application/json'
            },
            body: data
          }).then((response) => response.json())
            .then((response) => {
                product.CRG_ABUELO_PATERNO=response[0].CHIP_PADRE
                product.CRG_ABUELA_PATERNA=response[0].CHIP_MADRE
                product.NOMBRE_DEL_MACHO=response[0].NOMBRE
                setDatosMacho(response)
            })
            .catch((error) => {
              console.log(error);
            })
            setMacho(false)
        }
        traerNegocios()

      }, [macho]);

      useEffect(() => {
      if(!hembra) return
        async function traerNegocios() {
          setReloadUsuarios(false)
          const data = new FormData();
          data.append("CHIP", product.CRG_PADRE)
          await fetch(APIFORMULARIOA11 + 'formulariosA11DatosHembras', {
            method: 'POST',
            header: {
              'Accept': 'application/json',
              'Content-type': 'application/json'
            },
            body: data
          }).then((response) => response.json())
            .then((response) => {
                product.CRG_ABUELO_MATERNO=response[0].CHIP_PADRE
                product.CRG_ABUELA_MATERNA=response[0].CHIP_MADRE
                product.CRG_MADRE=response[0].NOMBRE

                setDatosHembra(response)
            })
            .catch((error) => {
              console.log(error);
            })
            setHembra(false)
        }
        traerNegocios()

      }, [hembra]);


    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductAyuda = () => {
        setAyuda(false);
    }

    const deleteProductDialogAyuda = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
        </>
    );


    const openNew = () => {
        setTitulo('Adicionar')
         setCodigoEspecie('')
         setCodigoRaza('')
         setSubmitted(false)
        setProduct(emptyProduct);
        setMuestraUsuario(false)
        setMuestraUsuario(false)
        setDatosMacho([])
        setDatosHembra([])
        setProductDialog(true);
        setDeleteProductDialog(false)
    }

    const openNewNext = async(datos) => {
        setDatosReubicacion(datos)
        setReloadCargo(true)
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setProduct(emptyProduct);
    }


    useEffect(() => {

        if(enviarInformacion){

        async function enviarData() {

            const data = new FormData();
            data.append("TID_SOLICITANTE", product.TID_SOLICITANTE)
            data.append("NID_PROPIETARIO", product.NID_SOLICITANTE)
            data.append("CONSECUTIVO", product.CONSECUTIVO)
            data.append("FECHA_SOLICITUD", product.FECHA_SOLICITUD)
            data.append("ESPECIE", product.ESPECIE)
            data.append("FECHA_SERVICIO", '')
            data.append("CANTIDAD_PAJILLAS", product.CANTIDAD_PAJILLAS)
            data.append("CRG_MACHO", product.CRG_MACHO)
            data.append("NOMBRE_DEL_MACHO", product.NOMBRE_DEL_MACHO)
            data.append("CRG_PADRE", product.CRG_PADRE)
            data.append("CRG_MADRE", product.CRG_MADRE)
            data.append("OBSERVACIONES", product.OBSERVACIONES)
            data.append("CRG_ABUELO_PATERNO", product.CRG_ABUELO_PATERNO)
            data.append("CRG_ABUELA_PATERNA", product.CRG_ABUELA_PATERNA)
            data.append("CRG_ABUELO_MATERNO", product.CRG_ABUELO_MATERNO)
            data.append("CRG_ABUELA_MATERNA", product.CRG_ABUELA_MATERNA)
            data.append("PAIS", product.PAIS)
            data.append("RAZA", product.RAZA)
            data.append("CODIGO", 'A11')
            data.append("OPCION", '1')
            await fetch(APIFORMULARIOA11 + 'ingresarFormularioA11', {
                method: 'POST',
                header: {
                  'Accept': 'application/json',
                  'Content-type': 'application/json'
                },
                body: data
              }).then((response) => response.json())
                .then((response) => {
                    if(response==1){
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado de forma exitosas', life: 3000 });
                          setEnviarInformacion(false)
                          setArchivosEnviados(false)
                          setSubmitted(false);
                          setReloadUsuarios(true)
                          hideDialog()
                          return
                    }else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'El Registro no ha sido Ingresado, inténtelo más tarde', life: 3000 });
                          return
                    }
                })
                .catch((error) => {
                  console.log(error);
                })
        }
        enviarData()
    }
    }, [enviarInformacion])


    useEffect(() => {

        setVerificaData(false)
        setSubmitted(true)
        if(product.TID_SOLICITANTE=='') {console.log(1);   return }
        if(product.NID_SOLICITANTE=='') {console.log(2); return}
        if(product.CONSECUTIVO=='') {console.log(3); return}
        if(product.FECHA_SOLICITUD=='') {console.log(4); return}
        if(product.ESPECIE=='') {console.log(5); return}
        if(product.FECHA_SERVICIO=='') {console.log(6); return}
        if(product.CANTIDAD_PAJILLAS=='') {console.log(7); return}
        if(product.CRG_MACHO=='') {console.log(8);return}
        if(product.NOMBRE_DEL_MACHO=='') {console.log(9);return}
        if(product.RAZA=='') {console.log(10);return}
        if(product.CRG_PADRE=='') {console.log(11);return}        
        if(product.CRG_MADRE=='') {console.log(12);return}                
        if(product.CRG_ABUELO_PATERNO=='') {console.log(13);return}                
        if(product.CRG_ABUELA_PATERNA=='') {console.log(14);return}                
        if(product.CRG_ABUELO_MATERNO=='') {console.log(15);return}                
        if(product.CRG_ABUELA_MATERNA=='') {console.log(16);return}                        
        setDeleteProductDialog(true)

    }, [verificaData])


    const saveProduct = () =>{
        setEnviarInformacion(false)
        setVerificaData(true)
    }


    const confirmDeleteProduct = (product) => {

//        setOtrosDatos(1)
        setProduct(product);
//        setIdPlanta(product.idPlanta)
        setDeleteProductDialog(true);
    }


    // const validarActualiza = () =>{
    const deleteProduct = () =>{
        setEnviarInformacion(true)
        //setCargarArchivos(true)
    }



    const handlepreguntar=(rowData) =>{
        Swal.fire({
          title: 'Esta seguro de borrar este nombramiento?',
          text: "Una vez borrado no se puede recuperar!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si borrelo!'
        }).then((result) => {
          if (result.isConfirmed) {
            handleborrar(rowData)
          }
        })
      }

      const handleborrar = async (rowData) => {
        const data = new FormData()
        data.append("idPlanta", rowData.idPlanta);
        data.append("idRegistroIca", rowData.idRegistroIca);
        data.append("idEmpleado", rowData.idEmpleado);
        data.append("opcion", 3);
        await fetch(APIFORMULARIOA11 + 'borrarNombramiento', {
          method: 'POST',
          header: {
            'Accept': 'application/json',
            'Content-type': 'application/json'
          },
          body: data
        }).then((response) => response.text())
          .then((response) => {
            if (response != 1) {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'El nombramiento ha sido eliminado de forma satisfactoria',
                showConfirmButton: false,
                timer: 2500
              })
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'El nombramiento no pudo ser eliminado, inténtelo mas tarde...',
                showConfirmButton: false,
                timer: 2500
              })
            }
            setReloadUsuarios(true)
          })
          .catch((error) => {
            console.log(error);
          })

      }



      const handleplanta = async(dato,opc) =>{
        if(dato==undefined) return
        if(opc==1){
            if(!prefijo){
                setMuestraCodigos(true)
                product.NID_SOLICITANTE = dato
            }else{
                setMuestraCodigos(false)
                product.NID_ENTIDAD = dato
            }
            setPrefijo(true)
           setNidPropietario(dato)
        }else if(opc==2){
           product.CONSECUTIVO=dato
        }else if(opc==3){
           setCodigoEspecie(dato)
           product.ESPECIE=dato
        }else if(opc==4){
           setCodigoRaza(dato)
           product.RAZA=dato
        }else if (opc == 9) {
            setTidPropietario(dato)
            if(!prefijo){
                product.TID_SOLICITANTE=dato
            }else{
                product.TID_ENTIDAD=dato
            }
           setMuestraUsuario(true)
       }else if (opc == 10) {
            setMacho(true)
            product.CRG_MACHO=dato
    }else if (opc == 11) {
           setHembra(true)
           product.CRG_PADRE=dato
    }else if (opc == 12) {
        setPais(true)
        product.PAIS=dato
    }
        else if(opc==1000){
           setNidPropietario(0)
    }else if(opc==5000){
            setEnviarInformacion(true)
    }
}



    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2"  onClick={() => openNew()} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">

            <div className="actions">
                {rowData.estado==1 &&
                <>

                <Button icon="pi pi-times" className="p-button-rounded p-button-danger mr-2"
                            tooltip='Eliminar Formulario A11'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "left"
                            }}
                            onClick={() => handlepreguntar(rowData)} />
                             <Button icon="pi pi-id-card" className="p-button-rounded p-button-warning mr-2"
                            tooltip='Completar datos nombramiento'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "left"
                            }}
                            onClick={() => confirmDeleteProduct(rowData)} />
                </>
                }


                <Button icon="pi pi-book" className="p-button-rounded p-button-success mr-2"
                            tooltip='Consultar datos adicionales'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "left"
                            }}
                            onClick={() => openNewNext(rowData)} />
            </div>

            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">REGISTRO DE SEMEN IMPORTADO A11</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                <Button label="Validar Contenido" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
        if(name=='NOMBRE_DEL_MACHO'){
            setSubmitted(true)
            setValidarSi(true)
        }
    }


    function calcularEdad(fecha,opcion) {
        let hoy = new Date();
        let cumpleanos = new Date(fecha);
        let edadN = hoy.getFullYear() - cumpleanos.getFullYear();
        const m = hoy.getMonth() - cumpleanos.getMonth();

        if(opcion==1){
                product.FECHA_SOLICITUD=new Date(fecha).toISOString().slice(0, 10)
        }
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${rowData.REGISTRO_ICA}`} alt={rowData.REGISTRO_ICA} className="shadow-2" width="100"
                onClick={()=>onLoadingClick(rowData.REGISTRO_ICA)}/>
            </>
        )
    }

    const imageBodyTemplate1 = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${rowData.PEDIGREE_ANIMAL}`} alt={rowData.PEDIGREE_ANIMAL} className="shadow-2" width="100"
                onClick={()=>onLoadingClick(rowData.PEDIGREE_ANIMAL)}/>
            </>
        )
    }

    const imageBodyTemplate2 = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${rowData.FACTURA_DE_COMPRA}`} alt={rowData.FACTURA_DE_COMPRA} className="shadow-2" width="100"
                onClick={()=>onLoadingClick(rowData.FACTURA_DE_COMPRA)}/>
            </>
        )
    }
    const onLoadingClick = (soporte) => {
        window.open(soporte, "_blank")
    }



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25,50,100]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        {/* <Column body={actionBodyTemplate}></Column> */}
                        <Column field="TID_SOLICITANTE" header="TID IMPORTADOR" sortable ></Column>
                        <Column field="NID_SOLICITANTE" header="NID IMPORTADOR" sortable ></Column>
                        <Column field="NOMBRE" header="NOMBRE" sortable ></Column>
                        <Column field="CONSECUTIVO" header="CONSECUTIVO" sortable ></Column>
                        <Column field="CRG_MACHO" header="CRG MACHO" sortable ></Column>
                        <Column field="NOMBRE_DEL_MACHO" header="NOMBRE DEL MACHO" sortable ></Column>
                        <Column field="CRG_PADRE" header="CRG PADRE" sortable ></Column>
                        <Column field="CRG_ABUELO_PATERNO" header="CRG ABUELO PATERNO" sortable ></Column>
                        <Column field="CRG_ABUELA_PATERNA" header="CRG ABUELA PATERNA" sortable ></Column>
                        <Column field="CRG_MADRE" header="CRG MADRE" sortable ></Column>
                        <Column field="CRG_ABUELO_MATERNO" header="CRG ABUELO MATERNO" sortable ></Column>
                        <Column field="CRG_ABUELA_MATERNA" header="CRG ABUELA MATERNA" sortable ></Column>
                        <Column field="ESPECIE" header="ESPECIE" sortable ></Column>
                        <Column field="RAZA" header="RAZA" sortable ></Column>
                        <Column field="FACTURA_DE_COMPRA" header="FACTURA DE COMPRA" body={imageBodyTemplate2} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="REGISTRO_ICA" header="DOCUMENTO DIAN" body={imageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="PEDIGREE_ANIMAL" header="PEDIGREE ANIMAL" body={imageBodyTemplate1} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="FECHA_DE_SOLICITUD" header="FECHA SOLICITUD" sortable ></Column>
                        <Column field="CANTIDAD_PAJILLAS" header="CANTIDAD PAJILLAS" sortable ></Column>
                        <Column field="OBSERVACIONES" header="OBSERVACIONES" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '850px' }} header={titulo} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col-4">
                                <label htmlFor="name">Tipo Documento</label>
                                <SeleccionTipoDocumento handleplanta={handleplanta} opcion={9} TID_PROPIETARIO={product.DESCRIP}/>
                                {submitted && !product.TID_SOLICITANTE && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El tipo de documento es requerido.</small>}
                            </div>
                            {/* {muestraUsuario && */}
                            <>
                                <div className="field col-4">
                                    <label htmlFor="name">Usuario</label>
                                    <SeleccionUsuario codigo={'A11'} handleplanta={handleplanta} NID_PROPIETARIO={product.NOMBRE}
                                     tidPropietario={tidPropietario}
                                    />
                                    {submitted && !product.NID_SOLICITANTE && <small className="p-invalid"
                                    style={{
                                        color: 'red',
                                    }}>Nombre del usuario es requerido.</small>}
                                </div>
                                <div className="field col-4">
                                <label htmlFor="name">Consecutivo</label>
                                    <SeleccionCodigos NID_PROPIETARIO={nidPropietario}
                                    codigo={'A11'} CONSECUTIVO={product.CONSECUTIVO} handleplanta={handleplanta}/>
                                {submitted && !product.CONSECUTIVO && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El consecutivo es requerido.</small>}
                                </div>
                                </>
                            {/* } */}

                        </div>
                        <hr/>
                        {/* {muestraUsuario && */}
                        <>
                            <div className="formgrid grid">
                            <div className="field col">
                                    <label htmlFor="dirección">Fecha de Solicitud: {product.FECHA_SOLICITUD}</label>
                                    <Calendar showIcon showButtonBar value={product.FECHA_SOLICITUD}
                                        dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                        yearRange="1960:2060" required
                                        readOnlyInput={true}
                                        locale='es'
                                        onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                                {submitted && !product.FECHA_SOLICITUD && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>La fecha de solicitud es requerida.</small>}
                                </div>
                                <div className="field col">
                                <label htmlFor="name">Selecione el País de origen</label>
                                    <SeleccionPaises
                                     handleplanta={handleplanta}/>
                                {submitted && !product.PAIS && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El país de origen requerido.</small>}
                                </div>
                            </div>
                            <hr/>
                        </>
                        {/* } */}
                        <div className="formgrid grid">
                           {/* {pais && */}
                            <div className="field col-4">
                                <label htmlFor="name">Código de la especie</label>
                                    <SeleccionCodigoEspecie handleplanta={handleplanta} COD_ESPECIE={product.ESPECIE}/>
                                {submitted && !product.ESPECIE && <small className="p-invalid"
                                    style={{
                                        color: 'red',
                                    }}>El código de la especie es requerido.</small>}
                            </div>
                           {/* } */}
                            {/* {codigoEspecie && */}
                                <div className="field col-4">
                                <label htmlFor="name">Raza</label>
                                    <SeleccionCodigoRaza COD_ESPECIE={codigoEspecie}
                                    NID_PROPIETARIO={nidPropietario}
                                    RAZA_MACHO={product.RAZA}
                                    setMuestraRaza={setMuestraRaza}
                                    handleplanta={handleplanta}/>
                                {submitted && !product.RAZA && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>La raza es requerida.</small>}
                                </div>
                            {/* } */}
                            {product.RAZA!='' &&
                            <div className="field col-4">
                                    <label htmlFor="name">Cantidad de Pajillas</label>
                                    <InputText id="name" value={product.CANTIDAD_PAJILLAS} onChange={(e) => onInputChange(e, 'CANTIDAD_PAJILLAS')} required />
                                    {submitted && !product.CANTIDAD_PAJILLAS && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El número de embriones es requerido.</small>}
                            </div>
                            }
                        </div>
                        <hr/>
                        {/* {product.CANTIDAD_PAJILLAS!='' && */}
                        <>
                            <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="name">CRG. MACHO</label>
                                            <InputText id="name" value={product.CRG_MACHO} onChange={(e) => onInputChange(e, 'CRG_MACHO')} required />

                                        {submitted && !product.CRG_MACHO && <small className="p-invalid"
                                            style={{
                                                color: 'red',
                                            }}>El Crg del Macho es requerido.</small>}
                                    </div>
                                    <div className="field col">
                                    <label htmlFor="name">NOMBRE DEL MACHO</label>
                                        <InputText id="name" value={product.NOMBRE_DEL_MACHO} onChange={(e) => onInputChange(e, 'NOMBRE_DEL_MACHO')} required />
                                    {submitted && !product.NOMBRE_DEL_MACHO && <small className="p-invalid"
                                    style={{
                                        color: 'red',
                                    }}>El nombre del macho requerida.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="name">CRG. PADRE</label>
                                            <InputText id="name" value={product.CRG_PADRE} onChange={(e) => onInputChange(e, 'CRG_PADRE')} required />
                                        {submitted && !product.CRG_PADRE && <small className="p-invalid"
                                            style={{
                                                color: 'red',
                                            }}>El Crg del padre es requerido.</small>}
                                    </div>
                                    <div className="field col">
                                    <label htmlFor="name">CRG. MADRE</label>
                                        <InputText id="name" value={product.CRG_MADRE} onChange={(e) => onInputChange(e, 'CRG_MADRE')} required />
                                    {submitted && !product.CRG_MADRE && <small className="p-invalid"
                                    style={{
                                        color: 'red',
                                    }}>El Crg de la madre es requerido.</small>}
                                    </div>
                            </div>
                            <hr/>
                            <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="name">CRG. ABUELO PATERNO</label>
                                            <InputText id="name" value={product.CRG_ABUELO_PATERNO} onChange={(e) => onInputChange(e, 'CRG_ABUELO_PATERNO')} required />
                                        {submitted && !product.CRG_ABUELO_PATERNO && <small className="p-invalid"
                                            style={{
                                                color: 'red',
                                            }}>El Crg del abuelo paterno es requerido.</small>}
                                    </div>
                                    <div className="field col">
                                    <label htmlFor="name">CRG. ABUELA PATERNA</label>
                                        <InputText id="name" value={product.CRG_ABUELA_PATERNA} onChange={(e) => onInputChange(e, 'CRG_ABUELA_PATERNA')} required />
                                    {submitted && !product.CRG_ABUELA_PATERNA && <small className="p-invalid"
                                    style={{
                                        color: 'red',
                                    }}>El Crg de la abuela paterna es requerido.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="name">CRG. ABUELO MATERNO</label>
                                            <InputText id="name" value={product.CRG_ABUELO_MATERNO} onChange={(e) => onInputChange(e, 'CRG_ABUELO_MATERNO')} required />
                                            {submitted && !product.CRG_ABUELO_MATERNO && <small className="p-invalid"
                                    style={{
                                        color: 'red',
                                    }}>El Crg del abuelo materno es requerido.</small>}                                            
                                    </div>
                                    <div className="field col">
                                    <label htmlFor="name">CRG. ABUELA MATERNA</label>
                                        <InputText id="name" value={product.CRG_ABUELA_MATERNA} onChange={(e) => onInputChange(e, 'CRG_ABUELA_MATERNA')} required />
                                        {submitted && !product.CRG_ABUELA_MATERNA && <small className="p-invalid"
                                    style={{
                                        color: 'red',
                                    }}>El Crg de la abuela materna es requerido.</small>}                                                                                    
                                    </div>
                            </div>
                            <hr/>
                            </>
                        {/* } */}

                        <div className="field">
                            <label htmlFor="description">Observaciones</label>
                            <InputTextarea id="observaciones" value={product.OBSERVACIONES} onChange={(e) => onInputChange(e, 'OBSERVACIONES')} required rows={3} cols={20} />
                        </div>
                        {deleteProductDialog &&
                        <div className="field">
                            <label>Seleccione los 3 archivos soporte</label>
                            <hr/>
                            <label>1. Factura de Compra</label>
                            <hr/>
                            <label>2. Documento DIAN</label>
                            <hr/>
                            <label>3. Pedigree del Animal</label>
                            <hr/>
                            <FileUpload archivos={3} handleplanta={handleplanta} product={product} OPCION={1} CODIGO={'A11'}/>
                        </div>
                        }

                    </Dialog>
                    <Dialog visible={ayuda} style={{ width: '900px' }} header="Ayuda Registro de Semen Importado Formulario A11" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={'A11'} />                    
                    </Dialog> 
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA11, comparisonFn);
