import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionDepartamento from '../components/SeleccionDepartamento';
import SeleccionMunicipios from '../components/SeleccionMunicipios';
import SeleccionTipoDocumentoOtros from '../components/SeleccionTipoDocumentoOtros';
import SeleccionProfesion from '../components/SeleccionProfesion';
import SeleccionEstadoTercero from '../components/SeleccionEstadoTercero';
import SeleccionTipoSocio from '../components/SeleccionTipoSocio';
import SeleccionTipoPersona from '../components/SeleccionTipoPersona';
import SeleccionTipoAsociacion from '../components/SeleccionTipoAsociacion';
import Lateral from '../components/Lateral';
const Terceros = () => {
    let emptyProduct = {
        TID_TERCERO:'',
        NID_ASOCIACION:'',
        NOMBRE:'',
        DEP_CODIGO:'',
        MUN_CODIGO:'',
        DIRECCION:'',
        TELEFONOS:'',
        CORREO:'',
        URL:'.',
        PROFESION:'',
        FECHA_INGRESO:'',
        FECHA_RETIRO:'',
        ESTADO_TERCERO:'',
        TIPO_TERCERO:'',
        TIPO_SOCIO:'',
        NID_TERCERO:'',
    };
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [value1, setValue1] = useState(true)
    const toast = useRef(null);
    const dt = useRef(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [departamento, setDepartamento] = useState(false)
    const TERCEROS = 'https://www.ancogenecop.com/APIANCO/controllers/terceros.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                await fetch(TERCEROS + 'terceros', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setProducts(response)
                        }
                    setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])


    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }


    const saveProduct = async() => {
        setSubmitted(true);
         if(product.TID_TERCERO=='') 
         {
         toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el tipo de identificación del tercero', life: 3000 });
         return;
         }
         
         if(product.NID_TERCERO=='') 
         {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el número de identificación del tercero', life: 3000 });
            return;
        }
   
         if(product.NOMBRE=='') 
         {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el nombre del tercero', life: 3000 });
            return;
        }
        if(product.DEP_CODIGO=='') 
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el departamento de ubicación del tercero', life: 3000 });
            return;
        }
        if(product.MUN_CODIGO=='') 
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el municipio de ubicación del tercero', life: 3000 });
            return;
        }        
        if(product.DIRECCION=='') 
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar la dirección del tercero', life: 3000 });
            return;
        }         
         if(product.TELEFONOS=='') 
         {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el teléfono del tercero', life: 3000 });
            return;
        }
         if(product.CORREO=='') 
         {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el correo del tercero', life: 3000 });
            return;
        }         
         
         if(product.FECHA_INGRESO=='') 
        {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar la fecha de ingreso del tercero', life: 3000 });
            return;
        }         
         if(product.ESTADO_TERCERO=='') 
         {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el estado del tercero', life: 3000 });
            return;
        }         
         if(product.TIPO_TERCERO=='') 
         {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el tipo del tercero', life: 3000 });
            return;
        }
         if(product.TIPO_SOCIO=='') 
         {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el tipo de socio para el tercero', life: 3000 });
            return;
        }
        const data = new FormData();
        data.append("TID_TERCERO", product.TID_TERCERO)
        data.append("NID_ASOCIACION", product.NID_ASOCIACION)
        data.append("NOMBRE", product.NOMBRE)
        data.append("DEP_CODIGO", product.DEP_CODIGO)
        data.append("MUN_CODIGO", product.MUN_CODIGO)
        data.append("DIRECCION", product.DIRECCION)
        data.append("TELEFONOS", product.TELEFONOS)
        data.append("CORREO", product.CORREO)
        data.append("URL", product.URL)
        data.append("PROFESION", product.PROFESION)
        data.append("FECHA_INGRESO", product.FECHA_INGRESO)
        data.append("FECHA_RETIRO", product.FECHA_RETIRO)
        data.append("ESTADO_TERCERO", product.ESTADO_TERCERO)
        data.append("TIPO_TERCERO", product.TIPO_TERCERO)
        data.append("TIPO_SOCIO", product.TIPO_SOCIO)
        data.append("NID_TERCERO", product.NID_TERCERO)
        await fetch(TERCEROS + 'ingresarTercero', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
    }

    const confirmDeleteProduct = async(product) => {
        setProduct({ ...product });
    //    const data = new FormData();
    //    data.append("NID_ASOCIACION", product.NID_ASOCIACION)
    //    data.append("NID_TERCERO", product.NID_TERCERO)
    //    await fetch(TERCEROS + 'borrarTercero', {
    //        method: 'POST',
    //        header: {
    //            'Accept': 'application/json',
    //            'Content-type': 'application/json'
    //        },
    //        body: data
    //    }).then((response) => response.json())
    //        .then((response) => {
    //            if (response == 1) {
    //                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Borrado', life: 3000 });
    //                setReloadUsuarios(true)
    //                setProduct(emptyProduct);
    //            } else {
    //                toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser borrado', life: 3000 });
    //            }
    //        })
    //        .catch((error) => {
    //            console.log(error);
    //        })
       
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Importar" chooseLabel="Importar" className="mr-2 inline-block" />
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    function calcularEdad(fecha, opcion) {

        if (opcion == 1) {
            product.FECHA_RECEPCION = new Date(fecha).toISOString().slice(0, 10)
        }
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Terceros</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );


    function calcularEdad(fecha,opcion) {

        if(opcion==1){
            product.FECHA_INGRESO=new Date(fecha).toISOString().slice(0, 10)
        }else if(opcion==2){
            product.FECHA_RETIRO=new Date(fecha).toISOString().slice(0, 10)
        }
    }

    const handleplanta = async (dato, opc) => {
        setSubmitted(false)
        if (opc == 1) {
            product.DEP_CODIGO = dato
            setDepartamento(dato)
        }else if(opc == 2){
            product.MUN_CODIGO=dato
        }else if(opc == 3){
            product.PROFESION=dato
        }else if(opc == 4){
            product.ESTADO_TERCERO=dato
        }else if(opc == 5){
            product.TIPO_SOCIO=dato
        }else if(opc == 6){
            product.TIPO_TERCERO=dato
        }else if(opc == 7){
            product.NID_ASOCIACION=dato
        }else if(opc == 16){
            product.TID_TERCERO=dato
        }
    }

    return (
        <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }

        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} to {last} of {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="descripcion" header="TID_TERCERO" sortable ></Column>
                        <Column field="NID_TERCERO" header="NID_TERCERO" sortable ></Column>
                        <Column field="NOMBRE" header="NOMBRE" sortable ></Column>
                        <Column field="DEPARTAMENTO" header="DEPARTAMENTO" sortable ></Column>
                        <Column field="MUNICIPIO" header="MUNICIPIO" sortable ></Column>
                        <Column field="DIRECCION" header="DIRECCION" sortable ></Column>
                        <Column field="TELEFONOS" header="TELEFONOS" sortable ></Column>
                        <Column field="CORREO" header="CORREO" sortable ></Column>
                        <Column field="URL" header="URL" sortable ></Column>
                        <Column field="TPROFESION" header="PROFESION" sortable ></Column>
                        <Column field="FECHA_INGRESO" header="FECHA_INGRESO" sortable ></Column>
                        <Column field="FECHA_RETIRO" header="FECHA_RETIRO" sortable ></Column>
                        <Column field="TESTADO" header="ESTADO_TERCERO" sortable ></Column>
                        <Column field="TIPO_TERCERO" header="TIPO_TERCERO" sortable ></Column>
                        <Column field="TIPO_SOCIO" header="TIPO_SOCIO" sortable ></Column>
                        <Column field="NID_ASOCIACION" header="NID_ASOCIACION" sortable ></Column>
                        <Column field="NASOCIACION" header="NOMBRE_ASOCIACION" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle del Tercero" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                             <div className="field col-6">
                                <label htmlFor="name">Tipo Documento Tercero</label>
                                <SeleccionTipoDocumentoOtros handleplanta={handleplanta} NOMBRE={product.descripcion} />
                                {submitted && !product.TID_TERCERO && <small className="p-invalid" required
                                style={{
                                    color: 'red',
                                }}>El tipo de documento es requerido.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="name">Nid. tercero </label>
                                <InputText id="name" value={product.NID_TERCERO} onChange={(e) => onInputChange(e, 'NID_TERCERO')} required
                                className={classNames({ 'p-invalid': submitted && !product.NID_TERCERO })} />
                                {submitted && !product.NID_TERCERO && <small className="p-invalid" required
                                style={{
                                    color: 'red',
                                }}>Número de identificación es requerido</small>}
                            </div>
                      </div>
                      <hr/>
                            <div className="field col">
                                    <label htmlFor="name">Nombre del tercero</label>
                                    <InputText id="name" value={product.NOMBRE} onChange={(e) => onInputChange(e, 'NOMBRE')} required />
                                    {submitted && !product.NOMBRE && <small className="p-invalid" required
                                style={{
                                    color: 'red',
                                }}>El nombre es requerido.</small>}
                            </div>
                            <hr/>
                    <div className="formgrid grid">
                    <div className="field col">
                            <label htmlFor="name">Departamento ubicación</label>
                            <SeleccionDepartamento  handleplanta={handleplanta} DEPARTAMENTO={product.DEPARTAMENTO}
                            required
                            />
                            {submitted && !product.DEP_CODIGO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El departamento es requerido.</small>}
                        </div>
                        <div className="field col">
                            <label htmlFor="name">Municipio ubicación</label>
                            <SeleccionMunicipios  handleplanta={handleplanta} MUNICIPIO={product.MUNICIPIO} CODIGO={departamento}
                            required
                            />
                            {submitted && !product.MUN_CODIGO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>Es municipio es requerido.</small>}
                        </div>
                    </div>
                    <hr/>
                    <div className="formgrid grid">
                        <div className="field col">
                                <label htmlFor="name">Dirección</label>
                                <InputText id="name" value={product.DIRECCION} onChange={(e) => onInputChange(e, 'DIRECCION')} required />
                                {submitted && !product.DIRECCION && <small className="p-invalid" required
                            style={{
                                color: 'red',
                            }}>La dirección es requerida.</small>}
                        </div>
                        <div className="field col">
                                <label htmlFor="name">Teléfono(s)</label>
                                <InputText id="name" value={product.TELEFONOS} onChange={(e) => onInputChange(e, 'TELEFONOS')} required />
                                {submitted && !product.TELEFONOS && <small className="p-invalid" required
                            style={{
                                color: 'red',
                            }}>El teléfono es requerida.</small>}
                        </div>
                    </div>
                    <hr/>
                    <div className="formgrid grid">
                        <div className="field col">
                                <label htmlFor="name">Correo</label>
                                <InputText id="name" value={product.CORREO} onChange={(e) => onInputChange(e, 'CORREO')} required />
                                {submitted && !product.CORREO && <small className="p-invalid" required
                            style={{
                                color: 'red',
                            }}>El correo es requerida.</small>}
                        </div>
                        <div className="field col">
                                <label htmlFor="name">Url</label>
                                <InputText id="name" value={product.URL} onChange={(e) => onInputChange(e, 'URL')} required />
                        </div>
                    </div>
                    <hr/>
                    <div className="formgrid grid">
                        <div className="field col">
                        <label htmlFor="dirección">Fecha de Ingreso: {product.FECHA_INGRESO}</label>
                            <Calendar showIcon showButtonBar value={product.FECHA_INGRESO}
                                dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                yearRange="1960:2060" required
                                readOnlyInput={true}
                                onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                            {submitted && !product.FECHA_INGRESO && <small className="p-invalid"
                            style={{
                            color: 'red',
                            }}>la fecha de ingreso es requerida.</small>}
                         </div>
                         <div className="field col">
                        <label htmlFor="dirección">Fecha de Retiro: {product.FECHA_RETIRO}</label>
                            <Calendar showIcon showButtonBar value={product.FECHA_RETIRO}
                                dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                yearRange="1960:2060" required
                                readOnlyInput={true}
                                onChange={(e) => calcularEdad(e.value, 2)}></Calendar>
                         </div>
                    </div>
                    <hr/>
                    <div className="formgrid grid">
                         <div className="field col">
                            <label htmlFor="name">Profesión</label>
                            <SeleccionProfesion  handleplanta={handleplanta}  TPROFESION={product.TPROFESION}
                            required
                            />
                            {submitted && !product.PROFESION && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>La profesión es requerida.</small>}
                        </div>
                         <div className="field col">
                            <label htmlFor="name">Estado actual</label>
                            <SeleccionEstadoTercero  handleplanta={handleplanta}  MESTADO={product.TESTADO}
                            required
                            />
                            {submitted && !product.ESTADO_TERCERO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El estado es requerido.</small>}
                        </div>
                    </div>
                    <hr/>
                    <div className="formgrid grid">
                        <div className="field col">
                            <label htmlFor="name">Tipo de socio</label>
                            <SeleccionTipoSocio  handleplanta={handleplanta}  DESCRIPCION={product.TIPO_SOCIO}
                            required
                            />
                            {submitted && !product.TIPO_SOCIO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El tipo de socio es requerido.</small>}
                        </div>

                        <div className="field col">
                            <label htmlFor="name">Tipo de persona</label>
                            <SeleccionTipoPersona  handleplanta={handleplanta}  DESCRIPCION={product.TIPO_TERCERO}
                            required
                            />
                            {submitted && !product.TIPO_TERCERO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El tipo de persona es requerida.</small>}
                        </div>
                        <div className="field col">
                            <label htmlFor="name">Asociación</label>
                            <SeleccionTipoAsociacion  handleplanta={handleplanta}  DESCRIPCION={product.NASOCIACION}
                            />
                        </div>


                    </div>

                    </Dialog>


                </div>
            </div>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Terceros, comparisonFn);
