import React, { useState, useEffect, useRef } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { locale, addLocale } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionUsuario from '../components/SeleccionUsuario';
import SeleccionUsuariosTotal from '../components/SeleccionUsuariosTotal';
import SeleccionCodigos from '../components/SeleccionCodigos';
import SeleccionTipoDocumento from '../components/SeleccionTipoDocumento';
import SeleccionTipoDocumentoOtros from '../components/SeleccionTipoDocumentoOtros';
import SeleccionChipsHembras from '../components/SeleccionChipsHembras';
import Lateral from '../components/Lateral';
import Ayuda from '../components/Ayuda'

const FormularioA06 = () => {
    let emptyProduct = {
        consecutivo:'',
        tip_propietario:'',
        nid_propietario:'',
        crg_animal:'',
        tip_transferido:'',
        nid_transferido:'',
        observaciones:'',
        fecha_transferencia:'',
    };

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');
    const [productDialog, setProductDialog] = useState(false);
    const [productDialogDet, setProductDialogDet] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [muestraCodigos, setMuestraCodigos] = useState(false)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [codigoEspecie, setCodigoEspecie] = useState('')
    const [muestraRaza, setMuestraRaza] = useState(false)
    const [codigoRaza, setCodigoRaza] = useState(0)
    const [parentesco, setParentesco] = useState('')
    const [muestraParentesco, setMuestraParentesco] = useState(false)
    const [prefijo, setPrefijo] = useState(false)
    const [enviar, setEnviar] = useState(false)
    const [reloadDatosConsecutivo, setReloadDatosConsecutivo] = useState(false)
    const [mdatosConsecutivo, setMdatosConsecutivo] = useState([])
    const [codigo, setCodigo] = useState(0)
    const [editProductDialog, setEditProductDialog] = useState(false)
    const [consecutivo, setConsecutivo] = useState(0)
    const [tidPropietario, setTidPropietario] = useState('')
    const [muestraUsuario, setMuestraUsuario] = useState(false)
    const [deleteProductsDialogDet, setDeleteProductsDialogDet] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const [value, setValue] = useState(0);
    const [value1, setValue1] = useState(true);
    const interval = useRef(null);
    const [productDetalle, setProductDetalle] = useState(false)
    const [ocultar, setOcultar] = useState(false)
    const [ayuda, setAyuda] = useState(false)            
    const APIFORMULARIOA06 = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA06.php?op='



    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                setUsuariosAnco([])
                const data = new FormData();
                if(localStorage.getItem('administrador')==1){
                    if(nidPropietario==''){
                        data.append('NID_PROPIETARIO','ADMINANCO')
                    }else{
                        data.append('NID_PROPIETARIO',nidPropietario)
                    }
                  }else{
                    data.append('NID_PROPIETARIO',localStorage.getItem('nid_propietario'))
                  }

                await fetch(APIFORMULARIOA06 + 'formulariosA06', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setUsuariosAnco(response)
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductAyuda = () => {
        setAyuda(false);
    }

    const deleteProductDialogAyuda = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
        </>
    );


    const openNew = () => {
        setMdatosConsecutivo([])
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false)
        setMuestraRaza(false)
        setMuestraUsuario(false)
        setMuestraParentesco(false)
        setProductDialog(true);
        setEnviar(true)
    }

    const hideDialog = () => {
        setProduct(emptyProduct)
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false);
        setMuestraParentesco(false)
    }


    const hideDialogDet = () => {
        setSubmitted(false);
        setEnviar(true)
        setDeleteProductsDialogDet(false);
    }

    const hideDialogDetalle = () => {
        setSubmitted(false);
        setEnviar(true)
        setProductDetalle(false);
    }

    const hideDeleteProductDialog = () => {
        setEditProductDialog(false);
    }

    const saveProduct = async () => {
        setSubmitted(true);
        setSubmitted(true)
        if(product.consecutivo=='') {console.log(1);return}
         if(product.tip_propietario=='') {console.log(2); return }
         if(product.nid_propietario=='') {console.log(3); return}
         if(product.crg_animal=='') {console.log(4); return}
         if(product.tip_transferido=='') {console.log(5); return}
         if(product.nid_transferido=='') {console.log(6); return}
         if(product.fecha_transferencia=='') {console.log(7); return}

        const data = new FormData();
        data.append("consecutivo", product.consecutivo)
        data.append("tip_propietario", product.tip_propietario)
        data.append("nid_propietario", product.nid_propietario)
        data.append("crg_animal", product.crg_animal)
        data.append("tip_transferido", product.tip_transferido)
        data.append("nid_transferido", product.nid_transferido)
        data.append("observaciones", product.observaciones)
        data.append("fecha_transferencia", product.fecha_transferencia)
        data.append("CODIGO", 'A06')
        await fetch(APIFORMULARIOA06 + 'ingresarFormularioA06', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setMuestraParentesco(false)
                    setPrefijo(false)
                    setMuestraCodigos(false)
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const editProduct = (product) => {
        setEnviar(true)
        setParentesco(product.COD_ESPECIE)
        setCodigoRaza(product.RAZA)
        setNidPropietario(product.NID_PROPIETARIO)
        setConsecutivo(product.CONSECUTIVO)
        setDeleteProductsDialogDet(true);
    }




    const confirmDeleteProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true)
        setEnviar(false)
        setMuestraUsuario(true)
        setMuestraCodigos(true)
        setConsecutivo(product.CONSECUTIVO)
         setNidPropietario(product.NID_PROPIETARIO)
         setReloadDatosConsecutivo(true)
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                    <Button icon="pi pi-search" className="p-button-rounded p-button-warning mt-2"
                            tooltip='Consultar Monta directa grupal'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">TRANSFERENCIA DE PROPIEDAD FORMULARIO A06</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="Buscar" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );


    const productDialogFooter = (
        <>
            <Button label={enviar ? "Cancelar" : "Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {enviar &&
                <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
            }

        </>
    );


    const handleplanta = async (dato, opc,opcion) => {
        console.log(opc);
        if(dato==undefined) return
        setSubmitted(false)
        if (opc == 1) {
            setMuestraCodigos(false)
                setMuestraCodigos(true)
                setNidPropietario(dato)
                product.nid_propietario = dato
            setPrefijo(true)
        } else if (opc == 2) {
            setPrefijo(true)
            setConsecutivo(dato)
            product.consecutivo = dato
        } else if (opc == 8) {
            product.crg_animal = dato
        } else if (opc == 9) {
                setMuestraUsuario(true)
                setTidPropietario(dato)
                product.tip_propietario = dato
        }else if (opc == 10) {
            product.nid_transferido = dato
        }
        else if (opc == 16) {
            product.tip_transferido = dato
        } else if (opc == 20) {
            product.nid_transferido = dato
        }
        else if (opc == 1000) {
            setNidPropietario(0)
            setMuestraCodigos(false)
        }
    }

    function calcularEdad(fecha, opcion) {
        if (opcion == 1) {
            product.fecha_transferencia = new Date(fecha).toISOString().slice(0, 10)
        }
    }



    return (
    <>
     {value1 &&
     <div className="formgrid grid">
          <div className="field col">
            <h5>Consultado información por favor espere</h5>
                <div className="col">
                <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
            </div>
        </div>
    </div>
    }
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="tip_propietario" header="NID_PROPIETARIO" sortable ></Column>
                        <Column field="nid_propietario" header="NID_PROPIETARIO" sortable ></Column>
                        <Column field="NOMBRE" header="NOMBRE" sortable ></Column>
                        <Column field="consecutivo" header="CONSECUTIVO" sortable ></Column>
                        <Column field="fecha_transferencia" header="FECHA_SOLICITUD" sortable ></Column>
                        <Column field="crg_animal" header="CHIP_MACHO" sortable ></Column>
                        <Column field="tip_transferido" header="TID_TRANSFERIDO" sortable ></Column>
                        <Column field="nid_transferido" header="NID_TRANFERIDO" sortable ></Column>
                        <Column field="TRANS" header="NOMBRE TRANSFERIDO" sortable ></Column>
                        <Column field="observaciones" header="OBSERVACIONES" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '900px' }} header={enviar ? "Adicionar transferencia de propiedad" : "Consultando transferencia de propiedad"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                        <div className="formgrid grid">
                            <div className="field col-4">
                                <label htmlFor="name">Tipo Documento</label>
                                <SeleccionTipoDocumento handleplanta={handleplanta} opcion={9}
                                descripcion={'Cédula de ciudadanía'} required/>
                                {submitted && !product.tip_propietario && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El tipo de documento es requerido.</small>}
                            </div>
                            <div className="field col-4">
                                <>
                                <label htmlFor="name">Usuario</label>
                                <SeleccionUsuario codigo={'A06'} handleplanta={handleplanta} NID_PROPIETARIO={product.NOMBRE}
                                tidPropietario={tidPropietario} setOcultar={setOcultar} required/>
                                {submitted && !product.nid_propietario && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>Nombre del usuario es requerido.</small>}
                                </>
                            </div>
                            <hr />
                                <>
                                    <div className="field col-4">
                                        <label htmlFor="name">Consecutivo</label>
                                        <SeleccionCodigos NID_PROPIETARIO={nidPropietario}
                                            codigo={'A06'} CONSECUTIVO={product.consecutivo} handleplanta={handleplanta}
                                            required/>
                                        {submitted && !product.consecutivo && <small className="p-invalid " style={{
                                          color: 'red',
                                        }}>El consecutivo es requerido.</small>}
                                    </div>
                                </>
                        </div>
                        <hr />
                            <>
                                <div className="formgrid grid">
                                <div className="field col col-6">
                                <label htmlFor="name">Chip</label>
                                    <SeleccionChipsHembras
                                    COD_ESPECIE={codigoEspecie}
                                    NID_PROPIETARIO={nidPropietario}
                                    CHIP_HEMBRA={product.crg_animal}
                                    RAZA={codigoRaza}
                                    PARENTESCO={''}
                                    CHIPMACHO=''
                                    SEXO=''
                                    required
                                    CONSECUTIVO={''}
                                    codigo={'A06'}
                                    
                                    handleplanta={handleplanta}/>
                                    {submitted && !product.crg_animal && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>El número el chip es requerido.</small>}
                                </div>
                                    <div className="field col-6">
                                        <label htmlFor="dirección">Fecha de Tranferencia: {product.fecha_transferencia}</label>
                                        <Calendar showIcon showButtonBar value={product.fecha_transferencia}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            yearRange="1960:2060" required
                                            readOnlyInput={true}
                                            locale='es'
                                            onChange={(e) => calcularEdad(e.value, 1)}
                                            ></Calendar>
                                        {submitted && !product.fecha_transferencia && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>la fecha de transferencia es requerida.</small>}
                                    </div>
                                    <hr/>

                                 </div>
                                <hr/>
                                <div className="formgrid grid">
                            <div className="field col-6">
                                <label htmlFor="name">Tipo Documento Traferido</label>
                                <SeleccionTipoDocumentoOtros handleplanta={handleplanta} opcion={0}
                                descripcion={'Cédula de ciudadanía'}
                                required
                                />
                                {submitted && !product.tip_transferido && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El tipo de documento es requerido.</small>}
                            </div>
                            <div className="field col-6">
                                <>
                                <label htmlFor="name">Nombre transferido</label>
                                <SeleccionUsuariosTotal handleplanta={handleplanta}
                                required
                                />
                                {submitted && !product.nid_transferido && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>Nombre de a quien se transfiere es requerido.</small>}
                                </>
                            </div>
                            <hr />
                        </div>

                            </>

                    <div className="formgrid grid">
                                <div className="field col col">
                                    <label htmlFor="description">Observaciones</label>
                                    <InputTextarea id="observaciones" value={product.observaciones} onChange={(e) => onInputChange(e, 'OBSERVACIONES')} required rows={3} cols={20} />
                                </div>

                    </div>
                    </Dialog>
                    <Dialog visible={ayuda} style={{ width: '900px' }} header="Ayuda Transferencia de Propiedad Formulario A06" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={'A06'} />                    
                    </Dialog>                    
                </div>
            </div>
        </div>
        </>
    );

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA06, comparisonFn);
