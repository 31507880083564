import React,{useState,useRef,useEffect} from 'react'
import { size, isEmpty } from 'lodash'
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useLocation } from "react-router-dom";
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
const ClaveInicial = (props) =>{
    const location = useLocation();



  const history = useHistory();
//  const captcha=useRef(null)
const [productDialog, setProductDialog] = useState(true)
const [value1, setValue1] = useState('')
const [value2, setValue2] = useState('')
const [value3, setValue3] = useState('')
const [verLogin, setVerLogin] = useState(true)
const [noSoyRobot, setNoSoyRobot] = useState('')
const [submitted, setSubmitted] = useState(false);
const [reloadNacimientos, setReloadNacimientos] = useState(false)
const toast = useRef(null);
const APIUSUARIOS = 'https://www.ancogenecop.com/APIANCO/controllers/usuario.php?op='

const [values, setValues] = React.useState({

  password: false,
});
const hideDialog = () => {
    setProductDialog(false);
    return props.handleclave()
}
// let emptyProduct = {
//     usuario: '',
//     password:'',
// };
//const [product, setProduct] = useState(emptyProduct);



useEffect(() => {

 }, [location]);



 const saveProduct = async() => {
    setSubmitted(true);
    if(value1=='') { 
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar la clave actual, verifique por favor...', life: 3000 });
        return
    }
    if(value2=='') { 
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar la nueva clave, verifique por favor...', life: 3000 });
        return
    }
    if(value3=='') { 
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe confirmar la nueva clave, verifique por favor...', life: 3000 });
        return
    }
    if(value1==value2){
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe utilizar una clave diferente a la actual, verifique por favor...', life: 3000 });
        return
    }
    if(value2!=value3){
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'La clave nueva y la confirmación no son iguales, verifique por favor...', life: 3000 });
        return
    }

               const data = new FormData();
               data.append("anterior", value1)
               data.append("password", value2)
               data.append("username", localStorage.getItem("nid_propietario"))
               await fetch(APIUSUARIOS + 'actualizaPassword', {
                   method: 'POST',
                   header: {
                     'Accept': 'application/json',
                     'Content-type': 'application/json'
                   },
                   body: data
                 }).then((response) => response.json())
                   .then((response) => {
                       if(response==1){
                             toast.current.show({ severity: 'success', summary: 'Successful', detail: 'La clave de acceso a sido actualizada, ingrese nuevamente con su nueva clave', life: 5000 });
                             setProductDialog(false);
                             //return props.onMobileSubTopbarMenuClickSalir;
                       }else if(response==98){
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'La clave anterior no corresponde, verifique por favor', life: 3000 });
                       }else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No fue posible cambiar la clave de acceso', life: 3000 });
                       }
                   })
                   .catch((error) => {
                     console.log(error);
                   })
   }


// useEffect(() => {
//     if (reloadNacimientos) {
//         const recarga = async () => {
//         setReloadNacimientos(false)
//         //setCargoNomina('')
//         const data = new FormData();
//             data.append("NID_PROPIETARIO", localStorage.getItem("nid_propietario"))
//             await fetch(APIPROCESOSDIARIOS + 'actualizaEdad', {
//                 method: 'POST',
//                 header: {
//                     'Accept': 'application/json',
//                     'Content-type': 'application/json'
//                 },
//                 body: data
//             }).then((response) => response.json())
//                 .then((response) => {
//                         console.log(response)
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                 })
//         }
//         recarga()
//     }
// }, [reloadNacimientos])







// const onInputChange = (e, name) => {
//     const val = (e.target && e.target.value) || '';
//     let _product = { ...product };
//     _product[`${name}`] = val;
//     setProduct(_product);
// }
const header = <h6>Digite password</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Sugerencias</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}
            Locale>
                <li>Al menos una letra minúscula</li>
                <li>Al menos una letra mayúscula</li>
                <li>Al menos un caracter numérico</li>
                <li>Al menos un caracter especial</li>
                <li>Mìnimo de 8 caracteres</li>
            </ul>
        </React.Fragment>
    );


    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    
return(
<>

<section className="vh-100">
<Toast ref={toast} />
  <div className="container-fluid h-custom">
    <div className="row d-flex justify-content-center align-items-center h-100">
<div className="tab-content">
  <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
  {verLogin &&
                // <form>
                <>

                <div className="card">
                <Dialog visible={productDialog} style={{ width: '650px' }} header={"Cambiar clave de acceso"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog} >
                <div>
                {props.cargo==0 &&
                <div style={{color: 'red'}}><h4><b>Por ser el primer ingreso debe cambiar la clave de acceso</b></h4></div>
                }
                </div>
                
                <div className="formgrid grid">
                    <div className="field col">
                        <Password value={value1} 
                        onChange={(e) => setValue1(e.target.value)} 
                        placeholder="Digite la clave actual" toggleMask
                        required />
                    </div>
                    <div className="field col">
                        <Password value={value2} 
                        onChange={(e) => setValue2(e.target.value)} 
                        header={header} footer={footer} placeholder="Digite la nueva clave" toggleMask />
                    </div>
                    <div className="field col">
                        <Password value={value3} 
                        onChange={(e) => setValue3(e.target.value)} 
                        placeholder="Confirme la nueva clave" toggleMask
                        required />
                    </div>

                </div>
                </Dialog>
                </div>

                  </>
                // </form>
}
  </div>

</div>

   </div>
   </div>
</section>
</>
)
}

export default ClaveInicial
