import React,{useState,useEffect,useRef} from 'react';
import { Editor } from 'primereact/editor';
import FileUpload from '../components/fileupload/FileUpload';
import { Toast } from 'primereact/toast';
import SeleccionFormulariosGeneral from '../components/SeleccionFormulariosGeneral';
import SeleccionAyudasAdicionales from '../components/SeleccionAyudasAdicionales';
const EditorText = (props) =>{
const [text, setText] = useState(props.product.descripcionAyuda)    
const [contenido, setContenido] = useState(null)
const [enviarInformacion, setEnviarInformacion] = useState('')
const [idAyuda, setIdAyuda] = useState(props.product.idAyuda)
const [codigo, setCodigo] = useState('A99')
const [adicionales, setAdicionales] = useState(0)
const [opcion, setOpcion] = useState(0)
const [codigoAyuda, setCodigoAyuda] = useState(props.product.codigoAyuda)
const toast = useRef(null);
const APIAYUDA = 'https://www.ancogenecop.com/APIANCO/controllers/ayuda.php?op='
useEffect(() => {
    if(enviarInformacion){
    
        setEnviarInformacion(false)
    async function enviarData() {
        const data = new FormData();
        if(contenido==null){
          data.append("descripcionAyuda", props.product.descripcionAyuda)
        }else{
          data.append("descripcionAyuda", contenido)
        }
        if(props.product.idAyuda==null || props.product.idAyuda==0){
          data.append("idAyuda", idAyuda)
        }else{
          data.append("idAyuda", props.product.idAyuda)
        }
        data.append("codigoAyuda", codigoAyuda)        
        data.append("adicionales", adicionales)        
        await fetch(APIAYUDA + 'ingresarAyuda', {
            method: 'POST',
            header: {
              'Accept': 'application/json',
              'Content-type': 'application/json'
            },
            body: data
          }).then((response) => response.json())
            .then((response) => {
                if(response==1){
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'La ayuda ha sido ingresada', life: 2000 });
                    return props.hideDialog()
                }else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El Registro no ha sido Ingresado, inténtelo más tarde', life: 3000 });
                      return
                }
            })
            .catch((error) => {
              console.log(error);
            })
    }
    enviarData()
}
}, [enviarInformacion])

const handlecontenido = (e) =>{
    setContenido(e)
}

const handleplanta = async(dato,opc) =>{
    if(opc==5000){
        setIdAyuda(dato)
        setEnviarInformacion(true)
    }else if(opc==1){
        setCodigoAyuda(dato)
    }else{
        setAdicionales(dato)
    }

}

return(
<>
<Toast ref={toast} />
<div className="formgrid grid">
  <div className="field col">
    <label>Seleccione el formulario de ayuda</label>
    <SeleccionFormulariosGeneral  handleplanta={handleplanta} />
  </div>
    <div className="field col">
        <label>Seleccione otras opciones de ayuda</label>    
        <SeleccionAyudasAdicionales  handleplanta={handleplanta} />
    </div>  
  </div>
<hr/>
<Editor value={text} onTextChange={(e) => handlecontenido(e.htmlValue)} style={{ height: '320px' }} />
<hr/>
    <div className="field">
    <FileUpload archivos={1} handleplanta={handleplanta} 
    OPCION={opcion} 
    {...props}
    estado={props.estado}
    idAyuda={props.product.idAyuda}
    CODIGO={codigo}/>
</div>
</>                        
)

}

export default EditorText