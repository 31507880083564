import React from "react";
import SeleccionRegistradoresAdmin from './SeleccionRegistradoresAdmin';
import SeleccionTipoDocumento from './SeleccionTipoDocumento';
const SeleccionRegistraInicialMachos = (props) => {
    console.log("en seleccion registra inicial machos");
    console.log(props);
    const handleretorna = async(dato,opcion) =>{
      //  return props.handleplanta(dato,opcion)
        if(props.handleplanta!=undefined){
            return props.handleplanta(dato,opcion)
        }else{
            return props.handleretorna(dato,opcion)
        }
    }
  return (
    <div className="p-error">
    <div className="formgrid grid">
        <div className="field col-6">
        <label htmlFor="name">Tipo Documento</label>
            <SeleccionTipoDocumento opcion={11} handleretorna={handleretorna} TIDREGISTRADOR={props.TIDREGISTRADOR1}
            required/>
            {props.submitted && !props.product.TID_REGISTRADOR1 && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El tipo de documento es requerido.</small>}
        </div>
        <div className="field col-6">
        <label htmlFor="name">Nombre Registrador 2</label>
            <SeleccionRegistradoresAdmin tidRegistrador='CC' opcion={15} handleretorna={handleretorna} NOMREGISTRADOR={props.NOMREGISTRADOR1}
            required
            />
            {props.submitted && !props.product.NID_REGISTRADOR1 && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El nombre segundo registrador es requerido.</small>}
        </div>
    </div>
        <div className="formgrid grid">
        <div className="field col-6">
        <label htmlFor="name">Tipo Documento</label>
            <SeleccionTipoDocumento opcion={13} handleretorna={handleretorna} TIDREGISTRADOR={props.TIDREGISTRADOR2}
            required/>
                {props.submitted && !props.product.TID_REGISTRADOR2 && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El tipo de documento es requerido.</small>}
        </div>
        <div className="field col-6">
        <label htmlFor="name">Nombre Registrador 3</label>
            <SeleccionRegistradoresAdmin tidRegistrador='CC' opcion={16} handleretorna={handleretorna} NOMREGISTRADOR={props.NOMREGISTRADOR2}
            required
            />
            {props.submitted && !props.product.NID_REGISTRADOR2 && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El nombre del tercer registrador es requerido.</small>}
        </div>
    </div>
    </div>
  );
};

export default SeleccionRegistraInicialMachos;
