import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import Lateral from '../components/Lateral';
const FormulariosRevisados = () => {
    const [file, setFile] = useState(null)
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [editar, setEditar] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const [value1, setValue1] = useState(true)
    const [id, setId] = useState(0)
    const [aprobar, setAprobar] = useState(false)

    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const FORMULARIOSSOLICITADOS = 'https://www.ancogenecop.com/APIANCO/controllers/formulariosRevisados.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                await fetch(FORMULARIOSSOLICITADOS + 'formularioSolicitados', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setEditar(false)
                            setProducts(response)
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])




    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }



    const editProduct = (product) => {
        setAprobar(true)
        setId(product.id)
    }

    const rechazaProduct = (product) => {
        setProductDialog(true)
        setId(product.id)
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }






    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Formularios Solicitados Revisados y aprobados</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );



    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${rowData.soporte}`} alt={rowData.soporte} className="shadow-2" width="100"
                onClick={()=>onLoadingClick(rowData.soporte)}/>
            </>
        )
    }

    const onLoadingClick = (soporte) => {
        window.open(soporte, "_blank")
    }



    return (
    <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <Toolbar className="mb-4" ></Toolbar>
                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column field="consec_solicitud" header="Solicitud" sortable ></Column>
                        <Column field="nid_propietario" header="NID_SOLICITANTE" sortable ></Column>
                        <Column field="NOMBRE" header="SOLICITANTE" sortable ></Column>
                        <Column field="total" header="No. FORMULARIOS" sortable ></Column>
                        <Column field="valor" header="VALOR" sortable ></Column>
                        <Column header="DOC. SOPORTE" body={imageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="MESTADO" header="ESTADO" sortable ></Column>
                        <Column field="fecha" header="FECHA SOLICITUD" sortable ></Column>
                        <Column field="fecha_aprobacion" header="FECHA APROBACION" sortable ></Column>
                    </DataTable>
                    </div>
                   
                    </div>


                </div>
            </div>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormulariosRevisados, comparisonFn);
