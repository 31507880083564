import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import classNames from 'classnames';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionDepartamento from '../components/SeleccionDepartamento';
import SeleccionMunicipios from '../components/SeleccionMunicipios';
import Lateral from '../components/Lateral';
import Ayuda from '../components/Ayuda'

const MisAprisco = () => {
    let emptyProduct = {
        CRIADERO:'',
        DEP_CODIGO:'',
        MUN_CODIGO:'',
        DIRECCION:'',
        NID_PROPIETARIO:'',
    };
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [value1, setValue1] = useState(true)
    const toast = useRef(null);
    const dt = useRef(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [departamento, setDepartamento] = useState(false)
    const [ayuda, setAyuda] = useState(false)                
    const TERCEROS = 'https://www.ancogenecop.com/APIANCO/controllers/miaprisco.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                data.append("NID_PROPIETARIO",localStorage.getItem("nid_propietario"))
                await fetch(TERCEROS + 'aprisco', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setProducts(response)
                        }
                    setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductAyuda = () => {
        setAyuda(false);
    }

    const deleteProductDialogAyuda = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
        </>
    );

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }


    const saveProduct = async() => {
        setSubmitted(true);
         if(product.CRIADERO=='') {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El nombre del aprisco es requerido, verifique por favor', life: 5000 });
         return}
         if(product.DEP_CODIGO=='') {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Debe seleccionar el departamento de ubicacion del aprisco, verifique por favor', life: 5000 });
         return}
         if(product.MUN_CODIGO=='') {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Debe seleccionar el municipio de ubicacion del aprisco, verifique por favor', life: 5000 });
         return}
         if(product.DIRECCION=='') {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Debe digitar la dirección del aprisco, verifique por favor', life: 5000 });
         return}
        const data = new FormData();
        data.append("CRIADERO", product.CRIADERO)
        data.append("DEP_CODIGO", product.DEP_CODIGO)
        data.append("MUN_CODIGO", product.MUN_CODIGO)
        data.append("DIRECCION", product.DIRECCION)
        data.append("NID_PROPIETARIO",localStorage.getItem("nid_propietario"))
        await fetch(TERCEROS + 'ingresarAprisco', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Mi Aprisco</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );


    function calcularEdad(fecha,opcion) {

        if(opcion==1){
            product.FECHA_INGRESO=new Date(fecha).toISOString().slice(0, 10)
        }else if(opcion==2){
            product.FECHA_RETIRO=new Date(fecha).toISOString().slice(0, 10)
        }
    }

    const handleplanta = async (dato, opc) => {
        setSubmitted(false)
        if (opc == 1) {
            product.DEP_CODIGO = dato
            setDepartamento(dato)
        }else if(opc == 2){
            product.MUN_CODIGO=dato
        }
    }

    return (
        <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }

        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>                    
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="CRIADERO" header="Criadero" sortable ></Column>
                        <Column field="DEPARTAMENTO" header="DEPARTAMENTO" sortable ></Column>
                        <Column field="MUNICIPIO" header="MUNICIPIO" sortable ></Column>
                        <Column field="DIRECCION" header="DIRECCION" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Actualizar datos de mi aprisco" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                             <div className="field col">
                                 <label htmlFor="name">Criadero</label>
                                 <InputText id="name" value={product.CRIADERO} onChange={(e) => onInputChange(e, 'CRIADERO')} required />
                            </div>
                      </div>
                      <hr/>
                    <div className="formgrid grid">
                    <div className="field col">
                            <label htmlFor="name">Departamento ubicación</label>
                            <SeleccionDepartamento  handleplanta={handleplanta} DEPARTAMENTO={product.DEPARTAMENTO}
                            required
                            />
                        </div>
                        <div className="field col">
                            <label htmlFor="name">Municipio ubicación</label>
                            <SeleccionMunicipios  handleplanta={handleplanta} MUNICIPIO={product.MUNICIPIO} CODIGO={departamento}
                            required
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className="formgrid grid">
                        <div className="field col">
                                <label htmlFor="name">Dirección</label>
                                <InputText id="name" value={product.DIRECCION} onChange={(e) => onInputChange(e, 'DIRECCION')} required />
                        </div>
                    </div>
                    <hr/>

                    </Dialog>
                    <Dialog visible={ayuda} style={{ width: '900px' }} header="Mis Datos" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={'3'} />                    
                    </Dialog>     
                </div>
            </div>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MisAprisco, comparisonFn);
