import React, { useState,useEffect } from "react";
import { AutoComplete } from 'primereact/autocomplete';


const SeleccionMunicipios = (props) => {
  const [selectedAutoValue, setSelectedAutoValue] = useState(props.MUNICIPIO);
  const [autoFilteredValue, setAutoFilteredValue] = useState([]);
  const [autoValue, setAutoValue] = useState(null);
  const [reload, setReload] = useState(true)
  const APITABLASBASICAS = 'https://www.ancogenecop.com/APIANCO/controllers/selecciones.php?op='

  useEffect(() => {
    async function traerUsuarios(CODIGO) {
      const data = new FormData();
      data.append("COD_DEPTO",CODIGO)
      await fetch(APITABLASBASICAS + 'municipios', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            setReload(false)
            setAutoValue(response)
        })
        .catch((error) => {
          console.log(error);
        })
    }
        traerUsuarios(props.CODIGO)
  }, [reload,props.CODIGO]);


  const searchCountry = (event) => {
    setTimeout(() => {
        if (!event.query.trim().length) {
            setAutoFilteredValue([...autoValue]);
        }
        else {
            setAutoFilteredValue(autoValue.filter((a) => {
                return a.name.toUpperCase().startsWith(event.query.toUpperCase());
            }));
        }
    }, 250);
};



  const handleChange = (event) => {
    setSelectedAutoValue(event)
    return props.handleplanta(event.code,2)
  };



  return (
    <div className="p-error">
      <AutoComplete placeholder="Buscar" id="dd" dropdown value={selectedAutoValue}
      onChange={(e) => handleChange(e.value,16)}
      suggestions={autoFilteredValue} completeMethod={searchCountry} field="name" />
    </div>
  );
};

export default SeleccionMunicipios;
