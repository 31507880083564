import React, { useState,useEffect } from "react";
import { InputText } from 'primereact/inputtext';
const SeleccionPedigreeInicial = (props) => {
    let emptyProduct = {
        CHIP_PADRE:'',
        CHIP_MADRE:'',
        CHIP_ABUELO_PATERNO:'',
        CHIP_ABUELO_PATERNO:'',
        CHIP_ABUELA_PATERNA:'',
        CHIP_ABUELO_MATERNO:'',
        CHIP_ABUELA_MATERNA:'',
    };

    const [product, setProduct] = useState(emptyProduct);
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;
        props.product[`${name}`] = val;
        setProduct(_product);
    }

  return (
    <div className="p-error">
        <div className="formgrid grid">
            <div className="field col">
                <label htmlFor="description">Chip Padre</label>
                <InputText id="nombre" value={product.CHIP_PADRE} onChange={(e) => onInputChange(e, 'CHIP_PADRE')} />
                {props.submitted && !props.product.CHIP_PADRE && <small className="p-invalid"
                 style={{
                 color: 'red',
                }}>El chip del padre es requerido.</small>}                
            </div>
            <div className="field col">
                <label htmlFor="description">Chip Abuelo Paterno</label>
                <InputText id="nombre" value={product.CHIP_ABUELO_PATERNO} onChange={(e) => onInputChange(e, 'CHIP_ABUELO_PATERNO')} />
                {props.submitted && !props.product.CHIP_ABUELO_PATERNO && <small className="p-invalid"
                 style={{
                 color: 'red',
                }}>El chip del abuelo paterno es requerido.</small>}                                
            </div>
            <div className="field col">
                <label htmlFor="description">Chip Abuela Paterna</label>
                <InputText id="nombre" value={product.CHIP_ABUELA_PATERNA} onChange={(e) => onInputChange(e, 'CHIP_ABUELA_PATERNA')} />
                {props.submitted && !props.product.CHIP_ABUELA_PATERNA && <small className="p-invalid"
                 style={{
                 color: 'red',
                }}>El chip de la abuela paterna es requerido.</small>}                                
             </div>
        </div>
        <div className="formgrid grid">
            <div className="field col">
                <label htmlFor="description">Chip Madre</label>
                <InputText id="nombre" value={product.CHIP_MADRE} onChange={(e) => onInputChange(e, 'CHIP_MADRE')} />
                {props.submitted && !props.product.CHIP_MADRE && <small className="p-invalid"
                 style={{
                 color: 'red',
                }}>El chip de la madre es requerido.</small>}                                
            </div>
            <div className="field col">
                <label htmlFor="description">Chip Abuelo Materna</label>
                <InputText id="nombre" value={product.CHIP_ABUELO_MATERNO} onChange={(e) => onInputChange(e, 'CHIP_ABUELO_MATERNO')} />
                {props.submitted && !props.product.CHIP_ABUELO_MATERNO && <small className="p-invalid"
                 style={{
                 color: 'red',
                }}>El chip del abuelo materno es requerido.</small>}                                                
            </div>
            <div className="field col">
                <label htmlFor="description">Chip Abuela Materna</label>
                <InputText id="nombre" value={product.CHIP_ABUELA_MATERNA} onChange={(e) => onInputChange(e, 'CHIP_ABUELA_MATERNA')} />
                {props.submitted && !props.product.CHIP_ABUELA_MATERNA && <small className="p-invalid"
                 style={{
                 color: 'red',
                }}>El chip de la abuela materna es requerido.</small>}                                                
             </div>
         </div>
        <hr/>
    </div>
  );
};

export default SeleccionPedigreeInicial;
