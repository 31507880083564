import React,{useState,useRef,useEffect} from 'react'
import { size, isEmpty } from 'lodash'
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useLocation } from "react-router-dom";
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import RecuperarContrasena  from './RecuperarContrasena';
import { convertLength } from '@mui/material/styles/cssUtils';

const Login = (props) =>{
    const location = useLocation();



  const history = useHistory();
//  const captcha=useRef(null)
const [verLogin, setVerLogin] = useState(true)
const [noSoyRobot, setNoSoyRobot] = useState('')
const [submitted, setSubmitted] = useState(false);
const [reloadNacimientos, setReloadNacimientos] = useState(false)
const [recuperar, setRecuperar] = useState(false)
const [nidPropietario, setNidPropietario] = useState('')
const [correoUsuario, setCorreoUsuario] = useState('');
const [correo, setCorreo] = useState(false)
const toast = useRef(null);
const APIUSUARIOS = 'https://www.ancogenecop.com/APIANCO/controllers/usuario.php?op='
const APIPROCESOSDIARIOS = 'https://www.ancogenecop.com/APIANCO/controllers/procesosDiarios.php?op='

const [values, setValues] = React.useState({

  password: false,
});

let emptyProduct = {
    usuario: '',
    password:'',
};
const [product, setProduct] = useState(emptyProduct);



useEffect(() => {

 }, [location]);

 const handleRecordar=async()=>{
    setRecuperar(true);
 }



const handleLogin=async()=>{
    setSubmitted(true);
    if(product.usuario=='') { console.log(1); return}
    if(product.password=='') { console.log(2); return}

    const data = new FormData()
  data.append("usuario", product.usuario);
  data.append("password", product.password);

  await fetch(APIUSUARIOS + 'login', {
    method: 'POST',
    header: {
      'Accept': 'application/json',
      'Content-type': 'application/json'
    },
    body: data
  }).then((response) => response.json())
    .then((response) => {
        console.log(response)
      if (response == 100 || response == 99) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Usuario y/o clave incorrectos verifique favor...', life: 3000 });
        return
    }
    if(response.cargo==0){
        localStorage.setItem('nid_propietario',response.nid_propietario)

        return props.handleplanta(response)
    }
    setReloadNacimientos(true)
    localStorage.setItem('nombres',response.nombres)
    if(response.nid_propietario=='ADMINANCO'){
        localStorage.setItem('administrador',1)
    }else{
        localStorage.setItem('administrador',0)
    }
    localStorage.setItem('nid_propietario',response.nid_propietario)
    localStorage.setItem('nivel',response.nivel)
    localStorage.setItem('tid_propietario',response.tid_propietario)
    console.log(localStorage)
    return props.handleplanta(response)
    })
    .catch((error) => {
      console.log(error);
    })
}

useEffect(() => {
    if (reloadNacimientos) {
        const recarga = async () => {
        setReloadNacimientos(false)
        //setCargoNomina('')
        const data = new FormData();
            data.append("NID_PROPIETARIO", localStorage.getItem("nid_propietario"))
            await fetch(APIPROCESOSDIARIOS + 'actualizaEdad', {
                method: 'POST',
                header: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                },
                body: data
            }).then((response) => response.json())
                .then((response) => {
                        console.log(response)
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        recarga()
    }
}, [reloadNacimientos])





const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email)
}



const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || '';
    let _product = { ...product };
    _product[`${name}`] = val;
    setProduct(_product);
}

const hideDialog = () => {
    setRecuperar(false);
}

const handleCorreo = (datoCorreo) => { 
    if(!validateEmail(datoCorreo)){
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar un correo valido, verifique por favor...', life: 3000 });
        return
    }
    setCorreoUsuario(datoCorreo);
}

const handleUsuario = async(nidPropietario) => {
    if(nidPropietario==='') { 
       toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el nombre del usuario, verifique por favor...', life: 3000 });
        return
    }
    setNidPropietario(nidPropietario);
    const data = new FormData();
    data.append("nidPropietario", nidPropietario); 
    await fetch(APIUSUARIOS + 'buscaCorreoUsuario', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response.Email===null){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'No tiene correo registrado, debe registrar un correo válido...', life: 3000 });
                setCorreo(true); 
            }else if(response===200){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'El usuario no se encuentra registrado...', life: 3000 });
                setRecuperar(false);
                return;
            }
        })
        .catch((error) => {
          console.log(error);
        })
}

const actualizaCorreo = async() => {
    const data = new FormData();
    data.append("nidPropietario", nidPropietario); 
    data.append("correoUsuario", correoUsuario);
    data.append("correo", correo);
    await fetch(APIUSUARIOS + 'enviaCorreoUsuario', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            if(response===200){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'El correo digitado ya se encuentra registrado, verifique por favor...', life: 3000 });
                return;
            }else if(response===2){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se presento un error de red, intentelo más tarde...', life: 3000 });
                return;
            }else{
                toast.current.show({ severity: 'success', summary: 'Atencióm!', detail: 'Ha sido enviado un correo al email registrado, siga las instrucciones que allí encuentra, la url estará activa durante los próximos 5 minutos', life: 5000 });
                setTimeout(function(){
                    setRecuperar(false);
                }, 5000);
                return;
            }
        })
        .catch((error) => {
          console.log(error);
        })
}

const verificaData = () => {
    if(nidPropietario==='') { 
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el nombre del usuario, verifique por favor...', life: 3000 });
         return
     }
     if(correo) {
        if(correoUsuario==='') { 
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar un correo valido, verifique por favor...', life: 3000 });
            return
        }else if(!validateEmail(correoUsuario)){
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar un correo valido, verifique por favor...', life: 3000 });
            return
        }
    }
     actualizaCorreo()
}


const productDialogFooter = (
    <>
        <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={()=>verificaData()} />
    </>
);



return(
<>

<section className="vh-100">
<Toast ref={toast} />
  <div className="container-fluid h-custom">
    <div className="row d-flex justify-content-center align-items-center h-100">
<div className="tab-content">
  <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
  {verLogin &&
                // <form>
                <>

                <div className="card">
                    <h5>Ingrese sus credenciales de acceso al sistema</h5>
                    <div className="grid p-fluid">
                        <div className="col-12 md:col-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-user"></i>
                                </span>
                                <InputText placeholder="Usuario registrado"
                                value={product.usuario} onChange={(e) => onInputChange(e, 'usuario')}
                                autoFocus />
                            </div>
                            {submitted && !product.usuario && <small className="p-invalid"
                            style={{
                                color: 'red',
                            }}>El usuario es de acceso al sistema requerido.</small>}
                        </div>
                        <div className="col-12 md:col-6">
                            <Password value={product.password} onChange={(e) => onInputChange(e, 'password')}
                            required toggleMask />
                            {submitted && !product.password && <small className="p-invalid"
                            style={{
                                color: 'red',
                            }}>La clave de acceso al sistema es requerida.</small>}
                        </div>
                        {/* <div className="col-12 md:col-6">
                            <ReCAPTCHA
                                sitekey="6LeMNywhAAAAAHyKlYII9ZsMKxVBPLF9Y_NC7IYt"
                                onChange={onChangeRe}
                            />
                        </div> */}
                      <div className="formgrid grid">
                        <div className="col-4">
                    <span className="p-buttonset">
                        <Button label="Enviar" icon="pi pi-check"
                        onClick={handleLogin}/>
                    </span>
                    </div>
                    <div className="col-8">
                    <span className="p-buttonset">
                        <Button label="Recordar Contraseña" icon="pi pi-unlock" severity="success"
                        onClick={handleRecordar}/>
                    </span>
                    </div>
                </div>
                
                    </div>
                </div>
                <Dialog visible={recuperar} style={{ width: '650px' }} header={"Recuperar clave de acceso"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog} >
                <div className="formgrid grid">
                    {recuperar &&
                    <RecuperarContrasena handleUsuario={handleUsuario} correo={correo}
                    handleCorreo={handleCorreo}
                    />
                    }
                </div>
                </Dialog>

                  </>
                // </form>
}
  </div>

</div>

   </div>
   </div>
</section>
</>
)
}

export default Login
