import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { ToggleButton } from 'primereact/togglebutton';
import { Rating } from 'primereact/rating';
import { CustomerService } from '../service/CustomerService';
import { ProductService } from '../service/ProductService';

const SeleccionMontaDirectaGrupal = (props) => {
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const APIFORMULARIOA01DET = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA01aDetalle.php?op='



    const customerService = new CustomerService();
    const productService = new ProductService();
    const [reloadUsuariosDetalle, setReloadUsuariosDetalle] = useState(true)
    const [reloadDetalleConsecutivo, setReloadDetalleConsecutivo] = useState([])


   useEffect(() => {
        if (reloadUsuariosDetalle) {
            const recarga = async (CONSECUTIVO) => {
                setReloadUsuariosDetalle(false)
                setUsuariosAnco([])
                const data = new FormData();
                data.append("CONSECUTIVO", CONSECUTIVO)
                await fetch(APIFORMULARIOA01DET + 'formulariosA01aDetalleConsecutivo', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setReloadDetalleConsecutivo(response)
                            return props.setReloadUsuarios(true)
                        }else{
                            return props.handlepalnta(1500,100)
                        }
                        //setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga(props.product.CONSECUTIVO)
        }
}, [reloadUsuariosDetalle,props.product.CONSECUTIVO])

const mostrarDetalle = async(product) => {
    const data = new FormData();
    data.append("CHIP_HEMBRA", product.CHIP_HEMBRA)
    data.append("CONSECUTIVO", product.CONSECUTIVO)
    await fetch(APIFORMULARIOA01DET + 'formulariosA01aDetalleBorrar', {
        method: 'POST',
        header: {
            'Accept': 'application/json',
            'Content-type': 'application/json'
        },
        body: data
    }).then((response) => response.json())
        .then((response) => {
            if (response != []) {
                setReloadUsuariosDetalle(true)
            }
            //setValue1(false)
        })
        .catch((error) => {
            console.log(error);
        })

}


const actionBodyTemplate = (rowData) => {
    return (
        <div className="actions">
                <Button icon="pi pi-minus-circle" className="p-button-rounded p-button-danger mt-2"
                        tooltip='Quitar hembra de la Monta directa grupal'
                        tooltipOptions={{
                            className: "hoverClass",
                            position: "right"
                }}
                onClick={() => mostrarDetalle(rowData)} />

        </div>
    );
}







    return (
        <div className="grid table-demo">

            <div className="col-12">
                <div className="card">
                    <DataTable value={reloadDetalleConsecutivo}  responsiveLayout="scroll"
                         dataKey="id" header={'HEMBRAS ASOCIADAS'}>
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="CHIP_HEMBRA" header="CHIP_HEMBRA" sortable />
                        <Column field="NOMBREHEMBRA" header="NOMBRE HEMBRA" sortable />
                        <Column field="RAZA" header="RAZA" sortable />
                    </DataTable>
                </div>
            </div>
        </div>
    );
}


export default React.memo(SeleccionMontaDirectaGrupal);
