import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { locale, addLocale } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import Lateral from '../components/Lateral';

const FormularioA12SubArrendados = () => {
    let emptyProduct = {
        consecutivoArrendamiento:'',
        fecha_inicio:'',
        fecha_fin:'',
        fecha_inicioArriendo:'',
        fecha_finArriendo:'',
    };
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [deleteProductsDialogDet, setDeleteProductsDialogDet] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const APIFORMULARIOA12 = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA12.php?op='

    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
            setReloadUsuarios(false)
            setUsuariosAnco([])
            //setCargoNomina('')
            const data = new FormData();
            if(localStorage.getItem('administrador')==1){
                data.append('nid_propietario','ADMINANCO')
              }else{
                data.append('nid_propietario_s',localStorage.getItem('nid_propietario'))
              }
                await fetch(APIFORMULARIOA12 + 'subArrendados', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setReloadUsuarios(false)
                            setUsuariosAnco(response)
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    const hideDialogC = () => {
        setDeleteProductsDialogDet(false);
    }




    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">REPRODUCTORES SUB ARRENDADOS</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="Buscar" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

        const productDialogFooterC = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogC} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" ></Toolbar>
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="CONSECUTIVO" header="Consecutivo" sortable ></Column>
                        <Column field="NID_PROPIETARIO" header="Tipo Documento" sortable ></Column>
                        <Column field="nomarrendador" header="Nombre" sortable ></Column>
                        <Column field="CONSECUTIVO_ARRENDAMIENTO" header="Consecutivo subarriendo" sortable ></Column>
                        <Column field="crg_animal" header="Chip" sortable ></Column>
                        <Column field="FECHA_INICIAL" header="Fecha Inicial" sortable ></Column>
                        <Column field="FECHA_FINAL" header="Fecha Final" sortable ></Column>
                        <Column field="DEPARTAMENTO" header="Departamento" sortable ></Column>
                        <Column field="MUNICIPIO" header="Municipio" sortable ></Column>
                        <Column field="CRIADERO" header="Criadero" sortable ></Column>
                        <Column field="DIRECCION" header="Direccion" sortable ></Column>
                    </DataTable>
                    </div>
                        {/* <div className="field col-3"><Lateral/>
                    </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA12SubArrendados, comparisonFn);
