import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Lateral from '../components/Lateral';
const Formularios = () => {
    let emptyProduct = {
        id:0,
        codigo:'',
        descripcion:'',
        valor:'',
        anio:'',
    };
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [estado, setEstado] = useState(localStorage.getItem('administrador'))
    
    const FORMULARIOS = 'https://www.ancogenecop.com/APIANCO/controllers/formularios.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                await fetch(FORMULARIOS + 'formularios', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setProducts(response)
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }


    const saveProduct = async() => {
        setSubmitted(true);
         if(product.codigo=='') {console.log(1); return}
         if(product.descripcion=='') {console.log(2); return}
         if(product.valor=='') {console.log(3); return}
         if(product.anio=='') {console.log(4); return}
        const data = new FormData();
        data.append("id", product.id)
        data.append("codigo", product.codigo)
        data.append("descripcion", product.descripcion)
        data.append("valor", product.valor)
        data.append("anio", product.anio)
        await fetch(FORMULARIOS + 'ingresarFormulario', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {/* <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} /> */}
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Importar" chooseLabel="Importar" className="mr-2 inline-block" />
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    function calcularEdad(fecha, opcion) {

        if (opcion == 1) {
            product.FECHA_RECEPCION = new Date(fecha).toISOString().slice(0, 10)
        }
    }



    const actionBodyTemplate = (rowData) => {
        return (
                <div className="actions">
                    {estado == 1  &&
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                    }                    
                </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Formularios FEDEANCO</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="codigo" header="CODIGO" sortable ></Column>
                        <Column field="descripcion" header="NOMBRE" sortable ></Column>
                        <Column field="valor" header="VALOR" sortable ></Column>
                        <Column field="anio" header="VIGENCIA" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle del Formulario" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col-3">
                            <label htmlFor="name">CODIGO </label>
                            <InputText id="name" value={product.codigo} onChange={(e) => onInputChange(e, 'codigo')} readOnly
                            autoFocus
                            className={classNames({ 'p-invalid': submitted && !product.CHIP_MAESTRO })} />
                            {submitted && !product.codigo && <small className="p-invalid">El código del formulario es requerido.</small>}
                        </div>
                        <div className="field col-9">
                            <label htmlFor="name">NOMBRE DEL FORMULARIO</label>
                            <InputText id="name" value={product.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} required
                            className={classNames({ 'p-invalid': submitted && !product.descripcion })} />
                             {submitted && !product.descripcion && <small className="p-invalid">La descripción del formulario es requerida.</small>}
                        </div>
                        <div className="formgrid grid">
                        <div className="field col-6">
                            <label htmlFor="name">VALOR DEL FORMULARIO</label>
                            <InputText id="name" value={product.valor} onChange={(e) => onInputChange(e, 'valor')} required
                            className={classNames({ 'p-invalid': submitted && !product.descripcion })} />
                            {submitted && !product.valor && <small className="p-invalid">El valor del formulario es requerida.</small>}
                        </div>
                        <div className="field col-6">
                            <label htmlFor="name">AÑO DE VIGENCIA</label>
                            <InputText id="name" value={product.anio} onChange={(e) => onInputChange(e, 'anio')} required
                            className={classNames({ 'p-invalid': submitted && !product.descripcion })} />
                            {submitted && !product.anio && <small className="p-invalid">La vigencia formulario es requerida.</small>}
                        </div>
                        </div>
                    </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Formularios, comparisonFn);
