import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';

const WhatsApp = () => {

    const [whatsapp, setWhatsapp] = useState([])
    const [codigoPais, setCodigoPais] = useState('')
    const [reload, setReload] = useState(true)
    const [mensaje, setMensaje] = useState('')
    const [finMensaje, setFinMensaje] = useState('')
    const [nombresUsuario, setNombresUsuario] = useState('')
    const APIPARAMETROS = 'https://www.ancogenecop.com/APIANCO/controllers/parametros.php?op='
        useEffect(() => {
            if (reload) {
                const recarga = async () => {
                    setReload(false)
                    const data = new FormData();
                    await fetch(APIPARAMETROS + 'parametros', {
                        method: 'POST',
                        header: {
                            'Accept': 'application/json',
                            'Content-type': 'application/json'
                        },
                        body: data
                    }).then((response) => response.json())
                        .then((response) => {
                        console.log(response)
                            if (response != []) {
                                setWhatsapp(response[0].whatsapp)
                                setCodigoPais(response[0].codigoPais)
                                setMensaje(response[0].mensaje)
                                setNombresUsuario(response[0].tituloaprisco)
                                setFinMensaje(response[0].finMensaje)
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
                recarga()
            }
        }, [reload])
        
    return (
    <>
            {/* <a href ={"https://api.whatsapp.com/send?phone="+codigoPais+whatsapp+"&text=Hola como estas para " + nombresUsuario+finMensaje} target="_blank"><img  src="https://www.ancogenecop.com/APIANCO/documentos/WhatsApp.png" width={40} height={40}/></a> */}
</>                 

    );
}


export default React.memo(WhatsApp);
