import React from 'react';
import { Button } from 'primereact/button';
import WhatsApp from './pages/WhatsApp'
export const AppFooter = (props) => {

    return (
        <div className="layout-footer">
            <div className="formgrid grid">
            {/* <div className="field col">
            <Button className="facebook p-0" aria-label="Facebook"
            onClick={() => window.open("https://www.facebook.com/profile.php?id=100064144357324&ref=page_internal", "_blank")}
            tooltip='Siguenos en Facebook'
                    tooltipOptions={{
                        className: "hoverClass",
                        position: "right"
            }}>
                <i className="pi pi-facebook px-2"></i>
            </Button>
            </div> */}
            {/* <div className="field col">
            <Button className="youtube p-0" aria-label="Youtube"
            onClick={() => window.open("https://www.youtube.com/channel/UCqII7MkhojaA3eEfok5BTmA", "_blank")}
            tooltip='Siguenos en Youtube'
                    tooltipOptions={{
                        className: "hoverClass",
                        position: "right"

            }}>
                <i className="pi pi-youtube px-2"></i>
            </Button>
            </div> */}
            {/* <div className="field col">
            <Button className="instagram p-0" aria-label="Instagram"
            onClick={() => window.open("https://www.instagram.com/fedeanco/", "_blank")}
            tooltip='Siguenos en Instagram'
                    tooltipOptions={{
                        className: "hoverClass",
                        position: "right"
            }}>
                <i className="pi pi-instagram px-2"></i>
            </Button>
            </div> */}
            <div className="field col ">
                {/* <WhatsApp/>                     */}
            </div>
            </div>
        </div>
    );
}
