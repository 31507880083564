import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Lateral from '../components/Lateral';
const Prefijos = () => {
    let emptyProduct = {
        ID:'',
        PREFIJO:'',
        CRIADERO:'',
        FECHA_SOLICITUD:'',
        FECHA_ASIGNACION:'',
        PREFIJO_SOLICITADO1:'',
        PREFIJO_SOLICITADO2:'',
        PREFIJO_SOLICITADO3:'',
    };
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [value1, setValue1] = useState(true)
    const toast = useRef(null);
    const dt = useRef(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const TERCEROS = 'https://www.ancogenecop.com/APIANCO/controllers/prefijos.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                await fetch(TERCEROS + 'prefijos', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setProducts(response)
                        }
                    setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])


    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }


    const saveProduct = async() => {
        setSubmitted(true);
         if(product.PREFIJO=='') {console.log(1); return}
         if(product.CRIADERO=='') {console.log(2); return}
         if(product.FECHA_SOLICITUD=='') {console.log(3); return}
         if(product.FECHA_ASIGNACION=='') {console.log(4); return}
        const data = new FormData();
        data.append("ID", product.ID)
        data.append("PREFIJO", product.PREFIJO)
        data.append("PREFIJO_SOLICITADO1", product.PREFIJO_SOLICITADO1)
        data.append("PREFIJO_SOLICITADO2", product.PREFIJO_SOLICITADO2)
        data.append("PREFIJO_SOLICITADO3", product.PREFIJO_SOLICITADO3)
        data.append("CRIADERO", product.CRIADERO)
        data.append("FECHA_SOLICITUD", product.FECHA_SOLICITUD)
        data.append("FECHA_ASIGNACION", product.FECHA_ASIGNACION)
                await fetch(TERCEROS + 'ingresarPrefijo', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                }if(response==97){
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El prefijo digitado ya esta en uso, verifique por favor...', life: 3000 });
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
    }



    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Importar" chooseLabel="Importar" className="mr-2 inline-block" />
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    function calcularEdad(fecha, opcion) {

        if (opcion == 1) {
            product.FECHA_RECEPCION = new Date(fecha).toISOString().slice(0, 10)
        }
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Prefijos</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );


    function calcularEdad(fecha,opcion) {

        if(opcion==1){
            product.FECHA_SOLICITUD=new Date(fecha).toISOString().slice(0, 10)
        }else if(opcion==2){
            product.FECHA_ASIGNACION=new Date(fecha).toISOString().slice(0, 10)
        }
    }


    return (
    <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }

        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="TIDSOCIO" header="TID_ASOCIACION" sortable ></Column>
                        <Column field="NID_ASOCIACION" header="NID_ASOCIACION" sortable ></Column>
                        <Column field="NASOCIACION" header="ASOCIACION" sortable ></Column>
                        <Column field="descripcion" header="TID_SOCIO" sortable ></Column>
                        <Column field="NID_SOCIO" header="NID_SOCIO" sortable ></Column>
                        <Column field="NOMSOCIO" header="ASOCIADO" sortable ></Column>
                        <Column field="CRIADERO" header="CRIADERO" sortable ></Column>
                        <Column field="DEPARTAMENTO" header="DEPARTAMENTO" sortable ></Column>
                        <Column field="MUNICIPIO" header="MUNICIPIO" sortable ></Column>
                        <Column field="DIRECCION" header="DIRECCION" sortable ></Column>
                        <Column field="FECHA_SOLICITUD" header="FECHA_SOLICITUD" sortable ></Column>
                        <Column field="FECHA_ASIGNACION" header="FECHA_ASIGNACION" sortable ></Column>
                        <Column field="PREFIJO" header="PREFIJO" sortable ></Column>
                        <Column field="PREFIJO_SOLICITADO1" header="PREFIJO_SOLICITADO1" sortable ></Column>
                        <Column field="PREFIJO_SOLICITADO2" header="PREFIJO_SOLICITADO2" sortable ></Column>
                        <Column field="PREFIJO_SOLICITADO3" header="PREFIJO_SOLICITADO3" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle del Tercero" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col">
                                <label htmlFor="name">Criadero</label>
                                <InputText id="name" value={product.CRIADERO} onChange={(e) => onInputChange(e, 'CRIADERO')} required />
                                {submitted && !product.CRIADERO && <small className="p-invalid" required
                            style={{
                                color: 'red',
                            }}>La nombre del criadero es requeriso.</small>}
                        </div>
                        <div className="field col">
                                <label htmlFor="name">Prefijo principal</label>
                                <InputText id="name" value={product.PREFIJO} onChange={(e) => onInputChange(e, 'PREFIJO')} required />
                                {submitted && !product.PREFIJO && <small className="p-invalid" required
                            style={{
                                color: 'red',
                            }}>El prefijo principal es requerido.</small>}
                        </div>
                        <div className="field col">
                                <label htmlFor="name">Prefijo 1</label>
                                <InputText id="name" value={product.PREFIJO_SOLICITADO1} onChange={(e) => onInputChange(e, 'PREFIJO_SOLICITADO1')}  />
                        </div>
                        <div className="field col">
                                <label htmlFor="name">Prefijo 2</label>
                                <InputText id="name" value={product.PREFIJO_SOLICITADO2} onChange={(e) => onInputChange(e, 'PREFIJO_SOLICITADO2')}  />
                        </div>
                        <div className="field col">
                                <label htmlFor="name">Prefijo 3</label>
                                <InputText id="name" value={product.PREFIJO_SOLICITADO3} onChange={(e) => onInputChange(e, 'PREFIJO_SOLICITADO3')}  />
                        </div>
                    </div>
                    <hr/>
                    <div className="formgrid grid">
                        <div className="field col">
                        <label htmlFor="dirección">Fecha de Solicitud: {product.FECHA_SOLICITUD}</label>
                            <Calendar showIcon showButtonBar value={product.FECHA_SOLICITUD}
                                dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                yearRange="1960:2060" required
                                readOnlyInput={true}
                                onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                            {submitted && !product.FECHA_SOLICITUD && <small className="p-invalid"
                            style={{
                            color: 'red',
                            }}>la fecha de solicitud es requerida.</small>}
                         </div>
                         <div className="field col">
                        <label htmlFor="dirección">Fecha de asignación: {product.FECHA_ASIGNACION}</label>
                            <Calendar showIcon showButtonBar value={product.FECHA_ASIGNACION}
                                dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                yearRange="1960:2060" required
                                readOnlyInput={true}
                                onChange={(e) => calcularEdad(e.value, 2)}></Calendar>
                         </div>
                         {submitted && !product.FECHA_ASIGNACION && <small className="p-invalid"
                            style={{
                            color: 'red',
                            }}>la fecha de asignación es requerida.</small>}

                    </div>
                    </Dialog>


                </div>
            </div>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Prefijos, comparisonFn);
