import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { Fieldset } from 'primereact/fieldset';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import EditorText from './Editor'
const EditorContenido = () => {
    let emptyProduct = {
        descripcionAyuda:'',
        codigoAyuda:'',
        adicionales:'',
        imagen:'',
        NID_SOLICITANTE:'',
        CONSECUTIVO:''
   };
    const [file, setFile] = useState(null)
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [editar, setEditar] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const [value1, setValue1] = useState(true)
    const [id, setId] = useState(0)
    const [aprobar, setAprobar] = useState(false)
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [estado, setEstado] = useState(true)
    const APIAYUDA = 'https://www.ancogenecop.com/APIANCO/controllers/ayuda.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                data.append("dato",'')
                await fetch(APIAYUDA + 'ayudaGeneral', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        //console.log(response)
                        if (response != []) {
                            console.log(response)
                            setValue1(false)
                            setProducts(response)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])



    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setReloadUsuarios(true)
    }


    const editProduct = (product) => {
        setEstado(false)
        setProduct(product);
        setProductDialog(true);
    }




    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)}
                    tooltip='Editar la ayuda '
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Ayudas del Aplicativo</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            {/* <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} /> */}
        </>
    );


    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${rowData.imagenAyuda}`} alt={rowData.imagenAyuda} className="shadow-2" width="100"
                onClick={()=>onLoadingClick(rowData.imagenAyuda)}/>
            </>
        )
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }


    const convertir = (datos) =>{
        return (
            <p dangerouslySetInnerHTML={{ __html: datos }}></p>
        )
    }


    const openNew = () => {
        setEstado(true)
        setProduct(emptyProduct);
        setProductDialog(true);
    }

    const onLoadingClick = (soporte) => {
        window.open(soporte, "_blank")
    }


    return (
    <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado ayudas por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle de la Ayuda" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <EditorText product={product} estado={estado} hideDialog={hideDialog}/>                        
                    </Dialog>
                    
            {products.map((datos, index) => (
                <div key={index}>
                    <Fieldset legend={datos.codigoAyuda} toggleable collapsed>
                        <Card className="li" >
                                <span><Button label="Editar" icon="pi pi-check" 
                                onClick={() => editProduct(datos)}
                                /></span>                            
                                <div >{convertir(datos.descripcionAyuda)} </div>
                                <hr />
                                <div className="card-footer">
                                    <img src={`${datos.imagenAyuda}`} alt={datos.imagenAyuda} />                            
                                </div>
                        </Card>
                    </Fieldset>
                </div>
            ))}

                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle de la Ayuda" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                            <EditorText product={product} estado={estado} hideDialog={hideDialog}/>                        
                    </Dialog>

                </div>
            </div>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(EditorContenido, comparisonFn);
