import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { locale, addLocale } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionUsuario from '../components/SeleccionUsuario';
import SeleccionCodigos from '../components/SeleccionCodigos';
import SeleccionChipsHembras from '../components/SeleccionChipsHembras';
import SeleccionTipoDocumento from '../components/SeleccionTipoDocumento';
import SeleccionFormularios from '../components/SeleccionFormularios';
import SeleccionConsecutivoServicio from '../components/SeleccionConsecutivoServicio';
import SeleccionInseminador from '../components/SeleccionInseminador';
import Lateral from '../components/Lateral';
import Ayuda from '../components/Ayuda'

const FormularioA03 = () => {
    let emptyProduct = {
        CONSECUTIVO:'',
        FECHA_SOLICITUD:'',
        ESPECIE:'',
        FECHA_SERVICIO:'',
        CRG_MACHO:'',
        CRG_HEMBRA:'',
        IDENTIF_EMBRION:'',
        PAIS:'',
        IDENTIF_RECEPTORA:'',
        RAZA:'',
        TID_PROFESIONAL:'',
        NID_PROFESIONAL:'',
        OBSERVACIONES:'',
        TID_TERCERO:'',
        NID_TERCERO:'',
        SOPORTE:'',
        CONSECUTIVO_SERVICIO:'',
        FORMULARIO:'',
        NUMERO_EMBRIONES:'',
        PAIS:'51',
        NOMBRE_DEL_MACHO:'',
        NOMBRE_DE_LA_HEMBRA:''
    };
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [muestraCodigos, setMuestraCodigos] = useState(false)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [fechaServicio, setFechaServicio] = useState('')
    const [fechaSolicitud, setFechaSolicitud] = useState('')
    const [codigoEspecie, setCodigoEspecie] = useState('')
    const [muestraRaza, setMuestraRaza] = useState(false)
    const [nomRaza, setNomRaza] = useState('')
    const [codigoRaza, setCodigoRaza] = useState(0)
    const [parentesco, setParentesco] = useState('')
    const [muestraParentesco, setMuestraParentesco] = useState(false)
    const [reloadPrefijo, setReloadPrefijo] = useState(false)
    const [prefijo, setPrefijo] = useState('')
    const [enviar, setEnviar] = useState(false)
    const [muestraConsecutivo, setMuestraConsecutivo] = useState(false)
    const [consecutivoServicio, setConsecutivoServicio] = useState(0)
    const [reloadDatosConsecutivo, setReloadDatosConsecutivo] = useState(false)
    const [mdatosConsecutivo, setMdatosConsecutivo] = useState([])
    const [codigo, setCodigo] = useState(0)
    const [inputNumberValue, setInputNumberValue] = useState(null);
    const [pajillasPendientes, setPajillasPendientes] = useState(false)
    const [muestraUsuario, setMuestraUsuario] = useState(false)
    const [pendientes, setPendientes] = useState(0)
    const [minimo, setMinimo] = useState(0)
    const [file, setFile] = useState(null)
    const [tidPropietario, setTidPropietario] = useState('')
    const [mostrarHembras, setMostrarHembras] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const [ayuda, setAyuda] = useState(false)    
    const APIFORMULARIOA03 = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA03.php?op='


    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                setUsuariosAnco([])
                const data = new FormData();
                if(localStorage.getItem('administrador')==1){
                    if(nidPropietario==''){
                        data.append('NID_TERCERO','ADMINANCO')
                    }else{
                        data.append('NID_TERCERO',nidPropietario)
                    }
                  }else{
                    data.append('NID_TERCERO',localStorage.getItem('nid_propietario'))
                  }

                await fetch(APIFORMULARIOA03 + 'formulariosA03', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setUsuariosAnco(response)
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    useEffect(() => {
        if (reloadDatosConsecutivo) {
            setReloadDatosConsecutivo(false)
            const recarga = async (consecutivoServicio,codigo) => {
                setUsuariosAnco([])
                setPendientes(0)
                setMinimo(0)
                const data = new FormData();
                data.append("CONSECUTIVO", consecutivoServicio)
                data.append("CODIGO", codigo)
                await fetch(APIFORMULARIOA03 + 'datosConsecutivoServicio', {
                    method: 'POST',
                    header: {

                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                             setMdatosConsecutivo(response)
                             setPajillasPendientes(true)
                             setReloadUsuarios(true)
                             setPendientes(parseInt(response[0].PENDIENTES))
                             setNomRaza(response[0].RAZA)
                             if(parseFloat(response[0].PENDIENTES)>0){
                                setMinimo(1)
                             }
                            product.ESPECIE = response[0].ESPECIE
                            product.CRG_MACHO = response[0].CRG_MACHO
                            product.CRG_HEMBRA = response[0].CRG_HEMBRA
                            product.NOMBRE_DEL_MACHO = response[0].NOMBRE_DEL_MACHO
                            product.NOMBRE_DE_LA_HEMBRA = response[0].NOMBRE_DE_LA_HEMBRA
                            product.RAZA = response[0].RAZA
                            setMostrarHembras(true)
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga(consecutivoServicio,codigo)
        }
    }, [reloadDatosConsecutivo, consecutivoServicio,codigo])


    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductDialog = () => {
        setAyuda(false);
    }

    const deleteProductDialogFooter = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
        </>
    );

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false)
        setMuestraRaza(false)
        setMuestraParentesco(false)
        setProductDialog(true);
        setMdatosConsecutivo([])
        setEnviar(true)
        setInputNumberValue('')
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false);
    }



    const saveProduct = async () => {
        setSubmitted(true)
        if(product.CONSECUTIVO=='') {
            console.log(1);
            return
         }
         if(product.TID_TERCERO=='') {
         console.log(2)
            return
         }
         if(product.NID_TERCERO=='') {
         console.log(3)
            return
         }
         if(product.FECHA_SOLICITUD=='') {
         console.log(4);
            return
         }
         if(product.FECHA_SERVICIO=='') {
         console.log(5)
            return
         }
         if(product.TID_PROFESIONAL=='') {
         console.log(7);
            return
         }
         if(product.NID_PROFESIONAL=='') {
         console.log(8)
            return
         }
         if(product.SOPORTE=='') {
         console.log(9)
            return
         }
         if(product.CONSECUTIVO_SERVICIO=='') {
         console.log(12)
            return
         }
         if(product.IDENTIF_EMBRION=='') {
            console.log(13)
               return
        }
        if(product.IDENTIF_RECEPTORA=='') {
            console.log(14)
               return
        }

        const data = new FormData();
        data.append("CONSECUTIVO", product.CONSECUTIVO)
        data.append("FECHA_SOLICITUD", product.FECHA_SOLICITUD)
        data.append("ESPECIE", product.ESPECIE)
        data.append("FECHA_SERVICIO", product.FECHA_SERVICIO)
        data.append("CRG_MACHO", product.CRG_MACHO)
        data.append("CRG_HEMBRA", product.CRG_HEMBRA)
        data.append("IDENTIF_EMBRION", product.IDENTIF_EMBRION)
        data.append("PAIS", product.PAIS)
        data.append("IDENTIF_RECEPTORA", product.IDENTIF_RECEPTORA)
        data.append("RAZA", product.RAZA)
        data.append("TID_PROFESIONAL", product.TID_PROFESIONAL)
        data.append("NID_PROFESIONAL", product.NID_PROFESIONAL)
        data.append("OBSERVACIONES", product.OBSERVACIONES)
        data.append("TID_TERCERO", product.TID_TERCERO)
        data.append("NID_TERCERO", product.NID_TERCERO)
        data.append("SOPORTE", product.SOPORTE)
        data.append("CONSECUTIVO_SERVICIO", product.CONSECUTIVO_SERVICIO)
        data.append("FORMULARIO", product.FORMULARIO)
        data.append("NUMERO_EMBRIONES", inputNumberValue)
        data.append("NOMBRE_DEL_MACHO", product.NOMBRE_DEL_MACHO)
        data.append("NOMBRE_DE_LA_HEMBRA", product.NOMBRE_DE_LA_HEMBRA)
        data.append("CODIGO", 'A03')
        data.append("file",file)
        await fetch(APIFORMULARIOA03 + 'ingresarInseminacion', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
        setMuestraCodigos(true)
        setMuestraRaza(true)
        setMuestraParentesco(true)
        setNidPropietario(product.NID_PROPIETARIO)
        setCodigoRaza(product.COD_ESPECIE)
        setParentesco(product.PARENTESCO)
        setFechaServicio(product.FECHA_SERVICIO)
        setFechaSolicitud(product.FECHA_SOLICITUD)
        setEnviar(true)
    }

    const confirmDeleteProduct = (product) => {
        setReloadUsuarios(true)
        setProduct({ ...product });
        setMuestraUsuario(true)
        setProductDialog(true);
        setMuestraCodigos(true)
        setMuestraRaza(true)
        setMuestraConsecutivo(true)
        setReloadDatosConsecutivo(true)
        setEnviar(false)
        setNidPropietario(product.NID_TERCERO)
        setCodigoRaza(product.COD_ESPECIE)
        setParentesco(product.PARENTESCO)
        setFechaServicio(product.FECHA_SERVICIO)
        setFechaSolicitud(product.FECHA_SOLICITUD)
        setCodigoEspecie(product.COD_ESPECIE)
        setPrefijo(product.PREFIJO_SOLICITANTE)
        setConsecutivoServicio(product.CONSECUTIVO_SERVICIO)
        setInputNumberValue(product.NUMERO_EMBRIONES)
        setCodigo(product.FORMULARIO)

    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/* <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} /> */}
                <Button icon="pi pi-search" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">TRANSFERENCIA DE EMBRIONES FORMULARIO A03</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="Buscar" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const headerDatos = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">INFORMACION CONTENIDA EN EL TIPO DE FORMULARIO Y EL CONSECUTIVO DE SERVICIO</h5>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label={enviar ? "Cancelar" : "Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {enviar &&
                <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
            }

        </>
    );

    const handleplanta = async (dato, opc,opcion) => {
        setSubmitted(false)
        if (opc == 1) {
            setNidPropietario(dato)
            setMuestraCodigos(false)
            product.NID_TERCERO = dato
        } else if (opc == 2) {
            product.CONSECUTIVO = dato
        } else if (opc == 3) {
            setCodigoEspecie(dato)
            product.COD_ESPECIE = dato
        } else if (opc == 4) {
            setCodigoRaza(dato)
            setMuestraRaza(true)
            product.RAZA_MACHO = dato
        }else if (opc == 5) {
            product.CHIP_MACHO = dato
        }else if (opc == 8) {
            product.IDENTIF_RECEPTORA = dato
        } else if (opc == 9) {
            setMuestraUsuario(true)
            setTidPropietario(dato)
            if(opcion==0){
                product.TID_TERCERO = dato
            }else{
                product.TID_PROFESIONAL = dato
            }
        } else if (opc == 10) {
            setConsecutivoServicio(true)
            setCodigo(dato)
            product.FORMULARIO = dato
        } else if (opc == 11) {
            setReloadDatosConsecutivo(true)
            setConsecutivoServicio(dato)
            product.CONSECUTIVO_SERVICIO = dato
        } else if (opc == 12) {
            product.NID_PROFESIONAL = dato
        }
        else if (opc == 13) {
            product.TID_PROFESIONAL = dato
        }
        else if (opc == 1000) {
            setNidPropietario(0)
            setMuestraCodigos(false)
        }
    }


    const imageBodyTemplate1 = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${rowData.SOPORTE}`} alt={rowData.SOPORTE} className="shadow-2" width="100"
                onClick={()=>onLoadingClick(rowData.SOPORTE)}/>
            </>
        )
    }


    const handleChange = (e) => {
        setFile(e.target.files[0]);
        product.SOPORTE=e.target.files[0].name;
      };

    function calcularEdad(fecha, opcion) {

        if (opcion == 1) {
            setFechaSolicitud(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_SOLICITUD = new Date(fecha).toISOString().slice(0, 10)
        } else if (opcion == 2) {
            setFechaServicio(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_SERVICIO = new Date(fecha).toISOString().slice(0, 10)
        }
    }

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${rowData.SOPORTE}`} alt={rowData.SOPORTE} className="shadow-2" width="100"
                onClick={()=>onLoadingClick(rowData.SOPORTE)}/>
            </>
        )
    }

    const onLoadingClick = (soporte) => {
        window.open(soporte, "_blank")
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="CEDULATERCERO" header="TID_SOLICITANTE" sortable ></Column>
                        <Column field="NID_TERCERO" header="NID_SOLICITANTE" sortable ></Column>
                        <Column field="TERCERO" header="NOMBRE_SOLICITANTE" sortable ></Column>
                        <Column field="CONSECUTIVO" header="CONSECUTIVO" sortable ></Column>
                        <Column field="NOMBREFORMULARIO" header="FORMULARIO" sortable ></Column>
                        <Column field="CONSECUTIVO_SERVICIO" header="CONSECUTIVO_SERVICIO" sortable ></Column>
                        <Column field="FECHA_SOLICITUD" header="FECHA_SOLICITUD" sortable ></Column>
                        <Column field="FECHA_SERVICIO" header="FECHA_SERVICIO" sortable ></Column>
                        <Column field="NOMESPECIE" header="ESPECIE" sortable ></Column>
                        <Column field="RAZA" header="RAZA" sortable ></Column>
                        <Column field="CRG_MACHO" header="CRG_MACHO" sortable ></Column>
                        <Column field="NOMBRE_DEL_MACHO" header="NOMBRE_MACHO" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="CRG_HEMBRA" header="CRG_HEMBRA" sortable ></Column>
                        <Column field="NOMBRE_DE_LA_HEMBRA" header="NOMBRE_HEMBRA" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="IDENTIF_EMBRION" header="IDENTIF_EMBRION" sortable ></Column>
                        <Column field="IDENTIF_RECEPTORA" header="IDENTIF_RECEPTORA" sortable ></Column>
                        <Column field="CEDULAEJECUTOR" header="TID_PROFESIONAL" sortable ></Column>
                        <Column field="NID_PROFESIONAL" header="NID_PROFESIONAL" sortable ></Column>
                        <Column field="EJECUTOR" header="NOMBRE_PROFESIONAL" sortable ></Column>
                        <Column field="SOPORTE" header="DOCUMENTO_SOPORTE" body={imageBodyTemplate1} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="OBSERVACIONES" header="OBSERVACIONES" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '850px' }} header={enviar ? "Adicionar Transfrencia de embriones" : "Consultando transferencia de embriones"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                        <div className="formgrid grid">
                            <div className="field col-6">
                                <label htmlFor="name">Tipo Documento</label>
                                <SeleccionTipoDocumento handleplanta={handleplanta} opcion={9} descripcion={product.CEDULATERCERO}/>
                                {submitted && !product.TID_TERCERO && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El tipo de documento es requerido.</small>}
                            </div>
                            {/* {muestraUsuario && */}
                            <div className="field col">
                                <label htmlFor="name">Usuario</label>
                                <SeleccionUsuario codigo={'A03'} handleplanta={handleplanta} NID_PROPIETARIO={product.TERCERO}
                                 tidPropietario={tidPropietario}
                                />
                                {submitted && !product.NID_TERCERO && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>Nombre del usuario es requerido.</small>}
                            </div>
                            {/* } */}
                            <hr />
                        </div>
                        <div className="formgrid grid">
                            {/* {muestraCodigos && nidPropietario > 0 && */}
                                <>
                                    <div className="field col">
                                        <label htmlFor="name">Consecutivo</label>
                                        <SeleccionCodigos NID_PROPIETARIO={nidPropietario}
                                            codigo={'A03'} CONSECUTIVO={product.CONSECUTIVO} handleplanta={handleplanta} />
                                        {submitted && !product.CONSECUTIVO && <small className="p-invalid " style={{
                                          color: 'red',
                                        }}>El consecutivo es requerido.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="name">Tipo de Formulario</label>
                                        <SeleccionFormularios
                                        formulario={'A08'} formulario1={'A09'}
                                        formulario2={''} formulario3={''}
                                        codigo={'A03'}
                                        FORMULARIO={product.NOMBREFORMULARIO}
                                        handleplanta={handleplanta} />
                                        {submitted && !product.FORMULARIO && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>El tipo de formulario es requerido.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="name">Consecutivo del Servicio</label>
                                        <SeleccionConsecutivoServicio codigo={codigo} NID_PROPIETARIO={nidPropietario}
                                        CONSECUTIVO_SERVICIO={product.CONSECUTIVO_SERVICIO}
                                        handleplanta={handleplanta} />
                                        {submitted && !product.CONSECUTIVO_SERVICIO && <small className="p-invalid"
                                        style={{
                                          color: 'red',
                                        }}>El consecutivo de servicio es requerido.</small>}
                                    </div>

                                </>
                            {/* } */}
                        </div>
                        <hr />
                        {/* {muestraConsecutivo && */}
                            <>
                                <div className="formgrid grid">
                                <DataTable ref={dt} value={mdatosConsecutivo}
                                    globalFilter={globalFilter} emptyMessage="No existen registros." header={headerDatos} responsiveLayout="scroll">
                                    <Column field="NOM_ESPECIE" header="COD_ESPECIE" sortable ></Column>
                                    <Column field="RAZA" header="RAZA" sortable ></Column>
                                    <Column field="NOMBRE_DEL_MACHO" header="NOMBRE_MACHO" sortable ></Column>
                                    <Column field="CRG_MACHO" header="CRG_MACHO" sortable ></Column>
                                    <Column field="CRG_PADRE_MACHO" header="PADRE" sortable ></Column>
                                    <Column field="CRG_MADRE_MACHO" header="MADRE" sortable ></Column>
                                    <Column field="NOMBRE_DE_LA_HEMBRA" header="NOMBRE_HEMBRA" sortable ></Column>
                                    <Column field="CRG_HEMBRA" header="CRG_HEMBRA" sortable ></Column>
                                    <Column field="CRG_PADRE_HEMBRA" header="PADRE" sortable ></Column>
                                    <Column field="CRG_MADRE_HEMBRA" header="MADRE" sortable ></Column>
                                </DataTable>

                                <hr/>
                                </div>
                                <hr />
                                <div className="grid">
                                    <div className="col-12 md:col-6">
                                        <label htmlFor="dirección">Fecha de Solicitud: {fechaSolicitud}</label>
                                        <Calendar showIcon showButtonBar value={fechaSolicitud}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            yearRange="1960:2060" required
                                            readOnlyInput={true}
                                            locale='es'
                                            onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                                        {submitted && !product.FECHA_SOLICITUD && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>la fecha de solicitud es requerida.</small>}
                                    </div>
                                    <div className="col-12 md:col-6">
                                        <label htmlFor="dirección">Fecha de Servicio: {fechaServicio}</label>
                                        <Calendar showIcon showButtonBar value={fechaServicio}
                                            dateFormat="yy-mm-dd" monthNavigator={true}
                                            locale='es'
                                            yearNavigator={true}
                                            yearRange="1960:2060" required
                                            onChange={(e) => calcularEdad(e.value, 2)}></Calendar>
                                        {submitted && !product.FECHA_SERVICIO && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>La fecha de servicio es requerida.</small>}
                                    </div>
                                </div>
                                <hr />
                                <div className="formgrid grid">
                                {mostrarHembras &&
                                    <div className="field col">
                                        <label htmlFor="name">Crg. Hembra</label>
                                        <SeleccionChipsHembras COD_ESPECIE={mdatosConsecutivo["ESPECIE"]}
                                            NID_PROPIETARIO={nidPropietario}
                                            RAZA={nomRaza}
                                            SEXO='H'
                                            PARENTESCO={''}
                                            CHIPMACHO={''}
                                            codigo={'A03'}
                                            CHIP_HEMBRA={product.IDENTIF_RECEPTORA}
                                            CONSECUTIVO={'1'}
                                            handleplanta={handleplanta} />
                                        {submitted && !product.IDENTIF_RECEPTORA && <small className="p-invalid"
                                        style={{
                                          color: 'red',
                                        }}>El número de chip de la hembra es requerido.</small>}
                                    </div>
                                }
                                    {pajillasPendientes &&
                                        <div className="field col">
                                            <label htmlFor="name">Embriones Disponibles:{pendientes}</label>
                                            <InputNumber value={inputNumberValue} max={pendientes} min={minimo} onValueChange={(e) => setInputNumberValue(e.value)} showButtons mode="decimal"></InputNumber>
                                            {submitted && (!inputNumberValue || inputNumberValue > pendientes) && <small className="p-invalid"
                                            style={{
                                              color: 'red',
                                            }}>La cantidad de embriones no es correcta.</small>}
                                        </div>
                                      }
                                <div className="field col">
                                    <label htmlFor="description">Identiicación Embrión</label>
                                    <InputText id="IDENTIF_EMBRIO" value={product.IDENTIF_EMBRION} onChange={(e) => onInputChange(e, 'IDENTIF_EMBRION')} required rows={3} cols={20} />
                                    {submitted && submitted && !product.IDENTIF_EMBRION && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                    }}>La identificación del embrión es requerida.</small>}
                                </div>

                                </div>
                                <hr />
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="name">Tipo Documento</label>
                                        <SeleccionTipoDocumento handleplanta={handleplanta} opcion={0} descripcion={product.CEDULAEJECUTOR}/>
                                        {submitted && !product.TID_PROFESIONAL && <small className="p-invalid"
                                        style={{
                                          color: 'red',
                                        }}>El tipo de documento es requerido.</small>}
                                    </div>

                                    <div className="field col">
                                        <label htmlFor="name">Inseminador</label>
                                        <SeleccionInseminador codigo={'A'} handleplanta={handleplanta} NOMBREINSEMINADOR={product.EJECUTOR} />
                                        {submitted && !product.NID_PROFESIONAL && <small className="p-invalid"
                                        style={{
                                          color: 'red',
                                        }}>Nombre del inseminador es requerido.</small>}
                                    </div>
                                    <hr />
                                </div>
                                <div className="formgrid grid">
                                    <label htmlFor="dirección">Documentos Soporte</label>

                                    <div className="field col">
                                        <input type="file" className="custom-file-input" id="customFileLang" lang="es"
                                            accept="jpg/*"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {submitted && !product.SOPORTE && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                    }}>El documento soporte es obligatorio.</small>}
                                    {!enviar &&
                                    <div className="field col">
                                        <img src={`${product.SOPORTE}`} alt={product.SOPORTE} className="shadow-2" width="100"
                                        onClick={()=>onLoadingClick(product.SOPORTE)}/>
                                    </div>
                                    }
                                </div>
                                <div className="formgrid grid">
                                </div>
                                <div className="field">
                                    <label htmlFor="description">Observaciones</label>
                                    <InputTextarea id="observaciones" value={product.OBSERVACIONES} onChange={(e) => onInputChange(e, 'OBSERVACIONES')} required rows={3} cols={20} />
                                </div>
                            </>
                        {/* } */}

                    </Dialog>

                    <Dialog visible={ayuda} style={{ width: '900px' }} header="Ayuda Transferencia de embriones Formulario A03" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <Ayuda formulario={'A03'} />                    
                    </Dialog>

                    {/* <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog> */}
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA03, comparisonFn);
