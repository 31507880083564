import React,{useState,useRef,useEffect} from 'react'
import { size, isEmpty } from 'lodash'
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useLocation } from "react-router-dom";
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
const CambiarClave = (props) =>{
    const location = useLocation();



  const history = useHistory();
//  const captcha=useRef(null)
const [productDialog, setProductDialog] = useState(true)
const [value2, setValue2] = useState('')
const [value3, setValue3] = useState('')
const toast = useRef(null);
const APIUSUARIOS = 'https://www.ancogenecop.com/APIANCO/controllers/usuario.php?op='

const hideDialog = () => {
    history.push(`/login`);
}



useEffect(() => {

 }, [location]);


 function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

 const saveProduct = async() => {
    if(value2==='') { 
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar la nueva clave, verifique por favor...', life: 3000 });
        return
    }
    if(value3==='') { 
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe confirmar la nueva clave, verifique por favor...', life: 3000 });
        return
    }
    if(value2!==value3){
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'La clave nueva y la confirmación no son iguales, verifique por favor...', life: 3000 });
        return
    }
    let datos = getParameterByName('value');

               const data = new FormData();
               data.append("password", value2)
               data.append("datos", datos)
               await fetch(APIUSUARIOS + 'actualizaPasswordNew', {
                   method: 'POST',
                   header: {
                     'Accept': 'application/json',
                     'Content-type': 'application/json'
                   },
                   body: data
                 }).then((response) => response.json())
                   .then((response) => {
                       if(response===1){
                             toast.current.show({ severity: 'success', summary: 'Successful', detail: 'La clave de acceso a sido actualizada, ingrese nuevamente con su nueva clave', life: 5000 });
                             setTimeout(function(){
                                history.push(`/login`);
                            }, 5000);
                             
                       }else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No fue posible cambiar la clave de acceso', life: 5000 });
                            return;
                        }
                   })
                   .catch((error) => {
                     console.log(error);
                   })
   }



const header = <h6>Digite password</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Sugerencias</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}
            Locale>
                <li>Al menos una letra minúscula</li>
                <li>Al menos una letra mayúscula</li>
                <li>Al menos un caracter numérico</li>
                <li>Al menos un caracter especial</li>
                <li>Mìnimo de 8 caracteres</li>
            </ul>
        </React.Fragment>
    );
    
return(
<>

<section className="vh-100">
<Toast ref={toast} />
  <div className="container-fluid h-custom">
    <div className="row d-flex justify-content-center align-items-center h-100">
<div className="tab-content">
  <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
                <>

                <div className="card">
              
                <div className="formgrid grid">
                    <div className="field col">
                        <Password value={value2} 
                        onChange={(e) => setValue2(e.target.value)} 
                        header={header} footer={footer} placeholder="Digite la nueva clave" toggleMask />
                    </div>
                    <div className="field col">
                        <Password value={value3} 
                        onChange={(e) => setValue3(e.target.value)} 
                        placeholder="Confirme la nueva clave" toggleMask
                        required />
                    </div>
                     <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />    
                     <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                </div>
                </div>

                  </>
  </div>

</div>

   </div>
   </div>
</section>
</>
)
}

export default CambiarClave
