import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { locale, addLocale } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionCodigos from '../components/SeleccionCodigos';
import Lateral from '../components/Lateral';

const FormularioA12SubArrendar = () => {
    let emptyProduct = {
        consecutivoArrendamiento:'',
        fecha_inicio:'',
        fecha_fin:'',
        fecha_inicioArriendo:'',
        fecha_finArriendo:'',
    };
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [muestraCodigos, setMuestraCodigos] = useState(false)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [fechaServicio, setFechaServicio] = useState('')
    const [fechaSolicitud, setFechaSolicitud] = useState('')
    const [deleteProductsDialogDet, setDeleteProductsDialogDet] = useState(false)
    const [reloadArrendamientos, setReloadArrendamientos] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const APIFORMULARIOA12 = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA12.php?op='

    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
            setReloadUsuarios(false)
            setUsuariosAnco([])
            //setCargoNomina('')
            const data = new FormData();
            if(localStorage.getItem('administrador')==1){
                data.append('nid_propietario','ADMINANCO')
              }else{
                data.append('nid_propietario',localStorage.getItem('nid_propietario'))
              }
                await fetch(APIFORMULARIOA12 + 'formulariosA12SubArrendar', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setReloadUsuarios(false)
                            setUsuariosAnco(response)
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    useEffect(() => {
        if(reloadArrendamientos){
            setReloadArrendamientos(false)
           
            setDeleteProductsDialogDet(true);
        }
    }, [reloadArrendamientos])



    const hideDialogC = () => {
        setDeleteProductsDialogDet(false);
    }


    const saveProduct = async() => {
     setSubmitted(true);
     if(product.consecutivoArrendamiento=='') {console.log(1); return}
     if(product.fecha_inicioArriendo=='') {console.log(2); return}
     if(product.fecha_finArriendo=='') {console.log(3); return}
     if(product.fecha_inicioArriendo<product.fecha_inicio){
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'La fecha inicial no puede ser menor a: '+product.fecha_inicio, life: 3000 });
        return
     }
     if(product.fecha_finArriendo>product.fecha_fin){
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'La fecha final no puede ser superior a: '+product.fecha_fin, life: 3000 });
        return
     }
                const data = new FormData();
                data.append("consecutivo", product.consecutivo)
                data.append("nid_propietario", product.nid_propietario)
                data.append("consecutivoArrendamiento", product.consecutivoArrendamiento)
                data.append("nid_propietario_s", localStorage.getItem("nid_propietario"))
                data.append("fecha_inicio", product.fecha_inicioArriendo)
                data.append("fecha_fin", product.fecha_finArriendo)
                data.append("CODIGO",'A12')
                await fetch(APIFORMULARIOA12 + 'ingresarSubArrendamiento', {
                    method: 'POST',
                    header: {
                      'Accept': 'application/json',
                      'Content-type': 'application/json'
                    },
                    body: data
                  }).then((response) => response.json())
                    .then((response) => {
                        if(response==1){
                              toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Subarriendo ha sido Ingresado o Actualizado', life: 3000 });
                              setReloadUsuarios(true)
                              setSubmitted(false);
                              setDeleteProductsDialogDet(false);
                              setProduct(emptyProduct);
                        }else {
                             toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Subarriendo no pudo ser Ingresaro o Actualizado', life: 3000 });
                        }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
    }


    const confirmDeleteProduct = (product) => {
        setProduct({ ...product });
        setNidPropietario(localStorage.getItem("nid_propietario"))
        setReloadArrendamientos(true)
    }





    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {/* <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} /> */}
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-search" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">REPRODUCTORES DISPONIBLES PARA SUB ARRENDAR</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="Buscar" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

        const productDialogFooterC = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialogC} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const handleplanta = async(dato,opc) =>{
        if(opc==2){
            product.consecutivoArrendamiento=dato
         }
     }


     function calcularEdad(fecha,opcion) {

        if(opcion==1){
            setFechaSolicitud(new Date(fecha).toISOString().slice(0, 10))
            product.fecha_inicioArriendo=new Date(fecha).toISOString().slice(0, 10)
        }else if(opcion==2){
            setFechaServicio(new Date(fecha).toISOString().slice(0, 10))
            product.fecha_finArriendo=new Date(fecha).toISOString().slice(0, 10)
        }
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="consecutivo" header="consecutivo" sortable ></Column>
                        <Column field="cedulaarrendador" header="Doc. Arrendador" sortable ></Column>
                        <Column field="nid_propietario" header="No. Doc. Arrendador" sortable ></Column>
                        <Column field="nomarrendador" header="Nombre Arrendador" sortable ></Column>
                        <Column field="nomespecie" header="Especie" sortable ></Column>
                        <Column field="crg_animal" header="Chip" sortable ></Column>
                        <Column field="fecha_inicio" header="Fecha Inicial" sortable ></Column>
                        <Column field="fecha_fin" header="Fecha Final" sortable ></Column>
                        <Column field="cedulaarrendatario" header="Doc. Propietario" sortable ></Column>
                        <Column field="nid_arrendatario" header="No. Doc. Propietario" sortable ></Column>
                        <Column field="nomarrendatario" header="Nombre Propietario" sortable ></Column>
                    </DataTable>
                    </div>
                        {/* <div className="field col-3"><Lateral/>
                    </div> */}
                    </div>
                    <Dialog visible={deleteProductsDialogDet} style={{ width: '750px' }}  modal className="p-fluid" footer={productDialogFooterC} onHide={hideDialogC}>
                    <div className="formgrid grid">
                    <div className="field col">
                                <label htmlFor="name">Consecutivo de Arrendamiento</label>
                                    <SeleccionCodigos NID_PROPIETARIO={nidPropietario}
                                    codigo={'A12'} CONSECUTIVO={product.consecutivoArrendamiento} handleplanta={handleplanta}
                                    required
                                    />
                                    {submitted && !product.consecutivoArrendamiento && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                    }}
                                    >El consecutivo es requerido.
                                    </small>}
                                </div>
                            <div className="field col">
                                <label htmlFor="dirección">Fecha inicial : {fechaSolicitud}</label>
                            <Calendar showIcon showButtonBar value={product.fecha_inicioArriendo}
                            dateFormat="yy-mm-dd" monthNavigator={true}
                            yearNavigator={true}
                            readOnlyInput={true}
                            locale='es'
                            yearRange="1960:2060" required
                            onChange={(e) => calcularEdad(e.value,1)}></Calendar>
                            {submitted && !product.fecha_inicioArriendo && <small className="p-invalid"
                            style={{
                                    color: 'red',
                                }}
                            >La fecha final de arrendamiento es requerida.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="dirección">Fecha final: {fechaServicio}</label>
                            <Calendar showIcon showButtonBar value={product.fecha_finArriendo}
                            dateFormat="yy-mm-dd" monthNavigator={true}
                            yearNavigator={true}
                            readOnlyInput={true}
                            locale='es'
                            yearRange="1960:2060" required
                            onChange={(e) => calcularEdad(e.value,2)}></Calendar>
                            {submitted && !product.fecha_finArriendo && <small className="p-invalid"
                            style={{
                                    color: 'red',
                                }}
                            >La fecha final de arrendamiento es requerida.</small>}
                            </div>

                            <hr/>
                       </div>

                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA12SubArrendar, comparisonFn);
