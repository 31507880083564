import React, { useState,useEffect,useRef } from "react";
import { AutoComplete } from 'primereact/autocomplete';
import { Toast } from 'primereact/toast';

const SeleccionUsuario = (props) => {
console.log(props)
 const [selectedAutoValue, setSelectedAutoValue] = useState(props.NOMPROPIETARIO); 
  const [autoFilteredValue, setAutoFilteredValue] = useState([]);
  const [autoValue, setAutoValue] = useState(null);
  const [reload, setReload] = useState(true)
  const toast = useRef(null);
  const APITABLASBASICAS = 'https://www.ancogenecop.com/APIANCO/controllers/selecciones.php?op='
 
  useEffect(() => {
    if(props.tidPropietario=='' || props.tidPropietario==undefined) return
    setAutoValue([])
    async function traerUsuarios(codigo,tidPropietario,nidPropietario) {
      const data = new FormData();
      data.append('codigo',codigo)
      data.append('TID_PROPIETARIO',tidPropietario)
      if(localStorage.getItem('administrador')==1){
        data.append('NID_PROPIETARIO','ADMINANCO')
      }else{
        data.append('NID_PROPIETARIO',localStorage.getItem('nid_propietario'))
      }
      await fetch(APITABLASBASICAS + 'seleccionUsuarios', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            if(response.length==0){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'No existen usuarios para este formulario con consecutivos disponibles', life: 3000 });
                // sleep(2500000);
                // return props.setMuestraUsuario(false)
            }
            setAutoValue(response)
        })
        .catch((error) => {
          console.log(error);
        })
    }
        traerUsuarios(props.codigo,props.tidPropietario,localStorage.getItem("nid_propietario"))
  }, [props.codigo,props.tidPropietario,localStorage.getItem("nid_propietario")]);

//   function sleep(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

  const searchCountry = (event) => {
    setTimeout(() => {
        if (!event.query.trim().length) {
            setAutoFilteredValue([...autoValue]);
        }
        else {
            setAutoFilteredValue(autoValue.filter((a) => {
                return a.name.toUpperCase().startsWith(event.query.toUpperCase());
            }));
        }
    }, 250);
};



  const handleChange = (event) => {
    setSelectedAutoValue(event)
    return props.handleplanta(event.code,1)
  };

  const handleClick = (event) => {
    return props.handleplanta('',1000)
  };


  return (
      <div className="p-error">
      <Toast ref={toast} />
      <AutoComplete placeholder="Buscar" id="dd" dropdown value={selectedAutoValue}
      onChange={(e) => handleChange(e.value)}
      onClick={(e) => handleClick(e.value)}
      suggestions={autoFilteredValue} completeMethod={searchCountry} field="name" />
    </div>
  );
};

export default SeleccionUsuario;
