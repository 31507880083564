import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionFormulariosNew from '../components/SeleccionFormulariosNew';
import SeleccionUsuarioConsecutivos from '../components/SeleccionUsuarioConsecutivos';
import SeleccionConsecutivos from '../components/SeleccionConsecutivos';
import ImportarExcel from '../components/ImportarExcel';
import Lateral from '../components/Lateral';
const MaestroConsecutivos = () => {
    let emptyProduct = { 
        id:0,
        codigo:'',
        nid_propietario:'',
        numero:'',
        estado:'',
        fecha_uso:'',
        MESTAOO:''
    };
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [editar, setEditar] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const [value1, setValue1] = useState(true)
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const MAESTROCONSECUTIVO = 'https://www.ancogenecop.com/APIANCO/controllers/maestroConsecutivos.php?op='
    const [deleteProductsDialogDet, setDeleteProductsDialogDet] = useState(false)
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                await fetch(MAESTROCONSECUTIVO + 'maestroConsecutivos', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            //console.log(response)
                            setEditar(false)
                            setProducts(response)
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])


    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }


    const hideDialogConsecutivos = () => {
        setDeleteProductsDialogDet(false);
    }

    const saveProduct = async() => {
        setSubmitted(true);
        // console.log(product.codigo+" "+product.nid_propietario+" "+product.numero+" "+product.estado);
        // return;
        if(product.codigo=='') {console.log(1); return}
         if(product.nid_propietario=='') {console.log(2); return}
         if(product.numero=='') {console.log(3); return}
         if(product.estado=='') {console.log(4); return}
        const data = new FormData();
        data.append("id", product.id)
        data.append("codigo", product.codigo)
        data.append("nid_propietario", product.nid_propietario)
        data.append("numero", product.numero)
        data.append("estado", product.estado)
        await fetch(MAESTROCONSECUTIVO + 'ingresarConsecutvo', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                } else if((response==100)){
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El consecutivo digitado ya existe, verifique por favor...', life: 3000 });
                }else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const editProduct = (product) => {
        setEditar(true)
        setProduct({ ...product });
        setProductDialog(true);
    }



    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevos Consecutivos" icon="pi pi-plus" className="p-button-info mr-2" onClick={importar} />            </React.Fragment>
        )
    }

    const importar = () => {
        setDeleteProductsDialogDet(true)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Maestro de Consecutivos</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );    const productDialogFooterC = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDialogConsecutivos} />
        </>
    );


    const handleplanta = async (dato, opc,opcion) => {
        setSubmitted(false)
        if (opc == 1) {
            product.codigo = dato
        }if (opc == 3) {
            product.estado = dato
        }
        else{
            product.nid_propietario = dato
        }
    }

    return (
    <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="NOMBRE" header="PROPIETARIO" sortable headerStyle={{ width: '20%', minWidth: '30rem' }}></Column>
                        <Column field="descripcion" header="FORMULARIO" sortable headerStyle={{ width: '50%', minWidth: '10rem' }}></Column>
                        <Column field="numero" header="NUMERO" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="MESTAOO" header="ESTADO" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        <Column field="fecha_uso" header="FECHA DE USO" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={deleteProductsDialogDet} style={{ width: '950px' }}  modal className="p-fluid" footer={productDialogFooterC} onHide={hideDialog}>

                        <ImportarExcel opcion={1} hideDialogConsecutivos={hideDialogConsecutivos}/>

                    </Dialog>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle del Consecutivo" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col">
                            {editar ?
                            <div>
                            <label htmlFor="name">Formulario</label>
                            <SeleccionFormulariosNew  handleplanta={handleplanta} 
                            required
                            autoFocus
                            />
                            </div>
                            :
                            <>
                            <div>
                            <label htmlFor="name">Formulario</label>
                            <SeleccionFormulariosNew  handleplanta={handleplanta} 
                            required
                            autoFocus
                            />
                            {submitted && !product.nid_propietario && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El tipo de formulario es obligatorio </small>}
                            </div>
                            <div><label htmlFor="name">Asociado</label>
                            <SeleccionUsuarioConsecutivos  handleplanta={handleplanta} 
                            required
                            autoFocus
                            />
                            {submitted && !product.codigo && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El asociado al consecutivo es obligatorio.</small>}
                            </div>
                            <div>
                            <label htmlFor="name">Consecutivo</label>
                            <InputText id="name" value={product.numero} onChange={(e) => onInputChange(e, 'numero')}
                            required
                            className={classNames({ 'p-invalid': submitted && !product.numero })} />
                            {submitted && !product.numero && <small className="p-invalid"
                            style={{
                                color: 'red',
                              }}>
                            El numero del consecutivo es requerido.</small>}
                            </div>
                            <div>
                            <label htmlFor="name">Estado</label>
                            <SeleccionConsecutivos  handleplanta={handleplanta} 
                            required
                            autoFocus
                            />
                            {submitted && !product.estado && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El estado del consecutivo es obligatorio.</small>}
                            </div>
                            </>
                            }

                        </div>
                        {/* <div className="field col">
                            {editar ?
                            <div>
                            <label htmlFor="name">Asociado</label>
                            <SeleccionUsuarioConsecutivos  handleplanta={handleplanta} NOMBRE={product.NOMBRE}
                            required
                            />
                            </div>
                            :
                            <>
                            <label htmlFor="name">Asociado</label>
                            <SeleccionUsuarioConsecutivos  handleplanta={handleplanta}
                            required
                            />
                            {submitted && !product.nid_propietario && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El nombre de asociado es requrido.</small>}
                            </>
                            }
                        </div> */}
                    </div>
                        <div className="formgrid grid">
                        <div className="field col-6">
                            {/* {editar ?
                            <div>
                             <label htmlFor="name">Consecutivo</label>
                            <InputText id="name" value={product.numero} onChange={(e) => onInputChange(e, 'numero')}
                            readOnly
                             />
                            </div>
                            :
                            <div>
                            <label htmlFor="name">Consecutivo</label>
                            <InputText id="name" value={product.numero} onChange={(e) => onInputChange(e, 'numero')}
                            required
                            className={classNames({ 'p-invalid': submitted && !product.numero })} />
                            {submitted && !product.numero && <small className="p-invalid">El numero del consecutivo es requerido.</small>}
                            </div>
                            } */}
                        </div>
                        {/* {editar &&
                            <div className="field col">
                                <label htmlFor="name">Estado</label>
                                    <SeleccionConsecutivos  handleplanta={handleplanta} MESTADO={product.MESTAOO}
                                required
                                />
                                {submitted && !product.estado && <small className="p-invalid"
                                style={{
                                  color: 'red',
                                }}>El estado es requerido.</small>}
                            </div>
                        } */}
                    </div>

                    </Dialog>

                </div>
            </div>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MaestroConsecutivos, comparisonFn);
