import React from "react";
import SeleccionRegistradoresAdmin from './SeleccionRegistradoresAdmin';
import SeleccionTipoDocumentoAdmin from './SeleccionTipoDocumento';
const SeleccionRegistraInicialMachosAdmin = (props) => {
 console.log(props)
//      const handleplanta = async(dato,opcion) =>{
//          console.log(dato+" "+opcion)
//          return props.handleplanta(dato,opcion)
// }

  return (
    <div className="p-error">
    {props.tipoSexo=='M' ?
    <>
        <div className="formgrid grid">
        <div className="field col-6">
        <label htmlFor="name">Tipo Documento 1</label>
            <SeleccionTipoDocumentoAdmin opcion={10} handleplanta={props.handleplanta} TIDREGISTRADOR={props.TIDREGISTRADOR} 
            required/>
            {props.submitted && !props.product.TID_REGISTRADOR && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El tipo de documento es requerido.</small>}
        </div>
        <div className="field col-6">
        <label htmlFor="name">Nombre Registrador 1</label>
            <SeleccionRegistradoresAdmin tidRegistrador='CC' opcion={14} handleplanta={props.handleplanta} NOMREGISTRADOR={props.NOMREGISTRADOR}
            required
            />
            {props.submitted && !props.product.NID_REGISTRADOR && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El nombre primer registrador es requerido.</small>}
        </div>
    </div>
    <div className="formgrid grid">
        <div className="field col-6">
        <label htmlFor="name">Tipo Documento 2</label>
            <SeleccionTipoDocumentoAdmin opcion={11} handleplanta={props.handleplanta} TIDREGISTRADOR={props.TIDREGISTRADOR1}
            required/>
            {props.submitted && !props.product.TID_REGISTRADOR1 && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El tipo de documento es requerido.</small>}
        </div>
        <div className="field col-6">
        <label htmlFor="name">Nombre Registrador 2</label>
            <SeleccionRegistradoresAdmin tidRegistrador='CC' opcion={15} handleplanta={props.handleplanta} NOMREGISTRADOR={props.NOMREGISTRADOR1}
            required
            />
            {props.submitted && !props.product.NID_REGISTRADOR1 && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El nombre segundo registrador es requerido.</small>}
        </div>
    </div>
        <div className="formgrid grid">
        <div className="field col-6">
        <label htmlFor="name">Tipo Documento 3</label>
            <SeleccionTipoDocumentoAdmin opcion={13} handleplanta={props.handleplanta} TIDREGISTRADOR={props.TIDREGISTRADOR2}
            required/>
                {props.submitted && !props.product.TID_REGISTRADOR2 && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El tipo de documento es requerido.</small>}
        </div>
        <div className="field col-6">
        <label htmlFor="name">Nombre Registrador 3</label>
            <SeleccionRegistradoresAdmin tidRegistrador='CC' opcion={16} handleplanta={props.handleplanta} NOMREGISTRADOR={props.NOMREGISTRADOR2}
            required
            />
            {props.submitted && !props.product.NID_REGISTRADOR2 && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El nombre del tercer registrador es requerido.</small>}
        </div>
    </div>
    </>    
    :
    <>
        <div className="formgrid grid">
        <div className="field col-6">
        <label htmlFor="name">Tipo Documento</label>
            <SeleccionTipoDocumentoAdmin opcion={10} handleplanta={props.handleplanta}
            required/>
            {props.submitted && !props.product.TID_REGISTRADOR && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El tipo de documento es requerido.</small>}
        </div>
        <div className="field col-6">
        <label htmlFor="name">Nombre Registrador 1</label>
            <SeleccionRegistradoresAdmin tidRegistrador='CC' opcion={14} handleplanta={props.handleplanta}
            required
            />
            {props.submitted && !props.product.NID_REGISTRADOR && <small className="p-invalid"
             style={{
             color: 'red',
            }}>El nombre registrador es requerido.</small>}
        </div>
    </div>
    </>    
    }
    </div>
  );
};

export default SeleccionRegistraInicialMachosAdmin;
