import React, { useState, useEffect, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale } from 'primereact/api';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { registerAllModules } from "handsontable/registry";
import { registerLanguageDictionary, esMX } from "handsontable/i18n";
import "handsontable/dist/handsontable.full.css";
import SeleccionUsuariosDash from './SeleccionUsuariosDash';
import SeleccionCodigoEspecieDash from './SeleccionCodigoEspecieDash';
import SeleccionCodigoRazaDash from './SeleccionCodigoRazaDash';
import SeleccionSexo from './SeleccionSexo';
import SeleccionMachosDash from './SeleccionMachosDash';
import SeleccionHembrasDash from './SeleccionHembrasDash';
import SeleccionLibrosDash from './SeleccionLibrosDash';
import SeleccionTipoPartoDash from './SeleccionTipoPartoDash';
import SeleccionFormasConcepcion from './SeleccionFormasConcepcion';
import Ayuda from './Ayuda'

// ejecutar para obtener todas las funciones de handsontable
registerAllModules();
registerLanguageDictionary(esMX);
const barOptionsOne = {
    plugins: {
        legend: {
            labels: {
                color: '#495057'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef',
            }
        },
        y: {
            ticks: {
                color: '#495057'
            },
            grid: {
                color: '#ebedef',
            }
        },
    }
};



const MisInventarios = (props) => { 
    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const hotTableComponent = React.useRef(null);
    const [lineOptions, setLineOptions] = useState(null)
    const [chartOptions, setChartOptions] = useState(null)
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const array = []
    const titulo = []
    const titulo1 = []
    const valores=[]
    const valores1=[]
    const [nidPropietario, setNidPropietario] = useState(0)
    const [value1, setValue1] = useState(true)
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [products, setProducts] = useState([]);
    const [datos, setDatos] = useState(false)
    const [animales, setAnimales] = useState([])
    const [reloadAnimales, setReloadAnimales] = useState(true)
    const [titulosGrafico, setTitulosGrafico] = useState()
    const [titulosGrafico1, setTitulosGrafico1] = useState()
    const [valoresGrafico, setValoresGrafico] = useState([])
    const [valoresGrafico1, setValoresGrafico1] = useState([])
    const [especie, setEspecie] = useState('')
    const [sexo, setSexo] = useState('')
    const [raza, setRaza] = useState('')
    const [chip, setChip] = useState('')
    const [hembras, setHembras] = useState('')
    const [fechaInicial, setFechaInicial] = useState('')
    const [fechaFinal, setFechaFinal] = useState('')
    const [reloadFechas, setReloadFechas] = useState(false)
    const [anioInicial, setAnioInicial] = useState('')
    const [anioFinal, setAnioFinal] = useState('')
    const [fechas, setFechas] = useState(false)
    const [libro, setLibro] = useState('')
    const [formasConcepcion, setFormasConcepcion] = useState('')
    const [tipoParto, setTipoParto] = useState('')
    const [ayuda, setAyuda] = useState(false)  
    const [tituloMacho, setTituloMacho] = useState('')
    const [tituloHembra, setTituloHembra] = useState('')
    const DASHBOARD = 'https://www.ancogenecop.com/APIANCO/controllers/dashboard.php?op='
    let minDate = new Date();
    let maxDate = new Date();
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });
    locale('es');
    useEffect(() => {
        async function traerNegocios() {
            //setReload(false)
            console.log(localStorage.getItem('administrador'));
            const data = new FormData();
            if(localStorage.getItem('administrador')==1){
                if(nidPropietario==''){
                    data.append('NID_PROPIETARIO','ADMINANCO')
                }else{
                    data.append('NID_PROPIETARIO',nidPropietario)
                }
              }else if(localStorage.getItem('administrador')==null){
                if(nidPropietario==''){
                    data.append('NID_PROPIETARIO','ADMINANCO')
                }else{
                    data.append('NID_PROPIETARIO',nidPropietario)
                }
              }else{
                data.append('NID_PROPIETARIO',localStorage.getItem("nid_propietario"))
              }
            data.append('COD_ESPECIE',especie)
            data.append('RAZA',raza)
            data.append('SEXO',sexo)
            data.append('MACHO',chip)
            data.append('HEMBRA',hembras)
            data.append('FECHA_INICIAL',fechaInicial)
            data.append('FECHA_FINAL',fechaFinal)
            data.append('LIBRO',libro)
            data.append('CONCEPCION',formasConcepcion)
            data.append('TIPO_PARTO',tipoParto)

            await fetch(DASHBOARD + 'informacionInicial', {
                method: 'POST',
                header: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                },
                body: data
            }).then((response) => response.json())
                .then((response) => {
                    setAnimales(response)
                    console.log(response)
                    for(var i in response) {
                        array.push([response[i]]);
                    }
                    for(var i=0;i<array.length;i++) {
                     //   if(array[i][0]["SEXO"]=='MACHOS'){
                            titulo.push(Object.values(array[i][0]["RAZA"]+" "+array[i][0]["SEXO"]));
                     //   }else{
                     //       titulo1.push(Object.values(array[i][0]["RAZA"]+" "+array[i][0]["SEXO"]));
                     //   }
                    }
                    for(var i=0;i<array.length;i++) {
                     //   if(array[i][0]["SEXO"]=='MACHOS'){
                            valores.push(Math.round(array[i][0]["chip"]));
                    //     }else{
                    //         valores1.push(Math.round(array[i][0]["chip"]));
                    //     }
                    }
                    console.log(valores)
                    setTitulosGrafico(titulo)
                    setTitulosGrafico1(titulo1)
                    setValoresGrafico(valores)
                    setValoresGrafico1(valores1)
                    setReloadUsuarios(true)
                    setReloadFechas(true)
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        traerNegocios()
        setReloadAnimales(false)
    }, [reloadAnimales]);


    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                if(localStorage.getItem('administrador')==1){
                    if(nidPropietario==''){
                        data.append('NID_PROPIETARIO','ADMINANCO')
                    }else{
                        data.append('NID_PROPIETARIO',nidPropietario)
                    }
                  }else if(localStorage.getItem('administrador')==null){
                    if(nidPropietario==''){
                        data.append('NID_PROPIETARIO','ADMINANCO')
                    }else{
                        data.append('NID_PROPIETARIO',nidPropietario)
                    }
                  }else{
                    data.append('NID_PROPIETARIO',localStorage.getItem("nid_propietario"))
                  }
    
                    data.append('COD_ESPECIE',especie)
                data.append('RAZA',raza)
                data.append('SEXO',sexo)
                data.append('MACHO',chip)
                data.append('HEMBRA',hembras)
                data.append('FECHA_INICIAL',fechaInicial)
                data.append('FECHA_FINAL',fechaFinal)
                data.append('LIBRO',libro)
                data.append('CONCEPCION',formasConcepcion)
                data.append('TIPO_PARTO',tipoParto)
                    await fetch(DASHBOARD + 'dataAnimales', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setDatos(true)
                            setProducts(response)
                        }
                        setValue1(false)

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])


    useEffect(() => {
        async function traerNegocios() {
            //setReload(false)
            const data = new FormData();
            data.append('COD_ESPECIE',especie)
            data.append('RAZA',raza)
            data.append('SEXO',sexo)
            data.append('MACHO',chip)
            data.append('HEMBRA',hembras)
            data.append('LIBRO',libro)
            data.append('CONCEPCION',formasConcepcion)
            data.append('TIPO_PARTO',tipoParto)
            await fetch(DASHBOARD + 'fechasNacimiento', {
                method: 'POST',
                header: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                },
                body: data
            }).then((response) => response.json())
                .then((response) => {
                if(response[0].fechaInicial!=null){
                    setFechas(true)
                    // setFechaInicial(response[0].fechaInicial)
                    // setFechaFinal(response[0].fechaFinal)
                    let salida = response[0].fechaInicial.split('-');
                    setAnioInicial(salida[0])
                    salida = response[0].fechaFinal.split('-');
                    setAnioFinal(salida[0])
                }else{
                    setFechas(false)
                }

                })
                .catch((error) => {
                    console.log(error);
                })
        }
        traerNegocios()
        setReloadFechas(false)
    }, [reloadFechas]);

    const descargarArchivo = () => {
        const pluginDescarga =
          hotTableComponent.current.hotInstance.getPlugin("exportFile");
          pluginDescarga.downloadFile("csv", {
          filename: "usuarios",
          fileExtension: "csv",
          mimeType: "text/csv",
          columnHeaders: true,
        });
      };


      const lineData = {
        labels:titulosGrafico,
        datasets: [
            {
                label: 'Animales',
                backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                borderColor: documentStyle.getPropertyValue('--blue-500'),
                data:valoresGrafico,
                fill: true,
                tension:.4
            },
            // {
            //     label: 'Animales',
            //         backgroundColor: documentStyle.getPropertyValue('--pink-500'),
            //         borderColor: documentStyle.getPropertyValue('--pink-500'),
            //         data:valoresGrafico1,
            //         fill: true,
            //         tension:.4
            //     }
        ]
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Información general de animales</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

   
    const onLoadingClick = (soporte) => {
        window.open(soporte, "_blank")
    }

    const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    fontColor: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary,
                    font: {
                        weight: 500
                    }
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
        
    };
    
    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
        //setChartOptions(options);
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
        //setChartOptions(options);
    }


    
    


    useEffect(() => {
    
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
        //setChartOptions(options);
    }, [props.colorMode]);

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    function calcularEdad(fecha, opcion) {
    console.log(fecha)
        if (opcion == 1) {
            setFechaInicial(new Date(fecha).toISOString().slice(0, 10))
        } else if (opcion == 2) {
            setFechaFinal(new Date(fecha).toISOString().slice(0, 10))
        }

        if(fechaInicial!='' && fechaFinal!=''){
            setReloadAnimales(true)
        }
    }

    const handleplanta = async (dato, opc) => {
        if(dato==undefined) return
        console.log(nidPropietario)
        if (opc == 15) {
            setNidPropietario(dato)
        }else if (opc == 3) {
            if(nidPropietario==null) return
            setEspecie(dato)
        }else if (opc == 4) {
            if(nidPropietario==null && especie==null) return
            setRaza(dato)
        }else if (opc == 12) {
            if(nidPropietario==null && especie==null && raza==null) return
            if(dato=='M'){
                setTituloMacho("Seleccion el chip del macho")
                setTituloHembra("Seleccion el chip de la hembra")
            }else{
                setTituloMacho("Seleccion el chip de la hembra")
                setTituloHembra("Seleccion el chip del macho")
            }
            setSexo(dato)
        }else if (opc == 11) {
            if(nidPropietario==null && especie==null && raza==null && sexo==null) return
            setChip(dato)
        }else if (opc == 16) {
            if(nidPropietario==null && especie==null && raza==null && sexo==null && chip==null) return
            setHembras(dato)
        }else if (opc == 10) {
            if(nidPropietario==null && especie==null && raza==null && sexo==null 
            && chip==null && hembras==null) return
            setLibro(dato)
        }else if (opc == 1) {
            if(nidPropietario==null && especie==null && raza==null && sexo==null 
                && chip==null && hembras==null && libro==null) return
            setFormasConcepcion(dato)
        }else if (opc == 13) {
            if(nidPropietario==null && especie==null && raza==null && sexo==null 
                && chip==null && hembras==null && formasConcepcion==null) return
            setTipoParto(dato)
        }
        setReloadAnimales(true)
    }

    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductAyuda = () => {
        setAyuda(false);
    }
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_'+nidPropietario+'_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
   
    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(products);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'consecutivos');
        });
    };
    
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            
               
                <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />           
              

            </React.Fragment>
        )
    }

    const deleteProductDialogAyuda = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
        </>
    );


    const openNew = () => {
        console.log("si ingreso")
        setEspecie('')
        setRaza('')
        setSexo('')
        setChip('')
        setHembras('')
        setFechaInicial('')
        setFechaFinal('')
        setLibro('')
        setFormasConcepcion('')
        setTipoParto('')
        setNidPropietario(0)
        setReloadAnimales(true)
    }

    return (
    <>
    <div 
         style={{borderRadius: '1rem', background: 'linear-gradient(135deg, #96a937 0, #85a534 12.5%, #709e2e 25%, #559525 37.5%, #318819 50%, #007b0f 62.5%, #00710d 75%, #006912 87.5%, #00641a 100%)'}}
    >
    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>                        
    <div className='field col-12' style={{color:"white", alignContent:"center" }} ><h2>Consulte su información</h2></div>
         <div className='formgrid grid'  >
         <div className="field col-4">
         <label htmlFor="name"
          style={{color:"white"}}
         >Seleccione el asociado</label>
         <SeleccionUsuariosDash handleplanta={handleplanta}  opcion={15} NOMBRES={nidPropietario}/>
         </div>
             <div className="field col-4">
                 <label htmlFor="name"
                 style={{color:"white"}}
                 >Seleccione la especie</label>
                 <SeleccionCodigoEspecieDash handleplanta={handleplanta} NID_PROPIETARIO={nidPropietario} />
            </div>
            <div className="field col-4">
            <label htmlFor="name"
          style={{color:"white"}}
            >Seleccione Raza</label>
                <SeleccionCodigoRazaDash COD_ESPECIE={especie}
                NID_PROPIETARIO={nidPropietario}
                RAZA_MACHO={raza}
                handleplanta={handleplanta}
                />
            </div>
         </div>
         <div className='formgrid grid'>
         <div className="field col-4">
             <label htmlFor="name"
          style={{color:"white"}}
             >Seleccione el sexo</label>
             <SeleccionSexo handleplanta={handleplanta}/>
         </div>
         <div className="field col-4">
             <label htmlFor="name"
          style={{color:"white"}}
             >{tituloMacho}</label>
             <SeleccionMachosDash handleplanta={handleplanta}
             NID_PROPIETARIO={nidPropietario}
             ESPECIE={especie}
             RAZA={raza}
             SEXO={sexo} />
         </div>
         <div className="field col-4">
             <label htmlFor="name"
          style={{color:"white"}}
             >{tituloHembra}</label>
             <SeleccionHembrasDash handleplanta={handleplanta}
             NID_PROPIETARIO={nidPropietario}
             ESPECIE={especie}
             CHIP={chip}
             RAZA={raza}
             SEXO={sexo} />
         </div>
         </div>
         <div className='formgrid grid'>
         <div className="field col-4">
             <label htmlFor="name"
          style={{color:"white"}}
             >Seleccione el libro</label>
             <SeleccionLibrosDash handleplanta={handleplanta}
             NID_PROPIETARIO={nidPropietario} />
         </div>
         <div className="field col-4">
                 <label htmlFor="name" style={{color:"white"}}>Seleccione la forma de concepción</label>
                 <SeleccionFormasConcepcion handleplanta={handleplanta}
             NID_PROPIETARIO={nidPropietario} />
            </div>
            <div className="field col-4">
                 <label htmlFor="name" style={{color:"white"}}>Seleccione el tipo de parto</label>
                 <SeleccionTipoPartoDash handleplanta={handleplanta}
             NID_PROPIETARIO={nidPropietario} />
            </div>
         {/* {fechas && */}
<>
        </>
         {/* } */}
         </div>
         <div className='formgrid grid'  >
         <div className="field col-4">
                    <label htmlFor="dirección"
                    style={{color:"white"}}
                    >Fecha de nacimiento inicial:{fechaInicial}</label><br/>
                    <Calendar showIcon showButtonBar value={fechaInicial}
                        dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                        yearRange={anioInicial+":"+anioFinal+'"'}
                        readOnlyInput={true}
                        locale='es'
                        showMinMaxRange={true}
                        onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                </div>
                <div className="field col-4">
                    <label htmlFor="dirección"
                    style={{color:"white"}}
                    >Fecha de nacimiento final:{fechaFinal}</label><br/>
                    <Calendar showIcon showButtonBar value={fechaFinal}
                        dateFormat="yy-mm-dd" monthNavigator={true}
                        yearNavigator={true}
                        locale='es'
                        yearRange={anioInicial+":"+anioFinal+'"'}
                        showMinMaxRange={true}
                        onChange={(e) => calcularEdad(e.value, 2)}></Calendar>
                </div>
            {/* <div className="field col-4">
            <br/>
                 <Button label="Borrar Filtro" icon="pi pi-minus" className="p-button-success mr-2" onClick={openNew} />
            </div> */}
        </div>
    </div>
        <div className="col-12 xl:col-12">
                <div className="card mb-0 col-md-12">
                    <h5>Inventario general de animales por raza</h5>
                    <Chart type="bar" data={lineData} options={barOptionsOne} />
                </div>
            </div>
            {value1 &&
             <div className="formgrid grid">
                  <div className="field col">
                    <h5>Consultado información por favor espere</h5>
                        <div className="col">
                        <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                    </div>
                </div>
            </div>
            }
                <div className="grid crud-demo">
                <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" ></Toolbar>
                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                        <Column field="NID_PROPIETARIO" header="CC/NIT" sortable ></Column>
                        <Column field="NOMPROP" header="NOMBRE" sortable ></Column>
                        <Column field="CONSECUTIVO" header="CONSEC." sortable ></Column>
                        <Column field="CONSEC_NACIMIENTO" header="CONSEC. NAC." sortable ></Column>
                        <Column field="CHIP" header="CHIP" sortable ></Column>
                        <Column field="DESCRIPCION" header="CONCEPCION" sortable ></Column>
                        <Column field="NOMBRE" header="NOMBRE" sortable ></Column>
                        <Column field="LIBRO" header="LIBRO" sortable ></Column>
                        <Column field="FECHA_NACIMIENTO" header="FECHA FECHA_NACIMIENTO" sortable ></Column>
                        <Column field="COLOR" header="COLOR" sortable ></Column>
                        <Column field="RASGOS_PARTICULARES" header="RASGOS_PARTICULARES" sortable ></Column>
                        <Column field="MARCAS_HATO" header="MARCAS_HATO" sortable ></Column>
                        <Column field="CHIP_PADRE" header="CHIP_PADRE" sortable ></Column>
                        <Column field="CHIP_MADRE" header="CHIP_MADRE" sortable ></Column>
                        <Column field="TIPO_PARTO" header="PARTO" sortable ></Column>
                        <Column field="PESO_AL_NACER" header="PESO_AL_NACER" sortable ></Column>
                    </DataTable>
                </div>
            </div>
            <Dialog visible={ayuda} style={{ width: '300px', height:'200px'}} header="Mis Inventarios" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={'1'} />                    
            </Dialog>   
        </div>
</>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return (prevProps.location.pathname === nextProps.location.pathname) && (prevProps.colorMode === nextProps.colorMode);
};

export default React.memo(MisInventarios, comparisonFn);
