import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { locale, addLocale } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionUsuario from '../components/SeleccionUsuario';
import SeleccionUsuariosTotal from '../components/SeleccionUsuariosTotal';
import SeleccionCodigos from '../components/SeleccionCodigos';
import SeleccionCodigoEspecie from '../components/SeleccionCodigoEspecie';
import SeleccionCodigoRaza from '../components/SeleccionCodigoRaza';
import SeleccionChipsA12 from '../components/SeleccionChipsA12';
import SeleccionTipoDocumentoOtros from '../components/SeleccionTipoDocumentoOtros';
import SeleccionTipoDocumento from '../components/SeleccionTipoDocumento';
import Ayuda from '../components/Ayuda'

const FormularioA12 = () => {
    let emptyProduct = {
        consecutivo:'',
        tid_propietario:'',
        nid_propietario:'',
        especie:'',
        sexo:'M',
        raza:'',
        crg_animal:'',
        fecha_inicio:'',
        fecha_fin:'',
        tid_arrendatario:'',
        nid_arrendatario:'',
        observaciones:''
    };
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [muestraCodigos, setMuestraCodigos] = useState(false)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [fechaServicio, setFechaServicio] = useState('')
    const [fechaSolicitud, setFechaSolicitud] = useState('')
    const [codigoEspecie, setCodigoEspecie] = useState('')
    const [muestraRaza, setMuestraRaza] = useState(false)
    const [codigoRaza, setCodigoRaza] = useState(0)
    const [parentesco, setParentesco] = useState('')
    const [muestraParentesco, setMuestraParentesco] = useState(false)
    const [reloadPrefijo, setReloadPrefijo] = useState(false)
    const [prefijo, setPrefijo] = useState('')
    const [enviar, setEnviar] = useState(false)
    const [tidPropietario, setTidPropietario] = useState('')
    const [muestraUsuario, setMuestraUsuario] = useState(false)
    const [ayuda, setAyuda] = useState(false)                    
    const toast = useRef(null);
    const dt = useRef(null);
    const APIFORMULARIOA12 = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA12.php?op='

    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
            setReloadUsuarios(false)
            setUsuariosAnco([])
            //setCargoNomina('')
            const data = new FormData();
                if(localStorage.getItem('administrador')==1){
                    if(nidPropietario==''){
                        data.append('nid_propietario','ADMINANCO')
                    }else{
                        data.append('nid_propietario',nidPropietario)
                    }
                  }else{
                    data.append('nid_propietario',localStorage.getItem('nid_propietario'))
                  }
                await fetch(APIFORMULARIOA12 + 'formulariosA12', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setReloadUsuarios(false)
                            setUsuariosAnco(response)

                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductAyuda = () => {
        setAyuda(false);
    }

    const deleteProductDialogAyuda = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
        </>
    );

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false)
        setMuestraRaza(false)
        setMuestraParentesco(false)
        setProductDialog(true);
        setEnviar(true)
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false);
    }



    const saveProduct = async() => {
     setSubmitted(true);
     if(product.consecutivo=='') { console.log(1); return}
     if(product.tid_propietario=='') {console.log(2); return}
     if(product.nid_propietario=='') {console.log(3); return}
     if(product.especie=='') { console.log(4); return}
     if(product.raza=='') {console.log(5); return}
     if(product.crg_animal=='') {console.log(6); return}
     if(product.fecha_inicio=='') {console.log(7); return}
     if(product.fecha_fin=='') {console.log(8); return}
     if(product.tid_arrendatario=='') {console.log(9); return}
     if(product.nid_arrendatario=='') {console.log(10); return}
                const data = new FormData();
                data.append("consecutivo", product.consecutivo)
                data.append("tid_propietario", product.tid_propietario)
                data.append("nid_propietario", product.nid_propietario)
                data.append("especie", product.especie)
                data.append("sexo", product.sexo)
                data.append("crg_animal", product.crg_animal)
                data.append("fecha_inicio", product.fecha_inicio)
                data.append("fecha_fin", product.fecha_fin)
                data.append("tid_arrendatario", product.tid_arrendatario)
                data.append("nid_arrendatario", product.nid_arrendatario)
                data.append("raza", product.raza)
                data.append("observaciones", product.observaciones)
                data.append("CODIGO",'A12')
                await fetch(APIFORMULARIOA12 + 'ingresarArrendamiento', {
                    method: 'POST',
                    header: {
                      'Accept': 'application/json',
                      'Content-type': 'application/json'
                    },
                    body: data
                  }).then((response) => response.json())
                    .then((response) => {
                        if(response==1){
                              toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                              setReloadUsuarios(true)
                              setSubmitted(false);
                              setProductDialog(false);
                              setProduct(emptyProduct);
                        }else {
                             toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                        }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
        setMuestraCodigos(true)
        setMuestraRaza(true)
        setMuestraParentesco(true)
        setNidPropietario(product.NID_PROPIETARIO)
        setCodigoRaza(product.COD_ESPECIE)
        setParentesco(product.PARENTESCO)
        setFechaServicio(product.FECHA_SERVICIO)
        setFechaSolicitud(product.FECHA_SOLICITUD)
        setEnviar(true)
    }

    const confirmDeleteProduct = (product) => {
        console.log(product);
        setProduct({ ...product });
        setProductDialog(true);
        setMuestraCodigos(true)
        setMuestraRaza(true)
        setMuestraParentesco(true)
        setNidPropietario(product.NID_PROPIETARIO)
        setCodigoRaza(product.COD_ESPECIE)
        setParentesco(product.PARENTESCO)
        setFechaServicio(product.FECHA_SERVICIO)
        setFechaSolicitud(product.FECHA_SOLICITUD)
        setCodigoEspecie(product.COD_ESPECIE)
        setPrefijo(product.PREFIJO_SOLICITANTE)
        setEnviar(false)
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }
    
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions"> 
            {rowData.diasPendientes>3 &&
                <Button icon="pi pi-search" className="p-button-rounded p-button-success mt-2" 
                tooltip={'Dias pendientes de arrendamiento:'+rowData.diasPendientes}
                tooltipOptions={{
                    className: "hoverClass",
                    position: "right"
                }}
                onClick={() => confirmDeleteProduct(rowData)} />
            }
            {rowData.diasPendientes==3 &&
                <Button icon="pi pi-search" className="p-button-rounded p-button-warning mt-2" 
                tooltip={'Dias pendientes de arrendamiento: '+rowData.diasPendientes}
                tooltipOptions={{
                    className: "hoverClass",
                    position: "right"
                }}
                onClick={() => confirmDeleteProduct(rowData)} />
            }
            {rowData.diasPendientes<3 && rowData.diasPendientes>-1 &&
                <Button icon="pi pi-search" className="p-button-rounded p-button-danger mt-2"
                tooltip={'Dias pendientes de arrendamiento:'+rowData.diasPendientes}
                tooltipOptions={{
                    className: "hoverClass",
                    position: "right"
                }}
                onClick={() => confirmDeleteProduct(rowData)} />
            }
            {rowData.diasPendientes<0 &&
                <Button icon="pi pi-search" className="p-button-rounded p-button-danger mt-2"
                tooltip={'El tiempo de arrendamiento se vencío'}
                tooltipOptions={{
                    className: "hoverClass",
                    position: "right"
                }}
                onClick={() => confirmDeleteProduct(rowData)} />
            }
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">ARRENDAMIENTO REPRODUCTORES FORMULARIO A12</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="Buscar" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label={enviar ? "Cancelar":"Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {enviar &&
              <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
            }

        </>
    );

    const handleplanta = async(dato,opc) =>{
         if(opc==1){
            setNidPropietario(dato)
            setMuestraCodigos(false)
             product.nid_propietario=dato
         }else if(opc==2){
            product.consecutivo=dato
         }else if(opc==3){
            setCodigoEspecie(dato)
            product.especie=dato
         }else if(opc==4){
            setCodigoRaza(dato)
            setMuestraRaza(true)
            product.raza=dato
         }
         else if(opc==5){
            product.crg_animal=dato
         }else if (opc == 9) {
            product.tid_propietario=dato
            setMuestraUsuario(true)
            setTidPropietario(dato)
        }else if (opc == 20) {
            product.nid_arrendatario = dato
        }
        else if (opc == 16) {
            product.tid_arrendatario = dato
        }
         else if(opc==1000){
            setNidPropietario(0)
            setMuestraCodigos(false)
         }
     }


     function calcularEdad(fecha,opcion) {

        if(opcion==1){
            setFechaSolicitud(new Date(fecha).toISOString().slice(0, 10))
            product.fecha_inicio=new Date(fecha).toISOString().slice(0, 10)
        }else if(opcion==2){
            setFechaServicio(new Date(fecha).toISOString().slice(0, 10))
            product.fecha_fin=new Date(fecha).toISOString().slice(0, 10)
        }
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="consecutivo" header="Consecutivo" sortable ></Column>
                        <Column field="cedulaarrendador" header="Tipo Doc," sortable ></Column>
                        <Column field="nid_propietario" header="No. Documento" sortable ></Column>
                        <Column field="nomarrendador" header="Nombre" sortable ></Column>
                        <Column field="nomespecie" header="Especie" sortable ></Column>
                        <Column field="crg_animal" header="Chip" sortable ></Column>
                        <Column field="fecha_inicio" header="Fecha Inicial" sortable ></Column>
                        <Column field="fecha_fin" header="Fecha Final" sortable ></Column>
                        <Column field="cedulaarrendatario" header="Tipo Doc. Arrendatario" sortable ></Column>
                        <Column field="nid_arrendatario" header="No. Doc. Arrendatario" sortable ></Column>
                        <Column field="nomarrendatario" header="Nombre Arrendatario" sortable ></Column>
                    </DataTable>
                    </div>
                        {/* <div className="field col-3"><Lateral/>
                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '850px' }} header={enviar ? "Adicionar Arrendamiento Reproductores":"Consultando Arrendamiento Reproductores"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                        <div className="formgrid grid">
                        <div className="field col">
                                <label htmlFor="name">Tipo Documento Arrendador</label>
                                <SeleccionTipoDocumento handleplanta={handleplanta} opcion={9} TIDPROPIETARIO={product.cedulaarrendador}
                                required/>
                                {submitted && !product.tid_propietario && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El tipo de documento es requerido.</small>}
                            </div>
                            {/* {muestraUsuario && */}
                        <div className="field col">
                                <label htmlFor="name">Nombre del arrendador</label>
                                <SeleccionUsuario codigo={'A12'} handleplanta={handleplanta} NOMPROPIETARIO={product.nomarrendador}
                                 required
                                 tidPropietario={tidPropietario}
                                />
                                {submitted && !product.nid_propietario && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>Nombre del usuario es requerido.</small>}
                            </div>
                        {/* } */}
                                <div className="field col">
                                <label htmlFor="name">Consecutivo de Arrendamiento</label>
                                    <SeleccionCodigos NID_PROPIETARIO={nidPropietario}
                                    codigo={'A12'} CONSECUTIVO={product.consecutivo} handleplanta={handleplanta}
                                    required
                                    />
                                    {submitted && !product.consecutivo && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                    }}
                                    >El consecutivo es requerido.
                                    </small>}
                                </div>

                        </div>
                        <hr/>
                        <div className="formgrid grid">
                            <div className="field col-4">
                                <label htmlFor="name">Código de la especie</label>
                                    <SeleccionCodigoEspecie handleplanta={handleplanta} COD_ESPECIE={product.especie}
                                    required
                                    />
                                    {submitted && !product.especie && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                }}
                                    >El código de la especie es requerido.</small>}
                            </div>
                            {/* {codigoEspecie && */}
                            <>
                                <div className="field col-4">
                                <label htmlFor="name">Raza</label>
                                    <SeleccionCodigoRaza COD_ESPECIE={codigoEspecie}
                                    NID_PROPIETARIO={nidPropietario}
                                    RAZA_MACHO={product.raza}
                                    handleplanta={handleplanta}
                                    required
                                    />
                                    {submitted && !product.raza && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                }}>Tipo de raza es requerido.</small>}
                                </div>
                                <div className="field col-4">
                                <label htmlFor="name">Crg. Macho</label>
                                    <SeleccionChipsA12 COD_ESPECIE={codigoEspecie} CHIP_MACHO={product.crg_animal}
                                    NID_PROPIETARIO={nidPropietario}
                                    RAZA={codigoRaza}
                                    SEXO='M'
                                    handleplanta={handleplanta}
                                    required/>
                                    {submitted && !product.crg_animal && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                }}>El número de chip del macho es requerido.</small>}
                                </div>

                                </>
                            {/* } */}
                        <hr/>
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                    <label htmlFor="dirección">Fecha Inicial: {product.fecha_inicio}</label>
                                    <Calendar showIcon showButtonBar value={fechaSolicitud}
                                        dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                        yearRange="1960:2060" required
                                        readOnlyInput={true}
                                        locale='es'
                                        onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                                    {submitted && !product.fecha_inicio && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                    }}>la fecha inicial de arrendamiento es requerida.</small>}
                                </div>
                            <div className="field col">
                                <label htmlFor="dirección">Fecha Final: {product.fecha_fin}</label>
                            <Calendar showIcon showButtonBar value={fechaServicio}
                            dateFormat="yy-mm-dd" monthNavigator={true}
                            yearNavigator={true}
                            readOnlyInput={true}
                            locale='es'
                            yearRange="1960:2060" required
                            onChange={(e) => calcularEdad(e.value,2)}></Calendar>
                            {submitted && !product.fecha_fin && <small className="p-invalid"
                            style={{
                                    color: 'red',
                                }}
                            >La fecha final de arrendamiento es requerida.</small>}
                            </div>
                            <hr/>
                       </div>
                       <div className="formgrid grid">
                            <div className="field col-6">
                                <label htmlFor="name">Tipo Documento Arrendatario</label>
                                <SeleccionTipoDocumentoOtros handleplanta={handleplanta} opcion={0}
                                NOMBRE={product.cedulaarrendatario}
                                required
                                />
                                {submitted && !product.tid_arrendatario && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El tipo de documento es requerido.</small>}
                            </div>
                            <div className="field col-6">
                                <>
                                <label htmlFor="name">Nombre del Arrendatario</label>
                                <SeleccionUsuariosTotal handleplanta={handleplanta} NID_PROPIETARIO={product.nomarrendatario} 
                                required
                                />
                                {submitted && !product.nid_arrendatario && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>Nombre de a quien se arrienda es requerido.</small>}
                                </>
                            </div>
                            <hr />
                        </div>

                    </Dialog>
                    <Dialog visible={ayuda} style={{ width: '300px', height:'220px'}} header="Arrendamiento Reproductores Formulario A12" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={8} />                    
                    </Dialog> 
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA12, comparisonFn);
