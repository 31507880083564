import React, { useState, useEffect, useRef } from 'react';
import { locale, addLocale } from 'primereact/api';
 import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Carousel } from 'primereact/carousel';
import SeleccionUsuario from '../components/SeleccionUsuario';
import SeleccionCodigos from '../components/SeleccionCodigos';
import SeleccionCodigoEspecie from '../components/SeleccionCodigoEspecie';
import SeleccionCodigoRaza from '../components/SeleccionCodigoRaza';
import SeleccionChips from '../components/SeleccionChips';
import SeleccionChipsHembras from '../components/SeleccionChipsHembras';
import SeleccionParentesco from '../components/SeleccionParentesco';
import SeleccionCodigoMestizaje from '../components/SeleccionCodigoMestizaje';
import SeleccionTipoDocumento from '../components/SeleccionTipoDocumento';
//import Lateral from '../components/Lateral';

import Ayuda from '../components/Ayuda'
const FormularioA01 = () => {
    let emptyProduct = {
        CONSECUTIVO: '', 
        NID_PROPIETARIO: '',
        COD_ESPECIE:'',
        FECHA_SOLICITUD: '',
        FECHA_SERVICIO:'',
        CHIP_MACHO: '',
        CHIP_HEMBRA: '',
        PREFIJO_SOLICITANTE: '',
        RAZA_MESTIZAJE: '',
        OBSERVACIONES: '',
        RAZA_MACHO: '',
        PARENTESCO:'',
        TID_PROPIETARIO:''
    };

    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [muestraCodigos, setMuestraCodigos] = useState(false)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [fechaServicio, setFechaServicio] = useState('')
    const [fechaSolicitud, setFechaSolicitud] = useState('')
    const [codigoEspecie, setCodigoEspecie] = useState('')
    const [muestraRaza, setMuestraRaza] = useState(false)
    const [codigoRaza, setCodigoRaza] = useState(0)
    const [parentesco, setParentesco] = useState('')
    const [muestraParentesco, setMuestraParentesco] = useState(false)
    const [reloadPrefijo, setReloadPrefijo] = useState(false)
    const [prefijo, setPrefijo] = useState('')
    const [enviar, setEnviar] = useState(false)
    const [tidPropietario, setTidPropietario] = useState('')
    const [muestraUsuario, setMuestraUsuario] = useState('')
    const [chipMacho, setChipMacho] = useState('')
    const [ayuda, setAyuda] = useState(false)
    const [razaMestizaje, setRazaMestizaje] = useState('')
    const [cargaAyuda, setCargaAyuda] = useState()
    const [reloadAyuda, setReloadAyuda] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');
    const APIFORMULARIOA01 = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA01.php?op='
    const APITABLASBASICAS = 'https://www.ancogenecop.com/APIANCO/controllers/selecciones.php?op='
    const APIAYUDA = 'https://www.ancogenecop.com/APIANCO/controllers/ayuda.php?op='    
    
    
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
            setReloadUsuarios(false)
            setUsuariosAnco([])
            //setCargoNomina('')
            const data = new FormData();
            if(localStorage.getItem('administrador')==1){
                if(nidPropietario==''){
                    data.append('NID_PROPIETARIO','ADMINANCO')
                }else{
                    data.append('NID_PROPIETARIO',nidPropietario)
                }
              }else{
                data.append('NID_PROPIETARIO',localStorage.getItem('nid_propietario'))
              }
                await fetch(APIFORMULARIOA01 + 'formulariosA01', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setReloadUsuarios(false)
                            setUsuariosAnco(response)
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])



    useEffect(() => {
        if (reloadPrefijo) {
            const recarga = async (nidPropietario) => {
            setReloadUsuarios(false)
            setUsuariosAnco([])
            //setCargoNomina('')
            const data = new FormData();
            if(nidPropietario!='ADMINANCO'){
                data.append("NID_PROPIETARIO",localStorage.getItem("nid_propietario"));
            }else{
                data.append("NID_PROPIETARIO", nidPropietario)
            }
                data.append("NID_PROPIETARIO", nidPropietario)
                await fetch(APITABLASBASICAS + 'seleccionPrefijo', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setReloadPrefijo(false)
                            setPrefijo(response["PREFIJO"])
                            product.PREFIJO_SOLICITANTE=response["PREFIJO"]
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga(nidPropietario)
        }
    }, [reloadPrefijo,nidPropietario])


    const openNew = () => {
        setProduct(emptyProduct);
        if(localStorage.getItem("nivel")!=2){
            setMuestraUsuario(localStorage.getItem("nombres"))
        }
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false)
        setMuestraRaza(false)
        setMuestraParentesco(false)
        setProductDialog(true);
        setEnviar(true)
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false);
    }



    const saveProduct = async() => {
     setSubmitted(true);
     if(product.CONSECUTIVO=='') {
        return
     }
     if(product.NID_PROPIETARIO=='') {
        return
     }
     if(product.FECHA_SOLICITUD=='') {
        return
     }
     if(product.FECHA_SERVICIO=='') {
        return
     }
     if(product.CHIP_MACHO=='') {
        return
     }
     if(product.CHIP_HEMBRA=='') {
        return
     }
     if(product.RAZA_MESTIZAJE=='') {
        return
     }
     if(product.RAZA_MACHO=='') {
        return
     }

     if(razaMestizaje=='') {
        return
     }
                const data = new FormData();
                data.append("CONSECUTIVO",product.CONSECUTIVO)
                data.append("NID_PROPIETARIO",product.NID_PROPIETARIO)
                data.append("COD_ESPECIE",product.COD_ESPECIE)
                data.append("FECHA_SOLICITUD",product.FECHA_SOLICITUD)
                data.append("FECHA_SERVICIO",product.FECHA_SERVICIO)
                data.append("CHIP_MACHO",product.CHIP_MACHO)
                data.append("CHIP_HEMBRA",product.CHIP_HEMBRA)
                data.append("PREFIJO_SOLICITANTE",product.PREFIJO_SOLICITANTE)
                data.append("RAZA_MESTIZAJE",razaMestizaje)
                data.append("OBSERVACIONES",product.OBSERVACIONES)
                data.append("RAZA_MACHO",product.RAZA_MACHO)
                data.append("PARENTESCO",product.PARENTESCO)
                data.append("CODIGO",'A01')
                await fetch(APIFORMULARIOA01 + 'ingresarNacimiento', {
                    method: 'POST',
                    header: {
                      'Accept': 'application/json',
                      'Content-type': 'application/json' 
                    },
                    body: data
                  }).then((response) => response.json())
                    .then((response) => {
                        if(response==1){
                              toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                              setReloadUsuarios(true)
                              setSubmitted(false);
                              setProductDialog(false);
                              setProduct(emptyProduct);
                        }else {
                             toast.current.show({ severity: 'error', summary: 'Error', detail: 'El Registro no pudo ser Ingresado o Actualizado', life: 3000 });
                        }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
    }

    const editProduct = (product) => {
        //console.log(product)
        setProduct({ ...product });
        setProductDialog(true);
        setMuestraCodigos(true)
        setMuestraRaza(true)
        setMuestraParentesco(true)
        setNidPropietario(product.NID_PROPIETARIO)
        setCodigoRaza(product.COD_ESPECIE)
        setRazaMestizaje(product.RAZA_MACHO)
        setParentesco(product.PARANTESCO)
        setFechaServicio(product.FECHA_SERVICIO)
        setFechaSolicitud(product.FECHA_SOLICITUD)
        setEnviar(true)
    }

    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const confirmDeleteProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
        setMuestraCodigos(true)
        setMuestraRaza(true)
        setMuestraParentesco(true)
        setNidPropietario(product.NID_PROPIETARIO)
        setCodigoRaza(product.COD_ESPECIE)
        setParentesco(product.PARENTESCO)
        setFechaServicio(product.FECHA_SERVICIO)
        setFechaSolicitud(product.FECHA_SOLICITUD)
        setCodigoEspecie(product.COD_ESPECIE)
        setPrefijo(product.PREFIJO_SOLICITANTE)
        setEnviar(false)
    }



    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }

    const hideDeleteProductDialog = () => {
        setAyuda(false);
    }

    const deleteProductDialogFooter = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
        </>
    );
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
            {localStorage.getItem('administrador')==1 &&
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
            }
                <Button icon="pi pi-search" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">MONTA DIRECTA FORMULARIO A01</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="Buscar" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label={enviar ? "Cancelar":"Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {enviar &&
              <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
            }

        </>
    );

    const handleplanta = async(dato,opc) =>{
         if(opc==1){
            console.log(dato)
            setNidPropietario(dato)
            setMuestraCodigos(false)
             product.NID_PROPIETARIO=dato
             if(product.NID_PROPIETARIO!=undefined){
                setReloadPrefijo(true)
                setMuestraCodigos(true)
             }else{
                setReloadPrefijo(false)
                setMuestraCodigos(false)
             }
         }else if(opc==2){
            product.CONSECUTIVO=dato
         }else if(opc==3){
            setCodigoEspecie(dato)
            product.COD_ESPECIE=dato
         }else if(opc==4){
            setCodigoRaza(dato)
            setMuestraRaza(true)
            product.RAZA_MACHO=dato
            product.RAZA_MESTIZAJE=dato
            setRazaMestizaje(dato)
         }
         else if(opc==5){
            setChipMacho(dato)
            product.CHIP_MACHO=dato
         }else if(opc==6){
            setParentesco(dato)
            setMuestraParentesco(true)
            product.PARENTESCO=dato
         }else if(opc==7){
            product.RAZA_MESTIZAJE=dato
         }else if(opc==8){
            product.CHIP_HEMBRA=dato
         }else if (opc == 9) {
            setMuestraUsuario(true)
            setTidPropietario(dato)
        }
         else if(opc==1000){
            setNidPropietario(0)
            setMuestraCodigos(false)
         }
     }


     function calcularEdad(fecha,opcion) {

        if(opcion==1){
            setFechaSolicitud(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_SOLICITUD=new Date(fecha).toISOString().slice(0, 10)
        }else if(opcion==2){
            setFechaServicio(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_SERVICIO=new Date(fecha).toISOString().slice(0, 10)
        }
    }

    const convertir = (datos) =>{
        return (
            <p dangerouslySetInnerHTML={{ __html: datos }}></p>
        )
    }

    const productTemplate = (docSoporte) => {
        return (
            <div className="product-item">
                <div>
                    {convertir(docSoporte.descripcionAyuda)}
                </div>
                <div className="product-item-content">
                    <div className="mb-3">
                        <img src={`${docSoporte.imagenAyuda}`}  alt={docSoporte.imagenAyuda} className="product-image"
                        width={650} height={650}/>
                    </div>
                </div>
                <div>
                    {convertir(docSoporte.observaciones)}
                </div>
            </div>
        );
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <div className="formgrid grid">
                        <div className="field col-12">
                            <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                                <Column body={actionBodyTemplate}></Column>
                                {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                                <Column field="NID_PROPIETARIO" header="NID_PROPIETARIO" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="NOMBRE" header="NOMBRE" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="CONSECUTIVO" header="CONSECUTIVO"  sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                                <Column field="FECHA_SOLICITUD" header="FECHA_SOLICITUD" sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="COD_ESPECIE" header="COD_ESPECIE"  sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="RAZA_MACHO" header="RAZA_MACHO"  sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="FECHA_SERVICIO" header="FECHA_SERVICIO"  sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="CHIP_MACHO" header="CHIP_MACHO"  sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="PARENTESCO" header="CRUZAR CON"  sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="CHIP_HEMBRA" header="CHIP_HEMBRA"  sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="PREFIJO_SOLICITANTE" header="PREFIJO_SOLICITANTE"  sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="RAZA_MESTIZAJE" header="RAZA_MESTIZAJE"  sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                                <Column field="OBSERVACIONES" header="OBSERVACIONES"  sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                            </DataTable>
                    </div>
                       
                    </div>
                    <Dialog visible={productDialog}
                    style={{ width: '850px' }}
                    header={enviar ? "Adicionar  Monta Directa":"Consultando Monta Directa"}
                    modal className="p-fluid"
                    footer={productDialogFooter}
                    onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                        <div className="formgrid grid">
                        <div className="field col">
                                <label htmlFor="name">Tipo Documento</label>
                                <SeleccionTipoDocumento handleplanta={handleplanta} opcion={9} TIDPROPIETARIO={product.TID_PROPIETARIO}
                                />
                            </div>
                            {/* {muestraUsuario && */}
                        <div className="field col">
                                <label htmlFor="name">Propietario</label>
                                <SeleccionUsuario codigo={'A01'} handleplanta={handleplanta} NOMPROPIETARIO={product.NOMBRE}
                                 required
                                 tidPropietario={tidPropietario}
                                />
                                {submitted && !product.NID_PROPIETARIO && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>Nombre del usuario es requerido.</small>}
                            </div>
                        {/* } */}
                                <div className="field col">
                                <label htmlFor="name">Consecutivo</label>
                                    <SeleccionCodigos NID_PROPIETARIO={nidPropietario}
                                    codigo={'A01'} CONSECUTIVO={product.CONSECUTIVO} handleplanta={handleplanta}
                                    required
                                    />
                                    {submitted && !product.CONSECUTIVO && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                    }}
                                    >El consecutivo es requerido.
                                    </small>}
                                </div>

                        </div>
                        <hr/>
                        <>
                            <div className="grid">
                            <div className="col-12 md:col-6">
                                    <label htmlFor="dirección">Fecha de Solicitud: {fechaSolicitud}</label>
                                    <Calendar showIcon showButtonBar value={fechaSolicitud}
                                        dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                        yearRange="1960:2060" required
                                        readOnlyInput={true}
                                        locale='es'
                                        onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                                    {submitted && !product.FECHA_SOLICITUD && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                    }}>la fecha de solicitud es requerida.</small>}
                                </div>
                            <div className="col-12 md:col-6">
                                <label htmlFor="dirección">Fecha de Servicio: {fechaServicio}</label>
                            <Calendar showIcon showButtonBar value={fechaServicio}
                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                            yearRange="1960:2060" required
                            onChange={(e) => calcularEdad(e.value,2)}></Calendar>
                            {submitted && !product.FECHA_SERVICIO && <small className="p-invalid"
                            style={{
                                    color: 'red',
                                }}
                            >La fecha de servicio es requerida.</small>}
                            </div>
                            </div>
                            <hr/>
                        </>
                        <div className="formgrid grid">
                            <div className="field col-6">
                                <label htmlFor="name">Código de la especie</label>
                                    <SeleccionCodigoEspecie handleplanta={handleplanta} COD_ESPECIE={product.COD_ESPECIE}
                                    required
                                    />
                                    {submitted && !product.COD_ESPECIE && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                }}
                                    >El código de la especie es requerido.</small>}
                            </div>
                            {/* {codigoEspecie && */}
                                <div className="field col-6">
                                <label htmlFor="name">Raza</label>
                                    <SeleccionCodigoRaza COD_ESPECIE={codigoEspecie} 
                                    RAZA={product.RAZA_MACHO}
                                    handleplanta={handleplanta}
                                    required
                                    />
                                    {submitted && !product.RAZA_MACHO && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                }}>Tipo de raza es requerido.</small>}
                                </div>
                            {/* } */}
                        </div>
                        <hr/>
                            <div className="formgrid grid">
                                <div className="field col">
                                <label htmlFor="name">Crg. Macho</label>
                                    <SeleccionChips COD_ESPECIE={codigoEspecie} CHIP_MACHO={product.CHIP_MACHO}
                                    NID_PROPIETARIO={nidPropietario}
                                    RAZA={codigoRaza}
                                    SEXO='M'
                                    handleplanta={handleplanta}
                                    required/>
                                    {submitted && !product.CHIP_MACHO && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                }}>El número de chip del macho es requerido.</small>}
                                </div>
                                <div className="field col">
                                <label htmlFor="name">Cruzar con</label>
                                    <SeleccionParentesco
                                    handleplanta={handleplanta} PARENTESCO={product.PARENTESCO}
                                    required/>
                                    {submitted && !product.PARENTESCO && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                }}>El tipo de cruce es obligatorio.</small>}
                                </div>
                            </div>
                            <hr/>
                            <>
                            <div className="formgrid grid">
                                <div className="field col col-6">
                                <label htmlFor="name">Crg. Hembra</label>
                                    <SeleccionChipsHembras
                                    COD_ESPECIE={codigoEspecie}
                                    NID_PROPIETARIO={nidPropietario}
                                    CHIP_HEMBRA={product.CHIP_HEMBRA}
                                    PARENTESCO={parentesco}
                                    RAZA={codigoRaza}
                                    SEXO='H'
                                    CONSECUTIVO={''}
                                    CHIPMACHO={chipMacho}
                                    codigo={'A01'}
                                    required
                                    handleplanta={handleplanta}/>
                                    {submitted && !product.CHIP_HEMBRA && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                }}>El número de chip de la hembra es requerido.</small>}
                                </div>
                                <div className="field col">
                                    <label htmlFor="name">Prefijo Solicitante</label>
                                    <InputText id="PREFIJO_SOLICITANTE" value={prefijo} readOnly />
                                </div>
                            </div>
                            <hr/>
                            <div className="formgrid grid">
                                <div className="field col">
                                <label htmlFor="name">Raza o Mestizaje</label>
                                <div>{razaMestizaje}</div>
                                    {/* <SeleccionCodigoMestizaje COD_ESPECIE={codigoEspecie}
                                    NID_PROPIETARIO={nidPropietario}
                                    RAZA_MESTIZAJE={razaMestizaje}
                                    handleplanta={handleplanta}
                                    required
                                    />
                                    {submitted && !product.RAZA_MESTIZAJE && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                }}>Tipo de raza o mestizaje es requerido.</small>} */}
                                </div>
                                <div className="field col">
                                    <label htmlFor="description">Observaciones</label>
                                    <InputTextarea id="observaciones" value={product.OBSERVACIONES} onChange={(e) => onInputChange(e, 'OBSERVACIONES')} required rows={3} cols={20} />
                                </div>

                            </div>
                            </>

                    </Dialog> 

                    <Dialog visible={ayuda} style={{ width: '300px', height:'200px'}} header="Monta Directa A01" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <Ayuda formulario={'5'} />                    
                    </Dialog>

                    {/* <Dialog visible={deleteProductsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {product && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog> */}
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA01, comparisonFn);
