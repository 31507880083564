
import React, { useState, useEffect,useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { CustomerService } from '../service/CustomerService';

const FormulariosA05Detalles = (props) => {
    const [customers1, setCustomers1] = useState(null);
    const [customers2, setCustomers2] = useState(null);
    const [filters1, setFilters1] = useState(null);

    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [sexo, setSexo] = useState(false);
    const [libro, setLibro] = useState(false);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [usuariosAnco, setUsuariosAnco] = useState([])

    const toast = useRef(null);

    const customerService = new CustomerService();
    const APIFORMULARIOA05INICIALES = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA05Iniciales.php?op='

useEffect(() => {
            const recarga = async () => {
                setUsuariosAnco([])
                const data = new FormData();
                data.append("NID_PROPIETARIO", props.nidPropietario)
                data.append("CONSECUTIVO", props.consecutivo)
                await fetch(APIFORMULARIOA05INICIALES + 'formulariosA05Detalle', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setUsuariosAnco(response)
                            if(response[0].SEXO=='M'){
                                setSexo(true)
                            }else{
                                setSexo(false)
                            }
                            if(response[0].LIBRO=='FUNDADORES'){
                                setLibro(true)
                            }else{
                                setLibro(false)
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()

    customerService.getCustomersLarge().then(setLoading1(false) );
    initFilters1();
}, [])


    const clearFilter1 = () => {
        initFilters1();
    }

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }


    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        setGlobalFilterValue1('');
    }

    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                {/* <Button type="button" icon="pi pi-filter-slash" label="Borrar filtro" className="p-button-outlined" onClick={clearFilter1} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Buscar" />
                </span> */}
            </div>
        )
    }

    const header1 = renderHeader1();

    return (
        <div className="datatable-filter-demo">
            <div className="card">
                <h5>Detalle de registro de animales iniciales</h5>
                <DataTable value={usuariosAnco} header={header1}  >
                    <Column field="NOMCONCEPCION" header="FORMA_DE_CONCEPCION" sortable ></Column>
                    <Column field="CRIADERO" header="CRIADERO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="DIRECCION" header="DIRECCION"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="DEPARTAMENTO" header="DEPARTMENTO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="MUNICIPIO" header="MUNICIPIO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="LIBRO" header="LIBRO"  filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="NID_REGISTRADOR" header="No. Doc" sortable ></Column>
                    <Column field="NOMREGISTRADOR" header="Nombre" sortable ></Column>
                </DataTable>
                {libro && sexo &&
                <DataTable value={usuariosAnco}  >
                <Column field="NID_REGISTRADOR1" header="No. Doc" sortable ></Column>
                <Column field="NOMREGISTRADOR1" header="Nombre" sortable ></Column>
                <Column field="NID_REGISTRADOR2" header="No. Doc." sortable ></Column>
                <Column field="NOMREGISTRADOR2" header="Nombre" sortable ></Column>
                </DataTable>
                }
                {!libro &&
                <DataTable value={usuariosAnco}  >
                    <Column field="CHIP_ABUELO_PATERNO" header="ABUELO PATERNO" sortable ></Column>
                    <Column field="CHIP_ABUELA_PATERNA" header="ABUELA PATERNA" sortable ></Column>
                    <Column field="CHIP_ABUELO_MATERNO" header="ABUELO MATERNO" sortable ></Column>
                    <Column field="CHIP_ABUELA_MATERNA" header="ABUELA MATERNA" sortable ></Column>
                </DataTable>
                }
            </div>

        </div>
    );
}
export default React.memo(FormulariosA05Detalles);
