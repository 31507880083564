
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';

export const ImportarExcel = (props) => {
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [importedData, setImportedData] = useState([]);
    const [selectedImportedData, setSelectedImportedData] = useState([]);
    const [repetidos, setRepetidos] = useState(false)
    const [titulo, setTitulo] = useState("Se encontraron los siguientes chips repetidos")
    const [opcionCargo, setOpcionCargo] = useState(true)
    const [muestraBotones, setMuestraBotones] = useState(true)
    const [importedCols, setImportedCols] = useState([{ field: '', header: 'Columnas' }]);
    const dt = useRef(null);
    const toast = useRef(null);
    const APICONSECUTIVOS = 'https://www.ancogenecop.com/APIANCO/controllers/consecutivos.php?op='

    useEffect(() => {
        if(props.opcion==1){
            setOpcionCargo(false)

            setTitulo("Se encontraron los siguientes consecutivos repetidos")
        }



    }, [props])

    const importExcel = (e) => {
        setRepetidos(false)
        setMuestraBotones(true)
        const file = e.files[0];
        import('xlsx').then(xlsx => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = xlsx.utils.sheet_to_json(ws, { header: 1 });

                // Prepare DataTable
                const cols = data[0];
                data.shift();
                setSelectedProducts(data);
                let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
                let _importedData = data.map(d => {
                    return cols.reduce((obj, c, i) => {
                        obj[c] = d[i];
                        return obj;
                    }, {});
                });

                setImportedCols(_importedCols);
                setImportedData(_importedData);
            };
            reader.readAsArrayBuffer(file);
        });
    }

    const procesar = async() =>{
        const data = new FormData();
        data.append("data", JSON.stringify(selectedProducts));
        if(opcionCargo){
            await fetch(APICONSECUTIVOS + 'insertaChips', {
                method: 'POST',
                header: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                },
                body: data
            }).then((response) => response.json())
                .then((response) => {
                    if(response==1){
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Los Chips fueron ingresados de manera exitosa', life: 3000 });
                        sleep(13000)
                        return props.hideDialogConsecutivos()
                    }else if(response==4){
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se presento un error de red, intentelo mas tarde', life: 3000 });
                        sleep(13000)
                        return props.hideDialogConsecutivos()
                    }else{
                        if(response.length>0){
                            setRepetidos(true)
                            setSelectedProducts(response)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }else{
            await fetch(APICONSECUTIVOS + 'insertaConsecutivos', {
                method: 'POST',
                header: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                },
                body: data
            }).then((response) => response.json())
                .then((response) => {
                    if(response==1){
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Los Consecutivos fueron ingresados de manera exitosa', life: 3000 });
                        sleep(13000)
                        return props.hideDialogConsecutivos()
                    }else if(response==4){
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Se presento un error de red, intentelo mas tarde', life: 3000 });
                        sleep(13000)
                        return props.hideDialogConsecutivos()
                    }else{
                        if(response.length>0){
                            setMuestraBotones(false)
                            setRepetidos(true)
                            setSelectedProducts(response)
                        }

                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const toCapitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const clear = () => {
        setImportedData([]);
        setSelectedImportedData([]);
        setImportedCols([{ field: '', header: 'Columnas' }]);
    }

    const onImportSelectionChange = (e) => {
        setSelectedImportedData(e.value);
        const detail = e.value.map(d => Object.values(d)[0]).join(', ');
        toast.current.show({ severity: 'info', summary: 'Data Selected', detail, life: 3000 });
    }



    return (
            <div >
                <Toast ref={toast} />
                <div className="formgrid grid">
                        <div className="field col">
                        <FileUpload
                            chooseOptions={{ label: 'Importar Archivo Excel', icon: 'pi pi-file-excel',
                            className: 'p-button-success' }}
                            mode="basic"
                            name="demo[]"
                            auto url="https://www.ancogenecop.com/APIANCO/controllers/subirExcel.php"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                            application/vnd.ms-excel" className="mr-2" onUpload={importExcel} />
                        </div>
                        {muestraBotones &&
                        <>
                            <div className="field col">
                                <Button type="button" label="Procesar Contenido" icon="pi pi-check" onClick={procesar} className="p-button-info ml-auto" />
                            </div>
                            <div className="field col">
                                <Button type="button" label="Limpiar Contenido" icon="pi pi-trash" onClick={clear} className="p-button-warning ml-auto" />
                            </div>
                        </>
                        }

                </div>
                {!repetidos &&
                <DataTable value={importedData} emptyMessage="No data"
                 paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                    responsiveLayout="scroll"
                    selectionMode="multiple" selection={selectedImportedData} onSelectionChange={onImportSelectionChange}>
                    {
                        importedCols.map((col, index) => <Column key={index} field={col.field} header={col.header} />)
                    }
                </DataTable>
                }
                {repetidos && opcionCargo &&
                <DataTable value={selectedProducts} emptyMessage="No data"
                paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll"
                header={titulo}
                    selectionMode="multiple" >
                    <Column field="0" header="CHIP" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="1" header="HOMOLOGADO" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                </DataTable>
                }
                {repetidos && !opcionCargo &&
                <DataTable value={selectedProducts} emptyMessage="No data"
                paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros" responsiveLayout="scroll"
                header={titulo}
                    selectionMode="multiple" >
                    <Column field="0" header="CODIGO" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="1" header="NUMERO" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="2" header="NID_PROPIETARIO" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                    <Column field="3" header="NOMBRE" sortable  headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                </DataTable>

                }

            </div>
    );
}

export default React.memo(ImportarExcel);
