import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import classNames from 'classnames';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Lateral from '../components/Lateral';
const FormulariosPorRevisar = () => {
    let emptyProduct = {
        id:0,
        observaciones:'',
    };
    const [file, setFile] = useState(null)
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [editar, setEditar] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const [value1, setValue1] = useState(true)
    const [nid_propietario, setNid_propietario] = useState(0)
    const [consec_solicitud, setConsec_solicitud] = useState(0)
    const [id, setId] = useState(0)
    const [aprobar, setAprobar] = useState(false)
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const FORMULARIOSSOLICITADOS = 'https://www.ancogenecop.com/APIANCO/controllers/formulariosParaRevisar.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                await fetch(FORMULARIOSSOLICITADOS + 'formularioSolicitados', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setEditar(false)
                            setProducts(response)
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    useEffect(() => {
        if (aprobar) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                data.append("id",id)
                data.append("consec_solicitud",consec_solicitud)
                data.append("nid_propietario",nid_propietario)
                await fetch(FORMULARIOSSOLICITADOS + 'ingresarFormularioSolicitados', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response==1) {
                            setAprobar(false)
                            setReloadUsuarios(true)
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'La solicitud de formularios ha sido aprobada con exito y los consecutivos han  sido generados.', life: 7000 });
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [aprobar])



    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }


    const saveProduct = async() => {
        setSubmitted(true);
        if(product.observaciones=='') {console.log(1); return}
        const data = new FormData();
        data.append("id", id)
        data.append("observaciones", product.observaciones)
        await fetch(FORMULARIOSSOLICITADOS + 'ingresarFormularioObservaciones', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'La solicitud ha sido rechazada.', life: 5000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                }else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const editProduct = (product) => {
        setAprobar(true)
        setId(product.id)
        setConsec_solicitud(product.consec_solicitud)
        setNid_propietario(product.nid_propietario)
    }

    const rechazaProduct = (product) => {
        setProductDialog(true)
        setId(product.id)
    }



    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }




    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.estado==2 &&
                <>
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)}
                    tooltip='Aprobar la solicitud '
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    />
                    <Button icon="pi pi-times" className="p-button-rounded p-button-danger mr-2" onClick={() => rechazaProduct(rowData)}
                    tooltip='Rechazar la solicitud '
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}

                    />
                </>
                }
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Formularios Solicitados Pendientes de Revisión</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );


    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${rowData.soporte}`} alt={rowData.soporte} className="shadow-2" width="100"
                onClick={()=>onLoadingClick(rowData.soporte)}/>
            </>
        )
    }

    const onLoadingClick = (soporte) => {
        window.open(soporte, "_blank")
    }


    const handleplanta = async (dato, opc,opcion) => {
        setSubmitted(false)
        if (opc == 1) {
            product.codigo = dato
        }if (opc == 3) {
            product.estado = dato
        }
        else{
            product.nid_propietario = dato
        }
    }
    const handleChange = (e) => {
        setFile(e.target.files[0]);
        product.soporte=e.target.files[0].name;
      };

    return (
    <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <Toolbar className="mb-4" ></Toolbar>

                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="consec_solicitud" header="Solicitud" sortable ></Column>
                        <Column field="nid_propietario" header="NID_SOLICITANTE" sortable ></Column>
                        <Column field="NOMBRE" header="SOLICITANTE" sortable ></Column>
                        <Column field="total" header="No. FORMULARIOS" sortable ></Column>
                        <Column field="valor" header="VALOR" sortable ></Column>
                        <Column header="DOC. SOPORTE" body={imageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="MESTADO" header="ESTADO" sortable ></Column>
                        <Column field="fecha" header="FECHA SOLICITUD" sortable ></Column>
                        <Column field="fecha_aprobacion" header="FECHA APROBACION" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle de la Solicitud" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col col">
                            <label htmlFor="description">Causal de rechazao</label>
                            <InputTextarea id="observaciones" value={product.observaciones} onChange={(e) => onInputChange(e, 'observaciones')} required rows={3} cols={20} />
                            {submitted && !product.observaciones && <small className="p-invalid " style={{
                                  color: 'red',
                            }}>La causal de rechazao es requerida.</small>}
                        </div>

                    </div>
                    </Dialog>

                </div>
            </div>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormulariosPorRevisar, comparisonFn);
