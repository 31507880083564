import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
const Ayuda = (props) => {
    const [ayuda, setAyuda] = useState(false)
    const [cargaAyuda, setCargaAyuda] = useState()
    const [reloadAyuda, setReloadAyuda] = useState(true)
    const APIAYUDA = 'https://www.ancogenecop.com/APIANCO/controllers/ayuda.php?op='    
    
    const responsiveOptions = [
        {
            breakpoint: '600px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    useEffect(() => {
        if(reloadAyuda){
            const recarga = async () => {
            setReloadAyuda(false)
            //setCargoNomina('')
            const data = new FormData();
            data.append('codigoAyuda',props.formulario)
                await fetch(APIAYUDA + 'ayudaPorFormulario', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response.length>0) {
                            setCargaAyuda(response)
                            setAyuda(true);
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadAyuda])




    const convertir = (datos) =>{
        return (
            <p dangerouslySetInnerHTML={{ __html: datos }}></p>
        )
    }

    const productTemplate = (docSoporte) => {
        return (
          
                <div>
                    {convertir(docSoporte.descripcionAyuda)}
                </div>
                
               
          
        );
    }

    return (
                <div className="carousel-demo">
                    <div className="card">
                {ayuda &&
                    <Carousel value={cargaAyuda} 
                    // numVisible={1} 
                    //numScroll={1} 
                    // responsiveOptions={responsiveOptions}
                     itemTemplate={productTemplate}
                     //infiniteLoop                     
                     //interval={1000}
                    // autoplay
                    />                
                }

                    </div>
                 </div>
    );
}


export default Ayuda;
