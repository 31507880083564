import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionAsociaciones from '../components/SeleccionAsociaciones';
import SeleccionAsociacionesSocios from '../components/SeleccionAsociacionesSocios';
import SeleccionTipoSocio from '../components/SeleccionTipoSocio';
import SeleccionTipoPersona from '../components/SeleccionTipoPersona';
import AsociacionesSocios from './AsociacionesSocios'
import Lateral from '../components/Lateral';

const Asociaciones = () => {
    const [muestraTabla, setMuestraTabla] = useState(true)
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [globalFilter1, setGlobalFilter1] = useState(null);
    const [sociosPorAsociacion, setSociosPorAsociacion] = useState([])
    const [sociosPorAsociaciones, setSociosPorAsociaciones] = useState([])
    const [nidAsociacion, setNidAsociacion] = useState(0)
    const [sociosAsocia, setSociosAsocia] = useState(false)
    const [deleteProductsDialogDet, setDeleteProductsDialogDet] = useState(false)
    const [filters1, setFilters1] = useState(null);
    const [idAsociacion, setIdAsociacion] = useState(0)
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [loading1, setLoading1] = useState(true);
    const [product, setProduct] = useState([])

    const ASOCIACIONES = 'https://www.ancogenecop.com/APIANCO/controllers/asociaciones.php?op='
    let emptyProduct = {
        NID_ASOCIACION: '',
        NID_ASOCIADO:'',
        TIPO_SOCIO:'',
        TIPO_PERSONA:''
    };

    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                await fetch(ASOCIACIONES + 'asociaciones', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setReloadUsuarios(false)
                            setProducts(response)
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])



    const saveProduct = async() => {
        setSubmitted(true);
        if(product.NID_ASOCIACION=='') {
           return
        }
        if(product.NID_ASOCIADO=='') {
           return
        }
        if(product.TIPO_SOCIO=='') {
            return
         }
         if(product.TIPO_PERSONA=='') {
            return
         }

                   const data = new FormData();
                   data.append("NID_ASOCIACION",product.NID_ASOCIACION)
                   data.append("NID_ASOCIADO",product.NID_ASOCIADO)
                   data.append("TIPO_SOCIO",product.TIPO_SOCIO)
                   data.append("TIPO_PERSONA",product.TIPO_PERSONA)
                   await fetch(ASOCIACIONES + 'ingresarNuevoAsociado', {
                       method: 'POST',
                       header: {
                         'Accept': 'application/json',
                         'Content-type': 'application/json'
                       },
                       body: data
                     }).then((response) => response.json())
                       .then((response) => {
                           if(response==1){
                                 toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El asociado ha sido asignado a la asociación', life: 3000 });
                                 setReloadUsuarios(true)
                                 setSubmitted(false);
                                 setDeleteProductsDialogDet(false);
                                 setProduct(emptyProduct);
                           }else {
                                toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                           }
                       })
                       .catch((error) => {
                         console.log(error);
                       })
       }

    const hideDialog = () => {
        setProductDialog(false);
    }

    const editProduct = (product) => {
        setNidAsociacion(product.NID_TERCERO)
        setMuestraTabla(true)
        setProductDialog(true)
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const openNew = () => {
        setDeleteProductsDialogDet(true)
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-users" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Asociaciones</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );
    const clearFilter1 = () => {
        initFilters1();
    }
    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }
    // const header1 = (
    //     <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
    //         <h5 className="m-0">Asociados</h5>
    //         <span className="block mt-2 md:mt-0 p-input-icon-left">
    //             <i className="pi pi-search" />
    //             <InputText type="search" onInput={(e) => setGlobalFilter1(e.target.value)} placeholder="Buscar..." />
    //         </span>
    //     </div>
    // );

    const productDialogFooter = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </>
    );

    const hideDialogDet = () => {
        setSubmitted(false);
        setDeleteProductsDialogDet(false);
    }

    const productDialogFooterDet = (
        <>
            <Button label={"Cancelar"} icon="pi pi-times" className="p-button-text" onClick={hideDialogDet} />
                <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );


    const handleplanta = async(dato,opc) =>{
        if(opc==1){
            setIdAsociacion(dato)
            product.NID_ASOCIACION=dato
        }else if(opc==5){
            product.TIPO_SOCIO=dato
        }else if(opc==6){
            product.TIPO_PERSONA=dato
        }else{
            product.NID_ASOCIADO=dato
        }
    }
    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" className="p-button-outlined" onClick={clearFilter1} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }


    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'NID_TERCERO': { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        setGlobalFilterValue1('');
    }

    const header1 = renderHeader1();
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12">
                            <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                            <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                                globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                                <Column body={actionBodyTemplate}></Column>
                                <Column field="descripcion" header="TID_TERCERO" sortable ></Column>
                                <Column field="NID_TERCERO" header="NID_TERCERO" sortable ></Column>
                                <Column field="NOMBRE" header="NOMBRE" sortable ></Column>
                                <Column field="DEPARTAMENTO" header="DEPARTAMENTO" sortable ></Column>
                                <Column field="MUNICIPIO" header="MUN_CODIGO" sortable ></Column>
                                <Column field="DIRECCION" header="DIRECCION" sortable ></Column>
                                <Column field="TELEFONOS" header="TELEFONOS" sortable ></Column>
                                <Column field="CORREO" header="CORREO" sortable ></Column>
                                <Column field="URL" header="URL" sortable ></Column>
                                <Column field="FECHA_INGRESO" header="FECHA_INGRESO" sortable ></Column>
                                <Column field="FECHA_RETIRO" header="FECHA_RETIRO" sortable ></Column>
                                <Column field="ESTADO_TERCERO" header="ESTADO_TERCERO" sortable ></Column>

                            </DataTable>
                            </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    {muestraTabla &&
                    <Dialog visible={productDialog} style={{ width: '950px' }}  modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>

                        <AsociacionesSocios  NID_ASOCIACION={nidAsociacion} setMuestraTabla={setMuestraTabla}/>

                    </Dialog>
                    }

                    <Dialog visible={deleteProductsDialogDet} style={{ width: '900px' }} header={"Adicionar un nuevo socio a una asociación"} modal className="p-fluid" footer={productDialogFooterDet} onHide={hideDialogDet}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                    <div className="formgrid grid">
                                <div className="field col col">
                                <label htmlFor="name">Seleccione la Asociación</label>
                                    <SeleccionAsociaciones
                                    handleplanta={handleplanta}/>
                                    {submitted && (!product.NID_ASOCIACION || product.NID_ASOCIACION=='') && <small className="p-invalid">El número el chip es requerido.</small>}
                                </div>
                                <div className="field col col">
                                <label htmlFor="name">Seleccione el nuevo asociado</label>
                                    <SeleccionAsociacionesSocios NID_ASOCIACION={idAsociacion}
                                    handleplanta={handleplanta}/>
                                    {submitted && (!product.NID_ASOCIADO || product.NID_ASOCIADO=='') && <small className="p-invalid">El número el chip es requerido.</small>}
                                </div>
                    </div>
                    <div className="formgrid grid">
                                <div className="field col col">
                                <label htmlFor="name">Seleccione el tipo de socio</label>
                                    <SeleccionTipoSocio
                                    handleplanta={handleplanta}/>
                                    {submitted && (!product.TIPO_SOCIO || product.TIPO_SOCIO=='') && <small className="p-invalid">El número el chip es requerido.</small>}
                                </div>
                                <div className="field col col">
                                <label htmlFor="name">Seleccione el tipo de personal</label>
                                    <SeleccionTipoPersona
                                    handleplanta={handleplanta}/>
                                    {submitted && (!product.TIPO_PERSONA || product.TIPO_PERSONA=='') && <small className="p-invalid">El número el chip es requerido.</small>}
                                </div>
                    </div>

                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Asociaciones, comparisonFn);
