import PrimeReact from './PrimeReact';

let locales = {
    'en': {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Greater than or equal to',
        dateIs: 'Date is',
        dateIsNot: 'Date is not',
        dateBefore: 'Date is before',
        dateAfter: 'Date is after',
        custom: 'Custom',
        clear: 'Borrar',
        apply: 'Applicar',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Si',
        reject: 'No',
        choose: 'Cambiar',
        upload: 'Cargar',
        cancel: 'Cancelar',
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
        dayNamesMin: ['Do','Lu','Ma','Mi','Ju','Vi','Sa'],
        monthNames: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agusto','Septiembre','Octubre','Noviembre','Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun','Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        today: 'Hoy',
        weekHeader: 'Sm',
        firstDayOfWeek: 0,
        dateFormat: 'mm/dd/yy',
        weak: 'Debil',
        medium: 'Mediana',
        strong: 'Fuerte',
        passwordPrompt: 'Ingrese su clave',
        emptyFilterMessage: 'No existen opciones',
        emptyMessage: 'No se encontraron resultados',
        aria: {
            trueLabel: 'True',
            falseLabel: 'False',
            nullLabel: 'No seleccionados',
            pageLabel: 'Ppagina',
            firstPageLabel: 'Primera Página',
            lastPageLabel: 'Ultima Página',
            nextPageLabel: 'Siguiente Página',
            previousPageLabel: 'Anterior Página'
        }
    }
};

function locale(locale) {
    locale && (PrimeReact.locale = locale);

    return {
        locale: PrimeReact.locale,
        options: locales[PrimeReact.locale]
    }
}

function addLocale(locale, options) {
    locales[locale] = { ...locales['en'], ...options };
}

function updateLocaleOption(key, value, locale) {
    localeOptions(locale)[key] = value;
}

function updateLocaleOptions(options, locale) {
    const _locale = locale || PrimeReact.locale;
    locales[_locale] = { ...locales[_locale], ...options };
}

function localeOption(key, locale) {
    const _locale = locale || PrimeReact.locale;

    try {
        return localeOptions(_locale)[key];
    }
    catch(error) {
        throw new Error(`The ${key} option is not found in the current locale('${_locale}').`);
    }
}

function ariaLabel(key) {
    const _locale = PrimeReact.locale;

    try {
        return localeOptions(_locale)['aria'][key];
    }
    catch(error) {
        throw new Error(`The ${key} option is not found in the current locale('${_locale}').`);
    }
}

function localeOptions(locale) {
    const _locale = locale || PrimeReact.locale;
    return locales[_locale];
}

export { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions, ariaLabel };
