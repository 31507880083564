import React,{useState,useRef}  from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
export const AppTopbar = (props) => {
    let emptyProduct = {
        password:'',
        username:'',
    };

const [productDialog, setProductDialog] = useState(false)
const [submitted, setSubmitted] = useState(false);
const [value1, setValue1] = useState('')
const [value2, setValue2] = useState('')
const [value3, setValue3] = useState('')
const toast = useRef(null);
const APIUSUARIOS = 'https://www.ancogenecop.com/APIANCO/controllers/usuario.php?op='
const handleClick = () =>{
    setProductDialog(!productDialog)
}
const hideDialog = () => {
    setProductDialog(false);
}



 const header = <h6>Digite password</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Sugerencias</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}
            Locale>
                <li>Al menos una letra minúscula</li>
                <li>Al menos una letra mayúscula</li>
                <li>Al menos un caracter numérico</li>
                <li>Al menos un caracter especial</li>
                <li>Mìnimo de 8 caracteres</li>
            </ul>
        </React.Fragment>
    );


 const saveProduct = async() => {
    setSubmitted(true);
    if(value1=='') { console.log(1); return}
    if(value2=='') { console.log(2); return}
    if(value3=='') { console.log(3); return}
    if(value1==value2){
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe utilizar una clave diferente a la actual, verifique por favor...', life: 3000 });
        return
    }
    if(value2!=value3){
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'La clave nueva y la repetida no son iguales, verifique por favor...', life: 3000 });
        return
    }

               const data = new FormData();
               data.append("anterior", value1)
               data.append("password", value2)
               data.append("username", localStorage.getItem("nid_propietario"))
               await fetch(APIUSUARIOS + 'actualizaPassword', {
                   method: 'POST',
                   header: {
                     'Accept': 'application/json',
                     'Content-type': 'application/json'
                   },
                   body: data
                 }).then((response) => response.json())
                   .then((response) => {
                       if(response==1){
                             toast.current.show({ severity: 'success', summary: 'Successful', detail: 'La clave de acceso a sido actualizada', life: 3000 });
                             setSubmitted(false);
                             setProductDialog(false);
                             return props.onMobileSubTopbarMenuClickSalir;
                       }else if(response==98){
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'La clave anterior no corresponde, verifique por favor', life: 3000 });
                       }else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'No fue posible cambiar la clave de acceso', life: 3000 });
                       }
                   })
                   .catch((error) => {
                     console.log(error);
                   })
   }

   const productDialogFooter = (
    <>
        <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
    </>
);

    return (
        <div className="layout-topbar">
        <Toast ref={toast} />
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/Logo-Fedeanco.png' : 'assets/layout/images/Logo-Fedeanco.png'} alt="logo"/>
                <span>FEDEANCO</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li>
                {!props.ingresar &&
                    <>
                    <label>{localStorage.getItem('nombres')}</label>
                     <Button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}
                        tooltip= "Ingresar "
                            tooltipOptions={{
                        className: "hoverClass",
                        position:"left"
                             }}

                        >
                            <i className= "pi pi-sign-out" />

                        </Button>
                        {props.cambiaPassword &&
                        <Button className="p-link layout-topbar-button" onClick={handleClick}
                        tooltip= "Cambiar clave de acceso"
                            tooltipOptions={{
                        className: "hoverClass",
                        position:"left"
                             }}

                        >
                            <i className= "pi pi-lock" />

                        </Button>
                        }

                    </>
                    }
                    {props.ingresar  &&
                        <>
                            <Button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClickSalir}
                                tooltip="Cerrar Sesión"
                                tooltipOptions={{
                                    className: "hoverClass",
                                    position: "left"
                                }}

                            >
                                <i className="pi pi-sign-in" />

                            </Button>
                        </>
                    }
                    </li>
                </ul>
                <Dialog visible={productDialog} style={{ width: '650px' }} header={"Cambiar clave de acceso"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog} >
                <div className="formgrid grid">
                    <div className="field col">
                        <Password value={value1} onChange={(e) => setValue1(e.target.value)} placeholder="Digite la clave actual" toggleMask
                            required />
                        {submitted && !value1 && <small className="p-invalid"
                            style={{
                                color: 'red',
                            }}>Digite la clave anterior es requerida.</small>}
                    </div>
                    <div className="field col">
                        <Password value={value2} onChange={(e) => setValue2(e.target.value)} header={header} footer={footer} placeholder="Digite la nueva clave" toggleMask />
                        {submitted && !value1 && <small className="p-invalid"
                            style={{
                                color: 'red',
                            }}>Digite la nueva clave es requerida.</small>}
                    </div>
                    <div className="field col">
                            <Password value={value3} onChange={(e) => setValue3(e.target.value)} placeholder="Repita la nueva clave" toggleMask
                            required />
                        {submitted && !value1 && <small className="p-invalid"
                            style={{
                                color: 'red',
                            }}>Repita la nueva clave es requerida.</small>}
                    </div>

                </div>
                </Dialog>
        </div>
    );
}
