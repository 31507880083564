import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import classNames from 'classnames';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionDepartamento from '../components/SeleccionDepartamento';
import SeleccionMunicipios from '../components/SeleccionMunicipios';
import Lateral from '../components/Lateral';
import Ayuda from '../components/Ayuda'

const MisDatos = () => {
    let emptyProduct = {
        TID_TERCERO:'',
        NID_ASOCIACION:'',
        NOMBRE:'',
        DEP_CODIGO:'',
        MUN_CODIGO:'',
        DIRECCION:'',
        TELEFONOS:'',
        CORREO:'',
        URL:'.',
        PROFESION:'',
        FECHA_INGRESO:'',
        FECHA_RETIRO:'',
        ESTADO_TERCERO:'',
        TIPO_TERCERO:'',
        TIPO_SOCIO:'',
        NID_TERCERO:'',
    };
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [value1, setValue1] = useState(true)
    const toast = useRef(null);
    const dt = useRef(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [departamento, setDepartamento] = useState(false)
    const [ayuda, setAyuda] = useState(false)                
    const TERCEROS = 'https://www.ancogenecop.com/APIANCO/controllers/misdatos.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                data.append("NID_TERCERO",localStorage.getItem("nid_propietario"))
                await fetch(TERCEROS + 'terceros', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setProducts(response)
                        }
                    setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductAyuda = () => {
        setAyuda(false);
    }

    const deleteProductDialogAyuda = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
        </>
    );

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }


    const saveProduct = async() => {
        setSubmitted(true);
         if(product.TID_TERCERO=='') {console.log(1); return}
         if(product.NID_TERCERO=='') {console.log(2); return}
         if(product.NOMBRE=='') {console.log(3); return}
         if(product.DEP_CODIGO=='') {console.log(4); return}
         if(product.MUN_CODIGO=='') {console.log(5); return}
         if(product.DIRECCION=='') {console.log(6); return}
         if(product.TELEFONOS=='') {console.log(7); return}
         if(product.CORREO=='') {console.log(8); return}
         if(product.PROFESION=='') {console.log(10); return}
         if(product.FECHA_INGRESO=='') {console.log(11); return}
         if(product.ESTADO_TERCERO=='') {console.log(13); return}
         if(product.TIPO_TERCERO=='') {console.log(14); return}
         if(product.TIPO_SOCIO=='') {console.log(15); return}
        const data = new FormData();
        data.append("TID_TERCERO", product.TID_TERCERO)
        data.append("NID_ASOCIACION", product.NID_ASOCIACION)
        data.append("NOMBRE", product.NOMBRE)
        data.append("DEP_CODIGO", product.DEP_CODIGO)
        data.append("MUN_CODIGO", product.MUN_CODIGO)
        data.append("DIRECCION", product.DIRECCION)
        data.append("TELEFONOS", product.TELEFONOS)
        data.append("CORREO", product.CORREO)
        data.append("URL", product.URL)
        data.append("PROFESION", product.PROFESION)
        data.append("FECHA_INGRESO", product.FECHA_INGRESO)
        data.append("FECHA_RETIRO", product.FECHA_RETIRO)
        data.append("ESTADO_TERCERO", product.ESTADO_TERCERO)
        data.append("TIPO_TERCERO", product.TIPO_TERCERO)
        data.append("TIPO_SOCIO", product.TIPO_SOCIO)
        data.append("NID_TERCERO", product.NID_TERCERO)
        await fetch(TERCEROS + 'ingresarTercero', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Mis Datos Personales</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );


    function calcularEdad(fecha,opcion) {

        if(opcion==1){
            product.FECHA_INGRESO=new Date(fecha).toISOString().slice(0, 10)
        }else if(opcion==2){
            product.FECHA_RETIRO=new Date(fecha).toISOString().slice(0, 10)
        }
    }

    const handleplanta = async (dato, opc) => {
        setSubmitted(false)
        if (opc == 1) {
            product.DEP_CODIGO = dato
            setDepartamento(dato)
        }else if(opc == 2){
            product.MUN_CODIGO=dato
        }else if(opc == 3){
            product.PROFESION=dato
        }else if(opc == 4){
            product.ESTADO_TERCERO=dato
        }else if(opc == 5){
            product.TIPO_SOCIO=dato
        }else if(opc == 6){
            product.TIPO_TERCERO=dato
        }else if(opc == 7){
            product.NID_ASOCIACION=dato
        }else if(opc == 16){
            product.TID_TERCERO=dato
        }
    }

    return (
        <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }

        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>                    
                    <div className="formgrid grid">
                        <div className="field col-9">
                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="descripcion" header="TIPO IDENTIFICACION" sortable ></Column>
                        <Column field="NID_TERCERO" header="NUMERO" sortable ></Column>
                        <Column field="NOMBRE" header="NOMBRE" sortable ></Column>
                        <Column field="DEPARTAMENTO" header="DEPARTAMENTO" sortable ></Column>
                        <Column field="MUNICIPIO" header="MUNICIPIO" sortable ></Column>
                        <Column field="DIRECCION" header="DIRECCION" sortable ></Column>
                        <Column field="TELEFONOS" header="TELEFONOS" sortable ></Column>
                        <Column field="CORREO" header="CORREO" sortable ></Column>
                        <Column field="URL" header="URL" sortable ></Column>
                        <Column field="TPROFESION" header="PROFESION" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Actualizar Mis Datos" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                             <div className="field col-6">
                                 <label htmlFor="name">Tipo de identificación </label>
                                <InputText id="name" value={product.TID_TERCERO} onChange={(e) => onInputChange(e, 'TID_TERCERO')} readOnly
                                className={classNames({ 'p-invalid': submitted && !product.TID_TERCERO })} />
                            </div>
                            <div className="field col">
                                <label htmlFor="name">Número de identificación </label>
                                <InputText id="name" value={product.NID_TERCERO} onChange={(e) => onInputChange(e, 'NID_TERCERO')} readOnly
                                className={classNames({ 'p-invalid': submitted && !product.NID_TERCERO })} />
                            </div>
                      </div>
                      <hr/>
                            <div className="field col">
                                    <label htmlFor="name">Nombre del usuario</label>
                                    <InputText id="name" value={product.NOMBRE} onChange={(e) => onInputChange(e, 'NOMBRE')}
                                    required
                                    autoFocus
                                    />
                                    {submitted && !product.NOMBRE && <small className="p-invalid" required
                                style={{
                                    color: 'red',
                                }}>El nombre es requerido.</small>}
                            </div>
                            <hr/>
                    <div className="formgrid grid">
                    <div className="field col">
                            <label htmlFor="name">Departamento ubicación</label>
                            <SeleccionDepartamento  handleplanta={handleplanta} DEPARTAMENTO={product.DEPARTAMENTO}
                            required
                            />
                            {submitted && !product.DEP_CODIGO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El departamento es requerido.</small>}
                        </div>
                        <div className="field col">
                            <label htmlFor="name">Municipio ubicación</label>
                            <SeleccionMunicipios  handleplanta={handleplanta} MUNICIPIO={product.MUNICIPIO} CODIGO={departamento}
                            required
                            />
                            {submitted && !product.MUN_CODIGO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>Es municipio es requerido.</small>}
                        </div>
                    </div>
                    <hr/>
                    <div className="formgrid grid">
                        <div className="field col">
                                <label htmlFor="name">Dirección</label>
                                <InputText id="name" value={product.DIRECCION} onChange={(e) => onInputChange(e, 'DIRECCION')} required />
                                {submitted && !product.DIRECCION && <small className="p-invalid" required
                            style={{
                                color: 'red',
                            }}>La dirección es requerida.</small>}
                        </div>
                        <div className="field col">
                                <label htmlFor="name">Teléfono(s)</label>
                                <InputText id="name" value={product.TELEFONOS} onChange={(e) => onInputChange(e, 'TELEFONOS')} required />
                                {submitted && !product.TELEFONOS && <small className="p-invalid" required
                            style={{
                                color: 'red',
                            }}>El teléfono es requerida.</small>}
                        </div>
                    </div>
                    <hr/>
                    <div className="formgrid grid">
                        <div className="field col">
                                <label htmlFor="name">Correo</label>
                                <InputText id="name" value={product.CORREO} onChange={(e) => onInputChange(e, 'CORREO')} required />
                                {submitted && !product.CORREO && <small className="p-invalid" required
                            style={{
                                color: 'red',
                            }}>El correo es requerida.</small>}
                        </div>
                        <div className="field col">
                                <label htmlFor="name">Url</label>
                                <InputText id="name" value={product.URL} onChange={(e) => onInputChange(e, 'URL')} required />
                        </div>
                    </div>

                    </Dialog>
                    <Dialog visible={ayuda} style={{ width: '900px' }} header="Mis Datos" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={'3'} />                    
                    </Dialog>     
                </div>
            </div>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MisDatos, comparisonFn);
