import React, { useState, useEffect, useRef } from 'react';
import { locale, addLocale } from 'primereact/api';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionUsuario from '../components/SeleccionUsuario';
import SeleccionCodigos from '../components/SeleccionCodigos';
import SeleccionTipoDocumento from '../components/SeleccionTipoDocumento';
import SeleccionCodigoEspecie from '../components/SeleccionCodigoEspecie';
import SeleccionCodigoRaza from '../components/SeleccionCodigoRaza';
import SeleccionChipsHembras from '../components/SeleccionChipsHembras';
import SeleccionChipsHembrasGrupal from '../components/SeleccionChipsHembrasGrupal';
import SeleccionMontaDirectaGrupal from '../components/SeleccionMontaDirectaGrupal';
import Lateral from '../components/Lateral';
import Ayuda from '../components/Ayuda'
const FormularioA01a = () => {
    let emptyProduct = {
        NID_PROPIETARIO:'',
        CONSECUTIVO:'',
        FECHA_SOLICITUD:'',
        COD_ESPECIE:'',
        FECHA_INICIO_EMPADRE:'',
        FECHA_FIN_EMPADRE:'',
        RAZA:'',
        CHIP_MACHO:'',
        OBSERVACIONES:'',
        CHIP_HEMBRA:'',
    };

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');

    const [productDialog, setProductDialog] = useState(false);
    const [productDialogDet, setProductDialogDet] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [muestraCodigos, setMuestraCodigos] = useState(false)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [fechaInicioEmpadre, setFechaInicioEmpadre] = useState('')
    const [fechaFinEmpadre, setFechaFinEmpadre] = useState('')
    const [fechaSolicitud, setFechaSolicitud] = useState('')
    const [codigoEspecie, setCodigoEspecie] = useState('')
    const [muestraRaza, setMuestraRaza] = useState(false)
    const [codigoRaza, setCodigoRaza] = useState(0)
    const [parentesco, setParentesco] = useState('')
    const [muestraParentesco, setMuestraParentesco] = useState(false)
    const [prefijo, setPrefijo] = useState(false)
    const [prefijoInicial, setPrefijoInicial] = useState('')
    const [enviar, setEnviar] = useState(false)
    const [consecutivoServicio, setConsecutivoServicio] = useState(0)
    const [reloadDatosConsecutivo, setReloadDatosConsecutivo] = useState(false)
    const [mdatosConsecutivo, setMdatosConsecutivo] = useState([])
    const [codigo, setCodigo] = useState(0)
    const [editProductDialog, setEditProductDialog] = useState(false)
    const [consecutivo, setConsecutivo] = useState(0)
    const [tidPropietario, setTidPropietario] = useState('')
    const [muestraUsuario, setMuestraUsuario] = useState(false)
    const [cambiaConcepcion, setCambiaConcepcion] = useState(false)
    const [deleteProductsDialogDet, setDeleteProductsDialogDet] = useState(false)
    const [deleteProductsDialogDetalle, setDeleteProductsDialogDetalle] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const [value, setValue] = useState(0);
    const [value1, setValue1] = useState(true);
    const interval = useRef(null);
    const [productDetalle, setProductDetalle] = useState(false)
    const [muestraHembras, setNuestraHembras] = useState(false)
    const [ayuda, setAyuda] = useState(false)    
    const APIFORMULARIOA01 = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA01a.php?op='
    const APIFORMULARIOA01DET = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA01aDetalle.php?op='





    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                setUsuariosAnco([])
                const data = new FormData();
                if(localStorage.getItem('administrador')==1){
                    if(nidPropietario==''){
                        data.append('NID_PROPIETARIO','ADMINANCO')
                    }else{
                        data.append('NID_PROPIETARIO',nidPropietario)
                    }
                  }else{
                    data.append('NID_PROPIETARIO',localStorage.getItem('nid_propietario'))
                  }

                await fetch(APIFORMULARIOA01 + 'formulariosA01a', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setUsuariosAnco(response)
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])


    const openNew = () => {
        setMdatosConsecutivo([])
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false)
        setMuestraRaza(false)
        setMuestraParentesco(false)
        setProductDialog(true);
        setEnviar(true)
    }

    const hideDialog = () => {
        setProduct(emptyProduct)
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false);
        setMuestraParentesco(false)
    }

    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDialogDet = () => {
        setSubmitted(false);
        setEnviar(true)
        setDeleteProductsDialogDet(false);
    }

    const hideDialogDetalle = () => {
        setSubmitted(false);
        setEnviar(true)
        setProductDetalle(false);
    }

    const hideDeleteProductDialog = () => {
        setAyuda(false);
    }

    const saveProduct = async () => {
        setSubmitted(true);
        if(product.NID_PROPIETARIO=='') { console.log(1); return}
        if(product.NID_PROPIETARIO=='') {console.log(2); return}
        if(product.FECHA_SOLICITUD=='') {console.log(3); return}
        if(product.COD_ESPECIE=='') { console.log(4); return}
        if(product.FECHA_INICIO_EMPADRE=='') {console.log(5); return}
        if(product.FECHA_FIN_EMPADRE=='') {console.log(6); return}
        if(product.RAZA=='') {console.log(7); return}
        if(product.CHIP_MACHO=='') {console.log(8); return}
        const data = new FormData();
        data.append("NID_PROPIETARIO", product.NID_PROPIETARIO)
        data.append("CONSECUTIVO", product.CONSECUTIVO)
        data.append("FECHA_SOLICITUD", product.FECHA_SOLICITUD)
        data.append("COD_ESPECIE", product.COD_ESPECIE)
        data.append("FECHA_INICIO_EMPADRE", product.FECHA_INICIO_EMPADRE)
        data.append("FECHA_FIN_EMPADRE", product.FECHA_FIN_EMPADRE)
        data.append("RAZA", product.RAZA)
        data.append("CHIP_MACHO", product.CHIP_MACHO)
        data.append("OBSERVACIONES", product.OBSERVACIONES)
        data.append("CODIGO", 'A01a')
        await fetch(APIFORMULARIOA01 + 'ingresarMontaGrupal', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setMuestraParentesco(false)
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const saveProductAdicional = async () => {
        setSubmitted(true);
        if(product.CHIP_HEMBRA===''){
            toast.current.show({ severity: 'error', summary: 'error', detail: 'El chip de la hembra es obligatorio', life: 3000 });
            return
        }
        const data = new FormData();
        data.append("CHIP_HEMBRA", product.CHIP_HEMBRA)
        data.append("CONSECUTIVO", consecutivo)
        await fetch(APIFORMULARIOA01DET + 'ingresarMontaGrupalDetalle', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setDeleteProductsDialogDet(false);
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const editProduct = (product) => {
        setEnviar(true)
        setParentesco(product.COD_ESPECIE)
        setCodigoRaza(product.RAZA)
        setNidPropietario(product.NID_PROPIETARIO)
        setConsecutivo(product.CONSECUTIVO)
        setDeleteProductsDialogDet(true);
    }




    const confirmDeleteProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true)
        setEnviar(false)
        setMuestraUsuario(true)
        setTidPropietario('CC')
        setConsecutivo(product.CONSECUTIVO)
         setNidPropietario(product.NID_PROPIETARIO)
         setReloadDatosConsecutivo(true)
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {rowData.TOTAL<=10 &&
                    <Button icon="pi pi-plus" className="p-button-rounded p-button-success mr-2"
                    tooltip='Adicionar Hembra a Monta Directa Grupal'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    onClick={() => editProduct(rowData)} />
                }
                    <Button icon="pi pi-search" className="p-button-rounded p-button-warning mt-2"
                            tooltip='Consultar Monta directa grupal'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">MONTA DIRECTA GRUPAL FORMULARIO A01a</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="Buscar" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );


    const productDialogFooter = (
        <>
            <Button label={enviar ? "Cancelar" : "Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {enviar &&
                <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
            }

        </>
    );

    const productDialogFooterDet = (
        <>
            <Button label={enviar ? "Cancelar" : "Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialogDet} />
            {enviar &&
                <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProductAdicional} />
            }

        </>
    );
    
    const deleteProductDialogFooter = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
        </>
    );


    const productDialogFooterDetalle = (
        <>
            <Button label={enviar ? "Cerrar" : "Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialogDetalle} />
        </>
    );

    const handleplanta = async (dato, opc) => {
    
        if(dato==undefined) return
        setSubmitted(false)
        if (opc == 1) {
            if(!prefijo){
                setNidPropietario(dato)
                product.NID_PROPIETARIO = dato
            }else{
                product.NID_REGISTRADOR = dato
            }

        } else if (opc == 2) {
            setPrefijo(true)
            setConsecutivo(dato)
            product.CONSECUTIVO = dato
        } else if (opc == 3) {
            setCodigoEspecie(dato)
            product.COD_ESPECIE = dato
        } else if (opc == 4) {
            setCodigoRaza(dato)
            setMuestraRaza(true)
            product.RAZA = dato
        }
        else if (opc == 6) {
            setParentesco(dato)
            setMuestraParentesco(true)
            product.PARENTESCO = dato
        } else if (opc == 8) {
            product.CHIP_MACHO = dato
        } else if (opc == 7) {
            product.CHIP_HEMBRA = dato
        } else if (opc == 9) {
                if(!prefijo){
                    setMuestraUsuario(true)
                    setTidPropietario(dato)
                    product.TID_PROPIETARIO = dato
                }else{
                    product.TID_REGISTRADOR = dato
                }
        } else if (opc == 11) {
            setReloadDatosConsecutivo(true)
            setConsecutivoServicio(dato)
            product.CONSECUTIVO_SERVICIO = dato
        } else if (opc == 12) {
            product.CHIP = dato
        }
        else if (opc == 1000) {
            setNidPropietario(0)
            setMuestraCodigos(false)
        }else if(opc==1500){
            setNuestraHembras(true)
        }
    }

    function calcularEdad(fecha, opcion) {

        if (opcion == 1) {
            setFechaSolicitud(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_SOLICITUD = new Date(fecha).toISOString().slice(0, 10)
        } else if (opcion == 2) {
            setFechaInicioEmpadre(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_INICIO_EMPADRE = new Date(fecha).toISOString().slice(0, 10)
        }else{
            setFechaFinEmpadre(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_FIN_EMPADRE = new Date(fecha).toISOString().slice(0, 10)
        }
    }



    const onLoadingClick = (soporte) => {
        window.open(soporte, "_blank")
    }

    return (
    <>
     {value1 &&
     <div className="formgrid grid">
          <div className="field col">
            <h5>Consultado información por favor espere</h5>
                <div className="col">
                <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
            </div>
        </div>
    </div>
    }
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    <div className="formgrid grid">
                        <div className="field col-12">

                    <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="NID_PROPIETARIO" header="NID_PROPIETARIO" sortable ></Column>
                        <Column field="CONSECUTIVO" header="CONSECUTIVO" sortable ></Column>
                        <Column field="FECHA_SOLICITUD" header="FECHA_SOLICITUD" sortable ></Column>
                        <Column field="ESPECIE" header="ESPECIE" sortable ></Column>
                        <Column field="FECHA_INICIO_EMPADRE" header="FECHA_INICIO_EMPADRE" sortable ></Column>
                        <Column field="FECHA_FIN_EMPADRE" header="FECHA_FIN_EMPADRE" sortable ></Column>
                        <Column field="RAZA" header="RAZA" sortable ></Column>
                        <Column field="CHIP_MACHO" header="CHIP_MACHO" sortable ></Column>
                        <Column field="ESTADO" header="ESTADO" sortable ></Column>
                        <Column field="OBSERVACIONES" header="OBSERVACIONES" sortable ></Column>
                        <Column field="dominio" header="dominio" sortable ></Column>
                        <Column field="TOTAL" header="TOTAL" sortable ></Column>

                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
 
                    <Dialog visible={productDialog} style={{ width: '900px' }} header={enviar ? "Adicionar Monta Directa Grupal" : "Consultando Monta Directa Grupal"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Tipo Documento</label>
                                <SeleccionTipoDocumento handleplanta={handleplanta} opcion={9}
                                descripcion={'Cédula de ciudadanía'}/>
                                {submitted && !product.TID_PROPIETARIO && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>El tipo de documento es requerido.</small>}
                            </div>
                            <div className="field col">
                            {/* {muestraUsuario && */}
                                <>
                                <label htmlFor="name">Usuario</label>
                                <SeleccionUsuario codigo={'A01a'} handleplanta={handleplanta} NID_PROPIETARIO={product.NOMBRE}
                                tidPropietario={tidPropietario} />
                                {submitted && !product.NID_PROPIETARIO && <small className="p-invalid"
                                style={{
                                    color: 'red',
                                }}>Nombre del usuario es requerido.</small>}
                                </>
                             {/* } */}
                            </div>
                            <hr />
                            {/* {muestraCodigos && nidPropietario > 0 && */}
                                <>
                                    <div className="field col">
                                        <label htmlFor="name">Consecutivo</label>
                                        <SeleccionCodigos NID_PROPIETARIO={nidPropietario}
                                            codigo={'A01a'} CONSECUTIVO={product.CONSECUTIVO} handleplanta={handleplanta} />
                                        {submitted && !product.CONSECUTIVO && <small className="p-invalid " style={{
                                          color: 'red',
                                        }}>El consecutivo es requerido.</small>}
                                    </div>
                                </>
                            {/* } */}
                        </div>
                        <hr />
                            <>
                                <div className="formgrid grid">

                                <hr/>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="dirección">Fecha de Solicitud: {product.FECHA_SOLICITUD}</label>
                                        <Calendar showIcon showButtonBar value={product.FECHA_SOLICITUD}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            yearRange="1960:2060" required
                                            readOnlyInput={true}
                                            locale='es'
                                            onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                                        {submitted && !product.FECHA_SOLICITUD && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>la fecha de solicitud es requerida.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="dirección">Fecha Inicial Empadronamiento: {product.FECHA_INICIO_EMPADRE}</label>
                                        <Calendar showIcon showButtonBar value={product.FECHA_INICIO_EMPADRE}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            yearRange="1960:2060" required
                                            readOnlyInput={true}
                                            locale='es'
                                            onChange={(e) => calcularEdad(e.value, 2)}></Calendar>
                                        {submitted && !product.FECHA_INICIO_EMPADRE && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>la fecha de solicitud es requerida.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="dirección">Fecha Final Empadronamiento: {product.FECHA_FIN_EMPADRE}</label>
                                        <Calendar showIcon showButtonBar value={product.FECHA_FIN_EMPADRE}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            yearRange="1960:2060" required
                                            readOnlyInput={true}
                                            onChange={(e) => calcularEdad(e.value, 3)}></Calendar>
                                        {submitted && !product.FECHA_FIN_EMPADRE && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>la fecha de solicitud es requerida.</small>}
                                    </div>

                                 </div>

                                <hr/>
                                <hr/>
                            </>

                    <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Especie</label>
                                <SeleccionCodigoEspecie handleplanta={handleplanta} COD_ESPECIE={product.COD_ESPECIE}/>
                                {submitted && !product.COD_ESPECIE && <small className="p-invalid " style={{
                                  color: 'red',
                                }}>La especie es requerida.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="name">Raza</label>
                                    <SeleccionCodigoRaza COD_ESPECIE={codigoEspecie}
                                    NID_PROPIETARIO={nidPropietario}
                                    RAZA_MACHO={product.RAZA}
                                    handleplanta={handleplanta}/>
                                    {submitted && !product.RAZA && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                    }}>Tipo de raza es requerido.</small>}
                                </div>
                                <div className="field col col">
                                <label htmlFor="name">Chip Macho</label>
                                    <SeleccionChipsHembras
                                    COD_ESPECIE={codigoEspecie}
                                    NID_PROPIETARIO={nidPropietario}
                                    CHIP_HEMBRA={product.CHIP_MACHO}
                                    RAZA={codigoRaza}
                                    SEXO='M'
                                    PARENTESCO={''}
                                    CHIPMACHO={''}
                                    CONSECUTIVO={''} 
                                    codigo={'A01a'}
                                    handleplanta={handleplanta}/>
                                    {submitted && !product.CHIP_MACHO && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                    }}
                                    >El número el chip es requerido.</small>}
                                </div>
                                <div className="field col col">
                                    <label htmlFor="description">Observaciones</label>
                                    <InputTextarea id="observaciones" value={product.OBSERVACIONES} onChange={(e) => onInputChange(e, 'OBSERVACIONES')} required rows={3} cols={20} />
                                </div>

                    </div>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                            <div className="formgrid grid">
                                        <div className="field col col">
                                        <SeleccionMontaDirectaGrupal handleplanta={handleplanta} product={product}
                                        setReloadUsuarios={setReloadUsuarios}/>
                                        </div>
                            </div>

                    </Dialog>

                    <Dialog visible={deleteProductsDialogDet} style={{ width: '300px' }} header={enviar ? "Adicionar Chip Hembra Monta Directa Grupal" : "Consultando Monta Directa Grupal"} modal className="p-fluid" footer={productDialogFooterDet} onHide={hideDialogDet}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}

                    <div className="formgrid grid">
                                <div className="field col col">
                                <label htmlFor="name">Chip Hembra</label>
                                    <SeleccionChipsHembrasGrupal
                                    codigo={parentesco}
                                    NID_PROPIETARIO={nidPropietario}
                                    RAZA={codigoRaza}
                                    handleplanta={handleplanta}/>
                                    {submitted && (!product.CHIP_HEMBRA || product.CHIP_HEMBRA=='') && <small className="p-invalid">El número el chip es requerido.</small>}
                                </div>
                    </div>
                    </Dialog>
                    <Dialog visible={ayuda} style={{ width: '300px', height:'200px'}}  header="Monta Directa Grupal A01a" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <Ayuda formulario={7} />                    
                    </Dialog>
                </div>
            </div>
        </div>
        </>
    );

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA01a, comparisonFn);
