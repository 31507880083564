import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionUsuarioOtros from '../components/SeleccionUsuarioOtros';
import SeleccionTipoDocumentoOtros from '../components/SeleccionTipoDocumentoOtros';
import SeleccionRegistrador from '../components/SeleccionRegistrador';
import SeleccionHomologado from '../components/SeleccionHomologado';
import SeleccionZonas from '../components/SeleccionZonas';
import Lateral from '../components/Lateral';
import { convertLength } from '@mui/material/styles/cssUtils';
const Registradores = () => {
    let emptyProduct = {
        TID_REGISTRADOR:'',
        NID_REGISTRADOR:'',
        ZON_CODIGO:'',
        ESTADO:'',
        ESTAD:'',
        COORDINADOR:'',
        ZON_CODIG:''
    };
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [homo, setHomo] = useState(false)
    const REGISTRADORES = 'https://www.ancogenecop.com/APIANCO/controllers/registradores.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                await fetch(REGISTRADORES + 'registradores', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setProducts(response)
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])


    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }


    const saveProduct = async() => {
        setSubmitted(true);
         if(product.TID_REGISTRADOR=='') {console.log(1); return}
         if(product.NID_REGISTRADOR=='') {console.log(2); return}
         if(product.ZON_CODIGO=='') {console.log(3); return}
         if(product.ESTADO=='') {console.log(4); return}
         if(product.COORDINADOR=='') {console.log(5); return}
        const data = new FormData();
        data.append("TID_REGISTRADOR", product.TID_REGISTRADOR)
        data.append("NID_REGISTRADOR", product.NID_REGISTRADOR)
        data.append("ZON_CODIGO", product.ZON_CODIGO)
        data.append("ESTADO", product.ESTADO)
        data.append("COORDINADOR", product.COORDINADOR)
        await fetch(REGISTRADORES + 'ingresarRegistrador', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const editProduct = (product) => {
        setProduct({ ...product });
        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Importar" chooseLabel="Importar" className="mr-2 inline-block" />
                <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    function calcularEdad(fecha, opcion) {

        if (opcion == 1) {
            product.FECHA_RECEPCION = new Date(fecha).toISOString().slice(0, 10)
        }
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Registradores</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const handleplanta = async (dato, opc) => {
        setSubmitted(false)
        if (opc == 1) {
            product.ESTADO = dato
        }else if(opc==3){
            product.ZON_CODIGO=dato
        }else if (opc == 15) {
            product.NID_REGISTRADOR=dato
        } else if (opc == 16) {
            product.TID_REGISTRADOR=dato
        }else{
            product.COORDINADOR = dato
        }
    }

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="descripcion" header="TID_REGISTRADOR" sortable ></Column>
                        <Column field="NID_REGISTRADOR" header="NID_REGISTRADOR" sortable ></Column>
                        <Column field="NOMBRE" header="NOMBRE" sortable ></Column>
                        <Column field="ZON_CODIG" header="ZONA" sortable ></Column>
                        <Column field="ESTAD" header="ESTADO" sortable ></Column>
                        <Column field="COORDINADO" header="COORDINADOR" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle del Registrador" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                             <div className="field col-6">
                                <label htmlFor="name">Tipo Documento Registrador</label>
                                <SeleccionTipoDocumentoOtros handleplanta={handleplanta} NOMBRE={product.descripcion} />
                                {submitted && !product.TID_REGISTRADOR && <small className="p-invalid" required
                                style={{
                                    color: 'red',
                                }}>El tipo de documento es requerido.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="name">Nombres </label>
                                <SeleccionUsuarioOtros  handleplanta={handleplanta} NOMBREREGISTRADOR={product.NOMBRE}
                                />
                                {submitted && !product.NID_REGISTRADOR && <small className="p-invalid" required
                                style={{
                                    color: 'red',
                                }}>Nombre de la entidad es requerido.</small>}
                            </div>
                            <hr/>
                    </div>

                    <div className="formgrid grid">
                    <div className="field col">
                            <label htmlFor="name">Zona</label>
                            <SeleccionZonas  handleplanta={handleplanta} ZON={product.ZON_CODIG}
                            required
                            />
                            {submitted && !product.ZON_CODIGO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>La zona es requerida.</small>}
                        </div>
                        <div className="field col">
                            <label htmlFor="name">Estado</label>
                            <SeleccionRegistrador  handleplanta={handleplanta} MESTADO={product.ESTAD}
                            required
                            />
                            {submitted && !product.ESTADO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>Es estado es requerido.</small>}
                        </div>
                        <div className="field col">
                            <label htmlFor="name">Coordinador</label>
                            <SeleccionHomologado  handleplanta={handleplanta} MESTADO={product.COORDINADO}
                            required
                            />
                            {submitted && !product.COORDINADOR && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El campo es requerido.</small>}
                        </div>
                    </div>
                    </Dialog>

                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Registradores, comparisonFn);
