import React,{useState,useRef,useEffect} from 'react'
import { size, isEmpty } from 'lodash'
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useLocation } from "react-router-dom";
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
const RecuperarContrasena = (props) =>{
    const location = useLocation();



  const history = useHistory();
//  const captcha=useRef(null)
const [productDialog, setProductDialog] = useState(true)
const [value1, setValue1] = useState('')
const [value2, setValue2] = useState('')
const [value3, setValue3] = useState('')
const [nidPropietario, setNidPropietario] = useState(0);
const toast = useRef(null);
const APIUSUARIOS = 'https://www.ancogenecop.com/APIANCO/controllers/usuario.php?op='

useEffect(() => {

 }, [location]);



   const buscaCorreo = async() => {
    if(value1==='') { 
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el nombre del usuario, verifique por favor...', life: 3000 });
        return
    }
    return props.handleUsuario(value1)
   }

   const enviaCorreo = async() => {
    if(value2==='') { 
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar un correo válido, verifique por favor...', life: 3000 });
        return
    }
    return props.handleCorreo(value2)
   } 
return(
<>

<section className="vh-100">
<Toast ref={toast} />
  <div className="container-fluid h-custom">
    <div className="row d-flex justify-content-center align-items-center h-100">
<div className="tab-content">
  <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
                <>
                <div className="card">                
                <div className="formgrid grid">
                    <div className="field col">
                        <InputText value={value1} 
                        onChange={(e) => setValue1(e.target.value)} 
                        placeholder="Digite el nombre del usuario" 
                        onBlur={(e) => buscaCorreo(e.target.value)}
                        required />
                    </div>
                    {props.correo &&
                    <div className="field col">
                    <InputText value={value2} 
                        onChange={(e) => setValue2(e.target.value)} 
                        placeholder="Digite un correo válido"  
                        onBlur={(e) => enviaCorreo(e.target.value)}
                        />
                    </div>
                    }
                    
                </div>
                </div>
            </>
  </div>

</div>

   </div>
   </div>
</section>
</>
)
}

export default RecuperarContrasena
