import React, { useState,useEffect,useRef } from "react";
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
const SeleccionFormulariosGeneral = (props) => {
  console.log(props);
  const [reload, setReload] = useState(true);
  const [plantaDePersonal, setPlantaDePersonal] = useState([])
  const [cantidad, setCantidad] = useState(0)
  const [totalFormularios,setTotalFormularios] = useState(0)
  const [total,setTotal] = useState(0)
  const [codigo,setCodigo] = useState('')
  const [envioInicial,setEnvioInicial] = useState(false)
  const [valor,setValor] = useState(0)
  const APITABLASBASICAS = 'https://www.ancogenecop.com/APIANCO/controllers/selecciones.php?op='
  const APIFORMULARIOS = 'https://www.ancogenecop.com/APIANCO/controllers/formulariosSolicitados.php?op='
  const toast = useRef(null);
  const [guardaValores,setGuardaValores] = useState(false)
  useEffect(() => { 
    async function traerUsuarios() {
      const data = new FormData();
      if(props.consec_solicitud==undefined) {
        data.append("consec_solicitud", 0)
      }else{
        data.append("consec_solicitud", props.consec_solicitud)
      }
      data.append("nid_propietario", localStorage.getItem("nid_propietario"))
      await fetch(APITABLASBASICAS + 'seleccionFormulariosGeneral', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            setReload(false)
          if(response!=[]){
              setPlantaDePersonal(response)
              if(response[0].totalformularios>0){
                setTotalFormularios(response[0].totalformularios)
                setTotal(response[0].totalgeneral)
              }else{
                setTotalFormularios(0)
                setTotal(0)
              }
          }else{
            setTotalFormularios(0)
            setTotal(0)
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
        traerUsuarios()
  }, [props.consec_solicitud]);

  useEffect(() => { 
    if(guardaValores){
    async function guardaValoresFinales(cantidad,total,codigo) {
      const data = new FormData();
        data.append("nid_propietario", localStorage.getItem('nid_propietario'))
        data.append("codigo", codigo)
        data.append("cantidad", cantidad)
        data.append("valor", valor*cantidad)
      await fetch(APIFORMULARIOS + 'formulariosGuardaValores', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
          setGuardaValores(false)
          setEnvioInicial(true)
          return props.handleplanta(true)
        })
        .catch((error) => {
          console.log(error);
        })
    }
        guardaValoresFinales(cantidad,valor,codigo)}
  }, [guardaValores,props,cantidad,valor,codigo]);

  const calculaValores = () =>{
    setGuardaValores(true)
  }

  const onRowEditComplete = (e) => {
 
        if(total==undefined) setTotal(0)
        console.log("sdads das das das da da da da "+total)
            setCantidad(e.newData.cantidad) 
            setCodigo(e.newData.code) 
            setValor(e.newData.valor) 
            setTotal(((parseInt(e.newData.cantidad)-parseInt(e.data.cantidad))*parseInt(e.newData.valor)+parseInt(total)))
            
            setTotalFormularios((parseInt(e.newData.cantidad)-parseInt(e.data.cantidad))+parseInt(totalFormularios))        
           // let plantaDePersonal = [...plantaDePersonal];
            let { newData, index } = e;
            plantaDePersonal[index] = newData;
    
            setPlantaDePersonal([...plantaDePersonal]);
            calculaValores(); 
        };

 



  const allowEdit = (rowData) => {
    console.log(rowData);
    //  if(rowData.estado!==1){
      return rowData.valor !== 'Blue Band';
    //}
  };

  
const textEditor = (options) => {
  return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} required />;
};



  return (
    <div className="p-error">
        <Toast ref={toast} />
        <div className="card p-fluid"><h4>{"Total formularios: "+totalFormularios+" total a pagar: "+total}</h4>
            {props.estado!=0 && props.estado!=undefined &&
              <DataTable value={plantaDePersonal} editMode="row"   dataKey="id" onRowEditComplete={onRowEditComplete} tableStyle={{ minnWidth: '60rem' }}>
              <Column field="code" header="Codigo"  style={{ width: '10%' }}></Column>
              <Column field="cantidad" header="Cantidad" editor={(options) => textEditor(options)} style={{ width: '10%' }}></Column>
              <Column field="name" header="Nombre"  style={{ width: '40%' }}></Column>
              <Column field="valor" header="valor"  style={{ width: '5%' }}></Column>
              <Column field="total" header="Total"  style={{ width: '5%' }}></Column>
              <Column field="minimo" header="# Inicial"  style={{ width: '5%' }}></Column>
              <Column field="maximo" header="# Final"  style={{ width: '5%' }}></Column>
              </DataTable>
            }
            {props.estado===undefined &&
            <DataTable value={plantaDePersonal} editMode="row"   dataKey="id" onRowEditComplete={onRowEditComplete} tableStyle={{ minWidth: '60rem' }}>
              <Column rowEditor={allowEdit} headerStyle={{ width: '5%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
              <Column field="code" header="Codigo"  style={{ width: '10%' }}></Column>
              <Column field="cantidad" header="Cantidad" editor={(options) => textEditor(options)} style={{ width: '10%' }}></Column>
              <Column field="name" header="Nombre"  style={{ width: '40%' }}></Column>
              <Column field="valor" header="valor"  style={{ width: '5%' }}></Column>
              <Column field="total" header="Total"  style={{ width: '5%' }}></Column>
            </DataTable>
            }
            
        </div>
    </div>
  );
};

export default SeleccionFormulariosGeneral;
