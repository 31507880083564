import React,{useState,useEffect} from 'react';
import { Image } from 'primereact/image';
import { Calendar } from 'primereact/calendar';
import { locale, addLocale } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import FileUpload from '../components/fileupload/FileUpload';
const Enterese = () => {
const APIPRESENTACION = 'https://www.ancogenecop.com/APIANCO/controllers/procesosDiarios.php?op='

const [docSoporte, setDocSoporte] = useState([])
const [reload, setReload] = useState(true)
const [inactivar, setInactivar] = useState(false)
const [estado, setEstado] = useState(0)
const [productDialog, setProductDialog] = useState(false)
const [product, setProduct] = useState([])
const [submitted, setSubmitted] = useState(false);
const [fechaSolicitud, setFechaSolicitud] = useState('')
const [fechaServicio, setFechaServicio] = useState('')
const [id, setId] = useState(0)
const [pagina, setPagina] = useState('')
const [descripcion, setDescripcion] = useState('')
const [minDate, setMinDate] = useState(new Date())
const history = useHistory();
let emptyProduct = {
    NID_SOLICITANTE: '',
    CONSECUTIVO:'',
    DESCRIPCION:'',
};

addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
});

locale('es');


useEffect(() => {
//    if(props.idTipoNotificacion==160){
    async function traerNegocios() {
    setReload(false)
      const data = new FormData();
      await fetch(APIPRESENTACION + 'documentosEnterese', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            setDocSoporte(response)
        })
        .catch((error) => {
          console.log(error);
        })
    }
         traerNegocios()
  }, [reload]);


  useEffect(() => {
//    if(props.idTipoNotificacion==160){
    async function traerNegocios() {
    setInactivar(false)
      const data = new FormData();
      data.append("ESTADO",estado)
      data.append("ID",id)
      data.append("CODIGO",'NOAPLICA1')
      await fetch(APIPRESENTACION + 'inactivarActivar', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            setReload(true)
        })
        .catch((error) => {
          console.log(error);
        })
    }
         traerNegocios()
  }, [inactivar]);

  const saveProduct = async() => {
    setSubmitted(true);
   }


  const openNew = () => {
    setProduct(emptyProduct)
    setProductDialog(true);
}

const hideDialog = () => {
    setProductDialog(false);
}
const productDialogFooter = (
    <>
        <Button label={"Cancelar"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
    </>
);

    const inactivarActivar = (opcion,id) => {
        setEstado(opcion)
        setId(id)
        setInactivar(true)
    }

    const handleplanta = async(dato,opc) =>{
        if(opc==5000){
           setProductDialog(false);
           setFechaServicio('')
           setFechaSolicitud('')
           setPagina('')
           setReload(true)
        }else if(opc=4000){
            setSubmitted(true)
        }
    }

    function calcularEdad(fecha,opcion) {
        if(opcion==1){
            setFechaSolicitud(new Date(fecha).toISOString().slice(0, 10))
        }else if(opcion==2){
            setFechaServicio(new Date(fecha).toISOString().slice(0, 10))
        }
    }

    const onInputChange=(e)=>{
        console.log(e);
        setPagina(e.value)
    }
    return (
    <>
      <div className="my-2">
            <Button label="Adicionar Publicación" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
      </div>

     <div className="grid p-fluid">
      {docSoporte.map((image) => (
            <div className="col-12 md:col-4">
                  <Image
                    src={image.URL}
                    alt={image.URL}
                    width="180" height="200"
                    className="img-fluid h-100 w-100"
                    style={{ objectFit: "cover" }}
                  />
                  <div >
                    {image.FECHA_INICIAL}
                  </div>
                  <div >
                    {image.FECHA_FINAL}
                  </div>
                  <div >
                    {image.PAGINA}
                  </div>
                <div className="grid p-fluid">
                {image.ESTADOPAGINA==1 ?
                  <div className="col-12 md:col-4">
                        <Button icon="pi pi-check" className="p-button-rounded p-button-success mt-2" onClick={() => inactivarActivar(0,image.ID)}
                            tooltip='Inactivar Imagen'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                        }}
                        />
                    </div>
                  :
                  <div className="col-12 md:col-4">
                        <Button icon="pi pi-times" className="p-button-rounded p-button-warning mt-2" onClick={() => inactivarActivar(1,image.ID)}
                            tooltip='Activar Imagen'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                        }}
                        />
                    </div>


                  }

                </div>

            </div>
      ))}
      </div>
      <Dialog visible={productDialog}
      style={{ width: '650px' }}
        header={"Adicionar imágen a la presentación inicial"}
        modal className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}>
        <div className="grid">
        <div className="col-12 md:col-6">
                <label htmlFor="dirección">Fecha Iniclal de Publicación: {fechaSolicitud}</label>
                <Calendar showIcon showButtonBar value={fechaSolicitud}
                    dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                    yearRange="1960:2060" required
                    readOnlyInput={true}
                    locale='es'
                    onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                {submitted && !fechaSolicitud && <small className="p-invalid"
                style={{
                color: 'red',
                }}>la fecha inicial de publicación es requerida.</small>}
            </div>
        {/* <div className="col-12 md:col-6">
            <label htmlFor="dirección">Fecha Final de Publicación: {fechaServicio}</label>
            <Calendar showIcon showButtonBar value={fechaServicio}
            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
            locale='es'
            yearRange="1960:2060" required
            onChange={(e) => calcularEdad(e.value,2)}></Calendar>
            {submitted && !fechaServicio && <small className="p-invalid"
            style={{
                    color: 'red',
                }}
            >La fecha final de publicación requerida.</small>}
        </div> */}
        </div>
        <div className="field col">
                <label htmlFor="description">Url de la información</label>
                    <InputText id="nombre" value={pagina} onChange={(e) => setPagina(e.target.value)} placeholder="www.elsitio.com" />
                    {submitted && !pagina && <small className="p-invalid"
                    style={{
                   color: 'red',
                }}>La url donde se encentra la información es requerida.</small>}
        </div>
        <div className="field col">
                <label htmlFor="description">Describa su Entidad</label>
                    <InputText id="descripcion" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} placeholder="Descripción máximo 100 caracteres" />
                    {submitted && !pagina && <small className="p-invalid"
                    style={{
                   color: 'red',
                }}>Digite la descripción de la entidad máximo 100 caracteres.</small>}
        </div>

        <div className="field">
            <label>Seleccione la imágen de extensión .jpg para la publicación</label>
            <FileUpload archivos={1} handleplanta={handleplanta} product={product}
            OPCION={1}
            CODIGO={'NOAPLICA1'}
            fechaSolicitud={fechaSolicitud}
            fechaServicio={fechaServicio}
            descripcion={descripcion}
            pagina={pagina}
            />
            {submitted && <small className="p-invalid"
            style={{
                    color: 'red',
                }}
            >Debe seleccionar la imágen de la entidad es requerida.</small>}
        </div>
      </Dialog>
      </>
  );

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Enterese, comparisonFn);
