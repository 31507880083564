import React, { useState, useEffect, useRef } from 'react';
import { PDFViewer  } from "@react-pdf/renderer";
import { ProgressBar } from 'primereact/progressbar';
import { DataTable } from 'primereact/datatable';
import { locale, addLocale } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import SeleccionUsuariosDash from '../components/SeleccionUsuariosDash';
import SeleccionUsuarioAdmin from '../components/SeleccionUsuarioAdmin';
import SeleccionCodigosAdmin from '../components/SeleccionCodigosAdmin';
import SeleccionTipoDocumento from '../components/SeleccionTipoDocumento';
import SeleccionConsecutivoServicioAdmin from '../components/SeleccionConsecutivoServicioAdmin';
import SeleccionChipsLibres from '../components/SeleccionChipsLibres';
import SeleccionTipoDocumentoOtros from '../components/SeleccionTipoDocumentoOtros';
import SeleccionRegistradoresGenealogia from '../components/SeleccionRegistradoresGenealogia';

import Lateral from '../components/Lateral';
import Docupdf from '../components/Docupdf';
import Ayuda from '../components/Ayuda'
const FormularioA05 = () => {
    let emptyProduct = {
        TID_PROPIETARIO:'',
        NID_PROPIETARIO:'',
        CONSECUTIVO:'',
        CONSEC_NACIMIENTO:'',
        CRIADERO:'',
        DIRECCION:'',
        DEPARTAMENTO:'',
        MUNICIPIO:'',
        PREFIJO:'',
        ESPECIE:'',
        CONCEPCION:'',
        SEXO:'',
        FECHA_NACIMIENTO:'',
        CHIP_MACHO:'',
        COLOR:'',
        CHIP_HEMBRA:'',
        COMPOSICION:'',
        LIBRO:'',
        CONSECUTIVO_SERVICIO:'',
        FECHA_SOLICITUD:'',
        NOMBRE:'',
        MARCAS_HATO:'',
        COLOR:'',
        RASGOS_PARTICULARES:'',
        CHIP_ABUELO_PATERNO:'',
        CHIP_ABUELA_PATERNA:'',
        CHIP_ABUELO_MATERNA:'',
        CHIP_ABUELA_MATERNA:'',
        CHIP:'',
        TID_REGISTRADOR:'',
        NID_REGISTRADOR:'',
        OBSERVACIONES:'',
        NOMPROPIETARIO:''
    };
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    });

    locale('es');
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [usuariosAnco, setUsuariosAnco] = useState([])
    const [muestraCodigos, setMuestraCodigos] = useState(false)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [fechaServicio, setFechaServicio] = useState('')
    const [fechaSolicitud, setFechaSolicitud] = useState('')
    const [codigoEspecie, setCodigoEspecie] = useState('')
    const [muestraRaza, setMuestraRaza] = useState(false)
    const [codigoRaza, setCodigoRaza] = useState(0)
    const [parentesco, setParentesco] = useState('')
    const [muestraParentesco, setMuestraParentesco] = useState(false)
    const [prefijo, setPrefijo] = useState(false)
    const [prefijoInicial, setPrefijoInicial] = useState('')
    const [enviar, setEnviar] = useState(false)
    const [consecutivoServicio, setConsecutivoServicio] = useState(0)
    const [reloadDatosConsecutivo, setReloadDatosConsecutivo] = useState(false)
    const [mdatosConsecutivo, setMdatosConsecutivo] = useState([])
    const [codigo, setCodigo] = useState(0)
    const [editProductDialog, setEditProductDialog] = useState(false)
    const [consecutivo, setConsecutivo] = useState(0)
    const [tidPropietario, setTidPropietario] = useState('')
    const [muestraUsuario, setMuestraUsuario] = useState(false)
    const [criadero, setCriadero] = useState('')
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false)
    const [padre, setPadre] = useState('')
    const [madre, setMadre] = useState('')
    const [chipPadre, setChipPadre] = useState('')
    const [chipMadre, setChipMadre] = useState('')
    const [chipHijo, setChipHijo] = useState('')
    const [hijo, setHijo] = useState('')
    const [nombreAnimal, setNombreAnimal] = useState('')
    const [totalData, setTotalData] = useState([])
    const toast = useRef(null);
    const dt = useRef(null);
    const [reloadArbol, setReloadArbol] = useState(false)
    const [value, setValue] = useState(0);
    const [datosPdf, setDatosPdf] = useState('')
    const [value1, setValue1] = useState(true);
    const [cambiaData, setCambiaData] = useState(false)
    const interval = useRef(null);
    const [tidRegistrador, setTidRegistrador] = useState(0)
    const [muestra, setMuestra] = useState(false)
    const [ayuda, setAyuda] = useState(false) 
    const [opcion, setOpcion] = useState(0) 
    const APIFORMULARIOA05 = 'https://www.ancogenecop.com/APIANCO/controllers/formularioA05.php?op='
    const APIPDF = 'https://www.ancogenecop.com/APIANCO/controllers/formularioPdf.php?op='


    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                setUsuariosAnco([])
                const data = new FormData();
                if(localStorage.getItem('administrador')==1){
                    if(nidPropietario==''){
                        data.append('NID_PROPIETARIO','ADMINANCO')
                    }else{
                        data.append('NID_PROPIETARIO',nidPropietario)
                    }
                  }else{
                    data.append('NID_PROPIETARIO',localStorage.getItem('nid_propietario'))
                  }

                await fetch(APIFORMULARIOA05 + 'formulariosA05', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            console.log(response)
                            setUsuariosAnco(response)
                            if(!muestra){
                                setMuestra(true)
                            }
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])



    useEffect(() => {
        if (reloadDatosConsecutivo) {
            setReloadDatosConsecutivo(false)
            const recarga = async (consecutivoServicio,nidPropietario) => {
            if(consecutivoServicio==null) return
                setUsuariosAnco([])
                const data = new FormData();
                data.append("CONSECUTIVO", consecutivoServicio)
                data.append("NID_PROPIETARIO", nidPropietario)
                await fetch(APIFORMULARIOA05 + 'datosConsecutivoServicio', {
                    method: 'POST',
                    header: {

                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                    console.log(response)
                        if (response.length>0) {
                            setMdatosConsecutivo(response)
                            product.CRIADERO= response[0].CRIADERO
                            product.SEXO= response[0].SEXO
                            product.DIRECCION= response[0].DIRECCION
                            product.DEPARTAMENTO= response[0].DEPARTAMENTO
                            product.MUNICIPIO= response[0].MUNICIPIO
                            product.PREFIJO= response[0].PREFIJO
                            product.ESPECIE= response[0].ESPECIE
                            product.CONCEPCION= response[0].CONCEPCION
                            product.FECHA_NACIMIENTO= response[0].FECHA_NACIMIENTO
                            product.CHIP_MACHO= response[0].CHIP_MACHO
                            product.CHIP_HEMBRA= response[0].CHIP_HEMBRA
                            product.COMPOSICION= response[0].COMPOSICION
                            product.LIBRO= response[0].LIBRO
                            product.RAZA= response[0].RAZA
                        }
                        // setReloadUsuarios(true)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga(consecutivoServicio,nidPropietario)
        }
    }, [reloadDatosConsecutivo, consecutivoServicio,nidPropietario])



    useEffect(() => {
        setDatosPdf([])
        if(chipPadre===null && chipMadre===null) return
        if(chipPadre==='' && chipMadre==='') return
        async function traerUsuarios(chipPadre,chipMadre,chipHijo,prefijo,nombreAnimal) {
          const data = new FormData();
          data.append("chip_padre",chipPadre)
          data.append("chip_madre",chipMadre)
          data.append("chip_hijo",chipHijo)
          data.append("prefijo",prefijo)
          data.append("nombre",nombreAnimal)
          await fetch(APIPDF + 'generarArbol', {
            method: 'POST',
            header: {
              'Accept': 'application/json',
              'Content-type': 'application/json'
            },
            body: data
          }).then((response) => response.json())
            .then((response) => {
                setDatosPdf(response)
                setReloadArbol(false)
            })
            .catch((error) => {
              console.log(error);
            })
        }
            traerUsuarios(chipPadre,chipMadre,chipHijo,prefijo,nombreAnimal)
          }, [reloadArbol,chipPadre,chipMadre,chipHijo,prefijo,nombreAnimal]);

        const muestraAyuda = () =>{
            setAyuda(true)
        }
    
        const hideDeleteProductAyuda = () => {
            setAyuda(false);
        }
    
        const deleteProductDialogAyuda = (
            <>
                <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
            </>
        );
    

    const openNew = () => {
        setMdatosConsecutivo([])
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false)
        setMuestraRaza(false)
        setMuestraUsuario(false)
        setMuestraParentesco(false)
        setProductDialog(true);
        setEnviar(true)
        setOpcion(1)
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setMuestraCodigos(false);
        setMuestraParentesco(false)
    }

    const hideDeleteProductDialog = () => {
        setEditProductDialog(false);
    }

    const saveProduct = async () => {
        if(product.CONSECUTIVO=='') 
        { toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el consecutivo, verifique por favor', life: 3000 });
            return
        }
        if(product.TID_PROPIETARIO=='') 
        { toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el tipo de identificacion, verifique por favor', life: 3000 });
            return
        }
        if(product.CONSEC_NACIMIENTO=='')
        { toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el consecutivo, verifique por favor', life: 3000 });
            return
        }
        if(product.FECHA_SOLICITUD=='')
        { toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe ingresar la fecha de la solicitud, verifique por favor', life: 3000 });
            return
        }
        if(product.CHIP=='') 
        { toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el chip, verifique por favor', life: 3000 });
            return
        }
        if(product.CHIP=='') 
        { toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el chip, verifique por favor', life: 3000 });
            return
        }

        if(product.NOMBRE=='') 
        { toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el nombre, verifique por favor', life: 3000 });
            return
        }
        if(product.COLOR=='') 
        { toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe digitar el color, verifique por favor', life: 3000 });
            return
        }
        if(product.TID_REGISTRADOR=='') { 
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el tipo de documemto, verifique por favor', life: 3000 });
            return
        }
        if(product.NID_REGISTRADOR=='') 
        { toast.current.show({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar el registrador, verifique por favor', life: 3000 });
            return
        }
        const data = new FormData();
        data.append("TID_PROPIETARIO", product.TID_PROPIETARIO)
        data.append("NID_PROPIETARIO", product.NID_PROPIETARIO)
        data.append("CONSECUTIVO", product.CONSECUTIVO)
        if(product.CONSECUTIVO_SERVICIO==null){
            data.append("CONSECUTIVO_SERVICIO",'')
        }else{
            data.append("CONSECUTIVO_SERVICIO", product.CONSECUTIVO_SERVICIO)
        }  
        data.append("CRIADERO", product.CRIADERO)
        data.append("DIRECCION", product.DIRECCION)
        data.append("DEPARTAMENTO", product.DEPARTAMENTO)
        data.append("MUNICIPIO", product.MUNICIPIO)
        data.append("PREFIJO", product.PREFIJO)
        data.append("ESPECIE", product.ESPECIE)
        data.append("RAZA", product.RAZA)
        data.append("CONCEPCION", product.CONCEPCION)
        // if(product.SEXO=='MACHO') data.append("SEXO", 'M')
        // if(product.SEXO=='HEMBRA') data.append("SEXO", 'H')
        data.append("FECHA_NACIMIENTO", product.FECHA_NACIMIENTO)
        if(product.CHIP_MACHO==undefined){
            data.append("CHIP_MACHO",'')
        }else{
            data.append("CHIP_MACHO", product.CHIP_MACHO)
        }        
        data.append("COLOR", product.COLOR)
        if(product.CHIP_HEMBRA==undefined){
            data.append("CHIP_HEMBRA",'')
        }else{
            data.append("CHIP_HEMBRA", product.CHIP_HEMBRA)
        }        
        data.append("COMPOSICION", product.COMPOSICION)
        data.append("SEXO", product.SEXO)    
        data.append("LIBRO", product.LIBRO)
        data.append("CONSECUTIVO_SERVICIO", product.CONSEC_NACIMIENTO)
        data.append("FECHA_SOLICITUD", product.FECHA_SOLICITUD)
        data.append("NOMBRE", product.NOMBRE)
        data.append("MARCAS_HATO", product.MARCAS_HATO)
        if(product.RASGOS_PARTICULARES==null){
            data.append("RASGOS_PARTICULARES",'')
        }else{
            data.append("RASGOS_PARTICULARES", product.RASGOS_PARTICULARES)
        }        
        data.append("RASGOS_PARTICULARES", product.RASGOS_PARTICULARES)
        if(product.CHIP_ABUELO_MATERNA==undefined){
            data.append("CHIP_ABUELO_MATERNA",'')
        }else{
            data.append("CHIP_ABUELO_MATERNA", product.CHIP_ABUELO_MATERNA)
        }
        if(product.CHIP_ABUELO_PATERNO==undefined){
            data.append("CHIP_ABUELO_PATERNO",'')
        }else{
            data.append("CHIP_ABUELO_PATERNO", product.CHIP_ABUELO_PATERNO)
        }
        if(product.CHIP_ABUELA_PATERNA==undefined){
            data.append("CHIP_ABUELA_PATERNA",'')
        }else{
            data.append("CHIP_ABUELA_PATERNA", product.CHIP_ABUELA_PATERNA)
        }
        if(product.CHIP_ABUELA_MATERNA==undefined){
            data.append("CHIP_ABUELA_MATERNA",'')
        }else{
            data.append("CHIP_ABUELA_MATERNA", product.CHIP_ABUELA_MATERNA)
        }
        data.append("CHIP", product.CHIP)
        data.append("TID_REGISTRADOR", product.TID_REGISTRADOR)
        data.append("NID_REGISTRADOR", product.NID_REGISTRADOR)
        if(product.OBSERVACIONES==undefined  || product.OBSERVACIONES==null){
            data.append("OBSERVACIONES",'')
        }else{
            data.append("OBSERVACIONES", product.OBSERVACIONES)
        }        
        data.append("CODIGO", 'A05')
        data.append("OPCION", opcion)
        await fetch(APIFORMULARIOA05 + 'ingresarGenealogia', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setMuestraParentesco(false)
                    //setProduct([]);
                  
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const saveProductAdicional = async () => {
        setSubmitted(true);
        const data = new FormData();
        data.append("TID_PROPIETARIO", product.TID_PROPIETARIO)
        data.append("NID_PROPIETARIO", nidPropietario)
        data.append("CONSECUTIVONEW", product.CONSECUTIVOADICIONAL)
        data.append("CONSECUTIVO", product.CONSECUTIVO)
        data.append("FORMA_CONCEPCION", product.CONCEPCION)
        data.append("CONSECUTIVO_SERVICIO", product.CONSEC_NACIMIENTO)
        data.append("FECHA_SOLICITUD", product.FECHA_SOLICITUD)
        data.append("COD_ESPECIE", product.COD_ESPECIE)
        data.append("FECHA_NACIMIENTO", product.FECHA_NACIMIENTO)
        data.append("SEXO", product.SEXO_ADICIONAL)
        data.append("RAZA_MACHO", product.RAZA)
        data.append("CHIP_MACHO", product.CHIP_MACHO)
        data.append("CHIP_HEMBRA", product.CHIP_HEMBRA)
        data.append("TIPO_DE_PARTO", product.TIPO_DE_PARTO)
        data.append("PESO_AL_NACER", product.PESO_AL_NACER_ADICIONAL)
        data.append("OBSERVACIONES", product.OBSERVACIONES)
        data.append("PREFIJO_SOLICITANTE", product.PREFIJO_SOLICITANTE)
        data.append("CODIGO", 'A05')
        data.append("SIGLA", product.sigla)
        
        await fetch(APIFORMULARIOA05 + 'ingresarNacimientoAdicionales', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setEditProductDialog(false);
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const editProduct = (product) => {
    console.log(product)
    setOpcion(0)
        setChipPadre(product.CHIP_PADRE)
        setChipMadre(product.CHIP_MADRE)
        setChipHijo(product.CHIP)
        setPrefijo(product.PREFIJO)
        product.NOMBRE=product.NOMANIMAL   
        //console.log("el nombres es "+product.NOMANIMAL)
        setNombreAnimal(product.NOMANIMAL)
        setReloadArbol(true)
        setConsecutivo(product.CONSECUTIVO)
        setPadre(product.CHIP_PADRE)
        setMadre(product.CHIP_MADRE)
        setHijo(product.CHIP)
        setPrefijoInicial(product.PREFIJO)
        //setNombreAnimal(product.NOMBRE)
        setDeleteProductsDialog(true);
        setTotalData(product)
    }

    const deleteProductDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProductAdicional} />
        </>
    );


    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    }

    const deleteProductsDialogFooter = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
        </>
    );
    const confirmDeleteProduct = (product) => {
   
        setProduct({ ...product });
        setConsecutivoServicio(product.CONSEC_NACIMIENTO)
        setReloadDatosConsecutivo(true)
        setProductDialog(true)
        setCriadero(product.CRIADERO)
        // setEnviar(false)
        // setCambiaConcepcion(true)
        //  setCodigoRaza(product.COD_ESPECIE)
          //setFechaServicio(product.FECHA_NACIMIENTO)
        //  setFechaSolicitud(product.FECHA_SOLICITUD)
        //  setCodigoEspecie(product.COD_ESPECIE)
        // setPrefijo(product.PREFIJO_SOLICITANTE)
        setConsecutivoServicio(product.CONSEC_NACIMIENTO)
        // setConsecutivo(product.CONSECUTIVO)
        // setCodigo(product.FORMA_CONCEPCION)
         setNidPropietario(product.NID_PROPIETARIO)
         setReloadDatosConsecutivo(true)
         setReloadUsuarios(true)
         
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }




    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                    <Button icon="pi pi-copy" className="p-button-rounded p-button-success mr-2"
                    tooltip='Consultar Certificado de Genealogía'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    onClick={() => editProduct(rowData)} />

                    <Button icon="pi  pi-replay" className="p-button-rounded p-button-primary mt-2"
                            tooltip='Modificar Información'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                    }}
                    onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">CERTIFICADO DE AFILIACION GENEALOGICA FORMULARIO A05</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="Buscar" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const headerDatos = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        {(consecutivoServicio!=null && consecutivoServicio!=0) &&
            <h5 className="m-0">INFORMACION CONTENIDA EN EL FORMULARIO DE NACIMIENTO Y CONSECUTIVO DEL SERVICIO</h5>        
        }
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label={enviar ? "Cancelar" : "Cerrar"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const handleplanta = async (dato, opc) => {
        
        if(dato==undefined) return
        if (opc == 1) {
              setNidPropietario(dato)
              product.NID_PROPIETARIO = dato
        } else if (opc == 2) {
            setConsecutivo(dato)
            product.CONSECUTIVO = dato
        } else if (opc == 3) {
            setCodigoEspecie(dato)
            product.COD_ESPECIE = dato
        } else if (opc == 4) {
            setCodigoRaza(dato)
            setMuestraRaza(true)
            product.RAZA_MACHO = dato
        }
        else if (opc == 5) {
            product.CHIP_MACHO = dato
        } else if (opc == 6) {
            setParentesco(dato)
            setMuestraParentesco(true)
            product.PARENTESCO = dato
        } else if (opc == 9) {
            setMuestraUsuario(true)
            setTidPropietario(dato)
            product.TID_PROPIETARIO = dato
        } else if (opc == 11) {
            setReloadDatosConsecutivo(true)
            setConsecutivoServicio(dato)
            product.CONSEC_NACIMIENTO = dato
        } else if (opc == 100) {
            product.CHIP = dato
        }else if(opc==16){
            product.TID_REGISTRADOR = dato
        }else if(opc==13){
            product.NID_REGISTRADOR = dato
        }else if(opc==150){
            setNidPropietario(dato)
            setReloadUsuarios(true)
        }
        else if (opc == 1000) {
            setNidPropietario(0)
            setMuestraCodigos(false)
        }
    }

    function calcularEdad(fecha, opcion) {

        if (opcion == 1) {
            setFechaSolicitud(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_SOLICITUD = new Date(fecha).toISOString().slice(0, 10)
        } else if (opcion == 2) {
            setFechaServicio(new Date(fecha).toISOString().slice(0, 10))
            product.FECHA_NACIMIENTO = new Date(fecha).toISOString().slice(0, 10)
        }
    }



    const onLoadingClick = (soporte) => {
        window.open(soporte, "_blank")
    }

    return (
    <>
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {value1 &&
                     <div className="formgrid grid">
                          <div className="field col">
                            <h5>Consultado información por favor espere</h5>
                                <div className="col">
                                <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                            </div>
                        </div>
                    </div>
                    }
                    <div className="formgrid grid">
                        <div className="field col-12">
                        <div>
                <SeleccionUsuariosDash handleplanta={handleplanta} opcion={150}/>
                </div>

                    <DataTable ref={dt} value={usuariosAnco} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        globalFilter={globalFilter} emptyMessage="No existen registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem'}}></Column> */}
                        <Column field="descripcion" header="TID_PROPIETARIO" sortable ></Column>
                        <Column field="NID_PROPIETARIO" header="NID_PROPIETARIO" sortable ></Column>
                        <Column field="NOMPROPIETARIO" header="NOMBRE" sortable ></Column>
                        <Column field="CONSECUTIVO" header="CONSECUTIVO" sortable headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        <Column field="CONSEC_NACIMIENTO" header="CONSEC_NACIMIENTO" sortable headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>
                        <Column field="CHIP" header="CHIP" sortable headerStyle={{ width: '8%', minWidth: '8rem' }}></Column>                        
                        <Column field="CHIP_PADRE" header="CHIP_MACHO" sortable ></Column>
                        <Column field="CHIP_MADRE" header="CHIP_HEMBRA" sortable ></Column>
                        <Column field="NOMCONCEPCION" header="FORMA_DE_CONCEPCION" sortable ></Column>
                        <Column field="FECHA_SOLICITUD" header="FECHA_SOLICITUD" sortable ></Column>
                        <Column field="COD_ESPECIE" header="COD_ESPECIE" sortable ></Column>
                        <Column field="SEXO" header="SEXO" sortable ></Column>
                        <Column field="RAZA" header="RAZA" sortable ></Column>
                        <Column field="COLOR" header="COLOR" sortable ></Column>
                        <Column field="NOMANIMAL" header="NOMBRE" sortable ></Column>
                        <Column field="PREFIJO" header="PREFIJO" sortable ></Column>
                        <Column field="CRIADERO" header="CRIADERO" sortable ></Column>
                        <Column field="DIRECCION" header="DIRECCION" sortable ></Column>
                        <Column field="MARCAR_HATO" header="MARCAR_HATO" sortable ></Column>
                        <Column field="RASGOS_PARTICULARES" header="RASGOS_PARTICULARES" sortable ></Column>
                        <Column field="PUNTAJE_FUNDADORES" header="PUNTAJE_FUNDADORES" sortable ></Column>
                        <Column field="TID_REGISTRADOR" header="TID_REGISTRADOR" sortable ></Column>
                        <Column field="NID_REGISTRADOR" header="NID_REGISTRADOR" sortable ></Column>
                        <Column field="CHIP_ABUELO_PATERNO" header="CHIP_ABUELO_PATERNO" sortable ></Column>
                        <Column field="CHIP_ABUELA_PATERNA" header="CHIP_ABUELA_PATERNA" sortable ></Column>
                        <Column field="CHIP_ABUELO_MATERNO" header="CHIP_ABUELO_MATERNO" sortable ></Column>
                        <Column field="CHIP_ABUELA_MATERNA" header="CHIP_ABUELA_MATERNA" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>
                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '900px' }} header={enviar ? "Adicionar Certificado de Genealogía" : "Consultando Certificado de Genealogía"} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {/* {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />} */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Tipo Documento</label>
                                <SeleccionTipoDocumento handleplanta={handleplanta} opcion={9}
                                TID_PROPIETARIO={product.TID_PROPIETARIO} descripcion={product.descripcion}/>
                            </div>
                            <div className="field col">
                            {/* {muestraUsuario && */}
                                <>
                                <label htmlFor="name">Usuario</label>
                                <SeleccionUsuarioAdmin codigo={'A05'} handleplanta={handleplanta} NID_PROPIETARIO={product.NOMPROPIETARIO}
                                tidPropietario={tidPropietario} />
                                </>
                             {/* } */}
                            </div>
                            <hr />
                            {/* {muestraCodigos && nidPropietario > 0 && */}
                                <>
                                    <div className="field col">
                                        <label htmlFor="name">Consecutivo</label>
                                        <SeleccionCodigosAdmin NID_PROPIETARIO={nidPropietario}
                                            codigo={'A05'} CONSECUTIVO={product.CONSECUTIVO} handleplanta={handleplanta} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="name">Consecutivo del Servicio</label>
                                        <SeleccionConsecutivoServicioAdmin codigo={'A05'} NID_PROPIETARIO={nidPropietario}
                                        CONSECUTIVO_SERVICIO={product.CONSEC_NACIMIENTO}
                                        handleplanta={handleplanta} />
                                    </div>

                                </>
                            {/* } */}
                        </div>
                        <hr />
                            <>
                                <div className="formgrid grid">
                        {(consecutivoServicio!=null && consecutivoServicio!=0) &&
                        <>
                                <DataTable ref={dt} value={mdatosConsecutivo}
                                    dataKey="id"  className="datatable-responsive"
                                    header={headerDatos} responsiveLayout="scroll">
                                    <Column field="CRIADERO" header="CRIADERO" sortable ></Column>
                                    <Column field="DIRECCION" header="DIRECCION" sortable ></Column>
                                    <Column field="NOMDEPTO" header="DEPARTAMENTO" sortable ></Column>
                                    <Column field="NOMMPIO" header="MUNICIPIO" sortable ></Column>
                                    <Column field="PREFIJO" header="PREFIJO" sortable ></Column>
                                    <Column field="NOMESPECIE" header="ESPECIE" sortable ></Column>
                                    <Column field="CONCEPCION" header="FORMA DE CONCEPCION" sortable ></Column>
                                </DataTable>
                                <hr/>
                                <DataTable ref={dt} value={mdatosConsecutivo}
                                    dataKey="id"  className="datatable-responsive"
                                    responsiveLayout="scroll">
                                    <Column field="NOMSEXO" header="SEXO" sortable ></Column>
                                    <Column field="FECHA_NACIMIENTO" header="FEC. NACIMIENTO" sortable ></Column>
                                    <Column field="CHIP_MACHO" header="CHIP_MACHO" sortable ></Column>
                                    <Column field="RAZA" header="RAZA" sortable ></Column>
                                    <Column field="CHIP_HEMBRA" header="CHIP_HEMBRA" sortable ></Column>
                                    <Column field="COMPOSICION" header="COMPOSICION" sortable ></Column>
                                    <Column field="LIBRO" header="LIBRO" sortable ></Column>
                                </DataTable>
                                </>
                            } 
                                <hr/>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-4">
                                        <label htmlFor="dirección">Fecha de Solicitud: {product.FECHA_SOLICITUD}</label>
                                        <Calendar showIcon showButtonBar value={fechaSolicitud}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            yearRange="1960:2060" required
                                            readOnlyInput={true}
                                            locale='es'
                                            onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                                    </div>
                                    <div className="field col-4">
                                        <label htmlFor="description">Nombre</label>
                                        <InputText id="nombre" value={product.NOMBRE} onChange={(e) => onInputChange(e, 'NOMBRE')} />
                                        </div>
                                   <div className="field col-4">
                                        <label htmlFor="description">Color</label>
                                        <InputText id="nombre" value={product.COLOR} onChange={(e) => onInputChange(e, 'COLOR')} />
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                    <div className="field col-6">
                                        <label htmlFor="description">Marcas de Hato</label>
                                        <InputText id="nombre" value={product.MARCAS_HATO} onChange={(e) => onInputChange(e, 'MARCAS_HATO')} />
                                    </div>
                                    <div className="field col-6">
                                        <label htmlFor="description">Rasgos Particulares</label>
                                        <InputText id="nombre" value={product.RASGOS_PARTICULARES} onChange={(e) => onInputChange(e, 'RASGOS_PARTICULARES')} />
                                     </div>
                                 </div>
                                <hr/>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="description">Chip Abuelo Paterno</label>
                                        <InputText id="nombre" value={product.CHIP_ABUELO_PATERNO} onChange={(e) => onInputChange(e, 'CHIP_ABUELO_PATERNO')} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="description">Chip Abuela Paterna</label>
                                        <InputText id="nombre" value={product.CHIP_ABUELA_PATERNA} onChange={(e) => onInputChange(e, 'CHIP_ABUELA_PATERNA')} />
                                     </div>
                                    <div className="field col">
                                        <label htmlFor="description">Chip Abuelo Materna</label>
                                        <InputText id="nombre" value={product.CHIP_ABUELO_MATERNA} onChange={(e) => onInputChange(e, 'CHIP_ABUELO_MATERNA')} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="description">Chip Abuela Materna</label>
                                        <InputText id="nombre" value={product.CHIP_ABUELA_MATERNA} onChange={(e) => onInputChange(e, 'CHIP_ABUELA_MATERNA')} />
                                     </div>
                                 </div>
                                <hr/>

                            </>

                    <div className="formgrid grid">
                            <div className="field col-6">
                                <label htmlFor="name">Chip Asignado</label>
                                <SeleccionChipsLibres handleplanta={handleplanta} CHIP={product.CHIP}/>
                            </div>
                            <div className="field col-6">
                                    <label htmlFor="description">Observaciones</label>
                                    <InputTextarea id="observaciones" value={product.OBSERVACIONES} onChange={(e) => onInputChange(e, 'OBSERVACIONES')} required rows={3} cols={20} />
                            </div>
                    </div>
           
                    <div className="formgrid grid">
                    <div className="field col-6">
                    <label htmlFor="name">Tipo Documento Registrador</label>
                           <SeleccionTipoDocumentoOtros handleplanta={handleplanta}  />
                           </div>
                           <div className="field col-6">
                           <label htmlFor="name">Nombre Registrador</label>
                           <SeleccionRegistradoresGenealogia handleplanta={handleplanta}  />
                           </div>
                           </div>
                    
                    </Dialog>
                    {datosPdf!='' ?
                     <Dialog visible={deleteProductsDialog} style={{ width: '950px' }} header="Certificado de Registro Genealógico" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                        <PDFViewer style={{ width: "100%", height: "90vh" }}>
                            <Docupdf CONSECUTIVO={consecutivo}
                            product={totalData}
                            datosPdf={datosPdf}
                            />
                        </PDFViewer>
                        </div>
                    </Dialog>
                    :
                    <Dialog visible={deleteProductsDialog} style={{ width: '950px' }} header="Certificado de Registro Genealógico" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                        <PDFViewer style={{ width: "100%", height: "90vh" }}>
                            <Docupdf CONSECUTIVO={consecutivo}
                            product={totalData}
                            />
                        </PDFViewer>
                        </div>
                    </Dialog>

                    }
                    <Dialog visible={ayuda} style={{ width: '900px' }} header="Ayuda Certificado de Afiliación Genealógica Formulario A05" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={'A05'} />                    
                    </Dialog>                    
                </div>
            </div>
        </div>
        </>
    );

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormularioA05, comparisonFn);
