import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionEstado from '../components/SeleccionEstado';
import SeleccionHomologado from '../components/SeleccionHomologado';
import ImportarExcel from '../components/ImportarExcel';
import Lateral from '../components/Lateral';
const MaestroChips = () => {
    let emptyProduct = {
        ID:0,
        CHIP_MAESTRO:'',
        FECHA_RECEPCION:'',
        HOMOLOGADO:'',
        NOMBRE:'',
        ESTADO:'',
        MESTADO:''
    };
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [homo, setHomo] = useState(false)
    const [value1, setValue1] = useState(true)
    const [consecutivosNuevos, setConsecutivosNuevos] = useState([])
    const [newConsecutivos, setNewConsecutivos] = useState([])
    const [deleteProductsDialogDet, setDeleteProductsDialogDet] = useState(false)
    const MAESTROCHIPS = 'https://www.ancogenecop.com/APIANCO/controllers/maestroChips.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                await fetch(MAESTROCHIPS + 'maestroChips', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setProducts(response)
                        }
                    setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }

    const hideDialogConsecutivos = () => {
        setDeleteProductsDialogDet(false);
    }


    const saveProduct = async() => {
        setSubmitted(true);
         if(product.CHIP_MAESTRO=='') {console.log(1); return}
         if(product.FECHA_RECEPCION=='') {console.log(2); return}
         if(product.HOMOLOGADO=='') {console.log(3); return}
         if(product.ESTADO=='') {console.log(4); return}
         if(homo){
            if(product.NOMBRE=='') {console.log(5); return}
         }else{
            product.NOMBRE=''
         }
        const data = new FormData();
        data.append("ID", product.ID)
        data.append("CHIP_MAESTRO", product.CHIP_MAESTRO)
        data.append("FECHA_RECEPCION", product.FECHA_RECEPCION)
        data.append("HOMOLOGADO", product.HOMOLOGADO)
        data.append("NOMBRE", product.NOMBRE)
        data.append("ESTADO", product.ESTADO)
        await fetch(MAESTROCHIPS + 'ingresarChip', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response == 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                } else {
                    toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    const importCSV = (e) => {
        const file = e.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const csv = e.target.result;
            const data = csv.split('\n');

            // Prepare DataTable
            const cols = data[0].replace(/['"]+/g, '').split(',');
            data.shift();

            const importedData = data.map(d => {
                d = d.split(',');
                const processedData = cols.reduce((obj, c, i) => {
                    c = c === 'Status' ? 'inventoryStatus' : (c === 'Reviews' ? 'rating' : c.toLowerCase());
                    obj[c] = d[i].replace(/['"]+/g, '');
                    (c === 'price' || c === 'rating') && (obj[c] = parseFloat(obj[c]));
                    return obj;
                }, {});

                processedData['id'] = createId();
                return processedData;
            });

            const _consecutivosNuevos = [...consecutivosNuevos, ...importedData];

            setNewConsecutivos(_consecutivosNuevos);
            console.log(_consecutivosNuevos)
        };
    }


    const editProduct = (product) => {
        if(product.HOMOLOGADO=='S'){
            setHomo(true)
        }
        setProduct({ ...product });

        setProductDialog(true);
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
    }


    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Nuevos Chips" icon="pi pi-plus" className="p-button-info mr-2" onClick={importar} />
                {/* <FileUpload mode="basic" name="demo[]" auto url="https://primefaces.org/primereact/showcase/upload.php" accept=".csv" chooseLabel="Import" className="mr-2 inline-block" onUpload={importCSV} /> */}
                {/* <Button label="Exportar" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />  */}
            </React.Fragment>
        )
    }

    const importar = () => {
        setDeleteProductsDialogDet(true)
    }

    function calcularEdad(fecha, opcion) {

        if (opcion == 1) {
            product.FECHA_RECEPCION = new Date(fecha).toISOString().slice(0, 10)
        }
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} /> */}
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Maestro de Chips</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const productDialogFooterC = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDialogConsecutivos} />
        </>
    );

    const handleplanta = async (dato, opc,opcion) => {
        setSubmitted(false)
        if (opc == 1) {
            product.ESTADO = dato
        }else{
            if(dato=='S'){
                setHomo(true)
            }else{
                setHomo(false)
            }
            product.HOMOLOGADO = dato
        }
    }

    return (
        <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }

        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12">
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="CHIP_MAESTRO" header="CHIP_MAESTRO" sortable ></Column>
                        <Column field="FECHA_RECEPCION" header="FECHA_RECEPCION" sortable ></Column>
                        <Column field="HOMO" header="HOMOLOGADO" sortable ></Column>
                        <Column field="NOMBRE" header="NOMBRE" sortable ></Column>
                        <Column field="MESTADO" header="ESTADO" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={deleteProductsDialogDet} style={{ width: '950px' }}  modal className="p-fluid" footer={productDialogFooterC} onHide={hideDialog}>

                    <ImportarExcel opcion={0} hideDialogConsecutivos={hideDialogConsecutivos}/>

                    </Dialog>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle del Chip" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col-6">
                            <label htmlFor="name">CHIP_MAESTRO</label>
                            <InputText id="name" value={product.CHIP_MAESTRO} onChange={(e) => onInputChange(e, 'CHIP_MAESTRO')} required
                            autoFocus
                            className={classNames({ 'p-invalid': submitted && !product.CHIP_MAESTRO })} />
                            {submitted && !product.CHIP_MAESTRO && <small className="p-invalid">Número de chip requerido.</small>}
                        </div>
                        <div className="field col-6">
                        <label htmlFor="dirección">Fecha de Recepción: {product.FECHA_RECEPCION}</label>
                                        <Calendar showIcon showButtonBar value={product.FECHA_RECEPCION}
                                            dateFormat="yy-mm-dd" monthNavigator={true} yearNavigator={true}
                                            yearRange="1960:2060" required
                                            readOnlyInput={true}
                                            onChange={(e) => calcularEdad(e.value, 1)}></Calendar>
                                        {submitted && !product.FECHA_RECEPCION && <small className="p-invalid"
                                        style={{
                                        color: 'red',
                                        }}>la fecha de recepción es requerida.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                    <div className="field col">
                            <label htmlFor="name">Estado</label>
                            <SeleccionEstado  handleplanta={handleplanta} MESTADO={product.MESTADO}
                            required
                            />
                            {submitted && !product.ESTADO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El estado es requerido.</small>}
                        </div>
                        <div className="field col">
                            <label htmlFor="name">Homologado</label>
                            <SeleccionHomologado  handleplanta={handleplanta} MESTADO={product.HOMO}
                            required
                            />
                            {submitted && !product.HOMOLOGADO && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>Es estado de homologado es requerido.</small>}
                        </div>
                    </div>
                    <div className="formgrid grid">
                    {homo &&
                        <div className="field col-6">
                            <label htmlFor="name">NOMBRE A QUIEN SE HOMOLOGA</label>
                            <InputText id="name" value={product.NOMBRE} onChange={(e) => onInputChange(e, 'NOMBRE')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.NOMBRE })} />
                            {submitted && !product.NOMBRE && <small className="p-invalid">El nombre a quien se homologa es requerido.</small>}
                        </div>
                    }
                    </div>
                    </Dialog>

                </div>
            </div>
        </div>
    </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MaestroChips, comparisonFn);
