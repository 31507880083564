import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Lateral from '../components/Lateral';
const Libros = () => {
    let emptyProduct = {
        id:0,
        codigo:'',
        descripcion:'',
        valor:'',
        anio:'',
    };
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [cargaComposicion, setCargaComposicion] = useState(false)
    const [detalle, setDetalle] = useState([])
    const [composicion, setComposicion] = useState('')
    const [nombre, setNombre] = useState('')
    const APISELECCIONES = 'https://www.ancogenecop.com/APIANCO/controllers/selecciones.php?op='
    const LIBROS = 'https://www.ancogenecop.com/APIANCO/controllers/libros.php?op='    
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                await fetch(APISELECCIONES + 'libros', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setProducts(response)
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])
    
    useEffect(() => {
        if (cargaComposicion) {
            const recarga = async () => {
                setCargaComposicion(false)
                const data = new FormData();
                data.append('COMPOSICION',composicion)
                await fetch(LIBROS + 'libros', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            setDetalle(response)                        
                            setNombre('DETALLE DEL '+response[0].LIBRO)
                            setProductDialog(true);
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [cargaComposicion])    


    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    }



    const editProduct = (product) => {
        setProduct({ ...product });
        setComposicion(product.COMPOSICION)
        setCargaComposicion(true)
    }

    const confirmDeleteProduct = (product) => {
        setProduct(product);
    }




    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    {/* <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} /> */}
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }




    const actionBodyTemplate = (rowData) => {
        return (
        <>
            {rowData.COMPOSICION !='FU' &&
            <div className="actions">
                    <Button icon="pi pi-search" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
            </div>                
            }
        </>                
        );
        
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            {/* <h5 className="m-0">Formularios FEDEANCO</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span> */}
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            {/* <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} /> */}
        </>
    );


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                    <div className="field col-12">
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>

                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        resizableColumns={true}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="DESCRIPCION" header="LIBRO" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    <Dialog visible={productDialog} style={{ width: '650px' }} header={nombre} modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <DataTable ref={dt} value={detalle} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        resizableColumns={true}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column field="MACHO" header="MACHO" sortable headerStyle={{ width: '45%', minWidth: '5rem' }}></Column>
                        <Column field="HEMBRA" header="HEMBRA" sortable headerStyle={{ width: '45%', minWidth: '5rem' }}></Column>                        
                    </DataTable>                    
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Libros, comparisonFn);
