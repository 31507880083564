import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext'; 
import { RadioButton } from "primereact/radiobutton";
import SeleccionUsuarioFormularios from '../components/SeleccionUsuarioFormularios';
import Ayuda from '../components/Ayuda'
const MaestroConsecutivosUsuario = () => {
    let emptyProduct = { 
        id:0,
        codigo:'',
        nid_propietario:'',
        numero:'',
        estado:'',
        fecha_uso:'',
        MESTAOO:''
    };
    const [formularios, setFormularios] = useState(false);
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [editar, setEditar] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const [value1, setValue1] = useState(true)
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [estado, setEstado] = useState(6)
    const [titulo, setTitulo] = useState('')
    const [usuario, setUsuario] = useState(0)
    const [nidPropietario, setNidPropietario] = useState(0)
    const [ayuda, setAyuda] = useState(false) 
    const MAESTROCONSECUTIVO = 'https://www.ancogenecop.com/APIANCO/controllers/maestroConsecutivos.php?op='
    const [deleteProductsDialogDet, setDeleteProductsDialogDet] = useState(false)
    useEffect(() => {
    console.log(usuario)
        if (reloadUsuarios) {
            const recarga = async () => {
                setFormularios(false)
                const data = new FormData();
                if(localStorage.getItem('administrador')==1 ){
                    if(nidPropietario=='' && usuario==0){
                        data.append('nid_propietario','ADMINANCO')
                    }else{
                        data.append('nid_propietario',usuario)
                    }
                  }else if(localStorage.getItem('administrador')==null){
                    if(nidPropietario==''){
                        data.append('nid_propietario','ADMINANCO')
                    }else{
                        data.append('nid_propietario',usuario)
                    }
                  }else{
                    data.append('nid_propietario',localStorage.getItem("nid_propietario"))
                  }
    
                data.append("estado", estado)
              //  data.append("nid_propietario", usuario)
                await fetch(MAESTROCONSECUTIVO + 'contarEstado', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                        if (response != []) {
                            //console.log(response)
                            setEditar(false)
                            setProducts(response)
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [formularios])


    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductAyuda = () => {
        setAyuda(false);
    }
    const hideDialogConsecutivos = () => {
        setDeleteProductsDialogDet(false);
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            
                <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />           


            </React.Fragment>
        )
    }

    const deleteProductDialogAyuda = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
        </>
    );

    // const saveProduct = async() => {
    //     setSubmitted(true);

    //     if(product.codigo=='') {console.log(1); return}
    //      if(product.nid_propietario=='') {console.log(2); return}
    //      if(product.numero=='') {console.log(3); return}
    //     const data = new FormData();
    //     data.append("id", product.id)
    //     data.append("codigo", product.codigo)
    //     data.append("nid_propietario", product.nid_propietario)
    //     data.append("numero", product.numero)
    //     data.append("estado", product.estado)
    //     await fetch(MAESTROCONSECUTIVO + 'ingresarConsecutvo', {
    //         method: 'POST',
    //         header: {
    //             'Accept': 'application/json',
    //             'Content-type': 'application/json'
    //         },
    //         body: data
    //     }).then((response) => response.json())
    //         .then((response) => {
    //             if (response == 1) {
    //                 toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El Registro ha sido Ingresado o Actualizado', life: 3000 });
    //                 setReloadUsuarios(true)
    //                 setSubmitted(false);
    //                 setProductDialog(false);
    //                 setProduct(emptyProduct);
    //             } else if((response==100)){
    //                 toast.current.show({ severity: 'error', summary: 'Error', detail: 'El consecutivo digitado ya existe, verifique por favor...', life: 3000 });
    //             }else {
    //                 toast.current.show({ severity: 'warning', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado', life: 3000 });
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })

    // }

    const editProduct = (product) => {
        setEditar(true)
        setProduct({ ...product });
        setProductDialog(true);
    }




    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }
    
    // const cols = [
    //     { field: 'code', header: 'Code' },
    //     { field: 'name', header: 'Name' },
    //     { field: 'category', header: 'Category' },
    //     { field: 'quantity', header: 'Quantity' }
    // ];

    // const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_'+usuario+'_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };
    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(products);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'consecutivos');
        });
    };
    
    const buscaEstado = (dato) =>{
        setEstado(dato)
        if(dato==1){
            setTitulo("Usados")
        }else{
            setTitulo("Disponibles")
        }
        setFormularios(true)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="card flex justify-content-center">
            <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                    <RadioButton inputId="ingredient1" name="formularios" value="1" onChange={(e) => buscaEstado(e.value)}  />
                    <label htmlFor="ingredient1" className="ml-2">Usados</label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton inputId="ingredient2" name="formularios" value="0" onChange={(e) => buscaEstado(e.value)}  />
                    <label htmlFor="ingredient2" className="ml-2">Disponibles</label>
                </div>
               
            </div>
            <div>
            {localStorage.getItem('nivel')!=4 && localStorage.getItem('nivel')!=1 &&
                <SeleccionUsuarioFormularios handleplanta={handleplanta} estado={estado}/>
            }
                
            </div>
        </div>
            </React.Fragment>
        )
    }

    


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Maestro de Consecutivos <b>{titulo}</b></h5>
            
        </div>
    );

    // const productDialogFooter = (
    //     <>
    //         <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
    //         <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
    //     </>
    // );    const productDialogFooterC = (
    //     <>
    //         <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDialogConsecutivos} />
    //     </>
    // );


    const handleplanta = async (dato, opc,opcion) => {
        setSubmitted(false)
        if (opc == 1) {
            if(dato==undefined) dato='General'
            setUsuario(dato) 
            setFormularios(true)
        }if (opc == 3) {
            product.estado = dato
        }
        else{
            product.nid_propietario = dato
        }
    }

    return (
    <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }
           
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12"> 
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}  ></Toolbar>

                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                       
                        <Column field="codigo" header="Formulario" sortable ></Column>
                        <Column field="descripcion" header="Descripción" ></Column>
                        <Column field="total" header="Total" sortable ></Column>
                    </DataTable>
                    </div>
                    <Dialog visible={ayuda} style={{ width: '300px', height:'200px'}} header="Mis Consecutivos" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={'2'} />                    
                    </Dialog>   
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>
                    {/* <Dialog visible={deleteProductsDialogDet} style={{ width: '950px' }}  modal className="p-fluid" footer={productDialogFooterC} onHide={hideDialog}>

                        <ImportarExcel opcion={1} hideDialogConsecutivos={hideDialogConsecutivos}/>

                    </Dialog> */}
                    {/* <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle del Consecutivo" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col">
                            {editar ?
                            <div>
                            <label htmlFor="name">Formulario</label>
                            <InputText value={product.codigo}  readOnly  />
                            </div>
                            :
                            <div>
                            <label htmlFor="name">Formulario</label>
                            <SeleccionFormulariosGeneral  handleplanta={handleplanta} codigo={product.codigo}
                            required
                            autoFocus
                            />
                            {submitted && !product.codigo && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El tipo de formulario es requerido.</small>}
                            </div>
                            }

                        </div>
                        <div className="field col">
                            {editar ?
                            <div>
                            <label htmlFor="name">Asociado</label>
                            <SeleccionUsuarioConsecutivos  handleplanta={handleplanta} NOMBRE={product.NOMBRE}
                            required
                            />
                            </div>
                            :
                            <>
                            <label htmlFor="name">Asociado</label>
                            <SeleccionUsuarioConsecutivos  handleplanta={handleplanta}
                            required
                            />
                            {submitted && !product.nid_propietario && <small className="p-invalid"
                            style={{
                              color: 'red',
                            }}>El nombre de asociado es requrido.</small>}
                            </>
                            }
                        </div>
                    </div>
                        <div className="formgrid grid">
                        <div className="field col-6">
                            {editar ?
                            <div>
                             <label htmlFor="name">Consecutivo</label>
                            <InputText id="name" value={product.numero} onChange={(e) => onInputChange(e, 'numero')}
                            readOnly
                             />
                            </div>
                            :
                            <div>
                            <label htmlFor="name">Consecutivo</label>
                            <InputText id="name" value={product.numero} onChange={(e) => onInputChange(e, 'numero')}
                            required
                            className={classNames({ 'p-invalid': submitted && !product.numero })} />
                            {submitted && !product.numero && <small className="p-invalid">El numero del consecutivo es requerido.</small>}
                            </div>
                            }
                        </div>
                        {editar &&
                            <div className="field col">
                                <label htmlFor="name">Estado</label>
                                    <SeleccionConsecutivos  handleplanta={handleplanta} MESTADO={product.MESTAOO}
                                required
                                />
                                {submitted && !product.estado && <small className="p-invalid"
                                style={{
                                  color: 'red',
                                }}>El estado es requerido.</small>}
                            </div>
                        }
                    </div>

                    </Dialog> */}

                </div>
            </div>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MaestroConsecutivosUsuario, comparisonFn);
