import React,{useEffect,useState} from 'react';
import { Carousel } from 'primereact/carousel';
const Inicio = () => {
const APIINICIO = 'https://www.ancogenecop.com/APIANCO/controllers/procesosDiarios.php?op='

const [docSoporte, setDocSoporte] = useState([])
const [reload, setReload] = useState(true)

const responsiveOptions = [
    {
        breakpoint: '600px',
        numVisible: 3,
        numScroll: 3
    },
    {
        breakpoint: '600px',
        numVisible: 2,
        numScroll: 2
    },
    {
        breakpoint: '480px',
        numVisible: 1,
        numScroll: 1
    }
];


    useEffect(() => {
        //    if(props.idTipoNotificacion==160){
            async function traerNegocios() {

              const data = new FormData();
              await fetch(APIINICIO + 'documentosInicio', {
                method: 'POST',
                header: {
                  'Accept': 'application/json',
                  'Content-type': 'application/json'
                },
                body: data
              }).then((response) => response.json())
                .then((response) => {
                    setDocSoporte(response)
                })
                .catch((error) => {
                  console.log(error);
                })
            }
                 traerNegocios()
          }, [reload]);


    const productTemplate = (docSoporte) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="mb-10">
                        <img src={`${docSoporte.URL}`}  alt={docSoporte.ID} className="product-image"
                        width={"85%"} />
                    </div>

                </div>
            </div>
        );
    }

    return (
        <>
        <div className="contenedor ">
                <>
                    <div className="carousel-demo">
                        <div className="card">
                            <Carousel value={docSoporte} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions}
                                itemTemplate={productTemplate}
                                autoplayInterval={10000} />
                        </div>

                    </div>
                </>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Inicio, comparisonFn);
