import React,{useState,useEffect} from 'react';
import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useHistory } from "react-router-dom";
import FileUpload from '../components/fileupload/FileUpload';
const Presentacion = () => {
const APIPRESENTACION = 'https://www.ancogenecop.com/APIANCO/controllers/procesosDiarios.php?op='

const [docSoporte, setDocSoporte] = useState([])
const [reload, setReload] = useState(true)
const [inactivar, setInactivar] = useState(false)
const [estado, setEstado] = useState(0)
const [productDialog, setProductDialog] = useState(false)
const [product, setProduct] = useState([])
const [submitted, setSubmitted] = useState(false);
const [id, setId] = useState(0)
const history = useHistory();
let emptyProduct = {
    NID_SOLICITANTE: '',
    CONSECUTIVO:'',
};




useEffect(() => {
//    if(props.idTipoNotificacion==160){
    async function traerNegocios() {
    setReload(false)
      const data = new FormData();
      await fetch(APIPRESENTACION + 'documentosInicioTotal', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            setDocSoporte(response)
        })
        .catch((error) => {
          console.log(error);
        })
    }
         traerNegocios()
  }, [reload]);


  useEffect(() => {
//    if(props.idTipoNotificacion==160){
    async function traerNegocios() {
    setInactivar(false)
      const data = new FormData();
      data.append("ESTADO",estado)
      data.append("ID",id)
      data.append("CODIGO",'NOAPLICA')
      await fetch(APIPRESENTACION + 'inactivarActivar', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            setReload(true)
        })
        .catch((error) => {
          console.log(error);
        })
    }
         traerNegocios()
  }, [inactivar]);

  const saveProduct = async() => {
    setSubmitted(true);
   }


  const openNew = () => {
    setProduct(emptyProduct)
    setProductDialog(true);
}

const hideDialog = () => {
    setProductDialog(false);
}
const productDialogFooter = (
    <>
        <Button label={"Cancelar"} icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
    </>
);

    const inactivarActivar = (opcion,id) => {
        setEstado(opcion)
        setId(id)
        setInactivar(true)
    }

    const handleplanta = async(dato,opc) =>{
        if(opc==5000){
           setProductDialog(false);
           setReload(true)
        }
    }


    return (
    <>
      <div className="my-2">
            <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
      </div>

     <div className="grid p-fluid">
      {docSoporte.map((image) => (
            <div className="col-12 md:col-3">
                  <Image
                    src={image.URL}
                    alt={image.URL}
                    width="139" height="150"
                    className="img-fluid h-100 w-100"
                    style={{ objectFit: "cover" }}
                  />
                <div className="grid p-fluid">
                  {image.ESTADO==1 ?
                  <div className="col-12 md:col-4">
                        <Button icon="pi pi-check" className="p-button-rounded p-button-success mt-2" onClick={() => inactivarActivar(0,image.ID)}
                            tooltip='Inactivar Imagen'
                            tooltipOptions={{
                                className: "hoverClass",
                                 position: "right"
                        }}
                        />
                    </div>
                  :
                  <div className="col-12 md:col-4">
                        <Button icon="pi pi-times" className="p-button-rounded p-button-warning mt-2" onClick={() => inactivarActivar(1,image.ID)}
                            tooltip='Activar Imagen'
                            tooltipOptions={{
                                className: "hoverClass",
                                position: "right"
                        }}
                        />
                    </div>


                  }
                </div>

            </div>
      ))}
      </div>
      <Dialog visible={productDialog}
      style={{ width: '650px' }}
        header={"Adicionar imágen a la presentación inicial"}
        modal className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}>
        <div className="field">
            <label>Seleccione las imagenes de extensión .jpg</label>
            <FileUpload archivos={3} handleplanta={handleplanta} product={product} OPCION={1} CODIGO={'NOAPLICA'}/>
        </div>
      </Dialog>
      </>
  );

}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Presentacion, comparisonFn);
