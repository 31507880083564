import React, { useState,useEffect } from "react";
import { AutoComplete } from 'primereact/autocomplete';


const SeleccionEspecies = (props) => {
  const [selectedAutoValue, setSelectedAutoValue] = useState(props.COD_ESPECIE);
  const [autoFilteredValue, setAutoFilteredValue] = useState([]);
  const [autoValue, setAutoValue] = useState(
  [
  { name: 'Caprina', code: 'C' },
  { name: 'Ovina', code: 'O' },
  ]
  );





  const searchCountry = (event) => {
    setTimeout(() => {
        if (!event.query.trim().length) {
            setAutoFilteredValue([...autoValue]);
        }
        else {
            setAutoFilteredValue(autoValue.filter((a) => {
                return a.name.toUpperCase().startsWith(event.query.toUpperCase());
            }));
        }
    }, 250);
};



  const handleChange = (event) => {
    setSelectedAutoValue(event)
    return props.handleplanta(event.code,1)
  };



  return (
    <div className="p-error">
      <AutoComplete placeholder="Buscar" id="dd" dropdown value={selectedAutoValue}
      onChange={(e) => handleChange(e.value)}
      suggestions={autoFilteredValue} completeMethod={searchCountry} field="name" />
    </div>
  );
};

export default SeleccionEspecies;
