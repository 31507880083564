import React,{useEffect,useState} from "react";
import { Document, Page, Text, View, Image} from "@react-pdf/renderer";

const DocuPDF = (props) => {

  return (
    <Document>
      <Page
        size="A4"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "white",
          marginTop:"65px",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "white",
            paddingTop: 100,
            marginLeft:250,
          }}
        >
          <Text style={{ color: "#3388af", fontSize: "12px" }}>
                {props["product"]["NOMANIMAL"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px", paddingTop: 3}}>
                {props["product"]["CHIP"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px" ,paddingTop: 12}}>
                {props["product"]["NOMPROPIETARIO"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 12 }}>
                {props["product"]["NOMCRIADOR"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 12 }}>
                {props["product"]["CRIADERO"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 10 }}>
                {props["product"]["SEXOYESPECIE"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 19 }}>
                {props["product"]["COMPOSICION"]+"  "+props["product"]["RAZA"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 12 }}>
                {props["product"]["LIBRO"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 13 }}>
                {props["product"]["NOMCONCEPCION"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 13,marginLeft:-20}}>
          {props["product"]["anionacimiento"]!=null && props["product"]["anionacimiento"]!=0 &&
           props["product"]["anionacimiento"]+" "+props["product"]["mesnacimiento"]+" "+props["product"]["dianacimiento"]
          }
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 16 }}>
                {props["product"]["MARCAS_HATO"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 10 }}>
                {props["product"]["RASGOS_PARTICULARES"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 12 }}>
                {props["product"]["COLOR"]}
          </Text>
          <Text style={{ color: "#3388af", fontSize: "12px",paddingTop: 12,marginLeft:-20 }}>
                {props["product"]["sanionacimiento"]+" "+props["product"]["smesnacimiento"]+" "+props["product"]["sdianacimiento"]}
          </Text>
              </View>
              {props.datosPdf &&
                  <>
    
                    <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "white",
                      paddingTop: 10,
                      marginLeft:230
    
                    }}
                  >
                  {props["datosPdf"][0]["parentesco"]=='PADRE' &&
                  /*primer dato */
                  <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "white",
                          paddingTop: 10,
    
                        }}
                      >
                        <Text style={{
                            color: "gray",
                            fontStyle: "italic",
                            fontSize: "10px",
                        }}>{props["datosPdf"][0]["nombre"]}</Text>
                        <Text style={{
                            color: "gray",
                            fontStyle: "italic",
                            fontSize: "10px",
                        }}>{props["datosPdf"][0]["label"]}</Text>
                    </View>
                  }
                  {props["datosPdf"][2]["parentesco"]=='ABUELO PATERNO' &&
                      /*tercer dato */
                      <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "white",
                            paddingTop: 10,
                            marginLeft:160
                          }}
                        >
                          <Text style={{
                              color: "gray",
                              fontStyle: "italic",
                              fontSize: "10px",
                              textAlign:"left"
                          }}>{props["datosPdf"][2]["nombre"]}</Text>
                          <Text style={{
                              color: "gray",
                              fontStyle: "italic",
                              fontSize: "10px",
                              textAlign:"left"
                          }}>{props["datosPdf"][2]["label"]}</Text>
                      </View>
                  }
            </View>

            /*INICIAL */
            <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "white",
                  paddingTop: 10,
                  marginLeft:230

                }}
              >
              /*primer dato blanco */
                      <View
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              backgroundColor: "white",
                              paddingTop: 10,
                              marginLeft:59
                            }}
                          >
                            <Text style={{
                                color: "gray",
                                fontStyle: "italic",
                                fontSize: "10px",
                            }}></Text>
                            <Text style={{
                                color: "gray",
                                fontStyle: "italic",
                                fontSize: "10px",
                            }}></Text>
                        </View>
                        /*tercer dato */
                      {props["datosPdf"][3]["parentesco"]=='ABUELA PATERNA' &&
      
                      <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "white",
                            paddingTop: 10,
                            marginLeft:180
                          }}
                        >
                          <Text style={{
                              color: "gray",
                              fontStyle: "italic",
                              fontSize: "10px",
                              textAlign:"left"
                          }}>{props["datosPdf"][3]["nombre"]}</Text>
                          <Text style={{
                              color: "gray",
                              fontStyle: "italic",
                              fontSize: "10px",
                              textAlign:"left"
                          }}>{props["datosPdf"][3]["label"]}</Text>
                      </View>
                      }
            </View>
/*FINAL */
                /*SEGUNDA PARTE INICIAL */
                            <View
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  backgroundColor: "white",
                                  paddingTop: 10,
                                  marginLeft:230
                
                                }}
                              >
                                {props["datosPdf"][1]["parentesco"]=='MADRE' &&
                                  /*segundo dato */
                                  <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          backgroundColor: "white",
                                          paddingTop: 10,
                    
                                        }}
                                      >
                                        <Text style={{
                                            color: "gray",
                                            fontStyle: "italic",
                                            fontSize: "10px",
                                        }}>{props["datosPdf"][1]["nombre"]}</Text>
                                        <Text style={{
                                            color: "gray",
                                            fontStyle: "italic",
                                            fontSize: "10px",
                                        }}>{props["datosPdf"][1]["label"]}</Text>
                                    </View>
                                  }
                                {props["datosPdf"][4]["parentesco"]=='ABUELO MATERNO' &&
                                    /*tercer dato */
                                    <View
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          backgroundColor: "white",
                                          paddingTop: 10,
                                          marginLeft:160
                                        }}
                                      >
                                        <Text style={{
                                            color: "gray",
                                            fontStyle: "italic",
                                            fontSize: "10px",
                                            textAlign:"left"
                                        }}>{props["datosPdf"][4]["nombre"]}</Text>
                                        <Text style={{
                                            color: "gray",
                                            fontStyle: "italic",
                                            fontSize: "10px",
                                            textAlign:"left",
                                            justifyContent:"flex-start"
                                        }}>{props["datosPdf"][4]["label"]}</Text>
                                    </View>
                                  }
                            </View>
                              /*INICIAL */
                                          <View
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                backgroundColor: "white",
                                                paddingTop: 10,
                                                marginLeft:230
                              
                                              }}
                                            >
                                            /*primer dato blanco */
                                                    <View
                                                          style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            backgroundColor: "white",
                                                            paddingTop: 10,
                                                            marginLeft:59
                                                          }}
                                                        >
                                                          <Text style={{
                                                              color: "gray",
                                                              fontStyle: "italic",
                                                              fontSize: "10px",
                                                          }}></Text>
                                                          <Text style={{
                                                              color: "gray",
                                                              fontStyle: "italic",
                                                              fontSize: "10px",
                                                          }}></Text>
                                                      </View>
                                                      {props["datosPdf"][5]["parentesco"]=='ABUELA MATERNA' &&
                                                      /*tercer dato */
                                                      <View
                                                          style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            backgroundColor: "white",
                                                            paddingTop: 10,
                                                            marginLeft:180
                                                          }}
                                                        >
                                                          <Text style={{
                                                              color: "gray",
                                                              fontStyle: "italic",
                                                              fontSize: "10px",
                                                              textAlign:"left"
                                                          }}>{props["datosPdf"][5]["nombre"]}</Text>
                                                          <Text style={{
                                                              color: "gray",
                                                              fontStyle: "italic",
                                                              fontSize: "10px",
                                                              textAlign:"left",
                                                          }}>{props["datosPdf"][5]["label"]}</Text>
                                                      </View>
                                                      }
                                          </View>
                              /*FINAL */
                
                /*SEGUNDA PARTE FINAL */
            </>
          
            }
      </Page>
    </Document>
  );
};

export default DocuPDF;
