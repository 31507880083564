import React,{useState,useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Lateral = () => {
const APIPRESENTACION = 'https://www.ancogenecop.com/APIANCO/controllers/procesosDiarios.php?op='

const [docSoporte, setDocSoporte] = useState([])
const history = useHistory();


useEffect(() => {
if(docSoporte==[]) return
    async function traerNegocios() {
      const data = new FormData();
      await fetch(APIPRESENTACION + 'documentosInicioLateral', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            setDocSoporte(response)
        })
        .catch((error) => {
          console.log(error);
        })
    }
         traerNegocios()
  }, []);

        const imageBodyTemplate = (rowData) => {
            return (
            <>
            <div className="formgrid grid">
                <div className="field col-12">
                    <Image src={rowData.URL}  zoomSrc={rowData.URL} alt="Image" width="139" height="150" preview className="product-image" />
                </div>
                <div className="field col-12">{rowData.DESCRIPCION}</div>
                <div className="field col-12">
                    <Button label="Leer Más" icon="pi pi-plus" className="p-button-success p-button-sm" onClick={() => window.open(rowData.PAGINA, "_blank")} />
                </div>
            </div>
            </>
            )
        }

    return (
    <>
    <div className="card" style={{ height: 'calc(147vh - 5px)' }}>
                <DataTable value={docSoporte}  scrollable scrollHeight="flex">
                    <Column header="Entérese" body={imageBodyTemplate}></Column>
                </DataTable>
            </div>
      </>
  );

}

// const comparisonFn = function (prevProps, nextProps) {
//     return prevProps.location.pathname === nextProps.location.pathname;
// };

export default React.memo(Lateral);
