import React, { useState,useEffect } from "react";
import { AutoComplete } from 'primereact/autocomplete';


const SeleccionChipsHembras = (props) => {
  const [selectedAutoValue, setSelectedAutoValue] = useState(props.CHIP_HEMBRA);
  const [autoFilteredValue, setAutoFilteredValue] = useState([]);
  const [autoValue, setAutoValue] = useState([]);
  const [reload, setReload] = useState(true)
  const APITABLASBASICAS = 'https://www.ancogenecop.com/APIANCO/controllers/selecciones.php?op='
 
  useEffect(() => {
    //if(props.NID_PROPIETARIO==undefined || props.codigo==undefined) return;
    if(reload){
        setAutoValue([])
    async function traerUsuarios(NID_PROPIETARIO,codigo,RAZA,SEXO,CONSECUTIVO,PARENTESCO,CHIPMACHO) {
//      if(PARENTESCO=='' || NID_PROPIETARIO =='' || RAZA=='' || SEXO=='' || CONSECUTIVO =='' || CHIPMACHO=='') return;
      const data = new FormData();
      if(localStorage.getItem('administrador')==1){
        data.append('NID_PROPIETARIO',NID_PROPIETARIO)
      }else{
        data.append('NID_PROPIETARIO',localStorage.getItem('nid_propietario'))
      }
      data.append('CODIGO',codigo) 
      data.append('RAZA',RAZA)
      data.append('SEXO',SEXO)
      data.append('CONSECUTIVO',CONSECUTIVO)
      data.append('PARENTESCO',PARENTESCO)
      data.append('CHIP_MACHO',CHIPMACHO)      
      await fetch(APITABLASBASICAS + 'seleccionChipsHembras', {
        method: 'POST',
        header: {
          'Accept': 'application/json',
          'Content-type': 'application/json'
        },
        body: data
      }).then((response) => response.json())
        .then((response) => {
            setAutoValue(response)
        })
        .catch((error) => {
          console.log(error);
        })
      }
        traerUsuarios(props.NID_PROPIETARIO,props.codigo,props.RAZA
        ,props.SEXO,props.CONSECUTIVO,props.PARENTESCO,props.CHIPMACHO)
    }
  }, [reload,props.NID_PROPIETARIO,props.codigo,props.RAZA,props.SEXO,
  props.CONSECUTIVO,props.PARENTESCO,props.CHIPMACHO]);


  const searchCountry = (event) => {
    setTimeout(() => {
        if (!event.query.trim().length) {
            setAutoFilteredValue([...autoValue]);
        }
        else {
            setAutoFilteredValue(autoValue.filter((a) => {
                return a.name.toUpperCase().startsWith(event.query.toUpperCase());
            }));
        }
    }, 250);
};



  const handleChange = (event) => {
    setSelectedAutoValue(event)
    return props.handleplanta(event.code,8)
  };



  return (
    <div className="p-error">
      <AutoComplete placeholder="Buscar" id="dd" dropdown value={selectedAutoValue}
      onChange={(e) => handleChange(e.value)}
      suggestions={autoFilteredValue} completeMethod={searchCountry} field="name" />
    </div>
  );
};

export default SeleccionChipsHembras;
