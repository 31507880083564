import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppConfig } from './AppConfig';
import AppLogout from './service/AppLogout';
import MisInventarios from './components/MisInventarios';
import ButtonDemo from './components/ButtonDemo';
import ChartDemo from './components/ChartDemo';
import Documentation from './components/Documentation';
import Inicio  from './pages/Inicio';
import FileDemo from './components/FileDemo';
import FloatLabelDemo from './components/FloatLabelDemo';
import FormLayoutDemo from './components/FormLayoutDemo';
import InputDemo from './components/InputDemo';
import ListDemo from './components/ListDemo';
import MenuDemo from './components/MenuDemo';
import MessagesDemo from './components/MessagesDemo';
import MiscDemo from './components/MiscDemo';
import OverlayDemo from './components/OverlayDemo';
import MediaDemo from './components/MediaDemo';
import PanelDemo from './components/PanelDemo';
import TableDemo from './components/TableDemo';
import TreeDemo from './components/TreeDemo';
import InvalidStateDemo from './components/InvalidStateDemo';
import BlocksDemo from './components/BlocksDemo';
import IconsDemo from './components/IconsDemo';

import Crud from './pages/Crud';
import FormularioA01 from './pages/FormularioA01';
import FormularioA01a from './pages/FormularioA01a';
import FormularioA02 from './pages/FormularioA02';
import FormularioA03 from './pages/FormularioA03';
import FormularioA04 from './pages/FormularioA04';
import FormularioA05 from './pages/FormularioA05';
import FormularioA05Iniciales from './pages/FormularioA05Iniciales';
import FormularioA06 from './pages/FormularioA06';
import FormularioA08 from './pages/FormularioA08';
import FormularioA09 from './pages/FormularioA09';
import FormularioA10 from './pages/FormularioA10';
import FormularioA11 from './pages/FormularioA11';
import FormularioA12 from './pages/FormularioA12';
import Login  from './pages/Login';
import ClaveInicial  from './pages/ClaveInicial';
import RecuperarContrasena  from './pages/RecuperarContrasena';
import Presentacion  from './pages/Presentacion';
import Enterese  from './pages/Enterese';
import EmptyPage from './pages/EmptyPage';
import TimelineDemo from './pages/TimelineDemo';
/*parte administrativa */
import MaestroChips from './admin/MaestroChips';
import MaestroConsecutivos from './admin/MaestroConsecutivos';
import Formularios from './admin/Formularios';
import Registradores from './admin/Registradores';
import Inseminadores from './admin/Inseminadores';
import Terceros from './admin/Terceros';
import Prefijos from './admin/Prefijos';
import Asociaciones from './admin/Asociaciones';
import MisDatos from './admin/MisDatos';
import Libros from './admin/Libros';
import EditorContenido from './admin/EditorContenido';

/*fin parte administrativa */
/*PARTE DE SOLICITUDES FORMULARIOS */
import FormulariosSolicitados from './admin/FormulariosSolicitados';
import FormulariosPorRevisar from './admin/FormulariosPorRevisar';
import FormulariosRevisados from './admin/FormulariosRevisados';
/*FIN PARTE DE SOLICITUDES FORMULARIOS */
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { useHistory } from "react-router-dom";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import FormularioA12SubArrendar from './pages/FormularioA12SubArrendar';
import FormularioA12SubArrendados from './pages/FormularioA12SubArrendados';
import MaestroConsecutivosUsuario from './admin/MaestroConsecutivosUsuario';
import Razas from './admin/Razas';
import MisAprisco from './admin/MisAprisco';
import CambiarClave from './pages/CambiarClave';

const App = () => {
    const [dato, setDato] = useState(0)
    const [cambiaClave, setCambiaClave] = useState(false)
    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('light')
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(true);
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const [menuPrincipal, setMenuPrincipal] = useState(0)
    const copyTooltipRef = useRef();
    const [nombres, setNombres] = useState('')
    const location = useLocation();
    const [menues, setMenues] = useState(true)
    const [ingresar, setIngresar] = useState(false)
    const history = useHistory();
    const [idPerfilUsuario, setIdPerfilUsuario] = useState(localStorage.getItem("nivel"))
    const [nidPropietario, setNidPropietario] = useState(0)
    const [cambiaPassword, setCambiaPassword] = useState(false)
    PrimeReact.ripple = true;
    let menuClick = false;
    let mobileTopbarMenuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, "body-overflow-hidden");
        } else {
            removeClass(document.body, "body-overflow-hidden");
        }
        setMenuPrincipal(localStorage.getItem('nivel'))
        setIdPerfilUsuario(localStorage.getItem('nivel'))
        setNombres(localStorage.getItem('nombres'))
    }, [mobileMenuActive]);

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }

        if (!mobileTopbarMenuClick) {
            setMobileTopbarMenuActive(false);
        }

        mobileTopbarMenuClick = false;
        menuClick = false;
    }

    const onToggleMenuClick = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                if (mobileMenuActive === true) {
                    setOverlayMenuActive(true);
                }

                setOverlayMenuActive((prevState) => !prevState);
                setMobileMenuActive(false);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive((prevState) => !prevState);
            }
        }
        else {
            setMobileMenuActive((prevState) => !prevState);
        }

        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMobileTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;

        setMobileTopbarMenuActive((prevState) => !prevState);
        event.preventDefault();
    }
 
    const onMobileSubTopbarMenuClick = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
        history.push(`/login`);
        setMenuPrincipal(0)
        setIngresar(true)
        setCambiaPassword(true)
    }

    const onMobileSubTopbarMenuClickSalir = (event) => {
        mobileTopbarMenuClick = true;
        event.preventDefault();
        localStorage.clear();
        setIdPerfilUsuario(0)
        setMenuPrincipal(0)
        setIngresar(true)
        setCambiaPassword(false)
        history.push(`/`);
    }
    
    const handleclave = () =>{
        localStorage.clear();
        setCambiaClave(false)
        history.push(`/login`);
    }
    const handleplanta = async(datos) =>{
    console.log(datos)
        /*verificamos si ya ingreso por lo menos una vez */
        if(datos.cargo==0){/*es la primera vez que ingresa */
            setDato(datos.cargo)
            setCambiaClave(true)
        }else{
            if(datos==10000){
                setMenues(false)
            }else{
                setMenues(true)
            }
            setIdPerfilUsuario(datos.nivel)
            setMenuPrincipal(datos.nivel)
            setNombres(datos.nombres)
            setNidPropietario(datos.nid_propietario)
            setIngresar(false)
        }
    }


    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }
    const isDesktop = () => {
        return window.innerWidth >= 992;
    }

    

    const menuPerfil2 = [
        {
            label: 'Inicio',
            items: [{
                label: 'Inventarios', icon: 'pi pi-fw pi-home', to: '/',                 
            }
        ]
        },
        {
            label: 'Menú Administración', icon: 'pi pi-fw pi-sitemap',
            items: [{
                label: 'Ir a', icon: 'pi pi-fw pi-user',
                items: [
                    { label: "Maestro Chips", icon: "pi pi-fw pi-bars", to: "/maestroChips" },
                    { 
                        label: "Consultar Consecutivos", icon: "pi pi-clone",
                        items: [
                        { label: 'Consulta General', icon: 'pi pi-fw pi-id-card', to: '/maestroConsecutivos' },
                        { label: 'Consulta por usuario', icon: 'pi pi-fw pi-id-card', to: '/maestroConsecutivosUsuario' },
                    ]
                    },
                    { label: "Formularios FEDEANCO", icon: "pi pi-copy", to: "/formularios" },
                    { label: "Registradores", icon: "pi pi-users", to: "/registradores" },
                    { label: "Inseminadores", icon: "pi pi-users", to: "/inseminadores" },
                    { label: "Terceros", icon: "pi pi-user-plus", to: "/terceros" },
                    { label: "Prefijos", icon: "pi pi-compass", to: "/prefijos" },
                    { label: "Asociaciones", icon: "pi pi-sort-alt", to: "/asociaciones" },
                    { label: "Libros", icon: "pi pi-book", to: "/libros" },                    
                    { label: "Razas", icon: "pi pi-users", to: "/razas" },
                    // { label: "Ayuda", icon: "pi pi-book", to: "/editor" },                                        
                    {
                        label: 'Consecutivos Solicitados', icon: 'pi pi-fw pi-sitemap',
                            items: [
                                { label: 'Para revisión', icon: 'pi pi-fw pi-id-card', to: '/paraRevisar' },
                                { label: 'Revisados y aprobados', icon: 'pi pi-fw pi-id-card', to: '/revisados' },
                            ]
                    },
                    {label: "Certificado de afiliación Genealógica A05", icon: "pi pi-fw pi-book", to: "/formularioA05" },
                    { label: "Transferencia Propieta A06", icon: "pi pi-fw pi-book", to: "/formularioA06" },
                ]
            }]
        },
        {
            label: 'Formularios Asociados', icon: 'pi pi-fw pi-sitemap',
            items: [{
                label: 'Ir a', icon: 'pi pi-fw pi-user',
                items: [
                    { label: "Animales Iniciales", icon: "pi pi-fw pi-bars", to: "/animalesIniciales" },
                    { label: "Monta Diecta A01", icon: "pi pi-fw pi-bars", to: "/formularioA01" },
                    { label: "Inseminación Artificial A02", icon: "pi pi-fw pi-github", to: "/formularioA02" },
                    { label: "Transferencia de Embriones A03", icon: "pi pi-fw pi-github", to: "/formularioA03" },
                    { label: "Denuncia de Nacimiento A04", icon: "pi pi-fw pi-prime", to: "/formularioA04" },
                    { label: "Lavado y Registro de Embriones A08", icon: "pi pi-fw pi-book", to: "/formularioA08" },
                    { label: "Registro de Embriones Importados A09", icon: "pi pi-fw pi-book", to: "/formularioA09" },
                    { label: "Denuncia y Colecta Semen A10", icon: "pi pi-fw pi-book", to: "/formularioA10" },
                    { label: "Semen Importado A11 ", icon: "pi pi-fw pi-book", to: "/formularioA11" },
                    { label: "Monta Diecta Grupal A01a", icon: "pi pi-fw pi-bars", to: "/formularioA01a" },
                    {
                        label: 'Reproductores Formuario A12', icon: 'pi pi-fw pi-sitemap',
                            items: [
                                { label: "Arrendados ", icon: "pi pi-fw pi-book", to: "/formularioA12" },
                                { label: "Para Subarrendar ", icon: "pi pi-fw pi-book", to: "/formularioA12SubArriendo" },
                                { label: "Sub Arrendados ", icon: "pi pi-fw pi-book", to: "/formularioA12SubArriendados" },
                            ]
                    },
                    // {
                    //     label: 'Formularios solicitados', icon: 'pi pi-fw pi-sitemap',
                    //         items: [
                    //             { label: "Solicitudes", icon: "pi pi-fw pi-bars", to: "/formulariosSolicitados" },
                    //         ]
                    // },
                ]
            }]
        },
        {
            label: 'Datos Asociados', icon: 'pi pi-fw pi-sitemap',
            items: [{
                label: 'Ir a', icon: 'pi pi-fw pi-user',
                items: [
                    { label: "Datos Asociados", icon: "pi pi-fw pi-bars", to: "/misdatos" },
                    { label: "Datos Asociados", icon: "pi pi-fw pi-bars", to: "/misAprisco" },
                ]
            }]
        },
        // {
        //     label: 'Presentación aplicativo', icon: 'pi pi-fw pi-sitemap',
        //     items: [{
        //         label: 'Ir a', icon: 'pi pi-fw pi-user',
        //         items: [
        //             { label: "Slider", icon: "pi pi-fw pi-bars", to: "/presentacion" },
        //             // { label: "Enterese", icon: "pi pi-fw pi-bars", to: "/enterese" },
        //         ]
        //     }]
        // },

    ];

    const menuPerfil1 = [
        {
            label: 'Home',
            items: [
                { label: 'Mis Inventarios', icon: 'pi pi-fw pi-home', to: '/', },
                { label: 'Mis Consecutivos', icon: 'pi pi-fw pi-id-card', to: '/maestroConsecutivosUsuario' },
            ]
            
        },
        {
            label: 'Mis Formularios', icon: 'pi pi-fw pi-sitemap',
            items: [{
                label: 'Ir a', icon: 'pi pi-fw pi-user',
                items: [
                    { label: "Monta Diecta A01", icon: "pi pi-fw pi-bars", to: "/formularioA01" },
                    { label: "Inseminación Artificial A02", icon: "pi pi-fw pi-github", to: "/formularioA02" },
                    { label: "Transferencia de Embriones A03", icon: "pi pi-fw pi-github", to: "/formularioA03" },
                    { label: "Denuncia de Nacimiento A04", icon: "pi pi-fw pi-prime", to: "/formularioA04" },                    { label: "Transferencia Propieta A06", icon: "pi pi-fw pi-book", to: "/formularioA06" },
                    { label: "Lavado y Registro de Embriones A08", icon: "pi pi-fw pi-book", to: "/formularioA08" },
                    { label: "Registro de Embriones Importados A09", icon: "pi pi-fw pi-book", to: "/formularioA09" },
                    { label: "Denuncia y Colecta Semen A10", icon: "pi pi-fw pi-book", to: "/formularioA10" },
                    { label: "Semen Importado A11 ", icon: "pi pi-fw pi-book", to: "/formularioA11" },
                    { label: "Monta Diecta Grupal A01a", icon: "pi pi-fw pi-bars", to: "/formularioA01a" },
                    {
                        label: 'Reproductores Formuario A12', icon: 'pi pi-fw pi-sitemap',
                            items: [
                                { label: "Arrendados ", icon: "pi pi-fw pi-book", to: "/formularioA12" },
                                { label: "Para Subarrendar ", icon: "pi pi-fw pi-book", to: "/formularioA12SubArriendo" },
                                { label: "Sub Arrendados ", icon: "pi pi-fw pi-book", to: "/formularioA12SubArriendados" },
                            ]
                    },
                    {
                        label: 'Consecutivos solicitados', icon: 'pi pi-fw pi-sitemap',
                            items: [
                                { label: "Formularios FEDEANCO", icon: "pi pi-copy", to: "/formularios" },                                                        
                                { label: "Solicitudes", icon: "pi pi-fw pi-bars", to: "/formulariosSolicitados" },
                            ],
                    },
                ]
            }]
        },
        {
            label: 'Mis Datos', icon: 'pi pi-fw pi-sitemap',
            items: [{
                label: 'Ir a', icon: 'pi pi-fw pi-user',
                items: [
                    { label: "Mis Datos Personales", icon: "pi pi-fw pi-bars", to: "/misdatos" },
                    // { label: "Mi Aprisco", icon: "pi pi-fw pi-bars", to: "/misAprisco" },
                ]
            }]
        },

    ];

    const menuPerfil4 = [
        { 
            label: "Consultar", icon: "pi pi-clone",
            items: [
            { label: 'Mis Inventarios', icon: 'pi pi-fw pi-home', to: '/', },
            { label: 'Mis Consecutivos', icon: 'pi pi-fw pi-id-card', to: '/maestroConsecutivosUsuario' },
        ]
        },
        {
            label: 'Mis Formularios', icon: 'pi pi-fw pi-sitemap',
            items: [{
                label: 'Ir a', icon: 'pi pi-fw pi-user',
                items: [
                    { label: "Animales Iniciales", icon: "pi pi-fw pi-bars", to: "/animalesIniciales" },
                    { label: "Monta Diecta A01", icon: "pi pi-fw pi-bars", to: "/formularioA01" },
                    { label: "Inseminación Artificial A02", icon: "pi pi-fw pi-github", to: "/formularioA02" },
                    { label: "Transferencia de Embriones A03", icon: "pi pi-fw pi-github", to: "/formularioA03" },
                    { label: "Denuncia de Nacimiento A04", icon: "pi pi-fw pi-prime", to: "/formularioA04" },
                    // { label: "Certificado de afiliación Genealógica A05", icon: "pi pi-fw pi-book", to: "/formularioA05" },
                    { label: "Lavado y Registro de Embriones A08", icon: "pi pi-fw pi-book", to: "/formularioA08" },
                    { label: "Registro de Embriones Importados A09", icon: "pi pi-fw pi-book", to: "/formularioA09" },
                    { label: "Denuncia y Colecta Semen A10", icon: "pi pi-fw pi-book", to: "/formularioA10" },
                    { label: "Semen Importado A11 ", icon: "pi pi-fw pi-book", to: "/formularioA11" },
                    { label: "Arrendamiento Reproductores A12 ", icon: "pi pi-fw pi-book", to: "/formularioA12" },
                    { label: "Monta Diecta Grupal A01a", icon: "pi pi-fw pi-bars", to: "/formularioA01a" },
                    {
                        label: 'Formularios solicitados', icon: 'pi pi-fw pi-sitemap',
                            items: [
                                { label: "Formularios FEDEANCO", icon: "pi pi-copy", to: "/formularios" },                                                                                    
                                { label: "Solicitudes", icon: "pi pi-fw pi-bars", to: "/formulariosSolicitados" },
                            ]
                    },
                ]
            }]
        },
        {
            label: 'Mis Datos', icon: 'pi pi-fw pi-sitemap',
            items: [{
                label: 'Ir a', icon: 'pi pi-fw pi-user',
                items: [
                    { label: "Mis Datos Personales", icon: "pi pi-fw pi-bars", to: "/misdatos" },
                    // { label: "Mi Aprisco", icon: "pi pi-fw pi-bars", to: "/misAprisco" },
                ]
            }]
        },

    ];

    const menuPerfil5 = [
        {
            label: 'Home',
            items: [{
                label: 'Mis Inventarios', icon: 'pi pi-fw pi-home', to: '/',
                label: 'Mis Consecutivos', icon: 'pi pi-fw pi-id-card', to: '/maestroConsecutivosUsuario' 
            }]
        },
        {
            label: 'Mis Formularios', icon: 'pi pi-fw pi-sitemap',
            items: [{
                label: 'Ir a', icon: 'pi pi-fw pi-user',
                items: [
                    { label: "Monta Diecta A01", icon: "pi pi-fw pi-bars", to: "/formularioA01" },
                    { label: "Inseminación Artificial A02", icon: "pi pi-fw pi-github", to: "/formularioA02" },
                    { label: "Transferencia de Embriones A03", icon: "pi pi-fw pi-github", to: "/formularioA03" },
                    { label: "Denuncia de Nacimiento A04", icon: "pi pi-fw pi-prime", to: "/formularioA04" },
                    { label: "Transferencia Propieta A06", icon: "pi pi-fw pi-book", to: "/formularioA06" },
                    { label: "Lavado y Registro de Embriones A08", icon: "pi pi-fw pi-book", to: "/formularioA08" },
                    { label: "Registro de Embriones Importados A09", icon: "pi pi-fw pi-book", to: "/formularioA09" },
                    { label: "Denuncia y Colecta Semen A10", icon: "pi pi-fw pi-book", to: "/formularioA10" },
                    { label: "Semen Importado A11 ", icon: "pi pi-fw pi-book", to: "/formularioA11" },
                    { label: "Arrendamiento Reproductores A12 ", icon: "pi pi-fw pi-book", to: "/formularioA12" },
                    { label: "Monta Diecta Grupal A01a", icon: "pi pi-fw pi-bars", to: "/formularioA01a" },
                    {
                        label: 'Formularios solicitados', icon: 'pi pi-fw pi-sitemap',
                            items: [
                                { label: "Formularios FEDEANCO", icon: "pi pi-copy", to: "/formularios" },                                                                                    
                                { label: "Solicitudes", icon: "pi pi-fw pi-bars", to: "/formulariosSolicitados" },
                            ]
                    },
                ]
            }]
        },
        {
            label: 'Mis Datos', icon: 'pi pi-fw pi-sitemap',
            items: [{
                label: 'Ir a', icon: 'pi pi-fw pi-user',
                items: [
                    { label: "Mis Datos Personales", icon: "pi pi-fw pi-bars", to: "/misdatos" },
                 { label: "Ayuda", icon: "pi pi-book", to: "/editor" },
                    // { label: "Mi Aprisco", icon: "pi pi-fw pi-bars", to: "/misAprisco" },
                ]
            }]
        },

    ];

    // const menu = [


    //     {
    //         label: 'UI Components', icon: 'pi pi-fw pi-sitemap',
    //         items: [
    //             { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout' },
    //             { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
    //             { label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel" },
    //             { label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "invalidstate" },
    //             { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button' },
    //             { label: 'Table', icon: 'pi pi-fw pi-table', to: '/table' },
    //             { label: 'List', icon: 'pi pi-fw pi-list', to: '/list' },
    //             { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree' },
    //             { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel' },
    //             { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay' },
    //             { label: "Media", icon: "pi pi-fw pi-image", to: "/media" },
    //             { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu' },
    //             { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages' },
    //             { label: 'File', icon: 'pi pi-fw pi-file', to: '/file' },
    //             { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart' },
    //             { label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc' },
    //         ]
    //     },
    //     {
    //         label: 'UI Blocks',
    //         items: [
    //             { label: 'Free Blocks', icon: 'pi pi-fw pi-eye', to: '/blocks', badge: "NEW" },
    //             { label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: 'https://www.primefaces.org/primeblocks-react' }
    //         ]
    //     },
    //     {
    //         label: 'Icons',
    //         items: [
    //             { label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', to: '/icons' }
    //         ]
    //     },
    //     {
    //         label: 'Pages', icon: 'pi pi-fw pi-clone',
    //         items: [
    //             { label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud' },
    //             { label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline' },
    //             { label: 'Empty', icon: 'pi pi-fw pi-circle-off', to: '/empty' }
    //         ]
    //     },
    //     {
    //         label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
    //         items: [
    //             {
    //                 label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
    //                 items: [
    //                     {
    //                         label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
    //                         items: [
    //                             { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
    //                             { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
    //                             { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
    //                         ]
    //                     },
    //                     {
    //                         label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
    //                         items: [
    //                             { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' },
    //                             { label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark' }
    //                         ]
    //                     },
    //                 ]
    //             },
    //             {
    //                 label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
    //                 items: [
    //                     {
    //                         label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
    //                         items: [
    //                             { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
    //                             { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
    //                             { label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark' },
    //                         ]
    //                     },
    //                     {
    //                         label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
    //                         items: [
    //                             { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
    //                             { label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark' }
    //                         ]
    //                     }
    //                 ]
    //             }
    //         ]
    //     },
    //     {
    //         label: 'Get Started',
    //         items: [
    //             { label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => { window.location = "#/documentation" } },
    //             { label: 'View Source', icon: 'pi pi-fw pi-search', command: () => { window.location = "https://github.com/primefaces/sakai-react" } }
    //         ]
    //     }
    // ];

    /*gerente general */
    const menuPerfil999 = [
        {
            label: '',
            items: [{
                label: '', icon: '', to: '/'
            }]
        }
    ];


    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false,
        'layout-theme-light': layoutColorMode === 'light'
    });

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <AppTopbar onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive}
                onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                onMobileSubTopbarMenuClickSalir={onMobileSubTopbarMenuClickSalir}
                ingresar={ingresar}
                nidPropietario={nidPropietario}
                nombres={nombres}
                idPerfilUsuario={idPerfilUsuario}
                cambiaPassword={cambiaPassword}
                />
            {(menues && idPerfilUsuario!=undefined) ?
            <>
                {menuPrincipal==0 &&
                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuPerfil999} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>
                }
                {menuPrincipal==1 &&
                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuPerfil1} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>
                }
                {menuPrincipal==2 &&
                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuPerfil2} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>
                }
                {menuPrincipal==4 &&
                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuPerfil4} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>
                }
                {menuPrincipal==5 &&
                <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuPerfil5} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
                </div>
                }
            </>
            :
            <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu model={menuPerfil999} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
            </div>
            }


            <div className="layout-main-container">
                <div className="layout-main">
                <AppLogout handleplanta={handleplanta}>
                    <Route path="/" exact render={() => <MisInventarios colorMode={layoutColorMode} location={location} />} />
                    <Route path="/formlayout" component={FormLayoutDemo} />
                    <Route path="/input" component={InputDemo} />
                    <Route path="/floatlabel" component={FloatLabelDemo} />
                    <Route path="/invalidstate" component={InvalidStateDemo} />
                    <Route path="/button" component={ButtonDemo} />
                    <Route path="/table" component={TableDemo} />
                    <Route path="/list" component={ListDemo} />
                    <Route path="/tree" component={TreeDemo} />
                    <Route path="/panel" component={PanelDemo} />
                    <Route path="/overlay" component={OverlayDemo} />
                    <Route path="/media" component={MediaDemo} />
                    <Route path="/menu" component={MenuDemo} />
                    <Route path="/messages" component={MessagesDemo} />
                    {/* <Route path="/blocks" component={BlocksDemo} /> */}
                    <Route path="/icons" component={IconsDemo} />
                    <Route path="/file" component={FileDemo} />
                    <Route path="/chart" render={() => <ChartDemo colorMode={layoutColorMode} location={location} />} />
                    <Route path="/misc" component={MiscDemo} />
                    <Route path="/timeline" component={TimelineDemo} />
                    <Route path="/crud" component={Crud} />
                    <Route path="/cambiarClave" component={CambiarClave} />
                    {/* <Route path="/inicio" component={Inicio} />  */}
                    <Route path="/formularioA01" component={FormularioA01} />
                    <Route path="/formularioA01a" component={FormularioA01a} />
                    <Route path="/formularioA02" component={FormularioA02} />
                    <Route path="/formularioA03" component={FormularioA03} />
                    <Route path="/formularioA04" component={FormularioA04} />
                    <Route path="/formularioA05" component={FormularioA05} />
                    <Route path="/animalesIniciales" component={FormularioA05Iniciales} />
                    <Route path="/formularioA06" component={FormularioA06} />
                    <Route path="/formularioA08" component={FormularioA08} />
                    <Route path="/formularioA09" component={FormularioA09} />
                    <Route path="/formularioA10" component={FormularioA10} />
                    <Route path="/formularioA11" component={FormularioA11} />
                    <Route path="/formularioA12" component={FormularioA12} />
                    <Route path="/formularioA12SubArriendo" component={FormularioA12SubArrendar} />
                    <Route path="/formularioA12SubArriendados" component={FormularioA12SubArrendados} />
                    <Route path="/maestroChips" component={MaestroChips} />
                    <Route path="/maestroConsecutivos" component={MaestroConsecutivos} />
                    <Route path="/maestroConsecutivosUsuario" component={MaestroConsecutivosUsuario} />
                    <Route path="/formularios" component={Formularios} />
                    <Route path="/registradores" component={Registradores} />
                    <Route path="/razas" component={Razas} />
                    <Route path="/inseminadores" component={Inseminadores} />                    
                    <Route path="/terceros" component={Terceros} />
                    <Route path="/prefijos" component={Prefijos} />
                    <Route path="/libros" component={Libros} />
                    <Route path="/asociaciones" component={Asociaciones} />
                    <Route path="/formulariosSolicitados" component={FormulariosSolicitados} />
                    <Route path="/revisados" component={FormulariosRevisados} />
                    <Route path="/paraRevisar" component={FormulariosPorRevisar} />
                    <Route path="/misDatos" component={MisDatos} />
                    <Route path="/misAprisco" component={MisAprisco} />
                    <Route path="/presentacion" component={Presentacion} />
                    {/* <Route path="/enterese" component={Enterese} /> */}
                    <Route path="/empty" component={EmptyPage} />
                    <Route path="/editor" component={EditorContenido} />                    
                    {ingresar &&
                        <Route path="/login">
                         <Login handleplanta={handleplanta} />
                        </Route>
                    }
                    </AppLogout>
                </div>
                {cambiaClave &&
                <ClaveInicial handleclave={handleclave} cargo={dato} />
                }
               
                <AppFooter layoutColorMode={layoutColorMode}  />
            </div>
           
            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
                <div className="layout-mask p-component-overlay"></div>
            </CSSTransition>

        </div>
    );

}

export default App;
