import React, { useState, useEffect, useRef } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import SeleccionFormulariosGeneral from '../components/SeleccionFormulariosGeneral';

import Ayuda from '../components/Ayuda'
const FormulariosSolicitados = () => {
    let emptyProduct = {
        id:0,
        codigo:'',
        total:'',
        nid_propietario:'',
        soporte:'',
    };
    const [file, setFile] = useState(null)
    const [products, setProducts] = useState([]);
    const [productDialog, setProductDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [editar, setEditar] = useState(false)
    const toast = useRef(null);
    const dt = useRef(null);
    const [value1, setValue1] = useState(true)
    const [enviar, setEnviar] = useState(false)
    const [archivo, setArchivo] = useState('')
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [estado, setEstado] = useState(0)
    const [ayuda, setAyuda] = useState(false)    
    const [muestraArchivo,setMuestraArchivo]     = useState(false)   
    const [cuenta, setCuenta] = useState('')
    const [banco, setBanco] = useState('')
    const [verificaEstado, setVerificaEstado] = useState(false)
    const [documentoSoporte,setDocumentoSoporte] = useState(false)
    const [solicitud, setSolicitud] = useState(0)
    const FORMULARIOSSOLICITADOS = 'https://www.ancogenecop.com/APIANCO/controllers/formulariosSolicitados.php?op='
    useEffect(() => {
        if (reloadUsuarios) {
            const recarga = async () => {
                setReloadUsuarios(false)
                const data = new FormData();
                data.append("NID_PROPIETARIO",localStorage.getItem('nid_propietario'))
                await fetch(FORMULARIOSSOLICITADOS + 'formularioSolicitados', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: data
                }).then((response) => response.json())
                    .then((response) => {
                      //  console.log(response);
                        if (response != []) {
                            setEditar(false)
                            setProducts(response)
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
        }
    }, [reloadUsuarios])


    useEffect(() => {
            const recarga = async () => {
                setReloadUsuarios(false)
                
                await fetch(FORMULARIOSSOLICITADOS + 'parametros', {
                    method: 'POST',
                    header: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    body: ''
                }).then((response) => response.json())
                    .then((response) => {
                        console.log(response);
                        if (response != []) {
                            setCuenta(response.subcontenido)
                            setBanco(response.contenido)
                        }
                        setValue1(false)
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
            recarga()
    }, [])


    useEffect(() => {
        const recarga = async () => {
            const data = new FormData();
            data.append("NID_PROPIETARIO",localStorage.getItem('nid_propietario'))
            setVerificaEstado(false)
            await fetch(FORMULARIOSSOLICITADOS + 'verificaEstado', {
                method: 'POST',
                header: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json'
                },
                body: data
            }).then((response) => response.json())
                .then((response) => {
                    console.log(response);
                    if (response === 1) {
                        toast.current.clear();
                        toast.current.show({
                            severity: 'error',
                            summary: 'Está seguro de eliminar este registro?',
                        sticky: true,
                        content: (props) => (
                            <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                            <div className="flex align-items-center gap-2">
                                    {/* <Avatar image="/images/avatar/amyelsner.png" shape="circle" /> */}
                                    <span className="font-bold text-900">{localStorage.getItem('nombreUsuario')}</span>
                                </div>
                                <div className="font-medium text-lg my-3 text-900">{"Esta a punto de borrar la solicitud actual, desea borrarla?"}</div>
                                <div className="formgrid grid">
                                <div className="col">
                                <Button className="p-button-danger flex" label="Si borrela" severity="error" onClick={()=>deleteProduct(product,1)}></Button>
                                </div>
                                <div className="col">
                                <Button className="p-button-success flex" label="Cancelar" severity="error" onClick={()=>deleteProduct(product,2)}></Button>
                                </div>
                                </div>
                            </div>
                        )
                        });
                    }else{
                        setProductDialog(false);
                    }
                  //   
                    //setValue1(false)
                })
                .catch((error) => {
                    console.log(error);
                })
        }
        recarga()
}, [verificaEstado])


    const muestraAyuda = () =>{
        setAyuda(true)
    }

    const hideDeleteProductAyuda = () => {
        setAyuda(false);
    }

    const hideDocumentoSoporte = () => {
        setDocumentoSoporte(false);
    }

    const deleteProductDialogAyuda = (
        <>
            <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductAyuda} />
        </>
    );

    
    
    const openNew = () => {
        setEnviar(true)
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }

    const hideDialog = () => {
        setVerificaEstado(true);
        setSubmitted(false);
        setEstado(0);
       // setProductDialog(false);
    }


    const saveProduct = async() => {
        setSubmitted(true);
        //  if(product.nid_propietario=='') {console.log(2); return}
        //  if(product.total=='') {console.log(3); return}
        //  if(product.soporte=='') {console.log(4); return}
        const data = new FormData();
        data.append("id", product.id)
        data.append("nid_propietario", localStorage.getItem("nid_propietario"));
     //   data.append("file",file)
        await fetch(FORMULARIOSSOLICITADOS + 'ingresarFormularioSolicitados', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response === 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'La solicitud ha sido enviada con exito, debe ingresar el documento soporte, debe consultar el estado de la misma', life: 6000 });
                    setReloadUsuarios(true)
                    setSubmitted(false);
                    setProductDialog(false);
                    setProduct(emptyProduct);
                }else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El Registro no pudo ser Ingresaro o Actualizado, verifique los datos', life: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const saveProductFile = async() => {
        setSubmitted(true);
        //  if(product.nid_propietario=='') {console.log(2); return}
        //  if(product.total=='') {console.log(3); return}
        //  if(product.soporte=='') {console.log(4); return}
        const data = new FormData();
        data.append("consec_solicitud", solicitud);
        data.append("nid_propietario", localStorage.getItem("nid_propietario"));
        data.append("file",file)
        await fetch(FORMULARIOSSOLICITADOS + 'ingresarArchivo', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
                if (response === 1) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'El documento soporte ha diso enviado con exito, debe consultar el estado de su solicitud', life: 5000 });
                    setReloadUsuarios(true)
                    setDocumentoSoporte(false)
                    setSubmitted(false);
                    setProduct(emptyProduct);
                }else if (response === 100) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El archivo no es de tipo .jpg, verifique por favor', life: 5000 });
                }else if(response===99){

                }else{ 
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'El tamaño del archivo es superior a 5MB, verifique por favor', life: 5000 });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const editProduct = (product) => {
        setArchivo(product.soporte)
     //   product.soporte=''
        setEnviar(false)
        setEditar(true)
        setProduct({ ...product });
        setEstado(product.estado)
        setProductDialog(true);
    }

    const docSoporte = (product) => {
        setSolicitud(product.consec_solicitud)
     //   product.soporte=''
        setDocumentoSoporte(true)
    }

    const deleteProduct = async(product,opc) => {
        if(opc===1){
            toast.current.clear();
            setProductDialog(false);
            const data = new FormData();
            data.append("nid_propietario", localStorage.getItem("nid_propietario"));
            await fetch(FORMULARIOSSOLICITADOS + 'borrarEstado', {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: data
        }).then((response) => response.json())
            .then((response) => {
            console.log(response)
                if (response === 0) {
                    toast.current.show({ severity: 'success', summary: 'Atención!!"', detail: 'La solicitud ha sido borrada de forma exitosa!!!', life: 3000 });
                    setProductDialog(false);
                    return
                }else{
                    toast.current.show({ severity: 'warn', summary: 'Atención!!"', detail: 'Se presento un error, intentelo más tarde!!!', life: 3000 });
                    return
                }
            })
            .catch((error) => {
                console.log(error);
            })
        }else{
            toast.current.clear();
            //setProductDialog(false);
        }
    }




    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                 <div className="formgrid grid">
                    <Button label="Adicionar" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <h4 style={{color:"Red"}} >{"El valor de los formularios debe ser consignado en "+banco+" cuenta "+cuenta}</h4>
                    
                    {/* <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} /> */}
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" /> */}
                <Button label="Ayuda" icon="pi pi-question-circle" className="p-button-help" onClick={() => muestraAyuda()} />
            </React.Fragment>
        )
    }


    const actionBodyTemplate = (rowData) => {
        console.log(rowData.estado);
        return (
            <div className="actions">
                {(rowData.estado==1 ||  rowData.estado==2 ) &&
                <>
                    <Button icon="pi pi-search" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} 
                     tooltip='Consultar la solicitud'
                     tooltipOptions={{
                         className: "hoverClass",
                         position: "right"
                 }}/>
                    <Button icon="pi pi-book" className="p-button-rounded p-button-info mr-2" onClick={() => docSoporte(rowData)} 
                     tooltip='Cargar documento soporte'
                     tooltipOptions={{
                         className: "hoverClass",
                         position: "right"
                 }}/>
                </>
                }
                {(rowData.estado==3 ) &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-danger mr-2" onClick={() => editProduct(rowData)} 
                    tooltip='Consultar solicitud de formularios'
                    tooltipOptions={{
                        className: "hoverClass",
                        position: "right"
                }}/>
                }
                {(rowData.estado==4) &&
                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-info mr-2" onClick={() => editProduct(rowData)} 
                    tooltip='Consultar solicitud de formularios'
                    tooltipOptions={{
                        className: "hoverClass",
                        position: "right"
                }}/>
                }
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Formularios Solicitados</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
    {(estado==1  ||  estado==2 ||  estado==4 ) &&
    <Button label="Cerrar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
    }
      
     {(estado==0) && 
     <div>
     <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
     <Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
     </div>
     }
      </>
            

    );

    const cerrarDocumentoSoporte = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDocumentoSoporte} />
     <      Button label="Enviar" icon="pi pi-check" className="p-button-text" onClick={saveProductFile} />
        </>
    );

    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${rowData.soporte}`} alt={rowData.soporte} className="shadow-2" width="100"
                onClick={()=>onLoadingClick(rowData.soporte)}/>
            </>
        )
    }

    const onLoadingClick = (soporte) => {
        window.open(soporte, "_blank")
    }


    const handleplanta = async (dato) => {
     //   console.log(dato);
        setSubmitted(false)
        setMuestraArchivo(dato)
    }
    const handleChange = (e) => {
        setFile(e.target.files[0]);
        product.soporte=e.target.files[0].name;
      };

    return (
    <>
        {value1 &&
            <div className="formgrid grid">
                 <div className="field col">
                   <h5>Consultado información por favor espere</h5>
                       <div className="col">
                       <ProgressBar mode="indeterminate" style={{ height: '16px', width:'auto' }}></ProgressBar>
                   </div>
               </div>
           </div>
           }
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <div className="formgrid grid">
                        <div className="field col-12">
                        <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} de {last} en {totalRecords} products"
                        globalFilter={globalFilter} emptyMessage="No se encontraron registros." header={header} responsiveLayout="scroll">
                        <Column body={actionBodyTemplate}></Column>
                        <Column field="consec_solicitud" header="No. Solicitud" sortable ></Column>
                        <Column field="nid_propietario" header="NID_SOLICITANTE" sortable ></Column>
                        <Column field="NOMBRE" header="SOLICITANTE" sortable ></Column>
                        <Column field="total" header="No. FORMULARIOS" sortable ></Column>
                        <Column field="valor" header="VALOR" sortable ></Column>
                        <Column header="DOC. SOPORTE" body={imageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="MESTADO" header="ESTADO" sortable ></Column>
                        <Column field="fecha" header="FECHA DE SOLICITUD" sortable ></Column>
                        <Column field="fecha_aprobacion" header="FECHA DE APROBACION" sortable ></Column>
                    </DataTable>
                    </div>
                    {/* <div className="field col-3"><Lateral/>

                    </div> */}
                    </div>

                    <Dialog visible={productDialog} style={{ width: '650px' }} header="Detalle de la Solicitud" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                    <div className="formgrid grid">
                        <div className="field col">
                            <div>
                            {/* <label htmlFor="name">Formulario</label> */}
                            <SeleccionFormulariosGeneral  handleplanta={handleplanta} consec_solicitud={product.consec_solicitud} estado={product.estado}
                            required
                            autoFocus
                            />
                            
                            </div>
                        </div>
                       
                    </div>
                        

                    </Dialog>
                    <Dialog visible={documentoSoporte} style={{ width: '600px', height:'200px'}} header="Documento Soporte" modal footer={cerrarDocumentoSoporte} onHide={hideDocumentoSoporte}>
                    <div className="formgrid grid">
                            <div className="formgrid grid">
                                    <label htmlFor="dirección">Documentos Soporte</label>
                                    <div className="col">
                                        <input type="file" className="custom-file-input" id="customFileLang" lang="es"
                                            accept="jpg/*"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {submitted && !product.soporte && <small className="p-invalid"
                                    style={{
                                    color: 'red',
                                    }}>El documento soporte es obligatorio.</small>}
                                    {!enviar &&
                                    <div className="field col">
                                        <img src={`${archivo}`} alt={archivo} className="shadow-2" width="100"
                                        onClick={()=>onLoadingClick(archivo)}/>
                                    </div>
                                    }
                                </div>
                            <div className="formgrid grid">
                              {product.estado==3 &&
                                    <div className="col"><h4>CAUSAL DE RECHAZO: <b>{product.observaciones}</b></h4>
                                        
                                        </div>
                                }      
                                </div>                                           
                        </div>                   
                    </Dialog> 
                    <Dialog visible={ayuda} style={{ width: '300px', height:'200px'}} header="Solicitud de formularios" modal footer={deleteProductDialogAyuda} onHide={hideDeleteProductAyuda}>
                        <Ayuda formulario={'4'} />                    
                    </Dialog>                    
                </div>
            </div>
        </div>
        </>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(FormulariosSolicitados, comparisonFn);
